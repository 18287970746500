import arrify from 'arrify';

import generateCube from '@/utils/generateCube'
import generateTextByType from '@/utils/generateTextByType'

const generateHitText = hit => arrify(hit)
  .map(
    hitItem => {
      if (typeof hitItem === 'object') {
        return arrify(hitItem)
          .map(
            hitObj => {
              const text = generateTextByType(hitObj)
              const cube = generateCube(hitObj)
              const preText = hitObj.preText || ''
              const comment = hitObj.comment || ''
              const twoHandText = hitObj.use2Hand
                ? ' при ударе двумя руками'
                : ''

              return `${preText}${text} ${cube}${twoHandText}${comment}`
            }
          )
          .join(' или ')
      } else if (typeof hitItem === 'string') {
        return hitItem
      } else {
        return ''
      }
    }
  )
  .join(' плюс ')

export default generateHitText
