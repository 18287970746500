const {
  CREATURE_GOBLIN,
} = require('./../../../creatureIdList')

const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_MM,
} = require('./../../../sourceList')

const {
  Goblins_Band_DMG_data,
  Goblins_bugbears_AYAGWnW_data,
  Goblins_VGTM_data,
} = require('./../../commonImageCollection')

const Goblin_ADND2 = require('./../../../../images/creatures/adnd2_mm/Goblin.jpg')
const Goblin_img_AYAGMnC = require('./../../../../images/creatures/ayagmc/goblin.jpg')
const Goblin_img_MM = require('./../../../../images/creatures/mm/goblin.jpg')

module.exports = [
  {
    src: Goblin_img_MM,
    creatureId: CREATURE_GOBLIN,
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Goblin_img_AYAGMnC,
    creatureId: CREATURE_GOBLIN,
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  Goblins_bugbears_AYAGWnW_data,
  Goblins_VGTM_data,
  Goblins_Band_DMG_data,
  {
    src: Goblin_ADND2,
    creatureId: CREATURE_GOBLIN,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
