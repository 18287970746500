import React from "react"

import {crCollection} from "@/constants/crList"

import StatInfoRow from "@/components/StatInfoRow";

const CreatureCr = ({cr}) => {
  if (cr) {
    const {name, exp} = crCollection[cr]

    return (
      <StatInfoRow header='Опасность'>
        {name} ({exp} опыта)
      </StatInfoRow>
    )
  }

  return null
}

export default CreatureCr
