import arrify from "arrify"
import React from "react"

import CreatureAc from "@/components/CreatureAc"
import HitPoints from "@/components/HitPoints"

import AbilityList from '@/components/AbilityList'

import generateAbilityFromAction from "./../../utils/generateAbilityFromAction"

import './WeaponListStyles.less'

const WeaponList = ({weaponList, ...rest}) => {
  if (weaponList && weaponList.length) {
    return (
      <section className='WeaponList'>
        {
          weaponList.map(
            ({ac, name, hp, damageThreshold, count, action }, i) => (
              <section
                className='WeaponList_item'
                key={i}
              >
                <header className='WeaponList_header'>
                  Оружие: {name}
                  {
                    count
                      ? ` (${count})`
                      : ''
                  }
                </header>
                <div className='WeaponList_content'>
                  <CreatureAc armor={ac} />
                  <HitPoints
                    damageThreshold={damageThreshold}
                    hp={hp}
                  />

                  <AbilityList
                    list={arrify(generateAbilityFromAction(action))}
                    {...rest}
                  />
                </div>
              </section>
            )
          )
        }
      </section>
    )
  }

  return null
}

export default WeaponList
