import {
  CREATURE_ACOLYTE,
  CREATURE_MAGE,
  CREATURE_SPY,
  CREATURE_THUG,
  CREATURE_ARCHER,
  CREATURE_ENCHANTER,
  CREATURE_SWASHBUCKLER,
} from '@/constants/creatureIdList'
import {SOURCE_AI} from '@/constants/sourceList'

export default {
  templateName: 'Образцовый агент Благородных Ножей',
  templateIcon: 'NobleKnife',
  templateColor: 'black',
  templateLimitations: {
    include: {
      id: [
        CREATURE_ACOLYTE,
        CREATURE_MAGE,
        CREATURE_SPY,
        CREATURE_THUG,
        CREATURE_ARCHER,
        CREATURE_ENCHANTER,
        CREATURE_SWASHBUCKLER,
      ],
    },
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_AI,
      page: 215,
    }],
    featureList: [
      {
        name: `Благородный удар`,
        limit: {
          count: 1,
          period: 'день',
        },
        description: `Когда ★СУЩЕСТВО★ в следующий раз попадёт по цели атакой, ★он★ может бонусным действием испугать цель. Испуганная цель может проходить испытание Телосложения СЛ 13 в конце каждого своего хода, оканчивая эффект при успехе.`,
      },
      {
        name: `Готов к бою`,
        description: `★СУЩЕСТВО★ получает преимущество к атакам по любому застанному врасплох существу.`,
      },
    ],
    reactionList: [
      {
        name: `Пронзание реальности`,
        limit: {
          count: 1,
          period: 'день',
        },
        description: `Когда по ★СУЩЕСТВО★ попадают атакой, ★он★ может реакцией переместиться на расстояние, не превышающее ★его★ скорость, не провоцируя атак по возможности, после чего совершить одну рукопашную атаку оружием.`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name}, агент Благородных Ножей`,
  },
}
