const {
  Githzerai_Anarch_MTOF_data,
  Githzerai_Enlightened_MTOF_data,
} = require('./../../commonImageCollection')
const {
  SOURCE_ADND2_MM,
  SOURCE_MGZN_DRGN_378,
  SOURCE_MM,
} = require('./../../../sourceList')
const Githzerai_ADND2 = require('./../../../../images/creatures/adnd2_mm/Githzerai.jpg')
const Githzerai_MM_img = require('./../../../../images/creatures/mm/githzerai.jpg')
const Githzerai_MGZN_DRGN_378_img = require('./../../../../images/races/githzerai-MGZN_DRGN_378.jpg')

module.exports = [
  {
    src: Githzerai_MM_img,
    text: 'Гитцерай',
    source: {
      id: SOURCE_MM,
    },
  },
  Githzerai_Anarch_MTOF_data,
  Githzerai_Enlightened_MTOF_data,
  {
    src: Githzerai_ADND2,
    text: 'Гитцерай',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  {
    src: Githzerai_MGZN_DRGN_378_img,
    text: 'Гитцераи',
    source: {
      id: SOURCE_MGZN_DRGN_378,
    },
  },
]
