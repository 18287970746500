import {faSwimmer} from '@fortawesome/free-solid-svg-icons'
import {SPEED_SWIM} from '@/constants/speedList'
import {CREATURE_GIANT_LIZARD} from '@/constants/creatureIdList'
import {ABILITY_HOLD_BREATH} from '@/constants/creatureAbilityList'

import giantLizardDescription from './constants/giantLizardDescription'

export default {
  templateName: 'Плавающая гигантская ящерица',
  templateIcon: faSwimmer,
  templateColor: 'navy',

  templateLimitations: {
    include: {
      id: [
        CREATURE_GIANT_LIZARD,
      ],
    },
  },

  extendPropCollection: {
    description: giantLizardDescription,
    speed: {
      [SPEED_SWIM]: 30,
    },
    featureList: [
      {
        id: ABILITY_HOLD_BREATH,
        time: '15 минут',
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Плавающая ${name.toLowerCase()}`,
  },
}
