const calcDiceAverage = (diceFormula) => {
  if (typeof diceFormula === 'object') {
    const {
      diceType = 0,
      diceCount = 0,
      diceBonus = 0,
      useMax = false,
    } = diceFormula

    const diceBonusNum = typeof diceBonus === 'number'
      ? diceBonus
      : 0

    return useMax
      ? diceType * diceCount + diceBonusNum
      : Math.max(
        diceCount + diceBonusNum + Math.floor((diceType * diceCount - diceCount) / 2),
        0,
      )
  } else if (typeof diceFormula === 'number') {
    return diceFormula
  } else {
    return 0
  }
}

export default calcDiceAverage
