import summonerAmnizu from './summonerAmnizu'
import summonerGeryon from './summonerGeryon'
import summonerInquisitor from './summonerInquisitor'
import summonerMolydeus from './summonerMolydeus'
import summonerShadowblade from './summonerShadowblade'

export default [
  summonerAmnizu,
  summonerGeryon,
  summonerInquisitor,
  summonerMolydeus,
  summonerShadowblade,
]
