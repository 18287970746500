import { CAST_MATERIAL, CAST_SOMATIC, CAST_VERBAL, castComponentCollection } from '@/constants/castComponentList'
import {FILTER_TYPE_SELECT} from '@/constants/filterTypes'

import upLetter from '@/utils/upLetter'

export default [
  {
    id: CAST_VERBAL,
    propName: 'hasVerbalComponent',
  },
  {
    id: CAST_SOMATIC,
    propName: 'hasSomaticComponent',
  },
  {
    id: CAST_MATERIAL,
    propName: 'hasMaterialComponent',
  },
]
  .reduce(
    (result, { id, propName }) => {
      const { name: { singular: { nominative: name } }, description } = castComponentCollection[id]

      return {
        ...result,
        children: [
          ...(result.children || []),
          {
            label: upLetter(name),
            title: description,
            type: FILTER_TYPE_SELECT,
            propName,
            value: '',
            list: [
              {
                value: '',
                text: 'Неважно',
              },
              {
                value: true,
                text: 'Да',
              },
              {
                value: false,
                text: 'Нет',
              },
            ],
          },
        ],
      }
    },
    {header: 'Типы компонентов'},
  )
