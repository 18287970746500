import arrify from 'arrify'
import React from "react"
import PropTypes from "prop-types"

import {paramCollection} from "@/constants/paramList"

import formatParamBonus from "@/utils/formatParamBonus"

import "./ParamStyles.less"

const ParamComponent = ({ id, value, hideModifier }) => {
  const bonus = formatParamBonus(value)
  const {shortName, name, description} = paramCollection[id]
  const descriptionItem = arrify(description)[0]
  const descriptionText = typeof descriptionItem === 'string'
    ? descriptionItem
    : descriptionItem.text
  const title = `${name}\n\n${descriptionText}`
  const text = hideModifier
    ? value
    : `${value} (${bonus})`

  return (
    <span
      className='Param'
      title={title}
    >
      <b className='Param_header'>
        {shortName}
      </b>
      <span className='Param_value'>
        {text}
      </span>
    </span>
  )
}

ParamComponent.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  hideModifier: PropTypes.bool,
}

ParamComponent.defaultProps = {
  hideModifier: false,
}

export default ParamComponent
