import {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_UNDEAD,
} from '@/constants/creatureTypeIdList'
import {
  PARAM_STR,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} from '@/constants/paramList'
import {
  CONDITION_EXHAUSTION,
  CONDITION_POISONED,
} from '@/constants/conditionList'
import {ABILITY_UNDEAD_FORTITUDE} from '@/constants/creatureAbilityList'
import {DAMAGE_POISON} from '@/constants/damageTypeList'
import {faSkull} from '@fortawesome/free-solid-svg-icons'
import {LANG_ALL_KNOWN_AT_LIFE} from '@/constants/languageIdList'
import {SENSE_DARK_VISION} from '@/constants/senseList'
import {SOURCE_DMG} from '@/constants/sourceList'

export default {
  templateName: 'Зомби',
  templateIcon: faSkull,
  templateColor: 'green',
  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_ANY_RACE,
        CREATURE_TYPE_BEAST,
        CREATURE_TYPE_HUMANOID,
      ],
    },
  },

  creatureTypeIdList: [
    CREATURE_TYPE_UNDEAD,
  ],
  languageList: [
    LANG_ALL_KNOWN_AT_LIFE,
  ],

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 283,
    }],
    featureList: [
      {
        id: ABILITY_UNDEAD_FORTITUDE,
        damageType: `излучением`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Зомби ${name}`,
    params: ({ params }) => ({
      ...params,
      [PARAM_STR]: params[PARAM_STR] + 1,
      [PARAM_CON]: params[PARAM_CON] + 2,
      [PARAM_INT]: params[PARAM_INT] - 6,
      [PARAM_WIT]: params[PARAM_WIT] - 4,
      [PARAM_CHA]: params[PARAM_INT] - 4,
    }),
    immunityList: ({ immunityList = [] }) => [
      ...immunityList.filter(
        damageTypeId => damageTypeId !== DAMAGE_POISON
      ),
      DAMAGE_POISON,
    ].sort(),
    immunityConditionList: ({ immunityConditionList = [] }) => [
      ...immunityConditionList.filter(
        conditionTypeId => !(
          conditionTypeId !== CONDITION_EXHAUSTION
          || conditionTypeId !== CONDITION_POISONED
        )
      ),
      CONDITION_EXHAUSTION,
      CONDITION_POISONED,
    ].sort(),
    senseList: ({ senseList = [] }) => [
      ...senseList.filter(
        ({id}) => id !== SENSE_DARK_VISION
      ),
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
  },
}
