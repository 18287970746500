const listToCollectionById = require('./../../../utils/listToCollectionById')
const {
  SOURCE_EE,
  SOURCE_MTG_CLB,
  SOURCE_XGTE,
} = require('./../../sourceList')
const {
  SPELL_ABSORB_ELEMENTS,
  SPELL_BONES_OF_THE_EARTH,
  SPELL_CONTROL_FLAMES,
  SPELL_DANSE_MACABRE,
  SPELL_EARTH_TREMOR,
  SPELL_FIND_GREATER_STEED,
  SPELL_WALL_OF_FIRE,
  SPELL_INFERNAL_CALLING,
  SPELL_MAGE_HAND,
  SPELL_MELF_S_MINUTE_METEORS,
  SPELL_MIGHTY_FORTRESS,
  SPELL_SHADOW_BLADE,
  SPELL_SHAPE_WATER,
  SPELL_STORM_SPHERE,
  SPELL_WHIRLWIND,
} = require('./../../spellIdList')

const Danse_macabre_PHB_img = require('./../../../images/spells/xgte/danse_macabre.jpg')
const Find_greater_steed_PHB_img = require('./../../../images/spells/xgte/find_greater_steed.jpg')
const Fire_wall_PHB_img = require('./../../../images/spells/xgte/fire_wall.jpg')
const Infernal_calling_PHB_img = require('./../../../images/spells/xgte/infernal_calling.jpg')
const Magic_hand_PHB_img = require('./../../../images/spells/xgte/magic_hand.jpg')
const Magic_hand_2_PHB_img = require('./../../../images/spells/xgte/magic_hand_2.jpg')
const Mighty_fortress_PHB_img = require('./../../../images/spells/xgte/mighty_fortress.jpg')
const Shadow_blade_PHB_img = require('./../../../images/spells/xgte/shadow_blade.jpg')
const Storm_sphere_PHB_img = require('./../../../images/spells/xgte/storm_sphere.jpg')
const Bones_of_the_earth_XGTE_img = require('./../../../images/spells/xgte/bones_of_the_earth.png')
const Spell_shape_water_XGTE_img = require('./../../../images/spells/xgte/shape_water.png')
const Whirlwind_XGTE_img = require('./../../../images/spells/xgte/whirlwind.jpg')
const Control_flames_XGTE_img = require('./../../../images/spells/xgte/control_flames.png')
const Melfs_minute_meteors_XGTE_img = require('./../../../images/spells/xgte/melfs_minute_meteors.png')
const Absorb_elements_XGTE_img = require('./../../../images/spells/xgte/absorb_elements.jpg')
const Earth_tremor_MTG_CLB_img = require('./../../../images/spells/xgte/earth_tremor_mtg_clb.webp')

const spellImageList_XGTE = [
  {
    id: SPELL_EARTH_TREMOR,
    src: Earth_tremor_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_DANSE_MACABRE,
    src: Danse_macabre_PHB_img,
  },
  {
    id: SPELL_FIND_GREATER_STEED,
    src: Find_greater_steed_PHB_img,
  },
  {
    id: SPELL_WALL_OF_FIRE,
    src: Fire_wall_PHB_img,
  },
  {
    id: SPELL_INFERNAL_CALLING,
    src: Infernal_calling_PHB_img,
  },
  {
    id: SPELL_MAGE_HAND,
    src: Magic_hand_PHB_img,
  },
  {
    id: SPELL_MAGE_HAND,
    src: Magic_hand_2_PHB_img,
  },
  {
    id: SPELL_MIGHTY_FORTRESS,
    src: Mighty_fortress_PHB_img,
  },
  {
    id: SPELL_SHADOW_BLADE,
    src: Shadow_blade_PHB_img,
  },
  {
    id: SPELL_STORM_SPHERE,
    src: Storm_sphere_PHB_img,
  },
  {
    id: SPELL_BONES_OF_THE_EARTH,
    src: Bones_of_the_earth_XGTE_img,
    source: {
      id: SOURCE_EE,
      page: 20,
    },
  },
  {
    id: SPELL_SHAPE_WATER,
    src: Spell_shape_water_XGTE_img,
    source: {
      id: SOURCE_EE,
      page: 24,
    },
  },
  {
    id: SPELL_WHIRLWIND,
    src: Whirlwind_XGTE_img,
    source: {
      id: SOURCE_EE,
      page: 23,
    },
  },
  {
    id: SPELL_CONTROL_FLAMES,
    src: Control_flames_XGTE_img,
    source: {
      id: SOURCE_EE,
      page: 15,
    },
  },
  {
    id: SPELL_MELF_S_MINUTE_METEORS,
    src: Melfs_minute_meteors_XGTE_img,
    source: {
      id: SOURCE_EE,
      page: 14,
    },
  },
  {
    id: SPELL_ABSORB_ELEMENTS,
    src: Absorb_elements_XGTE_img,
    source: {
      id: SOURCE_EE,
      page: 13,
    },
  },
]
  .map(
    spell => ({
      source: {
        id: SOURCE_XGTE,
      },
      ...spell,
    }),
  )

module.exports = listToCollectionById(spellImageList_XGTE)
