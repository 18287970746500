import {
  DAMAGE_COLD,
  DAMAGE_PIERCING,
} from '@/constants/damageTypeList'
import generateTextLinks from '@/utils/generateTextLinks'
import {ATTACK_MELEE_WEAPON} from '@/constants/attackTypeList'
import {CREATURE_ICE_DEVIL} from '@/constants/creatureIdList'
import {faCandyCane} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Ледяной дьявол c копьём',
  templateIcon: faCandyCane,
  templateColor: 'steelBlue',

  templateLimitations: {
    include: {
      id: [
        CREATURE_ICE_DEVIL,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 149,
    }],
    description: [
      {
        header: 'Вариант: Копья ледяных дьяволов',
        text: generateTextLinks(`Некоторые [ледяные дьяволы](CREATURE:${CREATURE_ICE_DEVIL}) обладают следующими вариантами действий:

**Мультиатака.** Дьявол совершает две атаки: одну ледяным копьём, и одну хвостом.

**Ледяное копьё.** Рукопашная атака оружием: +10 к попаданию, досягаемость 10 фт., одна цель. Попадание: Колющий урон 14 (2к8 + 5) плюс урон холодом 10 (3к6). Если цель — существо, она должна пройти испытание Телосложения СЛ 15, иначе её скорость на 1 минуту снижается на 10 футов; в каждом своём ходу она сможет совершать либо действие, либо бонусное действие, но не то и другое; и оно не может совершать реакции. Цель может повторять испытание в конце каждого своего хода, при успехе завершая этот эффект на себе.`),
        source: {
          id: SOURCE_MM,
          page: 149,
        },
      },
    ],
    actionList: [
      {
        name: 'Ледяное копьё',
        description: `Если цель — существо, она должна пройти испытание Телосложения СЛ 15, иначе её скорость на 1 минуту снижается на 10 футов; в каждом своём ходу она сможет совершать либо действие, либо бонусное действие, но не то и другое; и оно не может совершать реакции. Цель может повторять испытание в конце каждого своего хода, при успехе завершая этот эффект на себе.`,
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 10,
          range: 10,
          hit: [
            {
              type: DAMAGE_PIERCING,
              diceCount: 2,
              diceType: 8,
              diceBonus: 5,
            },
            {
              type: DAMAGE_COLD,
              diceCount: 3,
              diceType: 6,
            },
          ],
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace('дьявол', 'дьявол c копьём'),
    actionList: ({ actionList }) => actionList.map(
      action => action.name === 'Мультиатака'
        ? {
          ...action,
          description: `Дьявол совершает две атаки: одну ледяным копьём и одну хвостом. Он может совершить одну атаку укусом и одну когтями вместо атаки ледяным копьём.`,
        }
        : action,
    ),
  },
}
