const {
  CREATURE_GIFF,
} = require('./../../../creatureIdList')

const {SOURCE_ADND2_MM} = require('./../../../sourceList')
const {Giff_MTOF_data} = require('./../../commonImageCollection')

const Giff_ADND2_MM_img = require('./../../../../images/creatures/adnd2_mm/Giff.jpg')

module.exports = [
  Giff_MTOF_data,
  {
    src: Giff_ADND2_MM_img,
    creatureId: CREATURE_GIFF,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
