import {creatureTypeCollection} from "@/constants/creatureTypeList"

export default ({id, isSwarm = false, genderId}) => {
  const quantityName = isSwarm
    ? 'plural'
    : 'singular'
  const caseName = isSwarm
    ? 'genitive'
    : 'nominative'

  const nameObj = creatureTypeCollection[id].name[quantityName]

  return quantityName === 'plural'
    ? nameObj[caseName]
    : nameObj[genderId][caseName]
}
