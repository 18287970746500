import aarakokra from './aarakokra'
import dwarf from './dwarf'
import elfShadow from './elfShadow'
import gnoll from './gnoll'
import gnome from './gnome'
import halfling from './halfling'
import halforc from './halforc'
import human from './human'
import kobold from './kobold'
import orc from './orc'
import skeleton from './skeleton'
import svirfneblin from './svirfneblin'
import tiefling from './tiefling'
import zombie from './zombie'

export default [
  aarakokra,
  dwarf,
  elfShadow,
  gnoll,
  gnome,
  halfling,
  halforc,
  human,
  kobold,
  orc,
  skeleton,
  svirfneblin,
  tiefling,
  zombie,
]
