const {
  CREATURE_LAMIA,
  CREATURE_LEMURE,
  CREATURE_LICH,
  CREATURE_LIZARD,
  CREATURE_LIZARDFOLK,
  CREATURE_LIZARDFOLK_KING_QUEEN,
  CREATURE_LIZARDFOLK_SHAMAN,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_DMG,
  SOURCE_TCoE,
} = require('./../../../../sourceList')

const {
  Lizardfolk_MM_data,
  Lizardfolk_VGTM_data,
} = require('./../../../commonImageCollection')

const {
  LizardFolksList,
} = require('./../../../commonImageListCollection')

const lamia = require('./../../../../../images/creatures/mm/lamia.jpg')
const lemure = require('./../../../../../images/creatures/mm/lemure.jpg')
const lich = require('./../../../../../images/creatures/mm/lich.jpeg')

const Lamia_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Lamia.jpg')
const Lich_2_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Lich_2.jpg')
const Lich_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Lich.jpg')
const Lizard_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Lizard.jpg')
const Lizard_Man_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Lizard_Man.jpg')

const Lich_img_TCoE = require('./../../../../../images/creatures/tcoe/lich.png')

const Lich_DMG_img = require('./../../../../../images/creatures/dmg/lich.png')
const Lich_Acererak_DMG_img = require('./../../../../../images/creatures/dmg/lich-acererak.jpg')

module.exports = {
  [CREATURE_LAMIA]: [
    lamia,
    {
      src: Lamia_ADND2,
      source: SOURCE_ADND2_MM,
    },
  ],
  [CREATURE_LEMURE]: lemure,
  [CREATURE_LICH]: [
    lich,
    {
      src: Lich_DMG_img,
      source: SOURCE_DMG,
    },
    {
      src: Lich_Acererak_DMG_img,
      text: `Лич Ацерерак поднимает армию нежити и готовится натравить её на ничего не подозревающий мир`,
      source: SOURCE_DMG,
    },
    {
      src: Lich_img_TCoE,
      text: 'Лич Азалин шпионит за замком Равенлофт',
      source: SOURCE_TCoE,
    },
    {
      src: Lich_ADND2,
      source: SOURCE_ADND2_MM,
    },
    {
      src: Lich_2_ADND2,
      source: SOURCE_ADND2_MM,
    },
  ],
  [CREATURE_LIZARD]: {
    src: Lizard_ADND2,
    source: SOURCE_ADND2_MM,
  },
  [CREATURE_LIZARDFOLK]: LizardFolksList,
  [CREATURE_LIZARDFOLK_SHAMAN]: LizardFolksList,
  [CREATURE_LIZARDFOLK_KING_QUEEN]: LizardFolksList,
}
