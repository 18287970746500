const {
  CREATURE_DRAGON_CULT_FANATIC,
  CREATURE_DRAGON_CULT_INITIATE,
  CREATURE_DRAGONCLAW,
  CREATURE_DRAGONFANG_BLACK,
  CREATURE_DRAGONFANG_BLUE,
  CREATURE_DRAGONFANG_GREEN,
  CREATURE_DRAGONFANG_RED,
  CREATURE_DRAGONFANG_WHITE,
  CREATURE_DRAGONSOUL_BLACK,
  CREATURE_DRAGONSOUL_BLUE,
  CREATURE_DRAGONSOUL_GREEN,
  CREATURE_DRAGONSOUL_RED,
  CREATURE_DRAGONSOUL_WHITE,
  CREATURE_DRAGONWING_BLACK,
  CREATURE_DRAGONWING_BLUE,
  CREATURE_DRAGONWING_GREEN,
  CREATURE_DRAGONWING_RED,
  CREATURE_DRAGONWING_WHITE,
  CREATURE_TIAMAT,
} = require('./../../creatureIdList')
const {
  CultOfDragonList,
} = require('./../commonImageListCollection')
const {
  Tiamat_ROT_data,
  Tiamat_Emergence_from_Avernus_data,
  Tiamat_MTG_AFR_data,
  Tiamat_alt_MTG_AFR_data,
} = require('./../commonImageCollection')

module.exports = {
  [CREATURE_DRAGON_CULT_FANATIC]: CultOfDragonList,
  [CREATURE_DRAGON_CULT_INITIATE]: CultOfDragonList,
  [CREATURE_DRAGONCLAW]: CultOfDragonList,
  [CREATURE_DRAGONFANG_BLACK]: CultOfDragonList,
  [CREATURE_DRAGONFANG_BLUE]: CultOfDragonList,
  [CREATURE_DRAGONFANG_GREEN]: CultOfDragonList,
  [CREATURE_DRAGONFANG_RED]: CultOfDragonList,
  [CREATURE_DRAGONFANG_WHITE]: CultOfDragonList,
  [CREATURE_DRAGONSOUL_BLACK]: CultOfDragonList,
  [CREATURE_DRAGONSOUL_BLUE]: CultOfDragonList,
  [CREATURE_DRAGONSOUL_GREEN]: CultOfDragonList,
  [CREATURE_DRAGONSOUL_RED]: CultOfDragonList,
  [CREATURE_DRAGONSOUL_WHITE]: CultOfDragonList,
  [CREATURE_DRAGONWING_BLACK]: CultOfDragonList,
  [CREATURE_DRAGONWING_BLUE]: CultOfDragonList,
  [CREATURE_DRAGONWING_GREEN]: CultOfDragonList,
  [CREATURE_DRAGONWING_RED]: CultOfDragonList,
  [CREATURE_DRAGONWING_WHITE]: CultOfDragonList,
  [CREATURE_TIAMAT]: [
    Tiamat_ROT_data,
    Tiamat_Emergence_from_Avernus_data,
    Tiamat_MTG_AFR_data,
    Tiamat_alt_MTG_AFR_data,
  ],
}
