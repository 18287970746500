import {
  LANG_COMMON,
  LANG_ORC,
} from '@/constants/languageIdList'
import {
  PARAM_CON,
  PARAM_STR,
} from '@/constants/paramList'
import {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HALF_ORC,
} from '@/constants/creatureTypeIdList'
import {faFistRaised} from '@fortawesome/free-solid-svg-icons'
import {SENSE_DARK_VISION} from '@/constants/senseList'
import {SOURCE_DMG} from '@/constants/sourceList'

export default {
  templateName: 'Полуорк',
  templateIcon: faFistRaised,
  templateColor: 'grey',
  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_ANY_RACE,
      ],
    },
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 283,
    }],
    languageList: [
      LANG_COMMON,
      LANG_ORC,
    ],
    featureList: [
      {
        name: `Непоколебимая стойкость`,
        limit: {
          count: 1,
          period: 'день',
        },
        description: `Если хиты ★СУЩЕСТВО★ опустились до нуля, но ★СУЩЕСТВО★ при этом не ★убит★, ★его★ хиты вместо этого опускаются до 1.`,
      },
    ],
  },

  // TODO: владение навыком Запугивание;
  editPropCollection: {
    name: ({ name }) => `Полуорк ${name}`,
    creatureTypeIdList: ({ creatureTypeIdList }) => creatureTypeIdList.map(
      creatureTypeId => creatureTypeId === CREATURE_TYPE_ANY_RACE
        ? CREATURE_TYPE_HALF_ORC
        : creatureTypeId
    ),
    params: ({ params }) => ({
      ...params,
      [PARAM_STR]: params[PARAM_STR] + 2,
      [PARAM_CON]: params[PARAM_CON] + 1,
    }),
    senseList: ({ senseList = [] }) => [
      ...senseList.filter(
        ({id}) => id !== SENSE_DARK_VISION
      ),
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
  },
}
