import arrify from 'arrify'
import PropTypes from 'prop-types'
import React from 'react'

import { crCollection } from '@/constants/crList'
import { GENDER_MALE } from '@/constants/genderList'

import calcParamBonus from "@/utils/calcParamBonus"

import formatSpellCastDescription from './utils/formatSpellCastDescription'

import SpellCastComponent from './SpellCastComponent'

const SpellCastContainer = ({ spellCast, genderId, name, cr, params }) => {
  if (spellCast) {
    const list = arrify(spellCast).reduce(
      (list, item) => {
        if (item) {
          const headerPreText = item.title || (
            item.spellCasterLevel
              ? 'Использование заклинаний'
              : 'Врождённое колдовство'
          )

          const headerPostText = item.comment
            ? ` (${item.comment})`
            : ''

          const header = `${headerPreText}${headerPostText}.`
          const text = formatSpellCastDescription({ spellCast: item, genderId, name, cr, params })

          return [
            ...list,
            { header, text },
          ]
        }

        return list
      },
      [],
    )

    return (
      <SpellCastComponent list={list}/>
    )
  }

  return null
}

SpellCastContainer.propTypes = {
  genderId: PropTypes.string,
  name: PropTypes.string,
  spellCast: PropTypes.object,
}

SpellCastContainer.defaultProps = {
  genderId: GENDER_MALE,
  name: '',
  spellCast: null,
}

export default SpellCastContainer
