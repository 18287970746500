import PropTypes from "prop-types"

const RenderList = (
  {
    bracketsAfterFirst = false,
    list,
    or = false,
    renderer,
  }
) => list.reduce(
  (result, item, i) => {
    let preText = ''
    let postText = ''

    if (i) {
      preText = ', '

      if (i === 1 && bracketsAfterFirst) {
        preText = ' ('
      }

      if (i === list.length - 1) {
        if (bracketsAfterFirst) {
          postText = ')'
        }
        if (or) {
          preText = ' или '
        }
      }
    }

    return [
      ...result,
      preText,
      renderer(item),
      postText,
    ]
  },
  []
)

RenderList.propTypes = {
  list: PropTypes.array.isRequired,
  renderer: PropTypes.func.isRequired,
}

export default RenderList
