import {
  CREATURE_DUODRONE,
  CREATURE_MONODRONE,
  CREATURE_PENTADRONE,
  CREATURE_QUADRONE,
  CREATURE_TRIDRONE,
} from '@/constants/creatureIdList'
import {faRobot} from '@fortawesome/free-solid-svg-icons'
import {ALIGNMENT_NOT_LN} from '@/constants/aligmentList'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Модрон-ренегат',
  templateIcon: faRobot,
  templateColor: 'magenta',

  templateLimitations: {
    include: {
      id: [
        CREATURE_MONODRONE,
        CREATURE_DUODRONE,
        CREATURE_TRIDRONE,
        CREATURE_QUADRONE,
        CREATURE_PENTADRONE,
      ],
    },
  },

  alignmentId: ALIGNMENT_NOT_LN,

  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 212,
    }],
    description: [
      {
        header: 'Вариант: Модроны-ренегаты',
        text: `Модрон иногда может дезертировать, либо из-за того, что подвергся воздействию сил хаоса, либо по причине какой-то неисправности. Модроны-ренегаты перестают действовать в соответствии с директивами и желаниями Прайма, нарушая законы, не подчиняясь приказам и даже занимаясь насилием. Другие модроны стараются отлавливать и уничтожать таких ренегатов.

Модрон-ренегат теряет особенность _Косное сознание_, и может иметь любое мировоззрение кроме законно-нейтрального. В остальном у него статистика как у обычного модрона соответствующего ранга.`,
        source: {
          id: SOURCE_MM,
          page: 212,
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name}-ренегат`,
    featureList: ({ featureList }) => featureList.filter(
      ({ name }) => name !== 'Косное сознание',
    ),
  },
}
