import calcParamBonus from '@/utils/calcParamBonus'

import { crCollection } from '@/constants/crList'

const calculateSpellAttackBonus = ({baseStat, cr}) => {
  const {profBonus} = cr && crCollection[cr] || {profBonus: 0}
  const paramBonus = calcParamBonus(baseStat)

  return  profBonus + paramBonus
}

export default calculateSpellAttackBonus
