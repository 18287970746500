import calcParamBonus from '@/utils/calcParamBonus'

import { sizeCollection } from '@/constants/sizeList'
import { PARAM_CON } from '@/constants/paramList'

const generateHpDiceFormula = ({ hp, sizeType, params }) => hp.diceCount
  ? ({
    diceCount: hp.diceCount,
    diceBonus: hp.diceCount * calcParamBonus(params[PARAM_CON]),
    diceType: sizeCollection[sizeType].hitDiceSize,
  })
  : hp.value || hp

export default generateHpDiceFormula
