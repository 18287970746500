import {
  CREATURE_DROW,
  CREATURE_DROW_ELITE_WARRIOR,
  CREATURE_DROW_PRIESTESS_OF_LOLTH,
} from '@/constants/creatureIdList'
import {
  GEAR_SHIELD,
  GEAR_SHORTSWORD,
} from '@/constants/gearIdList'
import enchantWeapon from '@/constants/creatureTemplateList/utils/enchantWeapon'
import {faMagic} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Дроу с магическими доспехами и оружием',
  templateIcon: faMagic,
  templateColor: 'steelBlue',
  templateLimitations: {
    include: {
      id: [
        CREATURE_DROW,
        CREATURE_DROW_ELITE_WARRIOR,
        CREATURE_DROW_PRIESTESS_OF_LOLTH,
      ],
    },
  },

  extendPropCollection: {
    description: [
      {
        header: `Вариант: Дроу с магическими доспехами и оружием`,
        text: `Дроу часто носят магические доспехи и магическое оружие, которые при длительном воздействии солнечного света (от 1 часа и дольше) теряют бонусы улучшения.

* У [дроу](CREATURE:${CREATURE_DROW}), носящего [кольчугу +1](MAGIC_ITEM:chain_mail_plus_1) и [короткий меч +1](MAGIC_ITEM:shortsword_plus_1), КД 19 и бонус +1 к броскам атаки и урона коротким мечом.

* У [элитного воина дроу](CREATURE:${CREATURE_DROW_ELITE_WARRIOR}), носящего [клёпаный кожаный доспех +2](MAGIC_ITEM:studded_leather_armor_plus_2) и [короткий меч +2](MAGIC_ITEM:shortsword_plus_2), КД 20 и бонус +2 к броскам атаки и урону коротким мечом.

* У [дроу жрицы Ллос](CREATURE:${CREATURE_DROW_PRIESTESS_OF_LOLTH}), носящей [чешуйчатый доспех +3](MAGIC_ITEM:scale_mail_plus_3), КД 19.`,
        source: {
          id: SOURCE_MM,
          page: 305,
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name, id }) => {
      switch (id) {
        case CREATURE_DROW:
        case CREATURE_DROW_ELITE_WARRIOR:
          return `${name} с магическими доспехами и оружием`

        case CREATURE_DROW_PRIESTESS_OF_LOLTH:
          return `${name} с магическими доспехами`

        default:
          return name
      }
    },
    armor: ({ armor, id }) => {
      switch (id) {
        case CREATURE_DROW:
          return {
            ac: 19,
            magicItemId: 'chain_mail_plus_1',
          }

        case CREATURE_DROW_ELITE_WARRIOR:
          return {
            ac: 20,
            magicItemId: 'studded_leather_armor_plus_2',
            gearId: GEAR_SHIELD,
          }

        case CREATURE_DROW_PRIESTESS_OF_LOLTH:
          return {
            ac: 19,
            magicItemId: 'scale_mail_plus_3',
          }

        default:
          return armor
      }
    },
    actionList: ({ actionList, id }) => {
      switch (id) {
        case CREATURE_DROW:
          return enchantWeapon({
            gearId: GEAR_SHORTSWORD,
            enchantBonus: 1,
            actionList,
            magicItemId: 'shortsword_plus_1',
          })

        case CREATURE_DROW_ELITE_WARRIOR:
          return enchantWeapon({
            gearId: GEAR_SHORTSWORD,
            enchantBonus: 2,
            actionList,
            magicItemId: 'shortsword_plus_2',
          })

        default:
          return actionList
      }
    },
  },
}
