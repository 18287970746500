const {
  CREATURE_CRAG_CAT,
  CREATURE_GIANT_CRAB,
  CREATURE_OGRE_GOBLIN_HUCKER,
  CREATURE_TRESSYM,
  CREATURE_UTHGARDT_SHAMAN,
  CREATURE_YAKFOLK_PRIEST,
  CREATURE_YAKFOLK_WARRIOR,
} = require('./../../creatureIdList')
const {
  SOURCE_BGDIA,
  SOURCE_SKT,
} = require('./../../sourceList')
const {
  Storm_Giant_Ladies_Plays_SKT_data,
  Uthgardt_Totems_DND3_FnP_data,
} = require('./../commonImageCollection')

const Crag_Cat_SKT_img = require('./../../../images/creatures/skt/crag_cat.png')
const Tressym_img_SKT = require('./../../../images/creatures/skt/tressym.png')
const Uthgardt_Shaman_SKT_img = require('./../../../images/creatures/skt/uthgardt_shaman.png')
const Yakfolk_Warrior_SKT_img = require('./../../../images/creatures/skt/yakfolk_warrior.png')
const Ogre_Goblin_Hucker_SKT_img = require('./../../../images/creatures/skt/ogre_goblin_hucker.png')

const Tressym_slobberchops_img_BGDIA = require('./../../../images/creatures/bgdia/tressym_slobberchops.jpeg')

module.exports = {
  [CREATURE_TRESSYM]: [
    Tressym_img_SKT,
    {
      src: Tressym_slobberchops_img_BGDIA,
      text: 'Трессим Слюнявчик',
      source: {
        id: SOURCE_BGDIA,
      },
    },
  ],
  [CREATURE_CRAG_CAT]: {
    src: Crag_Cat_SKT_img,
    source: {
      id: SOURCE_SKT,
    },
  },
  [CREATURE_GIANT_CRAB]: Storm_Giant_Ladies_Plays_SKT_data,
  [CREATURE_UTHGARDT_SHAMAN]: [
    {
      src: Uthgardt_Shaman_SKT_img,
      source: {
        id: SOURCE_SKT,
      },
    },
    Uthgardt_Totems_DND3_FnP_data,
  ],
  [CREATURE_OGRE_GOBLIN_HUCKER]: {
    src: Ogre_Goblin_Hucker_SKT_img,
    source: {
      id: SOURCE_SKT,
    },
  },
  [CREATURE_YAKFOLK_PRIEST]: {
    src: Yakfolk_Warrior_SKT_img,
    creatureId: CREATURE_YAKFOLK_WARRIOR,
    source: {
      id: SOURCE_SKT,
    },
  },
  [CREATURE_YAKFOLK_WARRIOR]: {
    src: Yakfolk_Warrior_SKT_img,
    creatureId: CREATURE_YAKFOLK_WARRIOR,
    source: {
      id: SOURCE_SKT,
    },
  },
}
