export default (bonus, addSpace = false) => {
  const spaceText = addSpace
    ? ' '
    : ''
  const bonusAbs = `${spaceText}${Math.abs(bonus)}`

  return bonus < 0
    ? `−${bonusAbs}`
    : `+${bonusAbs}`
}
