const {
  CREATURE_KENKU,
  CREATURE_KOBOLD,
  CREATURE_KRAKEN,
  CREATURE_KUO_TOA,
  CREATURE_KUO_TOA_ARCHPRIEST,
  CREATURE_KUO_TOA_MONITOR,
  CREATURE_KUO_TOA_WHIP,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
} = require('./../../../../sourceList')

const {
  KuoToaImgList,
} = require('./../../../commonImageListCollection')

const {
  Kenku_DMG_data,
  Kenku_MM_data,
  Kenku_VGTM_data,
  Kobold_Trap_VGTM_data,
  Kobold_Tribe_VGTM_data,
  Kobolds_MTG_CLB_data,
  Kugluk_FTD_data,
} = require('./../../../commonImageCollection')

const kobold = require('./../../../../../images/creatures/mm/kobold.jpg')
const kraken = require('./../../../../../images/creatures/mm/kraken.jpg')
const kuo_toa_archpriest = require('./../../../../../images/creatures/mm/kuo_toa_archpriest.jpg')
const kuo_toa_whip = require('./../../../../../images/creatures/mm/kuo_toa_whip.jpg')

const Kenku_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Kenku.jpg')
const Kobold_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Kobold.jpg')

module.exports = {
  [CREATURE_KENKU]: [
    Kenku_MM_data,
    Kenku_VGTM_data,
    Kenku_DMG_data,
    {
      src: Kenku_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_KOBOLD]: [
    kobold,
    {
      src: Kobold_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    Kobold_Trap_VGTM_data,
    Kobold_Tribe_VGTM_data,
    Kugluk_FTD_data,
    Kobolds_MTG_CLB_data,
  ],
  [CREATURE_KRAKEN]: kraken,
  [CREATURE_KUO_TOA]: KuoToaImgList,
  [CREATURE_KUO_TOA_ARCHPRIEST]: [
    {
      src: kuo_toa_archpriest,
      creatureId: CREATURE_KUO_TOA_ARCHPRIEST,
    },
    ...KuoToaImgList,
  ],
  [CREATURE_KUO_TOA_MONITOR]: [
    {
      src: kuo_toa_whip,
      creatureId: CREATURE_KUO_TOA_WHIP,
    },
    ...KuoToaImgList,
  ],
  [CREATURE_KUO_TOA_WHIP]: [
    {
      src: kuo_toa_whip,
      creatureId: CREATURE_KUO_TOA_WHIP,
    },
    ...KuoToaImgList,
  ],
}
