import {
  CREATURE_SLAAD_BLUE,
  CREATURE_SLAAD_DEATH,
  CREATURE_SLAAD_GRAY,
  CREATURE_SLAAD_GREEN,
  CREATURE_SLAAD_RED,
} from '@/constants/creatureIdList'
import {
  SPELL_GREATER_RESTORATION,
  SPELL_IMPRISONMENT,
  SPELL_WISH,
} from '@/constants/spellIdList'
import generateTextLinks from '@/utils/generateTextLinks'
import {faGem} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Слаад с камнем управления',
  templateIcon: faGem,
  templateColor: 'teal',

  note: `В мозгу слаада покоится магический камень. Завладейте им, и слаад окажется полностью в вашем распоряжении`,

  templateLimitations: {
    include: {
      id: [
        CREATURE_SLAAD_RED,
        CREATURE_SLAAD_GREEN,
        CREATURE_SLAAD_BLUE,
        CREATURE_SLAAD_GRAY,
        CREATURE_SLAAD_DEATH,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 263,
    }],
    description: [
      {
        header: 'Вариант: Камни управления',
        text: generateTextLinks(`Слаады, родившиеся из Нерестильного камня, уже носят в голове магический камень, который является частичкой Нерестильного камня. Этот камень принимает вид магического драгоценного камня, соизмеримого по размерам с кулаком ребёнка. Цвет камня такой же, как и цвет слаада. Другое существо может с помощью магии вытащить камень и использовать его для подчинения слаада. Слаад обязан подчинятся владельцу камня. Если камень уничтожить, этого слаада уже не подчинить таким способом.

Слаады, рождённые не от Нерестильного камня, не имеют камня в своём мозгу, но после контакта с Нерестильным камнем он появляется в их головах. Слаады, попавшие в Лимбо, притягиваются к Нерестильному камню, поэтому каждый из них рано или поздно получит его. Слаады с камнем управления в мозгу обладают дополнительной особенностью:

**Камень управления.** В мозгу этого слаада покоится магический камень управления. Слаад должен подчиняться тому, кто завладеет этим камнем, а также обладает иммунитетом к очарованию, когда находится под контролем.

Некоторые заклинания могут помочь заполучить этот камень. Если слаад провалил испытание от [Заточения](SPELL:${SPELL_IMPRISONMENT}), заклинание переносит камень в открытую руку заклинателя, вместо того чтобы сковать слаада. Заклинание [Исполнение желаний](SPELL:${SPELL_WISH}), наложенное в присутствии слаада, тоже может дать возможность забрать камень.

Заклинание [Высшее восстановление](SPELL:${SPELL_GREATER_RESTORATION}), наложенное на слаада, уничтожает камень, не причиняя вреда слааду.

Персонаж, владеющий навыком Медицина может изъять камень из недееспособного слаада. Каждая попытка требует 1 минуту времени непрерывной работы и успешной проверки Мудрости (Медицина) СЛ 20. Каждый провал причиняет слааду урон психической энергией 22 (4к10).`),
        source: {
          id: SOURCE_MM,
          page: 263,
        },
      },
    ],
    featureList: [
      {
        name: 'Камень управления',
        description: generateTextLinks(`В мозгу этого слаада покоится магический камень управления. Слаад должен подчиняться тому, кто завладеет этим камнем, а также обладает иммунитетом к очарованию, когда находится под контролем.

Некоторые заклинания могут помочь заполучить этот камень. Если слаад провалил испытание от _Заточения_ (Imprisonment), заклинание переносит камень в открытую руку заклинателя, вместо того чтобы сковать слаада. Заклинание _Исполнение желаний_ (Wish), наложенное в присутствии слаада, тоже может дать возможность забрать камень.

Заклинание _Высшее восстановление_ (Greater restoration), наложенное на слаада, уничтожает камень, не причиняя вреда слааду.

Персонаж, владеющий навыком Мудрость (Медицина) может изъять камень из недееспособного слаада. Каждая попытка требует 1 минуту времени непрерывной работы и успешной проверки Мудрости (Медицина) СЛ 20. Каждый провал причиняет слааду урон психической энергией 22 (4к10).`),
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace('слаад', 'слаад c камнем управления'),
  },
}
