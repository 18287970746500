import React from "react";

import { crCollection } from '@/constants/crList'

import SaveThrowListComponent from "./SaveThrowListComponent";

const SaveThrowListContainer = ({ saveThrowList, params, cr }) => {
  if (saveThrowList && saveThrowList.length) {
    const {profBonus} = cr && crCollection[cr] || {profBonus: 0}

    return (
      <SaveThrowListComponent
        list={saveThrowList}
        params={params}
        profBonus={profBonus}
      />
    )
  }

  return null
}

export default SaveThrowListContainer
