import formatBonus from '@/utils/formatBonus'
import upLetter from '@/utils/upLetter'
import updateNameAtDescription from '@/utils/updateNameAtDescription'
import calculateSpellAttackBonus from '@/utils/calculateSpellAttackBonus'

import {genderCollection, GENDER_FEMALE } from '@/constants/genderList'
import { PC_CLASS_WARLOCK } from '@/constants/pcClassIdList'
import { castComponentCollection } from '@/constants/castComponentList'
import { paramCollection } from '@/constants/paramList'
import { pcClassCollection } from '@/constants/pcClassList'
import DC_BASE from '@/constants/DC_BASE'
import paramPcClassCollection from '@/constants/paramPcClassCollection'

import formatSpellText from './formatSpellText'
import generateExcludeComponentText from './generateExcludeComponentText'
import generateSpellText from './generateSpellText'

export default (
  {
    spellCast: {
      baseStat,
      componentExclude,
      componentInstead,
      componentOnly,
      infinitySpellIdList,
      postText = '',
      preText = '',
      slotCountList,
      spellCasterClass,
      spellCasterLevel,
      spellIdByCountList,
      spellIdList,
    },
    cr,
    genderId,
    name,
    params,
  }
) => {
  const spellText = generateSpellText({slotCountList, spellIdByCountList, spellIdList})
  let preTextResult = preText
  baseStat = baseStat || paramPcClassCollection[spellCasterClass]

  if (!preText) {
    const spellAttackBonus = calculateSpellAttackBonus({baseStat: params[baseStat], cr})
    const statName = paramCollection[baseStat].name
    const saveThrowDc = DC_BASE + spellAttackBonus
    const bonusText = spellAttackBonus
      ? `, ${formatBonus(spellAttackBonus)} к попаданию атаками заклинаниями`
      : ''
    const saveThrowDcText = saveThrowDc
      ? `СЛ испытания от заклинания ${saveThrowDc}`
      : ''
    const spellAdditionalInfoText = saveThrowDcText || bonusText
      ? ` (${saveThrowDcText}${bonusText})`
      : ''

    const spellComponentOnlyText = componentOnly
      ? spellCasterClass
        ? `, которому нужны только ${castComponentCollection[componentOnly].name.plural.genitive} компоненты`
        : `, нуждаясь только в ${castComponentCollection[componentOnly].name.plural.genitive} компонентах`
      : ``

    const spellComponentExcludeText = generateExcludeComponentText({componentExclude, componentInstead, spellCasterClass})

    const spellComponentText = spellComponentOnlyText || spellComponentExcludeText
      ? `${spellComponentOnlyText}${spellComponentExcludeText}`
      : ''

    const introText = spellCasterLevel
      ? `★СУЩЕСТВО★ является заклинателем ${spellCasterLevel} уровня${spellComponentText}.`
      : ''
    const baseStatText = `${genderId === GENDER_FEMALE ? 'Её' : 'Его'} базовой характеристикой является ${statName}`

    const spellCasterClassText = spellCasterClass
      ? pcClassCollection[spellCasterClass].name.singular.genitive
      : ''

    const infinitySpellText = infinitySpellIdList
      ? `. ★СУЩЕСТВО★ может неограниченно сотворять заклинания ${infinitySpellIdList.map(formatSpellText).join(', ')}`
      : ''

    const spellCastPrepareText = spellCasterClass === PC_CLASS_WARLOCK
      ? `${upLetter(genderCollection[genderId].pronoun.nominative)} знает`
      : `У ${genderCollection[genderId].pronoun.genitive.full} приготовлены`

    const spellCastText = spellCasterClass
      ? `${spellCastPrepareText} следующие заклинания ${spellCasterClassText}`
      : `★СУЩЕСТВО★ может сотворять следующие заклинания${spellComponentOnlyText}${spellComponentExcludeText}`

    const shortRestText = spellCasterClass === PC_CLASS_WARLOCK
      ? `. Он восстанавливает потраченные ячейки заклинаний после завершения короткого или длинного отдыха`
      : ''

    preTextResult = `${introText} ${baseStatText}${spellAdditionalInfoText}${shortRestText}${infinitySpellText}. ${spellCastText}:`
  }

  return updateNameAtDescription({
    genderId,
    name,
    description: `${preTextResult}
${spellText}

${postText}`,
  })
}
