const {
  CREATURE_STORM_GIANT,
  CREATURE_STORM_GIANT_QUINTESSENT,
} = require('./../../../creatureIdList')
const {
  SOURCE_MM,
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_SKT,
  SOURCE_MTG_AFR,
  SOURCE_VGTM,
} = require('./../../../sourceList')
const {
  Giant_Fights_SKT_data,
  Storm_Giant_Ladies_Plays_SKT_data,
} = require('./../../commonImageCollection')

const King_Gekaton_SKT_img = require(`./../../../../images/creatures/skt/king_gekaton.jpg`)
const Storm_Giant_MM_img = require('./../../../../images/creatures/mm/storm_giant.jpeg')
const Storm_Giant_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Giant_Storm.jpg')
const Storm_Giant_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/storm_giant.jpg')
const Storm_Giant_Sea_VGTM_img = require('./../../../../images/creatures/vgtm/sea_storm_giant.jpg')
const Storm_Giant_Quintessent_VGTM_img = require(`./../../../../images/creatures/vgtm/storm_giant_quintessent.jpeg`)
const Storm_Giants_SKT_img = require(`./../../../../images/creatures/skt/storm-giants.jpg`)
const Storm_Giant_MTG_AFR_img = require(`./../../../../images/creatures/mtg_afr/storm-giant-mtg_afr.jpg`)

module.exports = [
  {
    src: Storm_Giant_MM_img,
    creatureId: CREATURE_STORM_GIANT,
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Storm_Giant_AYAGMnC_img,
    creatureId: CREATURE_STORM_GIANT,
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Storm_Giant_Quintessent_VGTM_img,
    creatureId: CREATURE_STORM_GIANT_QUINTESSENT,
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: King_Gekaton_SKT_img,
    text: 'Гекатон, король штормовых великанов',
    source: {
      id: SOURCE_SKT,
    },
  },
  {
    src: Storm_Giants_SKT_img,
    text: 'Штормовые великаны',
    source: {
      id: SOURCE_SKT,
    },
  },
  {
    src: Storm_Giant_Sea_VGTM_img,
    text: 'Штормовой великан в океане',
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: Storm_Giant_MTG_AFR_img,
    text: 'Штормовой великан нападает на лодку',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  Giant_Fights_SKT_data,
  Storm_Giant_Ladies_Plays_SKT_data,
  {
    src: Storm_Giant_ADND2_img,
    creatureId: CREATURE_STORM_GIANT,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
