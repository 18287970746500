const {
  CREATURE_TARRASQUE,
  CREATURE_THRI_KREEN,
  CREATURE_THUG,
  CREATURE_TIGER,
  CREATURE_TOAD,
  CREATURE_TREANT,
  CREATURE_TRICERATOPS,
  CREATURE_TRIDRONE,
  CREATURE_TROGLODYTE,
  CREATURE_TROLL,
  CREATURE_TWIG_BLIGHT,
  CREATURE_TYRANNOSAURUS_REX,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_DMG,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  ThriKreenImgList,
} = require('./../../../commonImageListCollection')

const tarrasque = require('./../../../../../images/creatures/mm/tarrasque.jpeg')
const thug = require('./../../../../../images/creatures/mm/thug.jpg')
const treant = require('./../../../../../images/creatures/mm/treant.jpeg')
const tridrone = require('./../../../../../images/creatures/mm/tridrone.jpeg')
const troglodyte = require('./../../../../../images/creatures/mm/troglodyte.jpeg')
const troll = require('./../../../../../images/creatures/mm/troll.jpeg')
const twig_blight_1 = require('./../../../../../images/creatures/mm/twig_blight-1.jpg')
const twig_blight_2 = require('./../../../../../images/creatures/mm/twig_blight-2.jpg')
const tyrannosaurus_rex = require('./../../../../../images/creatures/mm/tyrannosaurus_rex.jpeg')

const Tarrasque_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Tarrasque.jpg')
const Tiger_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Tiger.jpg')
const Toad_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Toad.jpg')
const Treant_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Treant.jpg')
const Triceratops_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Triceratops.jpg')
const Troglodyte_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Troglodyte.jpg')
const Troll_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Troll.jpg')

const Treant_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/treant.jpg')

const Tarrasque_DMG_img = require('./../../../../../images/creatures/dmg/tarrasque.jpg')
const Troglodyte_DMG_img = require('./../../../../../images/creatures/dmg/troglodyte-dmg.png')

const Tarrasque_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/tarrasque-mtg_clb.jpg')
const Tridrone_treasure_keeper_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/tridrone-treasure-keeper-mtg_clb.jpg')

module.exports = {
  [CREATURE_TARRASQUE]: [
    tarrasque,
    {
      src: Tarrasque_DMG_img,
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Tarrasque_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Tarrasque_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_THRI_KREEN]: ThriKreenImgList,
  [CREATURE_THUG]: thug,
  [CREATURE_TIGER]: {
    src: Tiger_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_TREANT]: [
    treant,
    {
      src: Treant_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Treant_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_TOAD]: {
    src: Toad_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_TRICERATOPS]: {
    src: Triceratops_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_TRIDRONE]: [
    tridrone,
    {
      src: Tridrone_treasure_keeper_MTG_CLB_img,
      text: 'Тридрон, хранитель сокровищ',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_TROGLODYTE]: [
    troglodyte,
    {
      src: Troglodyte_DMG_img,
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Troglodyte_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_TROLL]: [
    troll,
    {
      src: Troll_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_TWIG_BLIGHT]: [twig_blight_1, twig_blight_2],
  [CREATURE_TYRANNOSAURUS_REX]: tyrannosaurus_rex,
}
