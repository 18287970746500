import React from 'react'
import arrify from 'arrify'
import PropTypes from 'prop-types'

import { spellCollection } from '@/constants/spellList'

import generateSpellPageUrlById from '@/utils/generateSpellPageUrlById'

import AbandonedAt from '@/components/AbandonedAt'
import Creature from '@/components/Creature'
import DescriptionList from '@/components/DescriptionList'
import MagicSchool from '@/components/MagicSchool'
import Note from '@/components/Note'
import PageHeader from '@/components/PageHeader'
import ReleasedAs from '@/components/ReleasedAs'
import ReleasedBeforeAs from '@/components/ReleasedBeforeAs'

import CastConstituant from './components/CastConstituant'
import CastInfo from './components/CastInfo'
import HighLvlCast from './components/HighLvlCast'
import PcClassTable from './components/PcClassTable'
import SpellImageList from './components/SpellImageList'

import './SpellStyles.less'

const SpellComponent = (
  {
    abandonedAt,
    castTime,
    castTimeComment,
    componentIdList,
    createdCreatureId,
    descriptionList,
    duration,
    highLvlCast,
    id,
    isDurationUntil,
    isRitual,
    lvlText,
    magicSchoolId,
    materialText,
    needConcentration,
    note,
    spell,
    range,
    rangeComment,
    releasedAs,
    releasedBeforeAsList,
    royaltyCost,
  }
) => (
  <section className='Spell'>
    <section className='Spell_infoBlock'>
      <PageHeader {...spell}/>

      <div className='Spell_mainInfo'>
        <span className='Spell_lvl'>{lvlText}</span>
        <MagicSchool
          magicSchoolId={magicSchoolId}
          showLarge
          showName
        />
      </div>

      <AbandonedAt
        preText='Это заклинание было отменено'
        abandonedAt={abandonedAt}
      />

      <ReleasedAs
        preText='Это заклинание было переиздано'
        releasedAs={releasedAs}
        collection={spellCollection}
        urlGenerator={generateSpellPageUrlById}
      />

      <div className='Spell_info'>
        <CastInfo
          castTime={castTime}
          castTimeComment={castTimeComment}
          duration={duration}
          isDurationUntil={isDurationUntil}
          isRitual={isRitual}
          needConcentration={needConcentration}
          range={range}
          rangeComment={rangeComment}
        />
        <PcClassTable
          className='Spell_classTable'
          spellId={id}
        />
        <CastConstituant
          componentIdList={componentIdList}
          materialText={materialText}
          royaltyCost={royaltyCost}
        />
      </div>

      <div className='Spell_text'>
        <DescriptionList description={descriptionList}/>
        <HighLvlCast highLvlCast={highLvlCast}/>
      </div>

      <ReleasedBeforeAs
        preText='Это заклинание ранее издавалось'
        releasedAs={releasedBeforeAsList}
        checkIsReady={false}
        collection={spellCollection}
        urlGenerator={generateSpellPageUrlById}
      />
    </section>

    <Note note={note}/>
    <SpellImageList spellId={id}/>

    {
      createdCreatureId
        ? arrify(createdCreatureId).map(
        creatureId => <Creature id={creatureId}/>
        )
        : null
    }
  </section>
)

SpellComponent.propTypes = {
  castTimeText: PropTypes.string.isRequired,
  componentIdList: PropTypes.array,
  descriptionList: PropTypes.array.isRequired,
  isRitual: PropTypes.bool,
  lvlText: PropTypes.string.isRequired,
  magicSchoolId: PropTypes.string.isRequired,
  mainHeader: PropTypes.string.isRequired,
  materialText: PropTypes.string,
  note: PropTypes.string,
  pcClassId: PropTypes.array,
  pcSubClassId: PropTypes.array,
  rangeText: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
}

SpellComponent.defaultProps = {
  componentIdList: [],
  duration: '',
  isRitual: false,
  materialText: '',
  note: null,
  pcClassId: null,
  pcSubClassId: null,
  subHeader: '',
}

export default SpellComponent
