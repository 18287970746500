import React from 'react'
import PropTypes from 'prop-types'

import StatInfoRow from "@/components/StatInfoRow";

import { crCollection } from "@/constants/crList";

import SkillCollectionComponent from './SkillCollectionComponent'

const SkillCollectionContainer = ({ skillCollection, params, cr, useWrapper = true }) => {
  const list = skillCollection
    ? Object.entries(skillCollection)
    : null

  if (list && list.length) {
    const {profBonus} = cr && crCollection[cr] || {profBonus: 0}
    const Wrapper = useWrapper
      ? StatInfoRow
      : React.Fragment

    return (
      <SkillCollectionComponent
        list={list}
        params={params}
        profBonus={profBonus}
        cr={cr}
        Wrapper={Wrapper}
      />
    )
  }

  return null
}

SkillCollectionContainer.propTypes = {
  collection: PropTypes.object,
  useWrapper: PropTypes.bool,
}

SkillCollectionContainer.defaultProps = {
  collection: null,
  useWrapper: true,
}

export default SkillCollectionContainer
