import {faHandMiddleFinger} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'
import {CREATURE_TROLL} from '@/constants/creatureIdList'

export default {
  templateName: 'Отвратительные конечности',
  templateIcon: faHandMiddleFinger,
  templateColor: 'olive',
  templateLimitations: {
    include: {
      id: [
        CREATURE_TROLL,
      ],
    },
  },

  name: 'Тролль с отвратительными конечностями',

  extendPropCollection: {
    featureList: [
      {
        name: `Отвратительные конечности`,
        description: `Если тролль получает за раз как минимум 15 рубящего урона, бросьте к20, чтобы определить дополнительный эффект:

* **1–10:** Ничего не происходит.

* **11–14:** У тролля отрублена одна нога, если у него оставалась хотя бы одна.

* **15–18:** У тролля отрублена одна рука, если у него оставалась хотя бы одна.

* **19–20:** Тролль обезглавлен, но умирает только если не может регенерировать. Если он умирает, то умирает и голова.

Если тролль совершает короткий или длинный отдых, не прикрепляя к себе отрубленную конечность или голову, то на её месте отрастает новая. Отрубленная при этом умирает. До тех пор отрубленная часть тролля действует с такой же инициативой как у тролля, и у неё есть собственное действие и собственное перемещение. У отрубленной части КД 13, 10 хитов и особенность Регенерация как у тролля.

**Отрубленная нога** не может атаковать, и у неё скорость 5 футов.

У **отрубленной руки** скорость 5 футов, и она может совершить одну атаку когтями в свой ход, причём с помехой, если только тролль не видит и эту руку и её цель. При потере троллем руки он также теряет одну атаку когтями.

Если **отрублена голова**, тролль теряет атаку укусом и его туловище считается ослеплённым, если только голова не видит его. У отрубленной головы скорость 0 футов и особенность тролля Тонкий нюх. Она может совершить атаку укусом, но только если цель находится в её клетке.

Если тролль потерял ногу, его скорость снижается вдвое. Если обе ноги — он падает ничком. Если при этом у него есть руки, он может ползти. Если рука при этом осталась только одна, он по-прежнему может ползти, но скорость уменьшается вдвое. Без рук и без ног его скорость становится равной 0, и тролль не получает никаких бонусов к скорости.`,
      },
    ],
    description: [
      {
        header: `Вариант: Отвратительные конечности`,
        text: `У некоторых троллей есть следующая особенность:

**Отвратительные конечности.** Если тролль получает за раз как минимум 15 рубящего урона, бросьте к20, чтобы определить дополнительный эффект:

* **1–10:** Ничего не происходит.

* **11–14:** У тролля отрублена одна нога, если у него оставалась хотя бы одна.

* **15–18:** У тролля отрублена одна рука, если у него оставалась хотя бы одна.

* **19–20:** Тролль обезглавлен, но умирает только если не может регенерировать. Если он умирает, то умирает и голова.

Если тролль совершает короткий или длинный отдых, не прикрепляя к себе отрубленную конечность или голову, то на её месте отрастает новая. Отрубленная при этом умирает. До тех пор отрубленная часть тролля действует с такой же инициативой как у тролля, и у неё есть собственное действие и собственное перемещение. У отрубленной части КД 13, 10 хитов и особенность Регенерация как у тролля.

**Отрубленная нога** не может атаковать, и у неё скорость 5 футов.

У **отрубленной руки** скорость 5 футов, и она может совершить одну атаку когтями в свой ход, причём с помехой, если только тролль не видит и эту руку и её цель. При потере троллем руки он также теряет одну атаку когтями.

Если **отрублена голова**, тролль теряет атаку укусом и его туловище считается ослеплённым, если только голова не видит его. У отрубленной головы скорость 0 футов и особенность тролля Тонкий нюх. Она может совершить атаку укусом, но только если цель находится в её клетке.

Если тролль потерял ногу, его скорость снижается вдвое. Если обе ноги — он падает ничком. Если при этом у него есть руки, он может ползти. Если рука при этом осталась только одна, он по-прежнему может ползти, но скорость уменьшается вдвое. Без рук и без ног его скорость становится равной 0, и тролль не получает никаких бонусов к скорости.`,
        source: {
          id: SOURCE_MM,
          page: 286,
        },
      },
    ],
  },
}
