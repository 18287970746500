const {
  CREATURE_DRAGON_GREEN_WYRMLING,
} = require('./../../../creatureIdList')

const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_DMG,
  SOURCE_DND3_5_DoF,
  SOURCE_DND3_5_DTBoD,
  SOURCE_FTD,
  SOURCE_MM,
  SOURCE_MTG_AFR,
} = require('./../../../sourceList')

const {
  Green_Dragon_meets_Bard_DMG_data,
  Green_Dragon_ROT_data,
  Ancient_Dragons_Hoard_data,
} = require('./../../commonImageCollection')

const Claugiyliamatar_DND3_5_DoF_img = require('./../../../../images/creatures/dnd3_5_dof/claugiyliamatar.webp')
const Green_Dragon_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Green_Dragon.jpg')
const Green_dragon_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/green_dragon.jpg')
const Green_Dragon_MM_img = require('./../../../../images/creatures/mm/green_dragon.jpg')
const Green_dragon_seek_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/green_dragon_seek.jpg')
const Green_Dragon_Wyrmling_DMG_img = require('./../../../../images/creatures/dmg/green_dragon_wyrmling.png')
const Green_Dragons_Fights_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/green-dragons-fights.jpg')
const Green_Dragon_s_Poison_FTD_img = require('./../../../../images/creatures/ftd/green-dragon-poison.webp')
const Claugiyliamatar_FTD_img = require('./../../../../images/creatures/ftd/claugiyliamatar.png')
const Green_Dragon_Attacks_FTD_img = require('./../../../../images/creatures/ftd/green_dragon.png')
const Green_Dragon_Exales_Poison_FTD_img = require('./../../../../images/creatures/ftd/green_dragon_exales_poison.png')

const Old_Gnawbone_MTG_AFR_img = require('./../../../../images/creatures/mtg_afr/old-gnawbone-mtg_afr.jpg')

module.exports = [
  {
    src: Green_Dragon_MM_img,
    text: 'Зелёный дракон',
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Green_dragon_AYAGMnC_img,
    text: 'Зелёный дракон',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Green_dragon_seek_AYAGMnC_img,
    text: 'Зелёный дракон',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  Green_Dragon_meets_Bard_DMG_data,
  {
    src: Green_Dragon_Wyrmling_DMG_img,
    creatureId: CREATURE_DRAGON_GREEN_WYRMLING,
    source: {
      id: SOURCE_DMG,
    },
  },
  Green_Dragon_ROT_data,
  Green_Dragon_s_Poison_FTD_img,
  {
    src: Claugiyliamatar_DND3_5_DoF_img,
    text: 'Клогиалиаматар завидует могущественным женщинам мира',
    source: {
      id: SOURCE_DND3_5_DoF,
    },
  },
  {
    src: Claugiyliamatar_FTD_img,
    text: 'Клогиалиаматар известна как «Старая костегрызка». Её часто видят с чьим-то трупом во рту',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Old_Gnawbone_MTG_AFR_img,
    text: 'Древняя зелёная драконица Клогиалиаматар',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  {
    src: Green_Dragon_Attacks_FTD_img,
    text: 'Зелёный дракон',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Green_Dragon_Exales_Poison_FTD_img,
    text: 'Зелёный дракон выдыхает облако яда',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Green_Dragons_Fights_DND3_5_DTBoD_img,
    text: 'Зелёный драконы бьются за территорию',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Green_Dragon_ADND2_img,
    text: 'Зелёный дракон',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  Ancient_Dragons_Hoard_data,
]
