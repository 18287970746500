import React from 'react'
import PropTypes from 'prop-types'

import formatTimePeriod from './utils/formatTimePeriod'
import formatSpellRange from '@/utils/formatSpellRange'

import CastInfoComponent from './CastInfoComponent'

const CastInfoContainer = (
  {
    castTime,
    castTimeComment = '',
    duration,
    isDurationUntil,
    isRitual,
    needConcentration,
    range,
    rangeComment,
    ...rest
  }
) => {
  const durationTimeFormatted = formatTimePeriod(duration, isDurationUntil)
  const castTimeFormatted = formatTimePeriod(castTime)

  const durationTimeText = isDurationUntil
    ? `вплоть до ${durationTimeFormatted}`
    : durationTimeFormatted

  const durationText = needConcentration
    ? `Концентрация, ${durationTimeText}`
    : durationTimeText

  const rangeText = rangeComment
    ? `${formatSpellRange(range)} (${rangeComment})`
    : `${formatSpellRange(range)}`

  const ritualText = isRitual
    ? ` (ритуал)`
    : ''

  const castTimeText = castTimeComment
    ? `${castTimeFormatted}${ritualText}, ${castTimeComment}`
    : `${castTimeFormatted}${ritualText}`

  return (
    <CastInfoComponent
      durationText={durationText}
      rangeText={rangeText}
      castTimeText={castTimeText}
      {...rest}
    />
  )
}

CastInfoContainer.propTypes = {
  castTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      timeId: PropTypes.string,
      count: PropTypes.number,
    }),
  ]).isRequired,
  castTimeComment: PropTypes.string,
  duration: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      timeId: PropTypes.string,
      count: PropTypes.number,
    }),
  ]).isRequired,
  isDurationUntil: PropTypes.bool,
  needConcentration: PropTypes.bool,
  range: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  rangeComment: PropTypes.string,
}

CastInfoContainer.defaultProps = {
  castTimeComment: '',
  isDurationUntil: false,
  needConcentration: false,
  rangeComment: '',
}

export default CastInfoContainer
