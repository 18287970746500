import {
  CREATURE_GERYON,
  CREATURE_MINOTAUR,
} from '@/constants/creatureIdList'
import generateTextLinks from '@/utils/generateTextLinks'
import {faUserPlus} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MTOF} from '@/constants/sourceList'

export default {
  templateName: 'Герион, трубящий в рог',
  templateIcon: faUserPlus,
  templateColor: 'purple',

  templateLimitations: {
    include: {
      id: [
        CREATURE_GERYON,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MTOF,
      page: 188,
    }],
    description: [
      {
        header: 'Вариант: Протрубить в рог',
        text: `Герион может действием призывать порабощённых [минотавров](CREATURE:${CREATURE_MINOTAUR}).

**Протрубить в рог (1/день)**. Герион дует в рог, призывая 5к4 [минотавров](CREATURE:${CREATURE_MINOTAUR}) в свободном пространстве по его выбору на расстоянии 600 футов от него. Минотавры действуют с собственной инициативой и остаются, пока не умрут или пока Герион не отпустит их действием.`,
        source: {
          id: SOURCE_MTOF,
          page: 188,
        },
      },
    ],
  },

  editPropCollection: {
    actionList: ({ actionList }) => [
      ...actionList,
      {
        name: 'Протрубить в рог',
        limit: {
          count: 1,
          period: 'день',
        },
        description: generateTextLinks(`Герион дует в рог, призывая 5к4 [минотавров](CREATURE:${CREATURE_MINOTAUR}) в свободном пространстве по его выбору на расстоянии 600 футов от него. Минотавры действуют с собственной инициативой и остаются, пока не умрут или пока Герион не отпустит их действием.`),
      },
    ],
  },
  name: 'Герион, трубящий в рог',
}
