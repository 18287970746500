import React, {PureComponent} from 'react'
import PropTypes from "prop-types";

import convertPriceIntoMonetList from './utils/convertPriceIntoMonetList'

import PriceComponent from './PriceComponent'

class PriceContainer extends PureComponent {
    render() {
        const {price, ...rest} = this.props
        const monetList = convertPriceIntoMonetList(price)

        return (
            <PriceComponent
              monetList={monetList}
              {...rest}
            />
        )
    }
}

PriceContainer.defaultProps = {
    price: 0,
}

PriceContainer.propTypes = {
    price: PropTypes.number,
}

export default PriceContainer
