import React from 'react'
import arrify from 'arrify'
import PropTypes from 'prop-types'

import upLetter from '@/utils/upLetter'

import { GENDER_MALE } from '@/constants/genderList'
import { creatureTypeCollection } from '@/constants/creatureTypeList'

import getCreatureTypeName from './utils/getCreatureTypeName'

import CreatureTypeComponent from './CreatureTypeComponent'

const CreatureTypeContainer = (
  {
    creatureTypeIdList,
    swarmMemberSizeType = null,
    genderId = GENDER_MALE,
    isVehicle = false,
  },
) => {
  if (
    isVehicle
    || (
      creatureTypeIdList
      && creatureTypeIdList.length
    )
  ) {
    const isSwarm = Boolean(swarmMemberSizeType)

    const list = creatureTypeIdList && creatureTypeIdList.length
      ? creatureTypeIdList
        .map(id => creatureTypeCollection[id])
        .filter(
          ({hideFromStatBlock}) => !hideFromStatBlock,
        )
        .map(
          ({id, description}) => {
            const text = getCreatureTypeName({ id, isSwarm, genderId })

            const title = description
              ? (({header, text}) => `${upLetter(header)}\n\n${text}`)(arrify(description)[0])
              : null

            return {
              id,
              title,
              text,
            }
          },
        )
      : [{
        id: 'vehicleType',
        title: 'Техника',
        text: 'техника',
      }]

    return list.length
      ? <CreatureTypeComponent list={list}/>
      : null
  }

  return null
}

CreatureTypeContainer.propTypes = {
  creatureTypeIdList: PropTypes.array.isRequired,
  isVehicle: PropTypes.bool,
  swarmMemberSizeType: PropTypes.string,
}

export default CreatureTypeContainer
