import React from "react"

import StatInfoRow from "@/components/StatInfoRow";

const CreatureCapacity = ({creatureCapacity}) => {
  if (creatureCapacity) {
    const {crew, passengers} = creatureCapacity

    return (
      <StatInfoRow header='Вмещает'>
        {crew} экипажа, {passengers} пассажиров
      </StatInfoRow>
    )
  }

  return null
}

export default CreatureCapacity
