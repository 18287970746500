const { crCollection } = require('./../constants/crList')

const calcProfBonusByCr = cr => {
  if (cr && crCollection[cr]) {
    return crCollection[cr].profBonus
  }

  return 0
}

module.exports = calcProfBonusByCr
