import React from "react"
import PropTypes from "prop-types"

import StatBlock from "@/components/StatBlock"
import DescriptionList from "@/components/DescriptionList"
import CreatureImageList from "@/components/Creature/components/CreatureImageList"
import Note from "@/components/Note"
import PageHeader from "@/components/PageHeader"

import "./CreatureStyles.less"

const CreatureComponent = ({creature, ...rest}) => (
  <section className='Creature'>
    <PageHeader {...creature}/>
    <section className='Creature_body'>
      <StatBlock
        data={creature}
        {...rest}
      />
      <Note {...creature} />
      <CreatureImageList {...creature} />
      <DescriptionList {...creature} />
    </section>
  </section>
)

CreatureComponent.propTypes = {
  creature: PropTypes.object.isRequired,
}

export default CreatureComponent
