const {
  SPELL_ASHARDALON_S_STRIDE,
  SPELL_RIME_S_BINDING_ICE,
} = require('./../../spellIdList')
const {
  Ashardalon_s_Stride_FTD_data,
  Rime_s_Binding_Ice_FTD_data,
} = require('./../commonImageCollection')

const spellImageCollection_FTD = {
  [SPELL_ASHARDALON_S_STRIDE]: Ashardalon_s_Stride_FTD_data,
  [SPELL_RIME_S_BINDING_ICE]: Rime_s_Binding_Ice_FTD_data,
}

module.exports = spellImageCollection_FTD
