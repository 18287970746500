import React from 'react'

import './RitualStyles.less'

const ritualDescription = `Ритуал

У некоторых заклинаний есть специальное ключевое слово: «ритуал». Такое заклинание можно использовать по обычным правилам использования заклинаний, или же использовать его как ритуал. Ритуальная версия сотворяется на 10 минут дольше, чем обычно. Она не использует ячейку заклинания, а значит, ритуальную версию заклинания нельзя использовать с увеличенным уровнем.

Для использования заклинания ритуалом заклинатель должен иметь умение, позволяющее делать это. У жрецов и друидов, например, есть такое умение. Заклинатель должен при этом иметь это заклинание подготовленным или просто иметь его в списках известных заклинаний, если в ритуальном умении не сказано обратное, как, например, у волшебника. `

export default () => (
  <span
    className='Ritual'
    title={ritualDescription}
  >
    (или ритуал)
  </span>
)
