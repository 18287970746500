const {
  CREATURE_AARAKOCRA,
  CREATURE_ABOLETH,
  CREATURE_AIR_ELEMENTAL,
  CREATURE_ANDROSPHINX,
  CREATURE_ANIMATED_ARMOR,
  CREATURE_ANKHEG,
  CREATURE_ANKYLOSAURUS,
  CREATURE_APE,
  CREATURE_ARCANALOTH,
  CREATURE_ARCHMAGE,
  CREATURE_ASSASSIN,
  CREATURE_AXE_BEAK,
  CREATURE_AZER,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  AarakokraList,
  Orc_Axe_Beak_Attacks_Halfling_SKT_data,
} = require('./../../../commonImageCollection')

const air_elemental = require('./../../../../../images/creatures/mm/air_elemental.jpg')
const androsphinx = require('./../../../../../images/creatures/mm/androsphinx.jpg')
const animated_armor = require('./../../../../../images/creatures/mm/animated_armor.jpeg')
const arcanaloth = require('./../../../../../images/creatures/mm/arcanaloth.jpg')
const archmage = require('./../../../../../images/creatures/mm/archmage.jpg')
const assassin_dagger = require('./../../../../../images/creatures/mm/assassin-dagger.png')
const azer = require('./../../../../../images/creatures/mm/azer.jpeg')

const Ape_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Ape.jpg')
const Elemental_Air_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Elemental_Air.jpg')
const Sphinx_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Sphinx.jpg')
const aboleth = require('./../../../../../images/creatures/mm/aboleth.jpg')

const Aboleth_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Aboleth.jpg')
const Ankheg_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Ankheg.jpg')
const Ankylosaurus_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Ankylosaurus.jpg')

const Aboleth_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/aboleth.jpg')
const Aboleth_at_ocean_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/aboleth_at_ocean.jpg')

const Aboleth_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/aboleth-mtg_clb.jpg')
const Giant_ankheg_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/giant_ankheg-mtg_clb.webp')


module.exports = {
  [CREATURE_AARAKOCRA]: AarakokraList,
  [CREATURE_ABOLETH]: [
    aboleth,
    {
      src: Aboleth_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Aboleth_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Aboleth_at_ocean_img_AYAGMnC,
      text: 'Аболет в океане',
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Aboleth_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_AIR_ELEMENTAL]: [
    air_elemental,
    {
      src: Elemental_Air_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_ANDROSPHINX]: [
    androsphinx,
    {
      src: Sphinx_ADND2,
      text: 'Сфинкс',
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_ANIMATED_ARMOR]: animated_armor,
  [CREATURE_ANKHEG]: [
    {
      src: Ankheg_ADND2,
      source: SOURCE_ADND2_MM,
    },
    {
      src: Giant_ankheg_MTG_CLB_img,
      text: 'Гигантский анхег',
      source: SOURCE_MTG_CLB,
    },
  ],
  [CREATURE_ANKYLOSAURUS]: {
    src: Ankylosaurus_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_APE]: {
    src: Ape_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_ARCANALOTH]: arcanaloth,
  [CREATURE_ARCHMAGE]: archmage,
  [CREATURE_ASSASSIN]: {
    src: assassin_dagger,
    text: 'Кинжал убийцы',
  },
  [CREATURE_AXE_BEAK]: Orc_Axe_Beak_Attacks_Halfling_SKT_data,
  [CREATURE_AZER]: azer,
}
