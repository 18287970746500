import React from "react"

import DmgList from "@/components/DmgList";

export default ({list}) => (
  <DmgList
    header='Иммунитет к урону'
    list={list}
  />
)
