import conditionList from '@/constants/conditionList'
import damageTypeList from '@/constants/damageTypeList'
import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'
import magicList from '@/constants/magicList'
import paramList from '@/constants/paramList'
import attackTypeList from '@/constants/attackTypeList'
import pcClassList from '@/constants/pcClassList'
import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME'
import {sourceCollection} from '@/constants/sourceList'
import {
  castTimeIdList,
  durationIdList,
  spellPossibleRangeList,
  spellSourceIdList,
} from '@/constants/spellList'
import {
  FILTER_TYPE_INPUT,
  FILTER_TYPE_SELECT,
} from '@/constants/filterTypes'

import formatSpellRange from '@/utils/formatSpellRange'
import sortByText from '@/utils/sortByText'
import upLetter from '@/utils/upLetter'

import timeListFormatter from './../utils/timeListFormatter'

import castComponentFilter from './castComponentFilter'
import lvlList from './lvlList'

export default [
  {
    header: 'Ограничения',
    children: [
      {
        label: 'Класс',
        type: FILTER_TYPE_SELECT,
        propName: 'pcClassId',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой',
          },
          ...pcClassList.map(({ name: { singular: { nominative: text } }, id: value }) => ({
            value,
            text: upLetter(text),
          })),
        ],
      },
    ],
  },
  {
    header: 'Круг',
    children: [
      {
        label: 'От',
        type: FILTER_TYPE_SELECT,
        propName: 'lvlMin',
        value: DEFAULT_FILTER_VALUE,
        list: lvlList,
        filterHandler: value => item => {
          if (value) {
            if ('lvl' in item && typeof item.lvl === 'number') {
              return item.lvl >= value
            }

            return false
          }

          return true
        },
      },
      {
        label: 'До',
        type: FILTER_TYPE_SELECT,
        propName: 'lvlMax',
        value: DEFAULT_FILTER_VALUE,
        list: lvlList,
        filterHandler: value => item => {
          if (value) {
            if ('lvl' in item && typeof item.lvl === 'number') {
              return item.lvl <= value
            }

            return false
          }

          return true
        },
      },
    ],
  },
  {
    header: 'Основное',
    children: [
      {
        label: 'Школа',
        type: FILTER_TYPE_SELECT,
        propName: 'magicSchoolId',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любая',
          },
          ...magicList.map(({ name: text, id: value }) => ({ value, text })),
        ],
      },
      {
        label: 'Источник',
        type: FILTER_TYPE_SELECT,
        propName: 'spellSourceIdList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          ...spellSourceIdList
            .map(
              id => {
                const { shortName: text, name: title } = sourceCollection[id]
                return {
                  value: id,
                  title,
                  text,
                }
              },
            )
            .sort(sortByText),
        ],
      },
      {
        label: 'Отменено?',
        title: 'Заклинание существовало только в Раскопанных Тайнах и позже было решено его не издавать?',
        type: FILTER_TYPE_SELECT,
        propName: 'isAbandoned',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
      {
        label: 'Переиздано?',
        title: 'Заклинание было переиздано в другой официальной версии',
        type: FILTER_TYPE_SELECT,
        propName: 'isReReleased',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
    ],
  },
  {
    header: 'Время',
    children: [
      {
        label: 'Время сотворения',
        type: FILTER_TYPE_SELECT,
        propName: 'castTimeId',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любое',
          },
          ...castTimeIdList.map(timeListFormatter),
        ],
      },
      {
        label: 'Длительность',
        type: FILTER_TYPE_SELECT,
        propName: 'durationTimeId',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любая',
          },
          ...durationIdList.map(timeListFormatter),
        ],
      },
    ],
  },
  {
    header: 'Особенности',
    children: [
      {
        label: 'Дальность',
        type: FILTER_TYPE_SELECT,
        propName: 'range',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любая',
          },
          ...spellPossibleRangeList.map(
            range => ({
              value: range,
              text: formatSpellRange(range),
            })
          ),
        ],
      },
      {
        label: 'Усиление',
        title: `Можно ли усиливать ли заклинание ячейками высоких уровней?`,
        type: FILTER_TYPE_SELECT,
        propName: 'canBeEmpowered',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
      {
        label: 'Концентрация',
        type: FILTER_TYPE_SELECT,
        propName: 'needConcentration',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
      {
        label: 'Ритуал',
        type: FILTER_TYPE_SELECT,
        propName: 'isRitual',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
      {
        label: 'Динамантия',
        title: 'Относится ли заклинание к технике динамантии?',
        type: FILTER_TYPE_SELECT,
        propName: 'isDunamisBased',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
    ],
  },
  castComponentFilter,
  {
    header: 'Стоимость',
    children: [
      {
        label: 'С ценой',
        title: 'Требуются ли конкретные материальные компоненты с указанными ценами?',
        type: FILTER_TYPE_SELECT,
        propName: 'hasCost',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
      {
        label: 'Расходуемые',
        title: 'Расходуются ли конкретные материальные компоненты?',
        type: FILTER_TYPE_SELECT,
        propName: 'hasConsumable',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
    ],
  },
  {
    header: 'Эффекты',
    children: [
      {
        label: 'Тип атаки',
        title: 'Требуется ли совершать атаку и какую?',
        type: FILTER_TYPE_SELECT,
        propName: 'attackTypeList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          ...attackTypeList
            .map(({ name: text, id: value }) => ({ value, text }))
            .sort(sortByText),
        ],
      },
      {
        label: 'Испытание',
        title: 'Требуется ли проходить испытание?',
        type: FILTER_TYPE_SELECT,
        propName: 'useSaveThrow',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
      {
        label: 'Тип испытания',
        type: FILTER_TYPE_SELECT,
        propName: 'saveThrowParamList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой',
          },
          ...paramList
            .map(({ name: text, id: value }) => ({ value, text })),
        ],
      },
      {
        label: 'Урон',
        title: 'Наносит ли заклинание кому-либо урон?',
        type: FILTER_TYPE_SELECT,
        propName: 'inflictDamage',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
      {
        label: 'Тип урона',
        type: FILTER_TYPE_SELECT,
        propName: 'damageTypeList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой',
          },
          ...damageTypeList
            .filter(({ isRealDamageType }) => isRealDamageType)
            .map(({ name: text, id: value }) => ({ value, text })),
        ],
      },
      {
        label: 'Состояние',
        title: 'Вводит ли заклинание существ в какие-либо состояния?',
        type: FILTER_TYPE_SELECT,
        propName: 'conditionList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          ...conditionList
            .map(({ name: text, id: value, description }) => ({ value, text, title: `${text}\n${description}` })),
        ],
      },
    ],
  },
  {
    label: 'Введите название заклинания на русском или английском',
    type: FILTER_TYPE_INPUT,
    propName: SEARCH_PROP_NAME,
    value: DEFAULT_FILTER_VALUE,
  },
]
