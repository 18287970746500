import PropTypes from "prop-types"
import React from "react"

import {GENDER_MALE} from '@/constants/genderList'

import Ability from '@/components/Ability'
import Markdown from '@/components/Markdown'
import SpellCast from '@/components/SpellCast'

import updateNameAtDescription from '@/utils/updateNameAtDescription'

import generateAbilityHeader from './utils/generateAbilityHeader'

import "./AbilityListStyles.less"

const AbilityListComponent = (
  {
    data,
    entry,
    header,
    genderId,
    list,
    name,
    spellCast,
  }
) => (
  <section className='AbilityList'>
    {
      header && (
        <header className='AbilityList_header'>{header}</header>
      )
    }
    {
      entry && (
        <Markdown className='AbilityList_entry'>
          {updateNameAtDescription({genderId, description: entry, name})}
        </Markdown>
      )
    }

    <ul className='AbilityList_list'>
      {
        list.length
          ? list.map(
            ({description, ...rest}, i) => {
              const itemHeader = generateAbilityHeader(rest)
              const updatedDescription = updateNameAtDescription({genderId, description, name})

              return (
                <Ability
                  header={itemHeader}
                  key={i}
                >
                  {updatedDescription}
                </Ability>
              )
            }
          )
          : null
      }
      {
        spellCast
          ? (
            <SpellCast
              {...data}
              spellCast={spellCast}
              genderId={genderId}
              name={name}
            />
          )
          : null
      }
    </ul>
  </section>
)

AbilityListComponent.propTypes = {
  entry: PropTypes.string,
  header: PropTypes.string,
  genderId: PropTypes.string,
  list: PropTypes.array,
  name: PropTypes.string,
  spellCast: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

AbilityListComponent.defaultProps = {
  entry: '',
  header: '',
  genderId: GENDER_MALE,
  list: [],
  name: '',
  spellCast: null,
}

export default AbilityListComponent
