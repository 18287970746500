import React from "react"

import sortByName from '@/utils/sortByName'

import {conditionCollection} from '@/constants/conditionList'

import StatInfoRow from "@/components/StatInfoRow";

import "./ImmunityConditionListStyles.less";

export default ({list, header}) => (
  <StatInfoRow header={header}>
    {
      list
        .map(
          id => {
            const {name, description} = conditionCollection[id]
            const title = `${name}\n${description}`

            return {
              name,
              title,
            }
          }
        )
        .sort(sortByName)
        .map(
          ({name, title}) => (
            <span
              className='ImmunityCondition'
              title={title}
            >
              {name.toLowerCase()}
            </span>
          )
        )
    }
  </StatInfoRow>
)
