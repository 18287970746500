import {
  GEAR_CHATKCHA,
  GEAR_GYTHKA,
} from '@/constants/gearIdList'
import generateTextLinks from '@/utils/generateTextLinks'
import {CREATURE_THRI_KREEN} from '@/constants/creatureIdList'
import {faGavel} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Три-кин с оружием',
  templateIcon: faGavel,
  templateColor: 'steelBlue',
  templateLimitations: {
    include: {
      id: [
        CREATURE_THRI_KREEN,
      ],
    },
  },

  name: 'Три-кин с оружием',

  extendPropCollection: {
    description: [
      {
        header: `Вариант: Оружие три-кринов`,
        text: generateTextLinks(`Некоторые [три-крины](CREATURE:${CREATURE_THRI_KREEN}) используют особое воинское оружие. [Гитка](GEAR:${GEAR_GYTHKA}) это двуручное древковое оружие с клинками на каждом конце. [Чаткча](GEAR:${GEAR_CHATKCHA}) это плоское оружие с тремя треугольными лезвиями (лёгкое метательное оружие).

Три-крин, вооружённый [гиткой](GEAR:${GEAR_GYTHKA}) и [чаткчей](GEAR:${GEAR_CHATKCHA}), получает следующие варианты действий:

**Мультиатака.** Три-крин совершает две атаки [гиткой](GEAR:${GEAR_GYTHKA}) или две атаки [чаткчей](GEAR:${GEAR_CHATKCHA}).

**[Гитка](GEAR:${GEAR_GYTHKA}).** Рукопашная атака оружием: +3 к попаданию, досягаемость 5 фт., одна цель. Попадание: Рубящий урон 5 (1к8 + 1).

**[Чаткча](GEAR:${GEAR_CHATKCHA}).** Дальнобойная атака оружием: +4 к попаданию, дистанция 30/120 фт., одна цель. Попадание: Рубящий урон 5 (1к6 + 2).`),
        source: {
          id: SOURCE_MM,
          page: 284,
        },
      },
    ],
    actionList: [
      {
        gearId: GEAR_GYTHKA,
      },
      {
        gearId: GEAR_CHATKCHA,
      },
    ],
  },

  editPropCollection: {
    actionList: ({ actionList }) => actionList.map(
      item => item.name === 'Мультиатака'
        ? {
          ...item,
          description: `Три-крин совершает две атаки гиткой или две атаки чаткчей или одну укусом, и одну когтями.`,
        }
        : item,
    ),
  },
}
