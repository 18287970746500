import {
  CREATURE_CULT_FANATIC,
  CREATURE_CULTIST,
  CREATURE_STAR_SPAWN_GRUE,
  CREATURE_STAR_SPAWN_HULK,
  CREATURE_STAR_SPAWN_LARVA_MAGE,
  CREATURE_STAR_SPAWN_MANGLER,
  CREATURE_STAR_SPAWN_SEER,
} from '@/constants/creatureIdList'
import { faPrayingHands } from '@fortawesome/free-solid-svg-icons'
import { SOURCE_MTOF } from '@/constants/sourceList'

export default [
  {
    templateName: 'Культ Борема из Озера Кипящей Грязи',
    templateIcon: faPrayingHands,
    templateColor: 'brown',
    namePostText: ' из культа Борема из Озера Кипящей Грязи',
    action: {
      name: 'Объятия Борема',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `Культист прикасается к существу в пределах 5 футов от себя. Цель должна пройти испытание Ловкости СЛ 15, иначе покроется липкой, бурлящей грязью. Пока существо покрыто этой грязью, его скорость снижается вдвое, оно не может использовать реакции, и получает урон огнём 10 (3к6) в начале каждого своего хода. Эффект длится 1 минуту, пока культиста не обезвредят или не убьют, либо пока цель не окунётся в воду.`,
    },
  },
  {
    templateName: 'Культ Атропуса, Мертворождённого Мира',
    templateIcon: faPrayingHands,
    templateColor: 'black',
    namePostText: ' из культа Атропуса, Мертворождённого Мира',
    action: {
      name: 'Взгляд разложения',
      restore: 6,
      description: `Культист прицеливается на существо, которое может видеть в пределах 30 футов от себя. Цель должна пройти испытание Телосложения СЛ 15, иначе получит урон некротической энергией 16 (3к10) и отравится на 1 минуту. Отравленное существо может повторить испытание в конце каждого своего хода, закончив эффект на себе при успехе.`,
    },
  },
  {
    templateName: 'Культ Хааска, Голоса Харгута',
    templateIcon: faPrayingHands,
    templateColor: 'crimson',
    namePostText: ' из культа Хааска, Голоса Харгута',
    action: {
      name: 'Присутствие Хааска',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `Культист превращается в Крошечное пиявкоподобное существо и телепортируется на плечо гуманоида, которое оно может видеть в пределах 30 футов от себя. Этот гуманоид должен пройти испытание Харизмы СЛ 15, иначе будет очаровано культистом. Пока цель очарована, культист имеет контроль над ней в следующий её ход. В конце этого хода, культист телепортируется в любое свободное место, которое он видит в пределах 30 футов от себя и возвращается в прежнюю форму. В культиста невозможно прицелиться напрямую любой атакой или любым другим эффектом, пока он находится в пиявкоподобной форме, но он всё ещё подвержен зональным атакам и эффектам.`,
    },
  },
  {
    templateName: 'Культ Тирантраксуса, Горящего',
    templateIcon: faPrayingHands,
    templateColor: 'red',
    namePostText: ' из культа Тирантраксуса, Горящего',
    action: {
      name: 'Лучезарные Огни',
      limit: {
        count: 1,
        period: 'день',
      },
      description: `Пёстрые огни окружают культиста на 1 минуту, пока его не убьют или обезвредят, либо пока культист сам не погасит огонь (не требует действия). Будучи окружённым в пламени, у культиста появляется телепатия с дальностью 30 футов, и он может, бонусным действием, телепортироваться на свободное пространство на расстоянии до 30 футов, которое он может видеть. Кроме того, каждое существо, начинающее свой ход и находящееся в пределах 5 футов от этого культиста, должно пройти испытание Ловкости СЛ 15, получая, при провале, урон излучением 16 (3к10), либо половину этого урона при успехе.`,
    },
  },
  {
    templateName: 'Культ Тариздуна, Закованного Бога',
    templateIcon: faPrayingHands,
    templateColor: 'steel',
    namePostText: ' из культа Тариздуна, Закованного Бога',
    action: {
      name: 'Искра Тариздуна',
      restore: 6,
      description: `Бонусным действием культист прикасается к обычному, боевому или природному оружию, если таковое имеется. Следующие существо, по которому попадёт атака таким оружием, должно пройти испытание СЛ 15, иначе на 10 минут испытает [кратковременное безумие](/generator-madness). Пострадавшее существо может повторить испытание в конце каждой минуты, закончив эффект при успехе.`,
    },
  },
]
  .map(
    ({
       action,
       namePostText,
       templateColor,
       templateIcon,
       templateName,
     }) => ({
      templateName,
      templateIcon,
      templateColor,

      isAlreadyHasAncientPower: true,

      templateLimitations: {
        exclude: {
          isAlreadyHasAncientPower: true,
        },
        include: {
          id: [
            CREATURE_CULT_FANATIC,
            CREATURE_CULTIST,
            CREATURE_STAR_SPAWN_GRUE,
            CREATURE_STAR_SPAWN_HULK,
            CREATURE_STAR_SPAWN_LARVA_MAGE,
            CREATURE_STAR_SPAWN_MANGLER,
            CREATURE_STAR_SPAWN_SEER,
          ],
        },
      },

      extendPropCollection: {
        description: [
          {
            header: 'Благословения Древних',
            text: `На протяжении многих поколений исследований и жутких опытов, адепты некоторых Древних овладели способность даровать сверхъестественные способности последователям, заслужившим это. Любое существо, служащее культам Древних, включая звёздные порождения, может получить один из этих даров… обычно в качестве компенсации за верную службу, но иногда как шанс искупления для существа, которое нарушило законы культа. Нижеследующие способности уникальны для каждого конкретного культа, и обычно, существо может обладать только не более чем одной способностью.

# Культ Борема из Озера Кипящей Грязи

**Объятия Борема (1/день).** Культист прикасается к существу в пределах 5 футов от себя. Цель должна пройти испытание Ловкости СЛ 15, иначе покроется липкой, бурлящей грязью. Пока существо покрыто этой грязью, его скорость снижается вдвое, оно не может использовать реакции, и получает урон огнём 10 (3к6) в начале каждого своего хода. Эффект длится 1 минуту, пока культиста не обезвредят или не убьют, либо пока цель не окунётся в воду.

# Культ Атропуса, Мертворождённого Мира

**Взгляд разложения (перезарядка 6).** Культист прицеливается на существо, которое может видеть в пределах 30 футов от себя. Цель должна пройти испытание Телосложения СЛ 15, иначе получит урон некротической энергией 16 (3к10) и отравится на 1 минуту. Отравленное существо может повторить испытание в конце каждого своего хода, закончив эффект на себе при успехе.

# Культ Хааска, Голоса Харгута

**Присутствие Хааска (1/день).** Культист превращается в Крошечное пиявкоподобное существо и телепортируется на плечо гуманоида, которое оно может видеть в пределах 30 футов от себя. Этот гуманоид должен пройти испытание Харизмы СЛ 15, иначе будет очаровано культистом. Пока цель очарована, культист имеет контроль над ней в следующий её ход. В конце этого хода, культист телепортируется в любое свободное место, которое он видит в пределах 30 футов от себя и возвращается в прежнюю форму. В культиста невозможно прицелиться напрямую любой атакой или любым другим эффектом, пока он находится в пиявкоподобной форме, но он всё ещё подвержен зональным атакам и эффектам.

# Культ Тирантраксуса, Горящего

**Лучезарные Огни (1/день).** Пёстрые огни окружают культиста на 1 минуту, пока его не убьют или обезвредят, либо пока культист сам не погасит огонь (не требует действия). Будучи окружённым в пламени, у культиста появляется телепатия с дальностью 30 футов, и он может, бонусным действием, телепортироваться на свободное пространство на расстоянии до 30 футов, которое он может видеть. Кроме того, каждое существо, начинающее свой ход и находящееся в пределах 5 футов от этого культиста, должно пройти испытание Ловкости СЛ 15, получая, при провале, урон излучением 16 (3к10), либо половину этого урона при успехе.

# Культ Тариздуна, Закованного Бога

**Искра Тариздуна (перезарядка 6).** Бонусным действием, культист прикасается к обычному, боевому или природному оружию, если таковое имеется. Следующие существо, по которому попадёт атака таким оружием, должно пройти испытание СЛ 15, иначе на 10 минут испытает [кратковременное безумие](/generator-madness). Пострадавшее существо может повторить испытание в конце каждой минуты, закончив эффект при успехе.`,
            source: {
              id: SOURCE_MTOF,
              page: 223,
            },
          },
        ],
        source: [{
          id: SOURCE_MTOF,
          page: 223,
        }],
        actionList: [action],
      },
      editPropCollection: {
        name: ({ name }) => `${name}${namePostText}`,
      },
    }),
  )
