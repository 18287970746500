import {CREATURE_YELLOW_MUSK_ZOMBIE} from '@/constants/creatureIdList'
import {faChild} from '@fortawesome/free-solid-svg-icons'
import {SIZE_SMALL} from '@/constants/sizeList'
import {SOURCE_TOA} from '@/constants/sourceList'

export default {
  templateName: 'Маленький жёлтый мускусный зомби',
  templateIcon: faChild,
  templateColor: 'orange',

  templateLimitations: {
    include: {
      id: [
        CREATURE_YELLOW_MUSK_ZOMBIE,
      ],
    },
  },

  sizeType: SIZE_SMALL,

  extendPropCollection: {
    hp: {
      diceType: 6,
    },
    source: [{
      id: SOURCE_TOA,
      page: 224,
    }],
  },

  editPropCollection: {
    name: ({ name }) => `Маленький ${name}`,
  },
}
