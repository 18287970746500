import {
  CREATURE_DRAGON_BLACK_ADULT,
  CREATURE_DRAGON_BLUE_ADULT,
  CREATURE_DRAGON_BRASS_ADULT,
  CREATURE_DRAGON_BRONZE_ADULT,
  CREATURE_DRAGON_COPPER_ADULT,
  CREATURE_DRAGON_GOLD_ADULT,
  CREATURE_DRAGON_GREEN_ADULT,
  CREATURE_DRAGON_RED_ADULT,
  CREATURE_DRAGON_SILVER_ADULT,
  CREATURE_DRAGON_WHITE_ADULT,
  CREATURE_DRAGON_BLACK_ANCIENT,
  CREATURE_DRAGON_BLUE_ANCIENT,
  CREATURE_DRAGON_BRASS_ANCIENT,
  CREATURE_DRAGON_BRONZE_ANCIENT,
  CREATURE_DRAGON_COPPER_ANCIENT,
  CREATURE_DRAGON_GOLD_ANCIENT,
  CREATURE_DRAGON_GREEN_ANCIENT,
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_DRAGON_SILVER_ANCIENT,
  CREATURE_DRAGON_WHITE_ANCIENT,
  CREATURE_PSEUDODRAGON,
  CREATURE_WYVERN,
} from '@/constants/creatureIdList'
import {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_POISONED,
} from '@/constants/conditionList'
import {
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_UNDEAD,
} from '@/constants/creatureTypeIdList'
import {
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
} from '@/constants/damageTypeList'
import generateTextLinks from '@/utils/generateTextLinks'
import {ABILITY_MAGIC_RESISTANCE} from '@/constants/creatureAbilityList'
import {faSkullCrossbones} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Драколич',
  templateIcon: faSkullCrossbones,
  templateColor: 'darkSlateGray',
  isAlreadyDracoLich: true,

  templateLimitations: {
    include: {
      id: [
        CREATURE_DRAGON_BLACK_ADULT,
        CREATURE_DRAGON_BLUE_ADULT,
        CREATURE_DRAGON_BRASS_ADULT,
        CREATURE_DRAGON_BRONZE_ADULT,
        CREATURE_DRAGON_COPPER_ADULT,
        CREATURE_DRAGON_GOLD_ADULT,
        CREATURE_DRAGON_GREEN_ADULT,
        CREATURE_DRAGON_RED_ADULT,
        CREATURE_DRAGON_SILVER_ADULT,
        CREATURE_DRAGON_WHITE_ADULT,
        CREATURE_DRAGON_BLACK_ANCIENT,
        CREATURE_DRAGON_BLUE_ANCIENT,
        CREATURE_DRAGON_BRASS_ANCIENT,
        CREATURE_DRAGON_BRONZE_ANCIENT,
        CREATURE_DRAGON_COPPER_ANCIENT,
        CREATURE_DRAGON_GOLD_ANCIENT,
        CREATURE_DRAGON_GREEN_ANCIENT,
        CREATURE_DRAGON_RED_ANCIENT,
        CREATURE_DRAGON_SILVER_ANCIENT,
        CREATURE_DRAGON_WHITE_ANCIENT,
      ],
      creatureTypeIdList: [
        CREATURE_TYPE_DRAGON,
      ],
    },
    exclude: {
      isAlreadyDarknessDragon: true,
      isAlreadyDracoLich: true,
    },
  },

  creatureTypeIdList: [CREATURE_TYPE_UNDEAD],

  editPropCollection: {
    name: ({ name }) => name.replace('дракон', 'драколич'),
    featureList: ({ featureList = [] }) => featureList.filter(
      ({ name }) => name !== 'Амфибия',
    ),
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 99,
    }],
    description: [
      {
        header: 'Шаблон драколича',
        text: generateTextLinks(`Только древний или взрослый истинный дракон может быть преобразован в драколича. Молодые драконы, которые пытаются пройти трансформацию, умирают, равно как и другие существа, не являющиеся истинными драконами, такие как [псевдодраконы](CREATURE:${CREATURE_PSEUDODRAGON}) и [виверны](CREATURE:${CREATURE_WYVERN}). Теневой дракон не может быть преобразован в драколича, поскольку он уже потерял слишком большую часть своего физического тела.

Когда дракон становится драколичем, он сохраняет свою статистику, за исключением пунктов, описанных ниже. Дракон теряет все особенности, такие как Амфибия, которые подразумевают живую физиологию. Драколич может сохранить или потерять некоторые или все свои действия логова, или унаследовать новые, в зависимости от решения Мастера.

**Вид.** Вид драколича меняется с «дракон» на «нежить», и ему больше не требуется воздух, еда, питьё и сон.

**Сопротивление к урону.** Драколич получает сопротивление к урону некротической энергией.

**Иммунитет к урону.** Драколич получает иммунитет к яду. Он также сохраняет все иммунитеты, которыми обладал до того, как стал нежитью.

**Иммунитет к состоянию.** Драколич не может быть очарован, испуган, парализован или отравлен. Он также не страдает от истощения.

**Сопротивление магии.** Драколич с преимуществом проходит испытания от заклинаний и прочих магических эффектов.`),
        source: {
          id: SOURCE_MM,
          page: 99,
        },
      },
      {
        header: 'Драколич',
        text: `Даже таким долгожителям как драконы когда-то приходится умирать. Осознание этого многим драконам не даёт покоя, и некоторые из них позволяют с помощью некромантии и древних ритуалов превращать себя в могущественную нежить — драколичей. Только самые самовлюблённые драконы выбирают этот путь, зная, что в результате они оборвут все связи со своими сородичами и богами драконов.

**По ту сторону смерти.** При трансформации драколич сохраняет свою форму и размеры, его кожа и чешуя плотно прилегают к костям или же наоборот, сбрасываются, оставляя голый скелет. Его глаза выглядят как пылающие точки света, плавающие в тёмных впадинах, и намекающие на недоброжелательность неживого разума.

Многие драконы преследуют тщеславные цели уничтожения и доминирования, но драколичи сильнее самых злых драконов движимы жаждой власти над всеми. Драколичи являются дьявольски умными тиранами, плетущими паутину из бесчестных схем и планов, завлекая прислужников, движимых алчностью и жаждой власти. Действуя из тени и тщательно стараясь сохранить своё существование в секрете, драколич представляет собой хитрого и опасного противника.

**Филактерий драколича.** Создание драколича требует сотрудничества дракона и группы магов или культистов, способных провести надлежащий ритуал. Во время ритуала дракон поглощает токсичное варево, мгновенно его убивающее. Заклинатели при этом уже готовы опутать его дух, чтобы поместить его в специальный драгоценный камень, функционирующий как филактерий лича. Когда плоть дракона изгнивает, дух заключённый внутри камня, возвращается и оживляет драконьи кости.

Если физическое тело драколича когда-нибудь будет разрушено, его дух вернётся в драгоценный камень, если они находятся на одном плане. Если драгоценный камень прикоснётся к трупу другого дракона, дух драколича может овладеть этим телом, чтобы стать новым драколичем. Если духовный камень драколича будет находиться на другом плане, духу драколича будет некуда идти при разрушении его тела, и он просто уйдёт в посмертие.`,
        source: {
          id: SOURCE_MM,
          page: 99,
        },
      },
    ],
    resistanceList: [
      DAMAGE_NECROTIC,
    ],
    immunityList: [
      DAMAGE_POISON,
    ],
    immunityConditionList: [
      CONDITION_FRIGHTENED,
      CONDITION_POISONED,
      CONDITION_CHARMED,
      CONDITION_PARALYZED,
      CONDITION_EXHAUSTION,
    ],
    featureList: [
      ABILITY_MAGIC_RESISTANCE,
    ],
  },
}
