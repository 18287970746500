import {
  SPEED_FLY,
  SPEED_WALK,
} from '@/constants/speedList'
import {
  CREATURE_TYPE_AARAKOCRA,
  CREATURE_TYPE_ANY_RACE,
} from '@/constants/creatureTypeIdList'
import {
  PARAM_DEX,
  PARAM_WIT,
} from '@/constants/paramList'
import {
  LANG_AARAKOCRA,
  LANG_AURAN,
} from '@/constants/languageIdList'
import {ABILITY_DIVE_ATTACK} from '@/constants/creatureAbilityList'
import {ATTACK_MELEE_WEAPON} from '@/constants/attackTypeList'
import {DAMAGE_SLASHING} from '@/constants/damageTypeList'
import {faDove} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_DMG} from '@/constants/sourceList'

export default {
  templateName: 'Ааракокра',
  templateIcon: faDove,
  templateColor: 'pink',
  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_ANY_RACE,
      ],
    },
  },

  speed: {
    [SPEED_WALK]: 20,
    [SPEED_FLY]: 50,
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 283,
    }],
    actionList: [{
      name: 'Коготь',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 4,
        range: 5,
        hit: {
          type: DAMAGE_SLASHING,
          diceCount: 1,
          diceType: 4,
          diceBonus: 2,
        },
      },
    }],
    featureList: [{
      id: ABILITY_DIVE_ATTACK,
      damageStr: '3 (1к6)',
    }],
    languageList: [
      LANG_AARAKOCRA,
      LANG_AURAN,
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Ааракокра ${name}`,
    creatureTypeIdList: ({ creatureTypeIdList }) => creatureTypeIdList.map(
      creatureTypeId => creatureTypeId === CREATURE_TYPE_ANY_RACE
        ? CREATURE_TYPE_AARAKOCRA
        : creatureTypeId
    ),
    params: ({ params }) => ({
      ...params,
      [PARAM_DEX]: params[PARAM_DEX] + 2,
      [PARAM_WIT]: params[PARAM_WIT] + 2,
    }),
  },
}
