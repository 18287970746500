import React from "react"
import PropTypes from 'prop-types'

import {sizeCollection} from "@/constants/sizeList"

import {GENDER_FEMALE} from "@/constants/genderList"

import upLetter from "@/utils/upLetter"

import getCreatureTypeGenderId from "./utils/getCreatureTypeGenderId"

import "./CreatureSizeStyles.less"

const CreatureSizeComponent = (
  {
    creatureTypeIdList,
    isVehicle,
    sizeType,
    spaceAfter = false,
    swarmMemberSizeType,
  }
) => {
  const genderId = isVehicle
    ? GENDER_FEMALE
    : getCreatureTypeGenderId(creatureTypeIdList)

  let text = ''
  let title = ''

  if (swarmMemberSizeType) {
    const size = sizeCollection[sizeType].name.singular[genderId].nominative
    const {description, name: {plural: {genitive: swarmMemberSize}}} = sizeCollection[swarmMemberSizeType]
    text = `${size} рой ${swarmMemberSize}`
    title = description
  } else {
    text = sizeCollection[sizeType].name.singular[genderId].nominative
    title = sizeCollection[sizeType].description
  }

  return (
    <span
      className='CreatureSize'
      title={title}
    >
      {upLetter(text)}{spaceAfter ? ' ' : ''}
    </span>
  )
}

CreatureSizeComponent.propTypes = {
  creatureTypeIdList: PropTypes.array.isRequired,
  isVehicle: PropTypes.bool,
  sizeType: PropTypes.string.isRequired,
  swarmMemberSizeType: PropTypes.string,
}

CreatureSizeComponent.defaultProps = {
  isVehicle: false,
  swarmMemberSizeType: null,
}

export default CreatureSizeComponent
