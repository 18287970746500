import generateTextLinks from '@/utils/generateTextLinks'
import {CREATURE_QUASIT} from '@/constants/creatureIdList'
import {faUserFriends} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Квазит-фамильяр',
  templateIcon: faUserFriends,
  templateColor: 'indigo',

  templateLimitations: {
    include: {
      id: [
        CREATURE_QUASIT,
      ],
    },
  },
  extendPropCollection: {
    description: [
      {
        header: 'Вариант: Фамильяр квазит',
        text: generateTextLinks(`Смертные заклинатели, которые хотят фамильяра с другого плана, считают [квазита](CREATURE:${CREATURE_QUASIT}) лёгким для призыва и служения. Квазиты — раболепные слуги. Они великолепно служат своим хозяевам, подвигая смертных на всё большее зло и хаос. Такие квазиты обладают следующей особенностью:

**Фамильяр.** Квазит может служить другому существу фамильяром, создав телепатическую связь с желающим того хозяином. Пока они связаны, хозяин может ощущать то же, что и квазит, пока они находятся в пределах мили друг от друга. Пока квазит находится в пределах 10 футов от хозяина, на хозяина распространяется особенность квазита Сопротивление магии. В любое время и по любой причине квазит может прекратить службу, оборвав телепатическую связь.`),
        source: {
          id: SOURCE_MM,
          page: 91,
        },
      },
    ],
    featureList: [
      {
        name: 'Фамильяр',
        description: `Квазит может служить другому существу фамильяром, создав телепатическую связь с желающим того хозяином. Пока они связаны, хозяин может ощущать то же, что и квазит, пока они находятся в пределах мили друг от друга. Пока квазит находится в пределах 10 футов от хозяина, на хозяина распространяется особенность квазита _Сопротивление магии_. В любое время и по любой причине квазит может прекратить службу, оборвав телепатическую связь.`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace('Квазит', 'Квазит-фамильяр'),
  },
}
