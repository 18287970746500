const {
  CREATURE_FIRE_GIANT,
  CREATURE_FIRE_GIANT_DREADNOUGHT,
} = require('./../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_MM,
  SOURCE_PHB,
  SOURCE_SKT,
  SOURCE_VGTM,
} = require('./../../../sourceList')
const {
  Giant_Fights_SKT_data,
  Fire_Giants_Attack_SKT_data,
} = require('./../../commonImageCollection')

const Duke_Zalto_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/duke_zalto.jpg')
const Duke_Zalto_SKT_img = require('./../../../../images/creatures/skt/duke_zalto.jpg')
const Fire_Giant_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Giant_Fire.jpg')
const Fire_Giant_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/fire_giant.jpg')
const Fire_Giant_Dreadnought_VGTM_img = require(`./../../../../images/creatures/vgtm/fire_giant_dreadnought.jpeg`)
const Fire_giant_king_VGTM_img = require('./../../../../images/creatures/vgtm/fire_giant_king.png')
const Fire_Giant_MM_img = require('./../../../../images/creatures/mm/fire_giant.jpeg')
const Fire_Giant_PHB_img = require('./../../../../images/creatures/phb/fire_giant.jpg')

module.exports = [
  {
    src: Fire_Giant_MM_img,
    creatureId: CREATURE_FIRE_GIANT,
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Fire_Giant_Dreadnought_VGTM_img,
    creatureId: CREATURE_FIRE_GIANT_DREADNOUGHT,
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: Fire_Giant_AYAGMnC_img,
    creatureId: CREATURE_FIRE_GIANT,
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Fire_Giant_PHB_img,
    creatureId: CREATURE_FIRE_GIANT,
    source: {
      id: SOURCE_PHB,
    },
  },
  {
    src: Duke_Zalto_AYAGMnC_img,
    text: 'Залто, герцог огненных великанов',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Duke_Zalto_SKT_img,
    text: 'Залто, герцог огненных великанов',
    source: {
      id: SOURCE_SKT,
    },
  },
  {
    src: Fire_giant_king_VGTM_img,
    text: 'Герцог огненных великанов',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Giant_Fights_SKT_data,
  Fire_Giants_Attack_SKT_data,
  {
    src: Fire_Giant_ADND2_img,
    creatureId: CREATURE_FIRE_GIANT,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
