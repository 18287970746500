const {
  CREATURE_ALHOON,
  CREATURE_ANNIS_HAG,
  CREATURE_APPRENTICE_WIZARD,
  CREATURE_ARCHDRUID,
  CREATURE_ARCHER,
  CREATURE_AUROCHS,
  CREATURE_BABAU,
  CREATURE_BANDERHOBB,
  CREATURE_BARD,
  CREATURE_BARGHEST,
  CREATURE_BHEUR_HAG,
  CREATURE_BODAK,
  CREATURE_BOGGLE,
  CREATURE_BRONTOSAURUS,
  CREATURE_CAVE_FISHER,
  CREATURE_CHAMPION,
  CREATURE_CHITINE,
  CREATURE_CHOLDRITH,
  CREATURE_CLOUD_GIANT_SMILING_ONE,
  CREATURE_CRANIUM_RAT,
  CREATURE_DARKLING,
  CREATURE_DARKLING_ELDER,
  CREATURE_DEATH_KISS,
  CREATURE_DEEP_SCION,
  CREATURE_DEVOURER,
  CREATURE_DRAEGLOTH,
  CREATURE_ELDER_BRAIN,
  CREATURE_FIRE_GIANT_DREADNOUGHT,
  CREATURE_FIRENEWT_WARLOCK_OF_IMIX,
  CREATURE_FIRENEWT_WARRIOR,
  CREATURE_FLAIL_SNAIL,
  CREATURE_FLIND,
  CREATURE_FROGHEMOTH,
  CREATURE_FROST_GIANT_EVERLASTING_ONE,
  CREATURE_GAUTH,
  CREATURE_GAZER,
  CREATURE_GIANT_STRIDER,
  CREATURE_GIRALLON,
  CREATURE_GNOLL_FLESH_GNAWER,
  CREATURE_GNOLL_HUNTER,
  CREATURE_GNOLL_WITHERLING,
  CREATURE_GRUNG,
  CREATURE_GRUNG_ELITE_WARRIOR,
  CREATURE_GRUNG_WILDLING,
  CREATURE_GUARD_DRAKE,
  CREATURE_GUARD_DRAKE_BLACK,
  CREATURE_GUARD_DRAKE_BLUE,
  CREATURE_GUARD_DRAKE_GREEN,
  CREATURE_GUARD_DRAKE_RED,
  CREATURE_GUARD_DRAKE_WHITE,
  CREATURE_HOBGOBLIN_DEVASTATOR,
  CREATURE_HOBGOBLIN_IRON_SHADOW,
  CREATURE_ILLITHILICH,
  CREATURE_KI_RIN,
  CREATURE_KOBOLD_DRAGONSHIELD_BLACK,
  CREATURE_KOBOLD_DRAGONSHIELD_BLUE,
  CREATURE_KOBOLD_DRAGONSHIELD_GREEN,
  CREATURE_KOBOLD_DRAGONSHIELD_RED,
  CREATURE_KOBOLD_DRAGONSHIELD_WHITE,
  CREATURE_KOBOLD_INVENTOR,
  CREATURE_KOBOLD_SCALE_SORCERER,
  CREATURE_KORRED,
  CREATURE_KRAKEN_PRIEST,
  CREATURE_LEUCROTTA,
  CREATURE_LICH,
  CREATURE_MAW_DEMON,
  CREATURE_MEENLOCK,
  CREATURE_MINDWITNESS,
  CREATURE_MORKOTH,
  CREATURE_MOUTH_OF_GROLANTOR,
  CREATURE_NEOGI,
  CREATURE_NEOGI_HATCHLING,
  CREATURE_NEOGI_MASTER,
  CREATURE_NEOTHELID,
  CREATURE_NILBOG,
  CREATURE_ORC,
  CREATURE_ORC_BLADE_OF_ILNEVAL,
  CREATURE_ORC_CLAW_OF_LUTHIC,
  CREATURE_ORC_HAND_OF_YURTRUS,
  CREATURE_ORC_NURTURED_ONE_OF_YURTRUS,
  CREATURE_ORC_RED_FANG_OF_SHARGAAS,
  CREATURE_QUICKLING,
  CREATURE_REDCAP,
  CREATURE_SEA_SPAWN,
  CREATURE_SHADOW_MASTIFF,
  CREATURE_SHADOW_MASTIFF_ALPHA,
  CREATURE_SHOOSUVA,
  CREATURE_SLITHERING_TRACKER,
  CREATURE_SPAWN_OF_KYUSS,
  CREATURE_STEGOSAURUS,
  CREATURE_STONE_GIANT_DREAMWALKER,
  CREATURE_STORM_GIANT_QUINTESSENT,
  CREATURE_SWARM_OF_CRANIUM_RATS,
  CREATURE_SWASHBUCKLER,
  CREATURE_TANARUKK,
  CREATURE_THORNY,
  CREATURE_TLINCALLI,
  CREATURE_TRAPPER,
  CREATURE_ULITHARID,
  CREATURE_VARGOUILLE,
  CREATURE_VEGEPYGMY,
  CREATURE_VEGEPYGMY_CHIEF,
  CREATURE_WARLOCK_OF_THE_FIEND,
  CREATURE_WOOD_WOAD,
  CREATURE_XVART,
  CREATURE_XVART_SPEAKER,
  CREATURE_YETH_HOUND,
  CREATURE_YUAN_TI_ANATHEMA,
  CREATURE_YUAN_TI_BROODGUARD,
  CREATURE_YUAN_TI_MIND_WHISPERER,
  CREATURE_YUAN_TI_NIGHTMARE_SPEAKER,
  CREATURE_YUAN_TI_PIT_MASTER,
} = require('./../../creatureIdList'),
{
  SOURCE_MTG_CLB,
  SOURCE_TCoE,
} = require('./../../sourceList')

const {
  GoblinsImgList,
  BeholderkinList,
  GiantCloudImgList,
  GiantFireImgList,
  GiantFrostImgList,
  GiantHillImgList,
  GiantStoneImgList,
  GiantStormImgList,
  GnollImgList,
} = require('./../commonImageListCollection')

const {
  MindFlayer_ElderBrain_AYAGMnC_data,
  Ceremorphosis_VGTM_data,
  Cover_MPMotM_data,
  Druid_MM_data,
  Elder_brain_traveling_VGTM_data,
  Grungs_Green_OGA_list,
  Grungs_Orange_OGA_data,
  Grungs_Purple_OGA_data,
  Grungs_Red_OGA_data,
  Grungs_VGTM_data,
  Guard_drake_blue_HOTDQ_data,
  Hag_lair_VGTM_data,
  Hobgoblin_Devastator_VGTM_data,
  Hobgoblin_Iron_Shadow_VGTM_data,
  Kobold_Dragonshield_VGTM_data,
  Kobold_Inventor_VGTM_data,
  Kobold_Scale_sorcerer_VGTM_data,
  Kobold_Trap_VGTM_data,
  Orc_on_giant_bat_VGTM_data,
  Yuan_ti_mind_whisperer_VGTM_data,
  Yuan_ti_nightmare_speaker_VGTM_data,
  Yuan_ti_pit_master_VGTM_data,
  Yuan_ti_sacrifice_VGTM_data,
} = require('./../commonImageCollection')

const alhoon = require(`./../../../images/creatures/vgtm/alhoon.jpeg`)
const annis_hag = require(`./../../../images/creatures/vgtm/annis_hag.jpeg`)
const apprentice_wizard = require(`./../../../images/creatures/vgtm/apprentice_wizard.jpeg`)
const archer = require(`./../../../images/creatures/vgtm/archer.jpeg`)
const aurochs = require(`./../../../images/creatures/vgtm/aurochs.jpeg`)
const babau = require(`./../../../images/creatures/vgtm/babau.jpeg`)
const banderhobb = require(`./../../../images/creatures/vgtm/banderhobb.jpeg`)
const bard = require(`./../../../images/creatures/vgtm/bard.jpeg`)
const barghest = require(`./../../../images/creatures/vgtm/barghest.jpeg`)
const bheur_hag = require(`./../../../images/creatures/vgtm/bheur_hag.jpeg`)
const bodak = require(`./../../../images/creatures/vgtm/bodak.jpeg`)
const boggle = require(`./../../../images/creatures/vgtm/boggle.jpeg`)
const brontosaurus = require(`./../../../images/creatures/vgtm/brontosaurus.jpeg`)
const cave_fisher = require(`./../../../images/creatures/vgtm/cave_fisher.jpeg`)
const champion = require(`./../../../images/creatures/vgtm/champion.jpeg`)
const chitine = require(`./../../../images/creatures/vgtm/chitine.jpeg`)
const choldrith = require(`./../../../images/creatures/vgtm/choldrith.jpeg`)
const darkling = require(`./../../../images/creatures/vgtm/darkling.jpeg`)
const death_kiss = require(`./../../../images/creatures/vgtm/death_kiss.jpeg`)
const deep_scion = require(`./../../../images/creatures/vgtm/deep_scion.jpeg`)
const devourer = require(`./../../../images/creatures/vgtm/devourer.jpeg`)
const draegloth = require(`./../../../images/creatures/vgtm/draegloth.jpeg`)
const elder_brain = require(`./../../../images/creatures/vgtm/elder_brain.jpeg`)
const firenewt_warrior = require(`./../../../images/creatures/vgtm/firenewt_warrior.jpeg`)
const flail_snail = require(`./../../../images/creatures/vgtm/flail_snail.jpeg`)
const flind = require(`./../../../images/creatures/vgtm/flind.jpeg`)
const froghemoth = require(`./../../../images/creatures/vgtm/froghemoth.jpeg`)
const gauth = require(`./../../../images/creatures/vgtm/gauth.jpeg`)
const gazer = require(`./../../../images/creatures/vgtm/gazer.jpeg`)
const giant_strider = require(`./../../../images/creatures/vgtm/giant_strider.jpeg`)
const girallon = require(`./../../../images/creatures/vgtm/girallon.jpeg`)
const gnoll_flesh_gnawer = require(`./../../../images/creatures/vgtm/gnoll_flesh_gnawer.jpeg`)
const gnoll_witherling = require(`./../../../images/creatures/vgtm/gnoll_witherling.jpeg`)
const guard_drake_blue = require(`./../../../images/creatures/vgtm/guard_drake_blue.jpeg`)
const guard_drake_green = require(`./../../../images/creatures/vgtm/guard_drake_green.jpeg`)
const ki_rin = require(`./../../../images/creatures/vgtm/ki_rin.jpeg`)
const korred = require(`./../../../images/creatures/vgtm/korred.jpeg`)
const kraken_priest = require(`./../../../images/creatures/vgtm/kraken_priest.png`)
const leucrotta = require(`./../../../images/creatures/vgtm/leucrotta.jpeg`)
const maw_demon = require(`./../../../images/creatures/vgtm/maw_demon.jpeg`)
const meenlock = require(`./../../../images/creatures/vgtm/meenlock.jpeg`)
const mindwitness = require(`./../../../images/creatures/vgtm/mindwitness.jpeg`)
const morkoth = require(`./../../../images/creatures/vgtm/morkoth.jpeg`)
const neogiImg = require(`./../../../images/creatures/vgtm/neogi.jpeg`)
const neothelid = require(`./../../../images/creatures/vgtm/neothelid.jpeg`)
const nilbog = require(`./../../../images/creatures/vgtm/nilbog.jpeg`)
const orc_claw_of_luthic = require(`./../../../images/creatures/vgtm/orc_claw_of_luthic.jpeg`)
const orc_nurtured_one_of_yurtrus = require(`./../../../images/creatures/vgtm/orc_nurtured_one_of_yurtrus.jpeg`)
const quickling = require(`./../../../images/creatures/vgtm/quickling.jpeg`)
const redcap = require(`./../../../images/creatures/vgtm/redcap.jpeg`)
const sea_spawn = require(`./../../../images/creatures/vgtm/sea_spawn.jpeg`)
const stegosaurus = require(`./../../../images/creatures/vgtm/stegosaurus.jpeg`)
const shadow_mastiff = require(`./../../../images/creatures/vgtm/shadow_mastiff.jpeg`)
const shoosuva = require(`./../../../images/creatures/vgtm/shoosuva.jpeg`)
const slithering_tracker = require(`./../../../images/creatures/vgtm/slithering_tracker.jpeg`)
const spawn_of_kyuss = require(`./../../../images/creatures/vgtm/spawn_of_kyuss.jpeg`)
const swarm_of_cranium_rats = require(`./../../../images/creatures/vgtm/swarm_of_cranium_rats.jpeg`)
const swashbuckler = require(`./../../../images/creatures/vgtm/swashbuckler.jpeg`)
const tanarukk = require(`./../../../images/creatures/vgtm/tanarukk.jpeg`)
const tlincalli = require(`./../../../images/creatures/vgtm/tlincalli.jpeg`)
const trapper = require(`./../../../images/creatures/vgtm/trapper.jpeg`)
const ulitharid = require(`./../../../images/creatures/vgtm/ulitharid.jpeg`)
const vargouille = require(`./../../../images/creatures/vgtm/vargouille.jpeg`)
const vegepygmy_with_thorny = require(`./../../../images/creatures/vgtm/vegepygmy_with_thorny.jpeg`)
const warlock_of_the_fiend = require(`./../../../images/creatures/vgtm/warlock_of_the_fiend.jpeg`)
const wood_woad = require(`./../../../images/creatures/vgtm/wood_woad.jpeg`)
const xvart = require(`./../../../images/creatures/vgtm/xvart.jpeg`)
const yeth_hound = require(`./../../../images/creatures/vgtm/yeth_hound.jpeg`)
const yuan_ti_anathema = require(`./../../../images/creatures/vgtm/yuan_ti_anathema.jpeg`)
const yuan_ti_broodguard = require(`./../../../images/creatures/vgtm/yuan_ti_broodguard.jpeg`)

const lich = require(`./../../../images/creatures/mm/lich.jpeg`)
const orcImg = require(`./../../../images/creatures/mm/orc.jpeg`)

const Redcap_Img_TCoE = require(`./../../../images/creatures/tcoe/redcap.jpg`)

const Elder_Brain_MTG_CLB_img = require(`./../../../images/creatures/mtg_clb/elder-brain-mtg_clb.jpg`)
const Elder_Brain_alt_MTG_CLB_img = require(`./../../../images/creatures/mtg_clb/elder-brain-alt-mtg_clb.jpg`)

const moldCreatures = {
  src: vegepygmy_with_thorny,
  text: 'Вегепигмей и Колючка',
}

const darklings = {
  src: darkling,
  text: 'Дарклинги',
}

const neogis = {
  src: neogiImg,
  text: 'Неоги',
}

const orcImgData = {
  src: orcImg,
  creatureId: CREATURE_ORC,
}

const shadowMastiffImgData = {
  src: shadow_mastiff,
  creatureId: CREATURE_SHADOW_MASTIFF,
}

const swarmOfCraniumRatsImgData = {
  src: swarm_of_cranium_rats,
  creatureId: CREATURE_SWARM_OF_CRANIUM_RATS,
}

const drakeImgData = [
  {
    src: guard_drake_blue,
    creatureId: CREATURE_GUARD_DRAKE_BLUE,
  },
  Guard_drake_blue_HOTDQ_data,
  {
    src: guard_drake_green,
    creatureId: CREATURE_GUARD_DRAKE_GREEN,
  },
]

module.exports = {
  [CREATURE_ALHOON]: alhoon,
  [CREATURE_ANNIS_HAG]: [
    annis_hag,
    Hag_lair_VGTM_data,
  ],
  [CREATURE_APPRENTICE_WIZARD]: apprentice_wizard,
  [CREATURE_ARCHDRUID]: Druid_MM_data,
  [CREATURE_ARCHER]: archer,
  [CREATURE_AUROCHS]: aurochs,
  [CREATURE_BABAU]: babau,
  [CREATURE_BANDERHOBB]: banderhobb,
  [CREATURE_BARD]: bard,
  [CREATURE_BARGHEST]: barghest,
  [CREATURE_BHEUR_HAG]: [
    bheur_hag,
    Hag_lair_VGTM_data,
  ],
  [CREATURE_BODAK]: bodak,
  [CREATURE_BOGGLE]: boggle,
  [CREATURE_BRONTOSAURUS]: brontosaurus,
  [CREATURE_CAVE_FISHER]: cave_fisher,
  [CREATURE_CHAMPION]: champion,
  [CREATURE_CHITINE]: chitine,
  [CREATURE_CHOLDRITH]: choldrith,
  [CREATURE_CLOUD_GIANT_SMILING_ONE]: GiantCloudImgList,
  [CREATURE_CRANIUM_RAT]: swarmOfCraniumRatsImgData,
  [CREATURE_DARKLING]: darklings,
  [CREATURE_DARKLING_ELDER]: darklings,
  [CREATURE_DEATH_KISS]: [
    death_kiss,
    ...BeholderkinList,
  ],
  [CREATURE_DEEP_SCION]: deep_scion,
  [CREATURE_DEVOURER]: devourer,
  [CREATURE_DRAEGLOTH]: draegloth,
  [CREATURE_ELDER_BRAIN]: [
    elder_brain,
    MindFlayer_ElderBrain_AYAGMnC_data,
    Elder_brain_traveling_VGTM_data,
    Ceremorphosis_VGTM_data,
    {
      src: Elder_Brain_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
    {
      src: Elder_Brain_alt_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_FIRE_GIANT_DREADNOUGHT]: GiantFireImgList,
  [CREATURE_FIRENEWT_WARLOCK_OF_IMIX]: {
    src: firenewt_warrior,
    creatureId: CREATURE_FIRENEWT_WARRIOR,
  },
  [CREATURE_FIRENEWT_WARRIOR]: firenewt_warrior,
  [CREATURE_FLAIL_SNAIL]: flail_snail,
  [CREATURE_FLIND]: flind,
  [CREATURE_FROGHEMOTH]: froghemoth,
  [CREATURE_FROST_GIANT_EVERLASTING_ONE]: GiantFrostImgList,
  [CREATURE_GAUTH]: [
    gauth,
    ...BeholderkinList,
  ],
  [CREATURE_GAZER]: [
    gazer,
    ...BeholderkinList,
  ],
  [CREATURE_GIANT_STRIDER]: {
    src: giant_strider,
    text: 'Огненный тритон воин верхом на гигантском ходуне',
  },
  [CREATURE_GIRALLON]: girallon,
  [CREATURE_GNOLL_FLESH_GNAWER]: [
    gnoll_flesh_gnawer,
    ...GnollImgList,
  ],
  [CREATURE_GNOLL_HUNTER]: GnollImgList,
  [CREATURE_GNOLL_WITHERLING]: [
    gnoll_witherling,
    ...GnollImgList,
  ],
  [CREATURE_GRUNG]: [
    Grungs_VGTM_data,
    Grungs_Purple_OGA_data,
    ...Grungs_Green_OGA_list,
  ],
  [CREATURE_GRUNG_ELITE_WARRIOR]: [
    Grungs_VGTM_data,
    Grungs_Orange_OGA_data,
  ],
  [CREATURE_GRUNG_WILDLING]: [
    Grungs_VGTM_data,
    Grungs_Red_OGA_data,
  ],
  [CREATURE_GUARD_DRAKE]: drakeImgData,
  [CREATURE_GUARD_DRAKE_BLACK]: drakeImgData,
  [CREATURE_GUARD_DRAKE_BLUE]: [
    guard_drake_blue,
    Guard_drake_blue_HOTDQ_data,
  ],
  [CREATURE_GUARD_DRAKE_GREEN]: guard_drake_green,
  [CREATURE_GUARD_DRAKE_RED]: drakeImgData,
  [CREATURE_GUARD_DRAKE_WHITE]: drakeImgData,
  [CREATURE_HOBGOBLIN_DEVASTATOR]: Hobgoblin_Devastator_VGTM_data,
  [CREATURE_HOBGOBLIN_IRON_SHADOW]: Hobgoblin_Iron_Shadow_VGTM_data,
  [CREATURE_ILLITHILICH]: [
    {
      src: alhoon,
      creatureId: CREATURE_ALHOON,
    },
    {
      src: lich,
      creatureId: CREATURE_LICH,
    },
  ],
  [CREATURE_KI_RIN]: [
    ki_rin,
    Cover_MPMotM_data,
  ],
  [CREATURE_KOBOLD_DRAGONSHIELD_BLACK]: Kobold_Dragonshield_VGTM_data,
  [CREATURE_KOBOLD_DRAGONSHIELD_BLUE]: Kobold_Dragonshield_VGTM_data,
  [CREATURE_KOBOLD_DRAGONSHIELD_GREEN]: Kobold_Dragonshield_VGTM_data,
  [CREATURE_KOBOLD_DRAGONSHIELD_RED]: Kobold_Dragonshield_VGTM_data,
  [CREATURE_KOBOLD_DRAGONSHIELD_WHITE]: Kobold_Dragonshield_VGTM_data,
  [CREATURE_KOBOLD_INVENTOR]: [
    Kobold_Inventor_VGTM_data,
    Kobold_Trap_VGTM_data,
  ],
  [CREATURE_KOBOLD_SCALE_SORCERER]: Kobold_Scale_sorcerer_VGTM_data,
  [CREATURE_KORRED]: korred,
  [CREATURE_KRAKEN_PRIEST]: kraken_priest,
  [CREATURE_LEUCROTTA]: leucrotta,
  [CREATURE_MAW_DEMON]: maw_demon,
  [CREATURE_MEENLOCK]: meenlock,
  [CREATURE_MINDWITNESS]: mindwitness,
  [CREATURE_MORKOTH]: morkoth,
  [CREATURE_MOUTH_OF_GROLANTOR]: GiantHillImgList,
  [CREATURE_NEOGI]: neogis,
  [CREATURE_NEOGI_HATCHLING]: neogis,
  [CREATURE_NEOGI_MASTER]: neogis,
  [CREATURE_NEOTHELID]: neothelid,
  [CREATURE_NILBOG]: [
    nilbog,
    ...GoblinsImgList,
  ],
  [CREATURE_ORC_BLADE_OF_ILNEVAL]: orcImgData,
  [CREATURE_ORC_CLAW_OF_LUTHIC]: orc_claw_of_luthic,
  [CREATURE_ORC_HAND_OF_YURTRUS]: orcImgData,
  [CREATURE_ORC_NURTURED_ONE_OF_YURTRUS]: orc_nurtured_one_of_yurtrus,
  [CREATURE_ORC_RED_FANG_OF_SHARGAAS]: Orc_on_giant_bat_VGTM_data,
  [CREATURE_QUICKLING]: quickling,
  [CREATURE_REDCAP]: [
    redcap,
    {
      src: Redcap_Img_TCoE,
      text: 'Красные колпаки охраняют трон их хозяйки ведьмы Таши',
      source: {
        id: SOURCE_TCoE,
      },
    },
  ],
  [CREATURE_SEA_SPAWN]: sea_spawn,
  [CREATURE_SHADOW_MASTIFF]: shadowMastiffImgData,
  [CREATURE_SHADOW_MASTIFF_ALPHA]: shadowMastiffImgData,
  [CREATURE_SHOOSUVA]: shoosuva,
  [CREATURE_SLITHERING_TRACKER]: slithering_tracker,
  [CREATURE_SPAWN_OF_KYUSS]: spawn_of_kyuss,
  [CREATURE_STEGOSAURUS]: stegosaurus,
  [CREATURE_STONE_GIANT_DREAMWALKER]: GiantStoneImgList,
  [CREATURE_STORM_GIANT_QUINTESSENT]: GiantStormImgList,
  [CREATURE_SWARM_OF_CRANIUM_RATS]: swarmOfCraniumRatsImgData,
  [CREATURE_SWASHBUCKLER]: swashbuckler,
  [CREATURE_TANARUKK]: tanarukk,
  [CREATURE_THORNY]: moldCreatures,
  [CREATURE_TLINCALLI]: tlincalli,
  [CREATURE_TRAPPER]: trapper,
  [CREATURE_ULITHARID]: ulitharid,
  [CREATURE_VARGOUILLE]: vargouille,
  [CREATURE_VEGEPYGMY]: moldCreatures,
  [CREATURE_VEGEPYGMY_CHIEF]: moldCreatures,
  [CREATURE_WARLOCK_OF_THE_FIEND]: warlock_of_the_fiend,
  [CREATURE_WOOD_WOAD]: wood_woad,
  [CREATURE_XVART]: xvart,
  [CREATURE_XVART_SPEAKER]: {
    src: xvart,
    creatureId: CREATURE_XVART,
  },
  [CREATURE_YETH_HOUND]: yeth_hound,
  [CREATURE_YUAN_TI_ANATHEMA]: [
    yuan_ti_anathema,
    Yuan_ti_sacrifice_VGTM_data,
  ],
  [CREATURE_YUAN_TI_BROODGUARD]: [
    yuan_ti_broodguard,
    Yuan_ti_sacrifice_VGTM_data,
  ],
  [CREATURE_YUAN_TI_MIND_WHISPERER]: [
    Yuan_ti_mind_whisperer_VGTM_data,
    Yuan_ti_sacrifice_VGTM_data,
  ],
  [CREATURE_YUAN_TI_NIGHTMARE_SPEAKER]: [
    Yuan_ti_nightmare_speaker_VGTM_data,
    Yuan_ti_sacrifice_VGTM_data,
  ],
  [CREATURE_YUAN_TI_PIT_MASTER]: [
    Yuan_ti_pit_master_VGTM_data,
    Yuan_ti_sacrifice_VGTM_data,
  ],
}
