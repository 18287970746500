import React from "react"


import RenderList from '@/components/RenderList'
import StatInfoRow from "@/components/StatInfoRow";

import LanguageItem from "./../LanguageItem";

const LanguageKnownList = ({list, use5eOfficialName = false}) => (
  <StatInfoRow header='Языки'>
    {
      list && list.length
        ? (
          <RenderList
            list={list}
            renderer={(lang, i) => (
              <LanguageItem
                lang={lang}
                key={i}
                use5eOfficialName={use5eOfficialName}
              />
            )}
          />
        )
        : '—'
    }
  </StatInfoRow>
)

export default LanguageKnownList
