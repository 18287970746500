const {
  CREATURE_EAGLE,
  CREATURE_EARTH_ELEMENTAL,
  CREATURE_EFREETI,
  CREATURE_ELEPHANT,
  CREATURE_EMPYREAN,
  CREATURE_ERINYES,
  CREATURE_ETTERCAP,
  CREATURE_ETTIN,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_DMG,
  SOURCE_MTG_CLB,
  SOURCE_SKT,
} = require('./../../../../sourceList')

const {
  Ettin_XGTE_data,
} = require('./../../../commonImageCollection')

const eagle = require('./../../../../../images/creatures/mm/eagle.jpeg')
const earth_elemental = require('./../../../../../images/creatures/mm/earth_elemental.jpg')
const efreeti = require('./../../../../../images/creatures/mm/efreeti.jpg')
const empyrean = require('./../../../../../images/creatures/mm/empyrean.jpeg')
const erinyes = require('./../../../../../images/creatures/mm/erinyes.jpeg')
const ettercap = require('./../../../../../images/creatures/mm/ettercap.jpeg')
const ettin = require('./../../../../../images/creatures/mm/ettin.jpeg')

const Efreet_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Efreet.jpg')
const Elemental_Earth_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Elemental_Earth.jpg')
const Elephant_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Elephant.jpg')
const Ettercap_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Ettercap.jpg')
const Ettin_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Ettin.jpg')

const Efreeti_City_of_Brass_DMG_img = require('./../../../../../images/creatures/dmg/efreeti_city_of_brass.png')
const Ettin_DMG_img = require('./../../../../../images/creatures/dmg/ettin.jpg')

const Ettin_and_Pigs_SKT_img = require('./../../../../../images/creatures/skt/ettin_and_pigs.png')

const Ettercap_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/ettercap_mtg_clb.webp')

module.exports = {
  [CREATURE_EAGLE]: eagle,
  [CREATURE_EARTH_ELEMENTAL]: [
    earth_elemental,
    {
      src: Elemental_Earth_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_EFREETI]: [
    efreeti,
    {
      src: Efreeti_City_of_Brass_DMG_img,
      text: 'Ифриты в Латунном городе на Плане Огня',
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Efreet_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_ELEPHANT]: {
    src: Elephant_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_EMPYREAN]: empyrean,
  [CREATURE_ERINYES]: erinyes,
  [CREATURE_ETTERCAP]: [
    ettercap,
    {
      src: Ettercap_MTG_CLB_img,
      source: SOURCE_MTG_CLB,
    },
    {
      src: Ettercap_ADND2,
      source: SOURCE_ADND2_MM,
    },
  ],
  [CREATURE_ETTIN]: [
    ettin,
    Ettin_XGTE_data,
    {
      src: Ettin_DMG_img,
      text: 'Эттин пытается пролезть в узкую дверь',
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Ettin_and_Pigs_SKT_img,
      text: 'Эттин пасёт свиней',
      source: {
        id: SOURCE_SKT,
      },
    },
    {
      src: Ettin_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
}
