import {
  SPELL_CURE_WOUNDS,
  SPELL_ENLARGE_REDUCE,
  SPELL_FEATHER_FALL,
  SPELL_HEAT_METAL,
  SPELL_MAGE_HAND,
  SPELL_MIRROR_IMAGE,
} from '@/constants/spellIdList'
import {CAST_NONE} from '@/constants/castComponentList'
import {CR_3} from '@/constants/crList'
import {CREATURE_QUAGGOTH} from '@/constants/creatureIdList'
import {faHatWizard} from '@fortawesome/free-solid-svg-icons'
import {PARAM_WIT} from '@/constants/paramList'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Кваггот тонот',
  templateIcon: faHatWizard,
  templateColor: 'blueViolet',

  templateLimitations: {
    include: {
      id: [
        CREATURE_QUAGGOTH,
      ],
    },
  },

  cr: CR_3,

  extendPropCollection: {
    spellCast: [{
      comment: 'псионика',
      baseStat: PARAM_WIT,
      componentExclude: CAST_NONE,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            SPELL_MAGE_HAND,
            SPELL_FEATHER_FALL,
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_CURE_WOUNDS,
            SPELL_ENLARGE_REDUCE,
            SPELL_HEAT_METAL,
            SPELL_MIRROR_IMAGE,
          ],
        },
      ],
    }],
    description: [{
      header: 'Вариант: Кваггот тонот',
      text: `Кваггот тонот отличается от обычного кваггота тем, что его показатель опасности равен 3 (700 опыта), и он обладает следующей особенностью:

**Врождённое колдовство (псионика).** Базовой характеристикой кваггота является Мудрость (СЛ испытания от заклинаний 11). Кваггот может сотворять следующие заклинания, не нуждаясь ни в каких компонентах:

* Неограниченно: [волшебная рука](SPELL:${SPELL_MAGE_HAND}), [падение пёрышком](SPELL:${SPELL_FEATHER_FALL})
* 1/день каждое: [лечение ран](SPELL:${SPELL_CURE_WOUNDS}), [увеличение/уменьшение](SPELL:${SPELL_ENLARGE_REDUCE}), [раскалённый металл](SPELL:${SPELL_HEAT_METAL}), [отражения](SPELL:${SPELL_MIRROR_IMAGE})`,
      source: {
        id: SOURCE_MM,
        page: 173,
      },
    }],
  },
  editPropCollection: {
    name: ({ name }) => `${name} тонот`,
  },
}
