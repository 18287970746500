import {
  CREATURE_AMNIZU,
  CREATURE_BARBED_DEVIL,
  CREATURE_BEARDED_DEVIL,
} from '@/constants/creatureIdList'
import generateTextLinks from '@/utils/generateTextLinks'
import {faUserPlus} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MTOF} from '@/constants/sourceList'

export default {
  templateName: 'Амнизу-призыватель',
  templateIcon: faUserPlus,
  templateColor: 'purple',

  templateLimitations: {
    include: {
      id: [
        CREATURE_AMNIZU,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MTOF,
      page: 180,
    }],
    description: [
      {
        header: 'Вариант: Призыв дьявола',
        text: `У некоторых амнизу есть действие, которое позволяет им призывать других дьяволов.

**Призыв дьявола (1/день)**. Амнизу вызывает 2к4 [бородатых дьяволов](CREATURE:${CREATURE_BEARDED_DEVIL}) или 1к4 [шипастых дьяволов](CREATURE:${CREATURE_BARBED_DEVIL}). В свободном пространстве на расстоянии 60 футов от амнизу появляется дьявол. Призванный дьявол служит союзником для амнизу, и не может призывать других дьяволов. Дьявол призывается на 1 минуту, пока он или его призыватель не умрут, либо пока призыватель не отпустит его действием.`,
        source: {
          id: SOURCE_MTOF,
          page: 180,
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name}-призыватель`,
    actionList: ({ actionList }) => [
      ...actionList,
      {
        name: 'Призыв дьявола',
        limit: {
          count: 1,
          period: 'день',
        },
        description: generateTextLinks(`Амнизу вызывает 2к4 [бородатых дьяволов](CREATURE:${CREATURE_BEARDED_DEVIL}) или 1к4 [шипастых дьяволов](CREATURE:${CREATURE_BARBED_DEVIL}). В свободном пространстве на расстоянии 60 футов от амнизу появляется дьявол. Призванный дьявол служит союзником для амнизу, и не может призывать других дьяволов. Дьявол призывается на 1 минуту, пока он или его призыватель не умрут, либо пока призыватель не отпустит его действием.`),
      },
    ],
  },
}
