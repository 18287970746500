import {
  CREATURE_ARCANALOTH,
  CREATURE_MEZZOLOTH,
  CREATURE_NYCALOTH,
  CREATURE_ULTROLOTH,
} from '@/constants/creatureIdList'
import generateTextLinks from '@/utils/generateTextLinks'
import {faUserPlus} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Юголот-призыватель',
  templateIcon: faUserPlus,
  templateColor: 'purple',

  templateLimitations: {
    include: {
      id: [
        CREATURE_ARCANALOTH,
        CREATURE_MEZZOLOTH,
        CREATURE_NYCALOTH,
        CREATURE_ULTROLOTH,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 317,
    }],
    description: [
      {
        header: 'Вариант: Призыв юголотов',
        text: generateTextLinks(`У некоторых юголотов может быть действие, позволяющее им призывать других юголотов.

**Призыв юголота (1/день).** Юголот выбирает, кого призвать, и пытается это сделать.



* [Арканалот](CREATURE:${CREATURE_ARCANALOTH}) получает 40% шанс призыва одного [арканалота](CREATURE:${CREATURE_ARCANALOTH}).

* [Меззолот](CREATURE:${CREATURE_MEZZOLOTH}) получает 30% шанс призыва одного [меззолота](CREATURE:${CREATURE_MEZZOLOTH}).

* [Никалот](CREATURE:${CREATURE_NYCALOTH}) получает 50% шанс призыва одного 1к4 [меззолотов](CREATURE:${CREATURE_MEZZOLOTH}) или одного [никалота](CREATURE:${CREATURE_NYCALOTH}).

* [Ультролот](CREATURE:${CREATURE_ULTROLOTH}) шанс призыва 1к6 [меззолотов](CREATURE:${CREATURE_MEZZOLOTH}), 1к4 [никалотов](CREATURE:${CREATURE_NYCALOTH}) или одного [ультролота](CREATURE:${CREATURE_ULTROLOTH}).



Призванный юголот появляется в свободном пространстве в пределах 60 футов от призывателя, действует самостоятельно (кроме случая, когда призывающим был ультролот, так как в этом случае призванный юголот действует как его союзник), и не имеет способности призывать других юголотов. Призванный юголот существует в течение 1 минуты, или пока призыватель не умрёт или бонусным действием его не отпустит.`),
        source: {
          id: SOURCE_MM,
          page: 317,
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name}-призыватель`,
    actionList: ({ id, name, actionList }) => {
      let preText = ``
      let actionWayText = 'самостоятельно'

      switch (id) {
        case CREATURE_ARCANALOTH:
          preText = `${name} пытается призвать одного [арканалота](CREATURE:${CREATURE_ARCANALOTH}) с шансом 40%.`
          break

        case CREATURE_MEZZOLOTH:
          preText = `${name} пытается призвать одного [меззолота](CREATURE:${CREATURE_MEZZOLOTH}) с шансом 30%.`
          break

        case CREATURE_NYCALOTH:
          preText = `${name} выбирает, кого призвать, и пытается это сделать с шансом 50%.

* 1к4 [меззолотов](CREATURE:${CREATURE_MEZZOLOTH})
 * одного [никалота](CREATURE:${CREATURE_NYCALOTH})`
          break

        case CREATURE_ULTROLOTH:
          preText = `${name} выбирает, кого призвать, и пытается это сделать без шанса провала.

* 1к6 [меззолотов](CREATURE:${CREATURE_MEZZOLOTH})
* 1к4 [никалотов](CREATURE:${CREATURE_NYCALOTH})
* одного [ультролота](CREATURE:${CREATURE_ULTROLOTH})`
          actionWayText = 'как его союзник'
          break
      }

      return [
        ...actionList,
        {
          name: 'Призыв юголота',
          description: generateTextLinks(`${preText}

Призванный юголот появляется в свободном пространстве в пределах 60 футов от призывателя, действует ${actionWayText}, и не имеет способности призывать других юголотов. Призванный юголот существует в течение 1 минуты, или пока призыватель не умрёт или бонусным действием его не отпустит.`),
          limit: {
            count: 1,
            period: 'день',
          },
        },
      ]
    },
  },
}
