import { dndMagicCollection } from '@/constants/magicList'
import { spellCollection } from '@/constants/spellList'

import formatSpellStr from './formatSpellStr'
import getMagicIcon from './getMagicIcon'

export default item => {
  if (typeof item === 'string') {
    return formatSpellStr(item)
  }

  if (typeof item === 'object') {
    const postTextArr = []
    const preTextArr = []

    if (item.comment) {
      postTextArr.push(item.comment)
    }

    if (item.alreadyCasted) {
      postTextArr.push(`уже наложено перед сражением`)
    }

    if (item.showMagicSchoolIcon) {
      const { magicSchoolId } = spellCollection[item.id]
      const iconPath = getMagicIcon(magicSchoolId)
      const { nameFull } = dndMagicCollection[magicSchoolId]
      const title = item.iconTitle || nameFull

      preTextArr.push(`![${nameFull}](${iconPath} "${title}")`)
    }

    const preText = preTextArr.length
      ? preTextArr.join(', ')
      : ''

    const postText = postTextArr.length
      ? postTextArr
        .join('; ')
        .replace(/^/, ' (')
        .replace(/$/, ')')
      : ''

    return formatSpellStr(item.id, preText, postText)
  }
}
