import React from 'react'
import PropTypes from 'prop-types'

import Monet from '@/components/Monet'

const PriceComponent = ({ monetList, className }) => (
  <span className={`Price ${className}`}>
    {
      monetList
        .filter(({ monet }) => monet)
        .map(
          ({ type, monet }, i) => (
            <Monet
              key={i}
              count={monet}
              type={type}
            />
          )
        )
    }
  </span>
)

PriceComponent.propTypes = {
  monetList: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    monet: PropTypes.number,
  })).isRequired,
  className: PropTypes.string,
}

PriceComponent.defaultProps = {
  className: '',
}

export default PriceComponent
