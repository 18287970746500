import {
  CREATURE_BANDIT_CAPTAIN,
  CREATURE_BARD,
  CREATURE_GUARD,
  CREATURE_SWASHBUCKLER,
  CREATURE_TRANSMUTER,
  CREATURE_VETERAN,
  CREATURE_WARLOCK_OF_THE_ARCHFEY,
} from '@/constants/creatureIdList'
import {SOURCE_AI} from '@/constants/sourceList'

export default {
  templateName: 'Образцовый сотрудник Предприятия Дранов',
  templateIcon: 'DranEnterprise',
  templateColor: 'black',
  templateLimitations: {
    include: {
      id: [
        CREATURE_BANDIT_CAPTAIN,
        CREATURE_BARD,
        CREATURE_GUARD,
        CREATURE_SWASHBUCKLER,
        CREATURE_TRANSMUTER,
        CREATURE_VETERAN,
        CREATURE_WARLOCK_OF_THE_ARCHFEY,
      ],
    },
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_AI,
      page: 215,
    }],
    featureList: [
      {
        name: `Корпоративная сосредоточенность`,
        description: `★СУЩЕСТВО★ получает преимущество к испытаниям от очарования и к соревнованиям Мудрости и Харизмы.`,
      },
      {
        name: `Заморозка активов`,
        limit: {
          count: 1,
          period: 'день',
        },
        description: `В следующий раз, когда ★СУЩЕСТВО★ попадёт по цели атакой, ★он★ может бонусным действием заставить цель пройти испытание Телосложения СЛ 13. При провале цель парализуется до начала её следующего хода.`,
      },
    ],
    reactionList: [
      {
        name: `Враждебное поглощение`,
        limit: {
          count: 1,
          period: 'день',
        },
        description: `Когда атакующий, видимый ★СУЩЕСТВО★ в пределах 15 футов, совершает бросок атаки, ★СУЩЕСТВО★ может реакцией заставить атакующего пройти испытание Мудрости СЛ 13. При провале ★СУЩЕСТВО★ заставляет его перебросить тот бросок и перенаправить атаку на другую цель по выбору ★СУЩЕСТВО★ (кроме самого атакующего) в пределах дальности атаки.`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name}, сотрудник Предприятия Дранов`,
  },
}
