const {
  CREATURE_GIANT_CENTIPEDE,
  CREATURE_GIANT_SCORPION,
  CREATURE_POISONOUS_SNAKE,
  CREATURE_QUIPPER,
  CREATURE_RAT,
  CREATURE_RAVEN,
  CREATURE_SAHUAGIN,
  CREATURE_SAHUAGIN_BARON,
  CREATURE_SAHUAGIN_PRIESTESS,
  CREATURE_SALAMANDER,
  CREATURE_SATYR,
  CREATURE_SCARECROW,
  CREATURE_SCORPION,
  CREATURE_SCOUT,
  CREATURE_SEA_HAG,
  CREATURE_SHADOW,
  CREATURE_SHADOW_DEMON,
  CREATURE_SHAMBLING_MOUND,
  CREATURE_SHIELD_GUARDIAN,
  CREATURE_SHRIEKER,
  CREATURE_SKELETON,
  CREATURE_SLAAD_BLUE,
  CREATURE_SLAAD_DEATH,
  CREATURE_SLAAD_GRAY,
  CREATURE_SLAAD_GREEN,
  CREATURE_SLAAD_RED,
  CREATURE_SMOKE_MEPHIT,
  CREATURE_SOLAR,
  CREATURE_SPECTATOR,
  CREATURE_SPECTER,
  CREATURE_SPIDER,
  CREATURE_SPINED_DEVIL,
  CREATURE_SPIRIT_NAGA,
  CREATURE_SPRITE,
  CREATURE_STEAM_MEPHIT,
  CREATURE_STIRGE,
  CREATURE_STONE_GIANT,
  CREATURE_STONE_GOLEM,
  CREATURE_STORM_GIANT,
  CREATURE_SUCCUBUS,
  CREATURE_SWARM_OF_BATS,
  CREATURE_SWARM_OF_CENTIPEDES,
  CREATURE_SWARM_OF_INSECTS,
  CREATURE_SWARM_OF_POISONOUS_SNAKES,
  CREATURE_SWARM_OF_QUIPPERS,
  CREATURE_SWARM_OF_RATS,
  CREATURE_SWARM_OF_RAVENS,
} = require('./../../../../creatureIdList')
const {
  SOURCE_CoS,
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_DMG,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  BeholderkinList,
  GiantStoneImgList,
  GiantStormImgList,
} = require('./../../../commonImageListCollection')

const {
  Satyr_MM_data,
} = require('./../../../commonImageCollection')

const blue_slaad = require('./../../../../../images/creatures/mm/blue_slaad.jpeg')
const death_slaad = require('./../../../../../images/creatures/mm/death_slaad.jpeg')
const gray_slaad = require('./../../../../../images/creatures/mm/gray_slaad.jpeg')
const green_slaad = require('./../../../../../images/creatures/mm/green_slaad.jpg')
const quipper = require('./../../../../../images/creatures/mm/quipper.jpg')
const rat = require('./../../../../../images/creatures/mm/rat.jpg')
const raven = require('./../../../../../images/creatures/mm/raven.jpeg')
const red_slaad = require('./../../../../../images/creatures/mm/red_slaad.jpeg')
const sahuagin = require('./../../../../../images/creatures/mm/sahuagin.jpeg')
const sahuagin_baron = require('./../../../../../images/creatures/mm/sahuagin_baron.jpg')
const salamander = require('./../../../../../images/creatures/mm/salamander.jpeg')
const scarecrow = require('./../../../../../images/creatures/mm/scarecrow.jpeg')
const scout = require('./../../../../../images/creatures/mm/scout.jpeg')
const sea_hag = require('./../../../../../images/creatures/mm/sea_hag.jpeg')
const shadow = require('./../../../../../images/creatures/mm/shadow.jpeg')
const shadow_demon = require('./../../../../../images/creatures/mm/shadow_demon.jpeg')
const shambling_mound = require('./../../../../../images/creatures/mm/shambling_mound.jpeg')
const shield_guardian = require('./../../../../../images/creatures/mm/shield_guardian.jpeg')
const shrieker = require('./../../../../../images/creatures/mm/shrieker.jpg')
const skeleton = require('./../../../../../images/creatures/mm/skeleton.jpeg')
const smoke_mephit = require('./../../../../../images/creatures/mm/smoke_mephit.jpg')
const solar = require('./../../../../../images/creatures/mm/solar.jpeg')
const spectator = require('./../../../../../images/creatures/mm/spectator.png')
const specter = require('./../../../../../images/creatures/mm/specter.jpeg')
const spider = require('./../../../../../images/creatures/mm/spider.jpeg')
const spined_devil = require('./../../../../../images/creatures/mm/spined_devil.jpg')
const spirit_naga = require('./../../../../../images/creatures/mm/spirit_naga.jpg')
const sprite = require('./../../../../../images/creatures/mm/sprite.jpeg')
const steam_mephit = require('./../../../../../images/creatures/mm/steam_mephit.jpeg')
const stirge = require('./../../../../../images/creatures/mm/stirge.jpg')
const stone_golem = require('./../../../../../images/creatures/mm/stone_golem.jpg')
const succubus = require('./../../../../../images/creatures/mm/succubus.jpeg')
const swarm_of_bats = require('./../../../../../images/creatures/mm/swarm_of_bats.jpeg')

const Centipede_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Centipede.jpg')
const Golem_Stone_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Golem_Stone.jpg')
const Insect_Swarm_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Insect_Swarm.jpg')
const Mephit_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Mephit.jpg')
const Sahuagin_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Sahuagin.jpg')
const Salamander_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Salamander.jpg')
const Satyr_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Satyr.jpg')
const Scorpion_Giant_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Scorpion_Giant.jpg')
const Shadow_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Shadow.jpg')
const Shambling_Mound_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Shambling_Mound.jpg')
const Skeleton_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Skeleton.jpg')
const Slaad_blue_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Slaad_blue.jpg')
const Snake_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Snake.jpg')
const Spectre_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Spectre.jpg')
const Sprite_1_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Sprite_1.jpg')
const Sprite_2_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Sprite_2.jpg')
const Stirge_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Stirge.jpg')

const Skeleton_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/skeleton.jpg')
const Skeleton_2_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/skeleton_2.jpg')
const Sprite_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/sprite.jpg')

const ShamblingMound_DMG_img = require('./../../../../../images/creatures/dmg/shambling_mound.png')

const Amber_Golem_CoS_img = require('./../../../../../images/creatures/cos/amber_golem.png')

const Sea_Hag_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/sea-hag-mtg_cls.jpg')

const Mephit_data = {
  src: Mephit_ADND2,
  text: 'Мефиты',
  source: {
    id: SOURCE_ADND2_MM,
  },
}

module.exports = {
  [CREATURE_SLAAD_BLUE]: [
    blue_slaad,
    {
      src: Slaad_blue_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_SLAAD_DEATH]: death_slaad,
  [CREATURE_SLAAD_GRAY]: gray_slaad,
  [CREATURE_SLAAD_GREEN]: green_slaad,
  [CREATURE_SLAAD_RED]: red_slaad,
  [CREATURE_SAHUAGIN]: [
    sahuagin,
    {
      src: Sahuagin_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_SAHUAGIN_PRIESTESS]: [
    {
      src: sahuagin,
      creatureId: CREATURE_SAHUAGIN,
    },
    {
      src: Sahuagin_ADND2,
      creatureId: CREATURE_SAHUAGIN,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_SAHUAGIN_BARON]: [
    sahuagin_baron,
    {
      src: Sahuagin_ADND2,
      creatureId: CREATURE_SAHUAGIN,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_SALAMANDER]: [
    salamander,
    {
      src: Salamander_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_SATYR]: [
    Satyr_MM_data,
    {
      src: Satyr_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_SCORPION]: [
    {
      src: Scorpion_Giant_ADND2,
      creatureId: CREATURE_GIANT_SCORPION,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_SCARECROW]: scarecrow,
  [CREATURE_SCOUT]: scout,
  [CREATURE_SEA_HAG]: [
    sea_hag,
    {
      src: Sea_Hag_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_SHADOW]: [
    shadow,
    {
      src: Shadow_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_SHADOW_DEMON]: shadow_demon,
  [CREATURE_SHAMBLING_MOUND]: [
    shambling_mound,
    {
      src: ShamblingMound_DMG_img,
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Shambling_Mound_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_SHIELD_GUARDIAN]: shield_guardian,
  [CREATURE_SHRIEKER]: shrieker,
  [CREATURE_SKELETON]: [
    skeleton,
    {
      src: Skeleton_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Skeleton_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Skeleton_2_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_SMOKE_MEPHIT]: [
    smoke_mephit,
    Mephit_data,
  ],
  [CREATURE_SOLAR]: solar,
  [CREATURE_SPECTATOR]: [
    spectator,
    ...BeholderkinList,
  ],
  [CREATURE_SPECTER]: [
    specter,
    {
      src: Spectre_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_SPIDER]: spider,
  [CREATURE_SPINED_DEVIL]: spined_devil,
  [CREATURE_SPIRIT_NAGA]: spirit_naga,
  [CREATURE_SPRITE]: [
    sprite,
    {
      src: Sprite_1_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Sprite_2_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Sprite_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_STEAM_MEPHIT]: [
    steam_mephit,
    Mephit_data,
  ],
  [CREATURE_STIRGE]: [
    stirge,
    {
      src: Stirge_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_STONE_GIANT]: GiantStoneImgList,
  [CREATURE_STONE_GOLEM]: [
    stone_golem,
    {
      src: Amber_Golem_CoS_img,
      text: 'Янтарный голем',
      source: {
        id: SOURCE_CoS,
      },
    },
    {
      src: Golem_Stone_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_STORM_GIANT]: GiantStormImgList,
  [CREATURE_SUCCUBUS]: succubus,
  [CREATURE_SWARM_OF_BATS]: swarm_of_bats,
  [CREATURE_SWARM_OF_CENTIPEDES]: {
    src: Centipede_ADND2,
    creatureId: CREATURE_GIANT_CENTIPEDE,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_SWARM_OF_INSECTS]: {
    src: Insect_Swarm_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_SWARM_OF_QUIPPERS]: {
    src: quipper,
    creatureId: CREATURE_QUIPPER,
  },
  [CREATURE_SWARM_OF_POISONOUS_SNAKES]: {
    src: Snake_ADND2,
    creatureId: CREATURE_POISONOUS_SNAKE,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_SWARM_OF_RATS]: {
    src: rat,
    creatureId: CREATURE_RAT,
  },
  [CREATURE_SWARM_OF_RAVENS]: {
    src: raven,
    creatureId: CREATURE_RAVEN,
  },
}
