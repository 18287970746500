import {
  CREATURE_BARBED_DEVIL,
  CREATURE_BEARDED_DEVIL,
  CREATURE_BONE_DEVIL,
  CREATURE_ERINYES,
  CREATURE_HORNED_DEVIL,
  CREATURE_ICE_DEVIL,
  CREATURE_PIT_FIEND,
  CREATURE_SPINED_DEVIL,
} from '@/constants/creatureIdList'
import generateTextLinks from '@/utils/generateTextLinks'
import {faUserPlus} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Дьявол-призыватель',
  templateIcon: faUserPlus,
  templateColor: 'purple',

  templateLimitations: {
    include: {
      id: [
        CREATURE_BARBED_DEVIL,
        CREATURE_BEARDED_DEVIL,
        CREATURE_BONE_DEVIL,
        CREATURE_ERINYES,
        CREATURE_HORNED_DEVIL,
        CREATURE_ICE_DEVIL,
        CREATURE_PIT_FIEND,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 142,
    }],
    description: [
      {
        header: 'Вариант: Призыв дьявола',
        text: generateTextLinks(`У некоторых дьяволов может быть действие, позволяющее им призывать других дьяволов.

**Призыв дьявола (1/день).** Дьявол выбирает, кого призвать, и пытается это сделать.



* [Бородатый дьявол](CREATURE:${CREATURE_BEARDED_DEVIL}) получает 30% шанс призыва одного [бородатого дьявола](CREATURE:${CREATURE_BEARDED_DEVIL}).

* [Исчадие преисподней](CREATURE:${CREATURE_PIT_FIEND}) призывает 2к4 [бородатых дьявола](CREATURE:${CREATURE_BEARDED_DEVIL}), 1к4 [шипастых дьявола](CREATURE:${CREATURE_BARBED_DEVIL}) или одну [эринию](CREATURE:${CREATURE_ERINYES}) без шанса провала.

* [Костяной дьявол](CREATURE:${CREATURE_BONE_DEVIL}) получает 40% шанс призыва 2к6 [игольчатых дьяволов](CREATURE:${CREATURE_SPINED_DEVIL}) или одного [костяного дьявола](CREATURE:${CREATURE_BONE_DEVIL}).

* [Ледяной дьявол](CREATURE:${CREATURE_ICE_DEVIL}) получает 60% шанс призыва одного [ледяного дьявола](CREATURE:${CREATURE_ICE_DEVIL}).

* [Рогатый дьявол](CREATURE:${CREATURE_HORNED_DEVIL}) получает 30% шанс призыва одного [рогатого дьявола](CREATURE:${CREATURE_HORNED_DEVIL}).

* [Шипастый дьявол](CREATURE:${CREATURE_BARBED_DEVIL}) получает 30% шанс призыва одного [шипастого дьявола](CREATURE:${CREATURE_BARBED_DEVIL}).

* [Эриния](CREATURE:${CREATURE_ERINYES}) получает 50% шанс призыва 3к6 [игольчатых дьяволов](CREATURE:${CREATURE_SPINED_DEVIL}), 1к6 [бородатых дьяволов](CREATURE:${CREATURE_BEARDED_DEVIL}) или одной [эринии](CREATURE:${CREATURE_ERINYES}).



Призванный дьявол появляется в свободном пространстве в пределах 60 футов от призывателя, и действует как его союзник, не имеющий способности призывать дьяволов. Он помогает в течение 1 минуты или пока призыватель не умрёт или действием его не отпустит.`),
        source: {
          id: SOURCE_MM,
          page: 142,
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace(/(дьявол|Эриния|Исчадие)/, '$1-призыватель'),
    actionList: ({ id, name, actionList }) => {
      let preText = ``

      switch (id) {
        case CREATURE_BARBED_DEVIL:
          preText = `${name} пытается призвать одного [шипастого дьявола](CREATURE:${CREATURE_BARBED_DEVIL}) с шансом 30%.`
          break

        case CREATURE_BEARDED_DEVIL:
          preText = `${name} пытается призвать одного [бородатого дьявола](CREATURE:${CREATURE_BEARDED_DEVIL}) с шансом 30%.`
          break

        case CREATURE_BONE_DEVIL:
          preText = `${name} выбирает, кого призвать, и пытается это сделать с шансом 30%.

* 2к6 [игольчатых дьяволов](CREATURE:${CREATURE_SPINED_DEVIL})
 * одного [костяного дьявола](CREATURE:${CREATURE_BONE_DEVIL})`
          break

        case CREATURE_ERINYES:
          preText = `${name} выбирает, кого призвать, и пытается это сделать с шансом 50%.

* 3к6 [игольчатых дьяволов](CREATURE:${CREATURE_SPINED_DEVIL})
* 1к6 [бородатых дьяволов](CREATURE:${CREATURE_BEARDED_DEVIL})
* одну [эринию](CREATURE:${CREATURE_ERINYES})`
          break

        case CREATURE_HORNED_DEVIL:
          preText = `${name} пытается призвать одного [рогатого дьявола](CREATURE:${CREATURE_HORNED_DEVIL}) с шансом 30%.`
          break

        case CREATURE_ICE_DEVIL:
          preText = `${name} пытается призвать одного [ледяного дьявола](CREATURE:${CREATURE_ICE_DEVIL}) с шансом 60%.`
          break

        case CREATURE_PIT_FIEND:
          preText = `${name} выбирает, кого призвать, и пытается это сделать без шанса провала.

* 2к4 [бородатых дьявола](CREATURE:${CREATURE_BEARDED_DEVIL})
* 1к4 [шипастых дьявола](CREATURE:${CREATURE_BARBED_DEVIL})
* одну [эринию](CREATURE:${CREATURE_ERINYES})`
          break
      }

      return [
        ...actionList,
        {
          name: 'Призыв дьявола',
          description: generateTextLinks(`${preText}

Призванный дьявол появляется в свободном пространстве в пределах 60 футов от призывателя, и действует как его союзник, не имеющий способности призывать дьяволов. Он помогает в течение 1 минуты или пока призыватель не умрёт или действием его не отпустит.`),
          limit: {
            count: 1,
            period: 'день',
          },
        },
      ]
    },
  },
}
