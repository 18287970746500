import {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_MIDDLE,
} from '@/constants/genderList'

const numberList = [
  {
    [GENDER_MALE]: 'ноль',
    [GENDER_FEMALE]: 'ноль',
    [GENDER_MIDDLE]: 'ноль',
  },
  {
    [GENDER_MALE]: 'один',
    [GENDER_FEMALE]: 'одна',
    [GENDER_MIDDLE]: 'одно',
  },
  {
    [GENDER_MALE]: 'два',
    [GENDER_FEMALE]: 'две',
    [GENDER_MIDDLE]: 'два',
  },
  {
    [GENDER_MALE]: 'три',
    [GENDER_FEMALE]: 'три',
    [GENDER_MIDDLE]: 'три',
  },
  {
    [GENDER_MALE]: 'четыре',
    [GENDER_FEMALE]: 'четыре',
    [GENDER_MIDDLE]: 'четыре',
  },
  {
    [GENDER_MALE]: 'пять',
    [GENDER_FEMALE]: 'пять',
    [GENDER_MIDDLE]: 'пять',
  },
  {
    [GENDER_MALE]: 'шесть',
    [GENDER_FEMALE]: 'шесть',
    [GENDER_MIDDLE]: 'шесть',
  },
  {
    [GENDER_MALE]: 'семь',
    [GENDER_FEMALE]: 'семь',
    [GENDER_MIDDLE]: 'семь',
  },
  {
    [GENDER_MALE]: 'восемь',
    [GENDER_FEMALE]: 'восемь',
    [GENDER_MIDDLE]: 'восемь',
  },
  {
    [GENDER_MALE]: 'девять',
    [GENDER_FEMALE]: 'девять',
    [GENDER_MIDDLE]: 'девять',
  },
]

export default numberList
