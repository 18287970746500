import React from 'react'
import arrify from 'arrify'

import upLetter from "@/utils/upLetter"

import {GENDER_ANY, GENDER_MALE, GENDER_MIDDLE} from "@/constants/genderList"
import {
  aligmentCollection,
  ALIGNMENT_ANY,
  ALIGNMENT_NO,
} from "@/constants/aligmentList"

import CreatureAlignmentComponent from "./CreatureAlignmentComponent"

export default ({ alignmentId, hasSpoilers, genderId, swarmMemberSizeType = null }) => {
  if (alignmentId) {
    const realGenderId = swarmMemberSizeType
      ? GENDER_MALE
      : genderId || GENDER_ANY

    const list = arrify(alignmentId)
      .map(
        item => {
          let alignmentId = null

          switch (typeof item) {
            case 'string': {
              alignmentId = item
              break
            }
            case 'object': {
              alignmentId = item.id
              break
            }
          }

          if (alignmentId) {
            const {name, description} = aligmentCollection[alignmentId]
            const {nominative: alignmentText} = name[realGenderId] || name[GENDER_ANY] || name[GENDER_MALE]
            const header = upLetter((name[GENDER_MIDDLE] || name[GENDER_ANY] || name[GENDER_MALE]).nominative)

            const commentText = item.comment
              ? ` (${item.comment})`
              : ''

            return {
              id: alignmentId,
              text: `${alignmentText}${commentText}`,
              title: description
                ? `${header} мировоззрение\n\n${description}`
                : null,
            }
          }

          return null
        },
      )
      .filter(e => e)

    const justTypical = !hasSpoilers && !(
      list.length === 1
      && (
        list[0].id === ALIGNMENT_ANY
        || list[0].id === ALIGNMENT_NO
      )
    )

    return (
      <CreatureAlignmentComponent
        list={list}
        justTypical={justTypical}
      />
    )
  }

  return null
}
