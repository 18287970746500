import {
  GEAR_CHAIN_MAIL,
  GEAR_LEATHER_ARMOR, GEAR_PLATE_ARMOR,
  GEAR_RING_MAIL,
  GEAR_SCALE_MAIL,
  GEAR_SPLINT_ARMOR,
  GEAR_STUDDED_LEATHER_ARMOR,
} from '@/constants/gearIdList'

const warhorseTemplateDataList = [
  {
    templateName: 'Боевой конь в кожаном доспехе',
    namePostfix: 'в кожаном доспехе',
    armorId: GEAR_LEATHER_ARMOR,
    color: 'chocolate',
  },
  {
    templateName: 'Боевой конь в доспехе из проклёпанной кожи',
    namePostfix: 'в доспехе из проклёпанной кожи',
    armorId: GEAR_STUDDED_LEATHER_ARMOR,
    color: 'brown',
  },
  {
    templateName: 'Боевой конь в колечном доспехе',
    namePostfix: 'в колечном доспехе',
    armorId: GEAR_RING_MAIL,
    color: 'slategray',
  },
  {
    templateName: 'Боевой конь в чешуйчатом доспехе',
    namePostfix: 'в чешуйчатом доспехе',
    armorId: GEAR_SCALE_MAIL,
    color: 'gray',
  },
  {
    templateName: 'Боевой конь в кольчуге',
    namePostfix: 'в кольчуге',
    armorId: GEAR_CHAIN_MAIL,
    color: 'darkgray',
  },
  {
    templateName: 'Боевой конь в наборном доспехе',
    namePostfix: 'в наборном доспехе',
    armorId: GEAR_SPLINT_ARMOR,
    color: 'darkslategray',
  },
  {
    templateName: 'Боевой конь в латах',
    namePostfix: 'в латах',
    armorId: GEAR_PLATE_ARMOR,
    color: 'black',
  },
]

export default warhorseTemplateDataList
