import AI from './templates/AI'
import DMG from './templates/DMG'
import MM from './templates/MM'
import MTOF from './templates/MTOF'
import TOA from './templates/TOA'
import VGTM from './templates/VGTM'

export default [
  ...AI,
  ...DMG,
  ...MM,
  ...MTOF,
  ...TOA,
  ...VGTM,
]
  .map(
    ({ templateName, templateLimitations = {}, extendPropCollection = {}, ...rest }) => ({
      ...rest,
      templateName,
      extendPropCollection: {
        ...extendPropCollection,
        templateName,
      },
      templateLimitations: {
        ...templateLimitations,
        exclude: {
          ...templateLimitations.exclude,
          templateName,
        },
      },
    }),
  )
