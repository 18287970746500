const {
  CREATURE_LIZARDFOLK,
} = require('./../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_MTG_AHBG,
} = require('./../../../sourceList')

const {
  Lizardfolk_MM_data,
  Lizardfolk_VGTM_data,
} = require('./../../commonImageCollection')

const Lizard_Man_ADND2 = require('./../../../../images/creatures/adnd2_mm/Lizard_Man.jpg')

const Lizardfolk_librarians_MTG_AHBG_img = require('./../../../../images/creatures/mtg_ahbg/lizardfolk_librarians_mtg_ahbg.jpg')

module.exports = [
  Lizardfolk_VGTM_data,
  Lizardfolk_MM_data,
  {
    src: Lizard_Man_ADND2,
    creatureId: CREATURE_LIZARDFOLK,
    source: SOURCE_ADND2_MM,
  },
  {
    src: Lizardfolk_librarians_MTG_AHBG_img,
    text: 'Людоящеры-библиотекари',
    source: SOURCE_MTG_AHBG,
  },
]
