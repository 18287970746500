const {
  CREATURE_DRAGON_BLUE_ADULT,
  CREATURE_DRAGON_BLUE_ANCIENT,
  CREATURE_DRAGON_BLUE_YOUNG,
  CREATURE_DRAGON_BLUE_WYRMLING,
} = require('./../../../creatureIdList')

const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_FTD,
  SOURCE_SKT,
  SOURCE_MM,
} = require('./../../../sourceList')

const {
  Blue_Dragon_Lightning_DND3_5_DTBoD_data,
  Color_Dragons_Attack_ROT_data,
  Greatwyrm_Blue_FTD_data,
  Ancient_Dragons_Hoard_data,
} = require('./../../commonImageCollection')

const Blue_Dragon_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Blue_Dragon.jpg')
const Blue_Dragon_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/blue_dragon.jpg')
const Blue_Dragon_MM_img = require('./../../../../images/creatures/mm/blue_dragon.jpg')
const Blue_Dragon_At_Ruins_SKT_img = require('./../../../../images/creatures/skt/blue-dragon-at-ruins.jpg')
const Blue_Wyrmling_FTD_img = require('./../../../../images/creatures/ftd/blue-wyrmling.png')
const Blue_Young_Dragon_FTD_img = require('./../../../../images/creatures/ftd/young-blue-dragon.png')
const Blue_Adult_Dragon_FTD_img = require('./../../../../images/creatures/ftd/adult-blue-dragon.png')
const Blue_Ancient_Dragon_FTD_img = require('./../../../../images/creatures/ftd/ancient-blue-dragon.png')
const Iymrith_FTD_img = require('./../../../../images/creatures/ftd/iymrith.png')

module.exports = [
  {
    src: Blue_Dragon_MM_img,
    text: 'Синий дракон',
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Blue_Dragon_AYAGMnC_img,
    text: 'Синий дракон',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  Blue_Dragon_Lightning_DND3_5_DTBoD_data,
  {
    src: Blue_Dragon_At_Ruins_SKT_img,
    creatureId: CREATURE_DRAGON_BLUE_ANCIENT,
    source: {
      id: SOURCE_SKT,
    },
  },
  {
    src: Blue_Wyrmling_FTD_img,
    creatureId: CREATURE_DRAGON_BLUE_WYRMLING,
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Blue_Young_Dragon_FTD_img,
    creatureId: CREATURE_DRAGON_BLUE_YOUNG,
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Blue_Adult_Dragon_FTD_img,
    creatureId: CREATURE_DRAGON_BLUE_ADULT,
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Blue_Ancient_Dragon_FTD_img,
    creatureId: CREATURE_DRAGON_BLUE_ANCIENT,
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Iymrith_FTD_img,
    text: 'Йимрит, «Рок пустыни», летает над своим логовом',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Blue_Dragon_ADND2_img,
    text: 'Синий дракон',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  Greatwyrm_Blue_FTD_data,
  Color_Dragons_Attack_ROT_data,
  Ancient_Dragons_Hoard_data,
]
