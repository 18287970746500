const {
  CREATURE_CLAWFOOT_RAPTOR,
} = require('./../../creatureIdList')

const {
  Clawfoot_Raptor_WGtE_data,
} = require('./../commonImageCollection')

module.exports = {
  [CREATURE_CLAWFOOT_RAPTOR]: Clawfoot_Raptor_WGtE_data,
}
