import {
  CREATURE_DROW_INQUISITOR,
  CREATURE_YOCHLOL,
} from '@/constants/creatureIdList'
import generateTextLinks from '@/utils/generateTextLinks'
import {faUserPlus} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MTOF} from '@/constants/sourceList'

export default {
  templateName: 'Инквизитор-призыватель',
  templateIcon: faUserPlus,
  templateColor: 'purple',

  templateLimitations: {
    include: {
      id: [
        CREATURE_DROW_INQUISITOR,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MTOF,
      page: 166,
    }],
    description: [
      {
        header: 'Вариант: Призыв Йоклол',
        text: `У некоторых дроу инквизиторов есть действие, которое позволит им призывать демона.

**Призыв демона (1/день)**. Дроу пытается магическим образом призвать [йоклол](CREATURE:${CREATURE_YOCHLOL}) с 50% шансом на успех. При проваленной попытке, дроу получает психический урон 5 (1к10). В ином случае, в свободном пространстве на расстоянии 60 футов от призывателя появляется демон, служащий союзником для призывателя, и не может призывать других демонов. Демон призывается на 10 минут, пока он или его призыватель не умрёт, либо пока призыватель, потратив действие, не отпустит его.`,
        source: {
          id: SOURCE_MTOF,
          page: 166,
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name}-призыватель`,
    actionList: ({ actionList }) => [
      ...actionList,
      {
        name: 'Призыв Йоклол',
        limit: {
          count: 1,
          period: 'день',
        },
        description: generateTextLinks(`Дроу пытается магическим образом призвать [йоклол](CREATURE:${CREATURE_YOCHLOL}) с 50% шансом на успех. При проваленной попытке, дроу получает психический урон 5 (1к10). В ином случае, в свободном пространстве на расстоянии 60 футов от призывателя появляется демон, служащий союзником для призывателя, и не может призывать других демонов. Демон призывается на 10 минут, пока он или его призыватель не умрёт, либо пока призыватель, потратив действие, не отпустит его.`),
      },
    ],
  },
}
