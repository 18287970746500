import magicAbjurationImg from '@/images/magic/16-abjuration.png'
import magicConjurationImg from '@/images/magic/16-conjuration.png'
import magicDivinationImg from '@/images/magic/16-divination.png'
import magicEnchantmentImg from '@/images/magic/16-enchantment.png'
import magicEvocationImg from '@/images/magic/16-evocation.png'
import magicIllusionImg from '@/images/magic/16-illusion.png'
import magicNecromancyImg from '@/images/magic/16-necromancy.png'
import magicTransmutationImg from '@/images/magic/16-transmutation.png'

import {
  MAGIC_ABJURATION,
  MAGIC_CONJURATION,
  MAGIC_DIVINATION,
  MAGIC_ENCHANTMENT,
  MAGIC_EVOCATION,
  MAGIC_ILLUSION,
  MAGIC_NECROMANCY,
  MAGIC_TRANSMUTATION,
} from '@/constants/magicList'

export default magicSchoolId => {
  switch (magicSchoolId) {
    case MAGIC_ABJURATION:
      return magicAbjurationImg
    case MAGIC_CONJURATION:
      return magicConjurationImg
    case MAGIC_DIVINATION:
      return magicDivinationImg
    case MAGIC_ENCHANTMENT:
      return magicEnchantmentImg
    case MAGIC_EVOCATION:
      return magicEvocationImg
    case MAGIC_ILLUSION:
      return magicIllusionImg
    case MAGIC_NECROMANCY:
      return magicNecromancyImg
    case MAGIC_TRANSMUTATION:
      return magicTransmutationImg
  }
}
