import {
  PARAM_STR,
  PARAM_DEX,
} from '@/constants/paramList'
import {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_KOBOLD,
} from '@/constants/creatureTypeIdList'
import {
  LANG_COMMON,
  LANG_DRACONIC,
} from '@/constants/languageIdList'
import {
  ABILITY_PACK_TACTICS,
  ABILITY_SUNLIGHT_SENSITIVITY,
} from '@/constants/creatureAbilityList'
import {faDragon} from '@fortawesome/free-solid-svg-icons'
import {SENSE_DARK_VISION} from '@/constants/senseList'
import {SIZE_SMALL} from '@/constants/sizeList'
import {SOURCE_DMG} from '@/constants/sourceList'

export default {
  templateName: 'Кобольд',
  templateIcon: faDragon,
  templateColor: 'red',
  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_ANY_RACE,
      ],
    },
  },

  sizeType: SIZE_SMALL,

  languageList: [
    LANG_COMMON,
    LANG_DRACONIC,
  ],

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 283,
    }],
    featureList: [
      ABILITY_PACK_TACTICS,
      ABILITY_SUNLIGHT_SENSITIVITY,
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Кобольд ${name}`,
    creatureTypeIdList: ({ creatureTypeIdList }) => creatureTypeIdList.map(
      creatureTypeId => creatureTypeId === CREATURE_TYPE_ANY_RACE
        ? CREATURE_TYPE_KOBOLD
        : creatureTypeId
    ),
    params: ({ params }) => ({
      ...params,
      [PARAM_STR]: params[PARAM_STR] - 4,
      [PARAM_DEX]: params[PARAM_DEX] + 2,
    }),
    senseList: ({ senseList = [] }) => [
      ...senseList.filter(
        ({id}) => id !== SENSE_DARK_VISION
      ),
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
  },
}
