const {
  CREATURE_FROST_GIANT,
  CREATURE_FROST_GIANT_EVERLASTING_ONE,
} = require('./../../../creatureIdList')

const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_SKT,
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../../sourceList')

const {
  Frost_giant_fight_Remorhaz_VGTM_data,
} = require('./../../commonImageCollection')

const Frost_Giant_MM_img = require('./../../../../images/creatures/mm/frost_giant.jpg')
const Frost_Giant_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Giant_Frost.jpg')
const Frost_Giant_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/frost_giant.jpg')
const Frost_Giant_Dwarf_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/frost_giant_dwarf.jpg')

const Frost_Giant_Parlament_VGTM_img = require('./../../../../images/creatures/vgtm/frost_giant_parlament.jpg')

const Frost_Giant_Everlasting_One_VGTM_img = require(`./../../../../images/creatures/vgtm/frost_giant_everlasting_one.jpeg`)

const Frost_Giant_Home_SKT_img = require(`./../../../../images/creatures/skt/frost-giants-town.png`)
const Yarl_Storvald_SKT_img = require(`./../../../../images/creatures/skt/yarl_storvald.jpg`)

module.exports = [
  {
    src: Frost_Giant_MM_img,
    creatureId: CREATURE_FROST_GIANT,
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Frost_Giant_AYAGMnC_img,
    creatureId: CREATURE_FROST_GIANT,
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Frost_Giant_Everlasting_One_VGTM_img,
    creatureId: CREATURE_FROST_GIANT_EVERLASTING_ONE,
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: Frost_Giant_Dwarf_AYAGMnC_img,
    text: 'Дварф и ледяной великан',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  Frost_giant_fight_Remorhaz_VGTM_data,
  {
    src: Yarl_Storvald_SKT_img,
    text: `Сторвальд, ярл ледяных великанов`,
    source: {
      id: SOURCE_SKT,
    },
  },
  {
    src: Frost_Giant_Home_SKT_img,
    text: `Поселение и корабли ледяных великанов`,
    source: {
      id: SOURCE_SKT,
    },
  },
  {
    src: Frost_Giant_Parlament_VGTM_img,
    text: `Тайлер Джейкобсон проиллюстрировал судьбоносную встречу между подозрительным ледяным великаном и одиноким приключенцем, вооруженным Справочником Воло по Монстрам. Разрубит ли великан нашего образованного героя надвое, или ситуация разрешится дипломатическим путем?`,
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: Frost_Giant_ADND2_img,
    creatureId: CREATURE_FROST_GIANT,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
