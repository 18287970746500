const {
  CREATURE_RAKSHASA,
  CREATURE_RAT,
  CREATURE_RAVEN,
  CREATURE_REEF_SHARK,
  CREATURE_REMORHAZ,
  CREATURE_REVENANT,
  CREATURE_RIDING_HORSE,
  CREATURE_ROC,
  CREATURE_ROPER,
  CREATURE_RUG_OF_SMOTHERING,
  CREATURE_RUST_MONSTER,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND_MM,
  SOURCE_DND3_5_PGTM,
  SOURCE_ADND2_MM,
  SOURCE_AYAGWnW,
  SOURCE_MTG_AFR,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  Frost_giant_fight_Remorhaz_VGTM_data,
} = require('./../../../commonImageCollection')

const rakshasa = require('./../../../../../images/creatures/mm/rakshasa.jpeg')
const rat = require('./../../../../../images/creatures/mm/rat.jpg')
const raven = require('./../../../../../images/creatures/mm/raven.jpeg')
const remorhaz = require('./../../../../../images/creatures/mm/remorhaz.jpeg')
const revenant = require('./../../../../../images/creatures/mm/revenant.jpeg')
const riding_horse = require('./../../../../../images/creatures/mm/riding_horse.jpg')
const roc = require('./../../../../../images/creatures/mm/roc.jpeg')
const roper = require('./../../../../../images/creatures/mm/roper.jpg')
const rug_of_smothering = require('./../../../../../images/creatures/mm/rug_of_smothering.jpg')
const rust_monster = require('./../../../../../images/creatures/mm/rust_monster.jpeg')

const rakshasha = require('./../../../../../images/creatures/dnd3_5_pgtm/rakshasha.jpg')

const rakshasaAdnd = require('./../../../../../images/creatures/adnd_mm/rakshasa.jpg')

const Horse_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Horse.jpg')
const Rakshasa_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Rakshasa.jpg')
const Rat_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Rat.jpg')
const Remorhaz_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Remorhaz.jpg')
const Revenant_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Revenant.jpg')
const Roc_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Roc.jpg')
const Roper_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Roper.jpg')
const Rust_Monster_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Rust_Monster.jpg')
const Shark_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Shark.jpg')

const Rust_monster_AYAGWW = require('./../../../../../images/creatures/ayagww/rust_monster.jpg')

const Mahadi_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/mahadi-mtg_clb.jpg')

const Rust_monster_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/rust-monster-mtg_afr.jpg')
const Lurking_roper_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/lurking-roper-mtg_afr.jpg')

const Rug_of_smothering_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/rug-of-smothering-mtg_clb.jpg')

module.exports = {
  [CREATURE_RAKSHASA]: [
    rakshasa,
    {
      src: rakshasaAdnd,
      source: {
        id: SOURCE_ADND_MM,
      },
    },
    {
      src: rakshasha,
      source: {
        id: SOURCE_DND3_5_PGTM,
      },
    },
    {
      src: Rakshasa_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Mahadi_MTG_CLB_img,
      text: 'Махади, владелец Базара',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_RAT]: [
    rat,
    {
      src: Rat_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_RAVEN]: raven,
  [CREATURE_REEF_SHARK]: {
    src: Shark_ADND2,
    text: 'Акула',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_REMORHAZ]: [
    remorhaz,
    {
      src: Remorhaz_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    Frost_giant_fight_Remorhaz_VGTM_data,
  ],
  [CREATURE_REVENANT]: [
    revenant,
    {
      src: Revenant_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_RIDING_HORSE]: [
    riding_horse,
    {
      src: Horse_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_ROC]: [
    roc,
    {
      src: Roc_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_ROPER]: [
    roper,
    {
      src: Roper_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Lurking_roper_MTG_AFR_img,
      source: {
        id: SOURCE_MTG_AFR,
      },
    },
  ],
  [CREATURE_RUG_OF_SMOTHERING]: [
    rug_of_smothering,
    {
      src: Rug_of_smothering_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_RUST_MONSTER]: [
    rust_monster,
    {
      src: Rust_Monster_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Rust_monster_AYAGWW,
      source: {
        id: SOURCE_AYAGWnW,
      },
    },
    {
      src: Rust_monster_MTG_AFR_img,
      source: {
        id: SOURCE_MTG_AFR,
      },
    },
  ],
}
