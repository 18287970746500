const {
  CREATURE_DAO,
  CREATURE_DARKMANTLE,
  CREATURE_DEATH_DOG,
  CREATURE_DEATH_KNIGHT,
  CREATURE_DEATH_TYRANT,
  CREATURE_DEMILICH,
  CREATURE_DEVA,
  CREATURE_DIRE_WOLF,
  CREATURE_DISPLACER_BEAST,
  CREATURE_DJINNI,
  CREATURE_DOPPELGANGER,
  CREATURE_DRAFT_HORSE,
  CREATURE_DRAGON_BLACK_ADULT,
  CREATURE_DRAGON_BLACK_ANCIENT,
  CREATURE_DRAGON_BLACK_WYRMLING,
  CREATURE_DRAGON_BLACK_YOUNG,
  CREATURE_DRAGON_BLUE_ADULT,
  CREATURE_DRAGON_BLUE_ANCIENT,
  CREATURE_DRAGON_BLUE_WYRMLING,
  CREATURE_DRAGON_BLUE_YOUNG,
  CREATURE_DRAGON_BRASS_ADULT,
  CREATURE_DRAGON_BRASS_ANCIENT,
  CREATURE_DRAGON_BRASS_WYRMLING,
  CREATURE_DRAGON_BRASS_YOUNG,
  CREATURE_DRAGON_BRONZE_ADULT,
  CREATURE_DRAGON_BRONZE_ANCIENT,
  CREATURE_DRAGON_BRONZE_WYRMLING,
  CREATURE_DRAGON_BRONZE_YOUNG,
  CREATURE_DRAGON_COPPER_ADULT,
  CREATURE_DRAGON_COPPER_ANCIENT,
  CREATURE_DRAGON_COPPER_WYRMLING,
  CREATURE_DRAGON_COPPER_YOUNG,
  CREATURE_DRAGON_GOLD_ADULT,
  CREATURE_DRAGON_GOLD_ANCIENT,
  CREATURE_DRAGON_GOLD_WYRMLING,
  CREATURE_DRAGON_GOLD_YOUNG,
  CREATURE_DRAGON_GREEN_ADULT,
  CREATURE_DRAGON_GREEN_ANCIENT,
  CREATURE_DRAGON_GREEN_WYRMLING,
  CREATURE_DRAGON_GREEN_YOUNG,
  CREATURE_DRAGON_RED_ADULT,
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_DRAGON_RED_WYRMLING,
  CREATURE_DRAGON_RED_YOUNG,
  CREATURE_DRAGON_SILVER_ADULT,
  CREATURE_DRAGON_SILVER_ANCIENT,
  CREATURE_DRAGON_SILVER_WYRMLING,
  CREATURE_DRAGON_SILVER_YOUNG,
  CREATURE_DRAGON_TURTLE,
  CREATURE_DRAGON_WHITE_ADULT,
  CREATURE_DRAGON_WHITE_ANCIENT,
  CREATURE_DRAGON_WHITE_WYRMLING,
  CREATURE_DRAGON_WHITE_YOUNG,
  CREATURE_DRETCH,
  CREATURE_DRIDER,
  CREATURE_DRIDER_SPELLCASTER,
  CREATURE_DROW,
  CREATURE_DROW_MAGE,
  CREATURE_DRUID,
  CREATURE_DRYAD,
  CREATURE_DUERGAR,
  CREATURE_DUODRONE,
  CREATURE_DUST_MEPHIT,
} = require('./../../../../creatureIdList')
const {
  SOURCE_DND3_5_PGTD,
  SOURCE_DND3_5_PGTW,
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_MTG_AFR,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  BeholderkinList,
  DragonsBlackList,
  DragonsBlueList,
  DragonsBrassList,
  DragonsBronzeList,
  DragonsCopperList,
  DragonsGoldList,
  DragonsGreenList,
  DragonsRedList,
  DragonsSilverList,
  DragonsWhiteList,
} = require('./../../../commonImageListCollection')

const {
  Drow_Mage_MM_data,
  Drow_PHB_data,
  Druid_MM_data,
  Duergar_ADND2_data,
  Dire_Wolf_Attack_Northfolk_Family_SKT_data,
} = require('./../../../commonImageCollection')

const dao = require('./../../../../../images/creatures/mm/dao.jpg')
const darkmantle = require('./../../../../../images/creatures/mm/darkmantle.jpeg')
const death_dog = require('./../../../../../images/creatures/mm/death_dog.jpeg')
const death_knight = require('./../../../../../images/creatures/mm/death_knight.jpeg')
const death_tyrant = require('./../../../../../images/creatures/mm/death_tyrant.jpeg')
const demilich = require('./../../../../../images/creatures/mm/demilich.jpg')
const deva = require('./../../../../../images/creatures/mm/deva.jpg')
const dire_wolf = require('./../../../../../images/creatures/mm/dire_wolf.jpeg')
const displacer_beast = require('./../../../../../images/creatures/mm/displacer_beast.jpeg')
const djinni = require('./../../../../../images/creatures/mm/djinni.jpg')
const doppelganger = require('./../../../../../images/creatures/mm/doppelganger.jpg')
const dragon_turtle = require('./../../../../../images/creatures/mm/dragon_turtle.jpg')
const dretch = require('./../../../../../images/creatures/mm/dretch.jpg')
const drider = require('./../../../../../images/creatures/mm/drider.jpg')
const dryad = require('./../../../../../images/creatures/mm/dryad.jpg')
const duergar = require('./../../../../../images/creatures/mm/duergar.jpg')
const duodrone = require('./../../../../../images/creatures/mm/duodrone.jpg')
const dust_mephit = require('./../../../../../images/creatures/mm/dust_mephit.jpeg')

const black_dragon_wyrmling = require('./../../../../../images/creatures/dnd3_5_pgtd/black_dragon_wyrmling.jpg')
const blue_dragon_wyrmling_1 = require('./../../../../../images/creatures/dnd3_5_pgtd/blue_dragon_wyrmling-1.jpg')
const blue_dragon_wyrmling_2 = require('./../../../../../images/creatures/dnd3_5_pgtd/blue_dragon_wyrmling-2.jpg')
const white_dragon_wyrmling = require('./../../../../../images/creatures/dnd3_5_pgtd/white_dragon_wyrmling.jpg')
const young_black_dragon = require('./../../../../../images/creatures/dnd3_5_pgtd/young_black_dragon.jpg')

const silver_dragon_wyrmling = require('./../../../../../images/creatures/dnd3_5_pgtw/silver_dragon_wyrmling.jpg')

const Dao_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Dao.jpg')
const Death_Knight_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Death_Knight.jpg')
const Displacer_Beast_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Displacer_Beast.jpg')
const Djinni_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Djinni.jpg')
const Doppelganger_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Doppelganger.jpg')
const Dragon_Turtle_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Dragon_Turtle.jpg')
const Drow_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Drow.jpg')
const Dryad_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Dryad.jpg')
const Horse_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Horse.jpg')
const Mephit_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Mephit.jpg')

const Displacer_beast_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/displacer_beast.jpg')
const Dragon_turtle_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/dragon_turtle.jpg')

const Djinni_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/djinni-mtg_afr.jpg')
const Demilich_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/demilich-mtg_afr.jpg')

const Displacer_kitten_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/displacer_kitten.jpg')
const Sailors_bane_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/sailors-bane.jpg')
const Marching_duodrone_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/marching-duodrone-mtg_clb.webp')

const Mephit_data = {
  src: Mephit_ADND2,
  text: 'Мефиты',
  source: {
    id: SOURCE_ADND2_MM,
  },
}


module.exports = {
  [CREATURE_DRAGON_BLACK_ADULT]: DragonsBlackList,
  [CREATURE_DRAGON_BLUE_ADULT]: DragonsBlueList,
  [CREATURE_DRAGON_BRASS_ADULT]: DragonsBrassList,
  [CREATURE_DRAGON_BRONZE_ADULT]: DragonsBronzeList,
  [CREATURE_DRAGON_COPPER_ADULT]: DragonsCopperList,
  [CREATURE_DRAGON_GOLD_ADULT]: DragonsGoldList,
  [CREATURE_DRAGON_GREEN_ADULT]: DragonsGreenList,
  [CREATURE_DRAGON_RED_ADULT]: DragonsRedList,
  [CREATURE_DRAGON_SILVER_ADULT]: DragonsSilverList,
  [CREATURE_DRAGON_WHITE_ADULT]: DragonsWhiteList,
  [CREATURE_DRAGON_BLACK_ANCIENT]: DragonsBlackList,
  [CREATURE_DRAGON_BLUE_ANCIENT]: DragonsBlueList,
  [CREATURE_DRAGON_BRASS_ANCIENT]: DragonsBrassList,
  [CREATURE_DRAGON_BRONZE_ANCIENT]: DragonsBronzeList,
  [CREATURE_DRAGON_COPPER_ANCIENT]: DragonsCopperList,
  [CREATURE_DRAGON_GOLD_ANCIENT]: DragonsGoldList,
  [CREATURE_DRAGON_GREEN_ANCIENT]: DragonsGreenList,
  [CREATURE_DRAGON_RED_ANCIENT]: DragonsRedList,
  [CREATURE_DRAGON_SILVER_ANCIENT]: DragonsSilverList,
  [CREATURE_DRAGON_WHITE_ANCIENT]: DragonsWhiteList,
  [CREATURE_DRAGON_BLACK_WYRMLING]: [
    {
      src: black_dragon_wyrmling,
      source: {
        id: SOURCE_DND3_5_PGTD,
      },
    },
    ...DragonsBlackList,
  ],
  [CREATURE_DRAGON_BLUE_WYRMLING]: [
    {
      src: blue_dragon_wyrmling_1,
      source: {
        id: SOURCE_DND3_5_PGTD,
      },
    },
    {
      src: blue_dragon_wyrmling_2,
      source: {
        id: SOURCE_DND3_5_PGTD,
      },
    },
    ...DragonsBlueList,
  ],
  [CREATURE_DRAGON_BRASS_WYRMLING]: DragonsBrassList,
  [CREATURE_DRAGON_BRONZE_WYRMLING]: DragonsBronzeList,
  [CREATURE_DRAGON_COPPER_WYRMLING]: DragonsCopperList,
  [CREATURE_DAO]: [
    dao,
    {
      src: Dao_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_DARKMANTLE]: darkmantle,
  [CREATURE_DEATH_DOG]: death_dog,
  [CREATURE_DEATH_KNIGHT]: [
    death_knight,
    {
      src: Death_Knight_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_DEATH_TYRANT]: [
    death_tyrant,
    ...BeholderkinList,
  ],
  [CREATURE_DEMILICH]: [
    demilich,
    {
      src: Demilich_MTG_AFR_img,
      source: {
        id: SOURCE_MTG_AFR,
      },
    },
  ],
  [CREATURE_DEVA]: deva,
  [CREATURE_DIRE_WOLF]: [
    dire_wolf,
    Dire_Wolf_Attack_Northfolk_Family_SKT_data,
  ],
  [CREATURE_DISPLACER_BEAST]: [
    displacer_beast,
    {
      src: Displacer_Beast_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Displacer_beast_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Displacer_kitten_MTG_CLB_img,
      text: 'Ускользающий котёнок',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_DJINNI]: [
    djinni,
    {
      src: Djinni_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Djinni_MTG_AFR_img,
      source: {
        id: SOURCE_MTG_AFR,
      },
    },
  ],
  [CREATURE_DOPPELGANGER]: [
    doppelganger,
    {
      src: Doppelganger_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_DRAGON_TURTLE]: [
    dragon_turtle,
    {
      src: Dragon_Turtle_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Dragon_turtle_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Sailors_bane_MTG_CLB_img,
      text: 'Погибель моряков',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_DRETCH]: dretch,
  [CREATURE_DRIDER]: drider,
  [CREATURE_DRIDER_SPELLCASTER]: {
    src: drider,
    creatureId: CREATURE_DRIDER,
  },
  [CREATURE_DROW]: [
    {
      src: Drow_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    Drow_PHB_data,
  ],
  [CREATURE_DROW_MAGE]: Drow_Mage_MM_data,
  [CREATURE_DRUID]: Druid_MM_data,
  [CREATURE_DRYAD]: [
    dryad,
    {
      src: Dryad_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_DUERGAR]: [
    duergar,
    Duergar_ADND2_data,
  ],
  [CREATURE_DUODRONE]: [
    duodrone,
    {
      src: Marching_duodrone_MTG_CLB_img,
      text: 'Марширующий дуодрон',
      source: SOURCE_MTG_CLB,
    }
  ],
  [CREATURE_DUST_MEPHIT]: [
    dust_mephit,
    Mephit_data,
  ],
  [CREATURE_DRAGON_GOLD_WYRMLING]: DragonsGoldList,
  [CREATURE_DRAGON_GREEN_WYRMLING]: DragonsGreenList,
  [CREATURE_DRAFT_HORSE]: {
    src: Horse_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_DRAGON_RED_WYRMLING]: DragonsRedList,
  [CREATURE_DRAGON_SILVER_WYRMLING]: {
    src: silver_dragon_wyrmling,
    source: {
      id: SOURCE_DND3_5_PGTW,
    },
  },
  [CREATURE_DRAGON_WHITE_WYRMLING]: [
    {
      src: white_dragon_wyrmling,
      creatureId: CREATURE_DRAGON_WHITE_WYRMLING,
      source: {
        id: SOURCE_DND3_5_PGTD,
      },
    },
    ...DragonsWhiteList,
  ],
  [CREATURE_DRAGON_BLACK_YOUNG]: [
    {
      src: young_black_dragon,
      source: {
        id: SOURCE_DND3_5_PGTD,
      },
    },
    ...DragonsBlackList,
  ],
  [CREATURE_DRAGON_BLUE_YOUNG]: DragonsBlueList,
  [CREATURE_DRAGON_BRASS_YOUNG]: DragonsBrassList,
  [CREATURE_DRAGON_BRONZE_YOUNG]: DragonsBronzeList,
  [CREATURE_DRAGON_COPPER_YOUNG]: DragonsCopperList,
  [CREATURE_DRAGON_GOLD_YOUNG]: DragonsGoldList,
  [CREATURE_DRAGON_GREEN_YOUNG]: DragonsGreenList,
  [CREATURE_DRAGON_RED_YOUNG]: DragonsRedList,
  [CREATURE_DRAGON_SILVER_YOUNG]: DragonsSilverList,
  [CREATURE_DRAGON_WHITE_YOUNG]: DragonsWhiteList,
}
