import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@/components/Icon'

import './CastConstituantStyles.less'

const CastConstituantComponent = ({list}) => (
  <section className='CastConstituant'>
    <header className='CastConstituant_header'>Компоненты</header>
    <ul className='CastConstituant_list'>
      {
        list.map(
          ({id, text, title, iconName}) => (
            <li
              className='CastConstituant_item'
              key={id}
              title={title}
            >
              <Icon
                icon={iconName}
                className='CastConstituant_icon'
              />
                {text}
            </li>
          )
        )
      }
    </ul>
  </section>
)

CastConstituantComponent.propTypes = {
  list: PropTypes.array,
}

CastConstituantComponent.defaultProps = {
  list: [],
}

export default CastConstituantComponent
