const {
  CREATURE_GNOLL,
} = require('./../../../creatureIdList')

const {
  SOURCE_ADND2_MM,
  SOURCE_VGTM,
} = require('./../../../sourceList')

const Gnoll_ADND2 = require('./../../../../images/creatures/adnd2_mm/Gnoll.jpg')
const Gnoll_Band_VGTM_img = require('./../../../../images/creatures/vgtm/gnoll_band.jpg')
const Gnoll_img_MM = require('./../../../../images/creatures/mm/gnoll.jpg')
const Gnolls_summon_Demons_VGTM_img = require('./../../../../images/creatures/vgtm/gnolls_summon_demons.png')

module.exports = [
  {
    src: Gnoll_img_MM,
    creatureId: CREATURE_GNOLL,
  },
  {
    src: Gnoll_ADND2,
    creatureId: CREATURE_GNOLL,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  {
    src: Gnoll_Band_VGTM_img,
    text: 'Банда гноллов',
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: Gnolls_summon_Demons_VGTM_img,
    text: 'Гноллы призывают демонов',
    source: {
      id: SOURCE_VGTM,
    },
  },
]
