import { CREATURE_DEATHLOCK, CREATURE_DEATHLOCK_MASTERMIND } from '@/constants/creatureIdList'
import { faHamsa, faTheaterMasks } from '@fortawesome/free-solid-svg-icons'
import { faFreebsd } from '@fortawesome/free-brands-svg-icons'
import {
  SPELL_ARMOR_OF_AGATHYS,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_BLINK,
  SPELL_BURNING_HANDS,
  SPELL_CLAIRVOYANCE,
  SPELL_COMMAND,
  SPELL_DETECT_THOUGHTS,
  SPELL_DISSONANT_WHISPERS,
  SPELL_DOMINATE_BEAST,
  SPELL_DOMINATE_PERSON,
  SPELL_EVARD_S_BLACK_TENTACLES,
  SPELL_FAERIE_FIRE,
  SPELL_FIRE_SHIELD,
  SPELL_FIREBALL,
  SPELL_FLAME_STRIKE,
  SPELL_GREATER_INVISIBILITY,
  SPELL_HELLISH_REBUKE,
  SPELL_HUNGER_OF_HADAR,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_PHANTASMAL_FORCE,
  SPELL_SCORCHING_RAY,
  SPELL_SEEMING,
  SPELL_SENDING,
  SPELL_SLEEP,
  SPELL_STINKING_CLOUD,
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  SPELL_TELEKINESIS,
  SPELL_WALL_OF_FIRE,
} from '@/constants/spellIdList'
import { SOURCE_MTOF } from '@/constants/sourceList'
import generateTextLinks from '@/utils/generateTextLinks'

export default [
  {
    templateName: 'Покровитель: Архифея',
    templateIcon: faTheaterMasks,
    templateColor: 'pink',
    namePostText: ' архифеи',
    creatureId: CREATURE_DEATHLOCK,
    spellList: [
      SPELL_BLINK,
      SPELL_FAERIE_FIRE,
      SPELL_HUNGER_OF_HADAR,
      SPELL_HYPNOTIC_PATTERN,
      SPELL_PHANTASMAL_FORCE,
      SPELL_SLEEP,
    ],
  },
  {
    templateName: 'Покровитель: Исчадие',
    templateIcon: faFreebsd,
    templateColor: 'red',
    namePostText: ' исчадия',
    creatureId: CREATURE_DEATHLOCK,
    spellList: [
      SPELL_BLINDNESS_DEAFNESS,
      SPELL_BURNING_HANDS,
      SPELL_COMMAND,
      SPELL_FIREBALL,
      SPELL_HELLISH_REBUKE,
      SPELL_SCORCHING_RAY,
    ],
  },
  {
    templateName: 'Покровитель: Великий Древний',
    templateIcon: faHamsa,
    templateColor: 'darkViolet',
    namePostText: ' Великого Древнего',
    creatureId: CREATURE_DEATHLOCK,
    spellList: [
      SPELL_ARMOR_OF_AGATHYS,
      SPELL_DETECT_THOUGHTS,
      SPELL_DISSONANT_WHISPERS,
      SPELL_HUNGER_OF_HADAR,
      SPELL_PHANTASMAL_FORCE,
      SPELL_TASHA_S_HIDEOUS_LAUGHTER,
    ],
  },
  {
    templateName: 'Покровитель: Архифея',
    templateIcon: faTheaterMasks,
    templateColor: 'pink',
    namePostText: ' архифеи',
    creatureId: CREATURE_DEATHLOCK_MASTERMIND,
    spellList: [
      SPELL_BLINK,
      SPELL_DOMINATE_BEAST,
      SPELL_DOMINATE_PERSON,
      SPELL_FAERIE_FIRE,
      SPELL_GREATER_INVISIBILITY,
      SPELL_HUNGER_OF_HADAR,
      SPELL_HYPNOTIC_PATTERN,
      SPELL_PHANTASMAL_FORCE,
      SPELL_SEEMING,
      SPELL_SLEEP,
    ],
  },
  {
    templateName: 'Покровитель: Исчадие',
    templateIcon: faFreebsd,
    templateColor: 'red',
    namePostText: ' исчадия',
    creatureId: CREATURE_DEATHLOCK_MASTERMIND,
    spellList: [
      SPELL_BLINDNESS_DEAFNESS,
      SPELL_BURNING_HANDS,
      SPELL_COMMAND,
      SPELL_FIRE_SHIELD,
      SPELL_FIREBALL,
      SPELL_FLAME_STRIKE,
      SPELL_HELLISH_REBUKE,
      SPELL_SCORCHING_RAY,
      SPELL_STINKING_CLOUD,
      SPELL_WALL_OF_FIRE,
    ],
  },
  {
    templateName: 'Покровитель: Великий Древний',
    templateIcon: faHamsa,
    templateColor: 'darkViolet',
    namePostText: ' Великого Древнего',
    creatureId: CREATURE_DEATHLOCK_MASTERMIND,
    spellList: [
      SPELL_CLAIRVOYANCE,
      SPELL_DETECT_THOUGHTS,
      SPELL_DISSONANT_WHISPERS,
      SPELL_DOMINATE_PERSON,
      SPELL_EVARD_S_BLACK_TENTACLES,
      SPELL_HUNGER_OF_HADAR,
      SPELL_PHANTASMAL_FORCE,
      SPELL_SENDING,
      SPELL_TASHA_S_HIDEOUS_LAUGHTER,
      SPELL_TELEKINESIS,
    ],
  },
]
  .map(
    ({
       creatureId,
       namePostText,
       spellList,
       templateColor,
       templateIcon,
       templateName,
     }) => ({
      templateName,
      templateIcon,
      templateColor,

      isAlreadyHasPatron: true,

      templateLimitations: {
        exclude: {
          isAlreadyHasPatron: true,
        },
        include: {
          id: [
            creatureId,
          ],
        },
      },

      extendPropCollection: {
        description: [
          {
            header: 'Характерные заклинания покровителя',
            text: generateTextLinks(`Вы можете изменить дэтлока, заменив одно или несколько из всех его заклинания на характерные для его покровителя. Ниже приведены примеры.

# [Дэтлок](CREATURE:${CREATURE_DEATHLOCK})

## Покровитель архифея

* [Воображаемая сила](SPELL:${SPELL_PHANTASMAL_FORCE})
* [Гипнотический узор](SPELL:${SPELL_HYPNOTIC_PATTERN})
* [Голод Хадара](SPELL:${SPELL_HUNGER_OF_HADAR})
* [Мерцание](SPELL:${SPELL_BLINK})
* [Огонь фей](SPELL:${SPELL_FAERIE_FIRE})
* [Усыпление](SPELL:${SPELL_SLEEP})

## Покровитель исчадие

* [Адское возмездие](SPELL:${SPELL_HELLISH_REBUKE})
* [Глухота/слепота](SPELL:${SPELL_BLINDNESS_DEAFNESS})
* [Огненные ладони](SPELL:${SPELL_BURNING_HANDS})
* [Огненный шар](SPELL:${SPELL_FIREBALL})
* [Палящий луч](SPELL:${SPELL_SCORCHING_RAY})
* [Приказ](SPELL:${SPELL_COMMAND})

## Покровитель Великий Древний

* [Воображаемая сила](SPELL:${SPELL_PHANTASMAL_FORCE})
* [Голод Хадара](SPELL:${SPELL_HUNGER_OF_HADAR})
* [Диссонирующий шёпот](SPELL:${SPELL_DISSONANT_WHISPERS})
* [Доспех Агатиса](SPELL:${SPELL_ARMOR_OF_AGATHYS})
* [Жуткий смех Таши](SPELL:${SPELL_TASHA_S_HIDEOUS_LAUGHTER})
* [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS})

# [Дэтлок манипулятор](CREATURE:${CREATURE_DEATHLOCK_MASTERMIND})

## Покровитель архифея

* [Воображаемая сила](SPELL:${SPELL_PHANTASMAL_FORCE})
* [Высшая невидимость](SPELL:${SPELL_GREATER_INVISIBILITY})
* [Гипнотический узор](SPELL:${SPELL_HYPNOTIC_PATTERN})
* [Голод Хадара](SPELL:${SPELL_HUNGER_OF_HADAR})
* [Мерцание](SPELL:${SPELL_BLINK})
* [Огонь фей](SPELL:${SPELL_FAERIE_FIRE})
* [Подчинение зверя](SPELL:${SPELL_DOMINATE_BEAST})
* [Подчинение личности](SPELL:${SPELL_DOMINATE_PERSON})
* [Притворство](SPELL:${SPELL_SEEMING})
* [Усыпление](SPELL:${SPELL_SLEEP})

## Покровитель исчадие

* [Адское возмездие](SPELL:${SPELL_HELLISH_REBUKE})
* [Глухота/слепота](SPELL:${SPELL_BLINDNESS_DEAFNESS})
* [Зловонное облако](SPELL:${SPELL_STINKING_CLOUD})
* [Небесный огонь](SPELL:${SPELL_FLAME_STRIKE})
* [Огненная стена](SPELL:${SPELL_WALL_OF_FIRE})
* [Огненные ладони](SPELL:${SPELL_BURNING_HANDS})
* [Огненный шар](SPELL:${SPELL_FIREBALL})
* [Огненный щит](SPELL:${SPELL_FIRE_SHIELD})
* [Палящий луч](SPELL:${SPELL_SCORCHING_RAY})
* [Приказ](SPELL:${SPELL_COMMAND})

## Покровитель Великий Древний

* [Воображаемая сила](SPELL:${SPELL_PHANTASMAL_FORCE})
* [Голод Хадара](SPELL:${SPELL_HUNGER_OF_HADAR})
* [Диссонирующий шёпот](SPELL:${SPELL_DISSONANT_WHISPERS})
* [Жуткий смех Таши](SPELL:${SPELL_TASHA_S_HIDEOUS_LAUGHTER})
* [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS})
* [Подсматривание](SPELL:${SPELL_CLAIRVOYANCE})
* [Подчинение личности](SPELL:${SPELL_DOMINATE_PERSON})
* [Послание](SPELL:${SPELL_SENDING})
* [Телекинез](SPELL:${SPELL_TELEKINESIS})
* [Эвардовы чёрные щупальца](SPELL:${SPELL_EVARD_S_BLACK_TENTACLES})
`),
            source: {
              id: SOURCE_MTOF,
              page: 197,
            },
          },
        ],
        source: [{
          id: SOURCE_MTOF,
          page: 197,
        }],
      },
      editPropCollection: {
        name: ({ name }) => `${name}${namePostText}`,
        spellCast: ({ spellCast }) => {
          const [spellCastInner, spellCastByPatron] = spellCast
          const [spellCountInfinity, spellCountLimited] = spellCastByPatron.spellIdByCountList

          return [
            spellCastInner,
            {
              ...spellCastByPatron,
              spellIdByCountList: [
                spellCountInfinity,
                {
                  ...spellCountLimited,
                  list: spellList,
                },
              ],
            },
          ]
        },
      },
    }),
  )
