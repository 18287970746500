import React from "react"

import StatInfoRow from "@/components/StatInfoRow";
import calcProfBonusByCr from '@/utils/calcProfBonusByCr'

const ProfBonus = ({cr}) => {
  if (cr) {
    const profBonus = calcProfBonusByCr(cr)

    return (
      <StatInfoRow header='Бонус мастерства'>
        +{profBonus}
      </StatInfoRow>
    )
  }

  return null
}

export default ProfBonus
