import {
  MONET_PLATINUM,
  MONET_SILVER,
  MONET_ELECTRUM,
  MONET_GOLD,
  MONET_COPPER,
} from '@/constants/monetTypeList'

import goldImg from "@/images/monets/gold.png"
import copperImg from "@/images/monets/copper.png"
import silverImg from "@/images/monets/silver.png"
import electrumImg from "@/images/monets/electrum.png"
import platinumImg from "@/images/monets/platinum.png"

const imageCollection = {
  [MONET_GOLD]: goldImg,
  [MONET_COPPER]: copperImg,
  [MONET_SILVER]: silverImg,
  [MONET_ELECTRUM]: electrumImg,
  [MONET_PLATINUM]: platinumImg,
}

export default imageCollection
