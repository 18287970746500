import React from "react"

import StatInfoRow from "@/components/StatInfoRow";

export default ({list, header, joiner}) => (
  <StatInfoRow header={header}>
    {
      list
        .join(`${joiner} `)
        .toLowerCase()
    }
  </StatInfoRow>
)
