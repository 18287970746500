import React from "react"

import ImmunityConditionList from "./ImmunityConditionListComponent";

const ImmunityConditionListContainer = (
  {
    immunityConditionList,
  }
) => (
  immunityConditionList
  && immunityConditionList.length
)
  ? (
    <ImmunityConditionList
      header='Иммунитет к состоянию'
      list={immunityConditionList}
    />
  )
  : null

export default ImmunityConditionListContainer
