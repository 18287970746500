import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import { creatureCollection } from '@/constants/creatureList'

import generateCreaturePageUrlById from '@/utils/generateCreaturePageUrlById'

import AbilityList from "@/components/AbilityList"
import CreatureAc from "@/components/CreatureAc"
import CreatureSize from "@/components/CreatureSize"
import HitPoints from "@/components/HitPoints"
import ImmunityConditionList from "@/components/ImmunityConditionList"
import ImmunityList from "@/components/ImmunityList"
import ParamList from "@/components/ParamList"
import ReleasedAs from '@/components/ReleasedAs'
import ReleasedBeforeAs from '@/components/ReleasedBeforeAs'
import ResistanceList from "@/components/ResistanceList"
import StatBlockSource from "@/components/StatBlockSource"
import VehicleProportions from "@/components/VehicleProportions"

import ActionList from "./components/ActionList"
import Cargo from "./components/Cargo"
import ControlList from "./components/ControlList"
import CreatureAlignment from "./components/CreatureAlignment"
import CreatureCapacity from "./components/CreatureCapacity"
import CreatureCr from "./components/CreatureCr"
import CreatureExtender from "./components/CreatureExtender"
import CreatureSpeed from "./components/CreatureSpeed"
import CreatureType from "./components/CreatureType"
import Hull from "./components/Hull"
import LanguageKnownList from "./components/LanguageKnownList"
import LegendaryActionList from "./components/LegendaryActionList"
import MovementList from "./components/MovementList"
import ProfBonus from "./components/ProfBonus"
import ReactionList from "./components/ReactionList"
import SaveThrowList from "./components/SaveThrowList"
import SenseList from "./components/SenseList"
import SkillCollection from "./components/SkillCollection"
import TravelSpeed from "./components/TravelSpeed"
import VulnerabilityList from "./components/VulnerabilityList"
import WeaponList from "./components/WeaponList"

import "./StatBlockStyles.less"

const StatBlockComponent = (
  {
    data,
    data: {
      actionList = [],
      featureList = [],
      genderId,
      immunityList = [],
      isVehicle,
      languageList = [],
      legendaryActionList = [],
      legendaryActionDescription = '',
      legendaryPoints = 0,
      params,
      reactionList = [],
      releasedAs,
      releasedBeforeAsList,
      resistanceList = [],
      saveThrowList = [],
      skillCollection = null,
      source,
      speed,
      spellCast = null,
      vulnerabilityList = [],
    },
    columnCount,
    extendCreature,
    header,
    onChangeHeader,
    restoreName,
    showRestoreBtn,
    hideMentalParamModifiers,
  },
) => (
  <section className={`StatBlock StatBlock-columnCount_${columnCount}`}>
    <ReleasedAs
      className='StatBlock_ReleasedAs'
      preText='Это существо было переиздано'
      releasedAs={releasedAs}
      collection={creatureCollection}
      urlGenerator={generateCreaturePageUrlById}
    />
    <section className='StatBlock_block'>
      <header className={classNames(
        'StatBlock_header',
        {
          'StatBlock_header-showRestoreBtn': showRestoreBtn,
        }
      )}>
        <label className='StatBlock_headerLabel'>
          {
            !isVehicle && onChangeHeader
              ? (
                <textarea
                  className='StatBlock_headerInput'
                  value={header}
                  onChange={onChangeHeader}
                />
              )
              : null
          }
          <p className={classNames(
            'StatBlock_headerText',
            {
              'StatBlock_headerText-hasHover': !isVehicle,
            }
          )}>{header}</p>
        </label>

        {
          restoreName
            ? (
              <button
                onClick={restoreName}
                className='StatBlock_restoreNameBtn'
                title='Вернуть название по-умолчанию'
              >
                ×
              </button>
            )
            : null
        }
      </header>

      <p className='StatBlock_info'>
        <CreatureSize {...data} spaceAfter />
        <CreatureType {...data}/>
        <CreatureAlignment {...data}/>
        <VehicleProportions {...data}/>
      </p>

      <section className='StatBlock_infoBlock'>
        <CreatureCapacity {...data}/>
        <Cargo {...data}/>
        <TravelSpeed {...data}/>
        <CreatureAc {...data}/>
        <HitPoints {...data}/>
        <CreatureSpeed speed={speed} />
      </section>

      <ParamList
        className='StatBlock_infoBlock'
        params={params}
        hideMentalParamModifiers={hideMentalParamModifiers}
      />

      <section className='StatBlock_infoBlock'>
        { saveThrowList && <SaveThrowList {...data}/> }
        { skillCollection && <SkillCollection {...data}/> }
        <VulnerabilityList list={vulnerabilityList}/>
        <ResistanceList list={resistanceList}/>
        <ImmunityList list={immunityList}/>
        <ImmunityConditionList {...data}/>
        <SenseList {...data} />
        {
          isVehicle
            ? null
            : (
              <LanguageKnownList
                list={languageList}
                use5eOfficialName
              />
            )
        }
        <CreatureCr {...data}/>
        <ProfBonus {...data}/>
        <StatBlockSource source={source}/>
      </section>

      <AbilityList
        data={data}
        list={featureList}
        name={header}
        genderId={genderId}
        spellCast={spellCast}
      />
      <ActionList
        {...data}
        list={actionList}
        name={header}
        genderId={genderId}
      />
      <Hull {...data}/>
      <ControlList {...data}/>
      <MovementList {...data}/>
      <WeaponList {...data}/>
      <LegendaryActionList
        list={legendaryActionList}
        description={legendaryActionDescription}
        name={header}
        points={legendaryPoints}
        genderId={genderId}
      />
      <ReactionList
        list={reactionList}
        name={header}
        genderId={genderId}
      />
    </section>
    <ReleasedBeforeAs
      className='StatBlock_ReleasedBeforeAs'
      checkIsReady={false}
      preText='Это существо ранее издавалось'
      releasedAs={releasedBeforeAsList}
      collection={creatureCollection}
      urlGenerator={generateCreaturePageUrlById}
    />
    {
      extendCreature
        ? (
          <CreatureExtender
            className='StatBlock_extender'
            creature={data}
            callback={extendCreature}
          />
        )
        : null
    }
  </section>
)

StatBlockComponent.propTypes = {
  hideMentalParamModifiers: PropTypes.bool,
  extendCreature: PropTypes.func,
  onChangeHeader: PropTypes.func,
  restoreName: PropTypes.func,
  showRestoreBtn: PropTypes.bool.isRequired,
  columnCount: PropTypes.number,
  data: PropTypes.shape({
    actionList: PropTypes.array,
    extendCreature: PropTypes.func,
    featureList: PropTypes.array,
    hp: PropTypes.object.isRequired,
    genderId: PropTypes.string.isRequired,
    immunityList: PropTypes.array,
    isVehicle: PropTypes.bool,
    languageList: PropTypes.array,
    legendaryActionList: PropTypes.array,
    legendaryActionDescription: PropTypes.string,
    legendaryPoints: PropTypes.number,
    params: PropTypes.object.isRequired,
    reactionList: PropTypes.array,
    saveThrowList: PropTypes.array,
    senseList: PropTypes.array,
    skillCollection: PropTypes.object,
    source: PropTypes.PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]).isRequired,
    speed: PropTypes.object.isRequired,
    spellCast: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    vulnerabilityList: PropTypes.array,
  }).isRequired,
}

StatBlockComponent.defaultProps = {
  columnCount: 1,
  extendCreature: null,
  onChangeHeader: null,
  restoreName: null,
  hideMentalParamModifiers: false,
}

export default StatBlockComponent
