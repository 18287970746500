const {
  SOURCE_MTG_CLB,
  SOURCE_WDH,
} = require('./../../sourceList')
const {
  CREATURE_DROW_GUNSLINGER,
  CREATURE_NIMBLEWRIGHT,
  CREATURE_VAJRA_SAFAHR,
  CREATURE_WALKING_STATUE_OF_WATERDEEP,
} = require('./../../creatureIdList')

const {Vajra_Safahr_WDH_data} = require('./../commonImageCollection')

const Drow_gunslinger_img_WDH = require('./../../../images/creatures/wdh/drow_gunslinger.png')
const Nimblewright_img_WDH = require('./../../../images/creatures/wdh/nimblewright.png')

const Nimblewright_schematic_MTG_CLB_img = require('./../../../images/creatures/mtg_clb/nimblewright-schematic-mtg_clb.webp')
const Walking_statue_of_waterdeep_God_catcher_WDH_img = require('./../../../images/creatures/wdh/walking_statue_of_waterdeep_god_catcher_wdh.webp')
const Walking_statue_of_waterdeep_Great_drunkard_WDH_img = require('./../../../images/creatures/wdh/walking_statue_of_waterdeep_great_drunkard_wdh.jpeg')

module.exports = {
  [CREATURE_DROW_GUNSLINGER]: Drow_gunslinger_img_WDH,
  [CREATURE_NIMBLEWRIGHT]: [
    Nimblewright_img_WDH,
    {
      src: Nimblewright_schematic_MTG_CLB_img,
      text: 'Чертёж проворника',
      source: SOURCE_MTG_CLB,
    },
  ],
  [CREATURE_WALKING_STATUE_OF_WATERDEEP]: [
    {
      src: Walking_statue_of_waterdeep_God_catcher_WDH_img,
      text: 'Ходячая статуя «Ловец богов» высится на заднем плане',
      source: {
        id: SOURCE_WDH,
        page: 182,
      },
    },
    {
      src: Walking_statue_of_waterdeep_Great_drunkard_WDH_img,
      text: 'Ходячая статуя «Великий пьяница»',
      source: {
        id: SOURCE_WDH,
        page: 218,
      },
    },
  ],
  [CREATURE_VAJRA_SAFAHR]: Vajra_Safahr_WDH_data,
}
