const {
  CREATURE_CAMBION,
  CREATURE_CARRION_CRAWLER,
  CREATURE_CAT,
  CREATURE_CENTAUR,
  CREATURE_CHAIN_DEVIL,
  CREATURE_CHASME,
  CREATURE_CHIMERA,
  CREATURE_CHUUL,
  CREATURE_CLAY_GOLEM,
  CREATURE_CLOACKER,
  CREATURE_CLOUD_GIANT,
  CREATURE_COCKATRICE,
  CREATURE_COUATL,
  CREATURE_CRAWLING_CLAW,
  CREATURE_CROCODILE,
  CREATURE_CULT_FANATIC,
  CREATURE_CULTIST,
  CREATURE_CYCLOPS,
} = require('./../../../../creatureIdList')
const {
  SOURCE_DND3_5_PGTM,
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_GAME_BG_3,
  SOURCE_DMG,
  SOURCE_MTG_CLB,
  SOURCE_TCoE,
} = require('./../../../../sourceList')

const {
  GiantCloudImgList,
} = require('./../../../commonImageListCollection')

const {
  Centaur_Female_GGTR_data,
  Centaur_Male_ADND2_data,
  Centaur_Male_AYAGMnC_data,
  Centaur_Male_MM_data,
  Princeps_Kovik_BGDIA_data,
} = require('./../../../commonImageCollection')

const cambion = require('./../../../../../images/creatures/mm/cambion.jpg')
const carrion_crawler = require('./../../../../../images/creatures/mm/carrion_crawler.jpeg')
const chain_devil = require('./../../../../../images/creatures/mm/chain_devil.jpg')
const chasme = require('./../../../../../images/creatures/mm/chasme.jpeg')
const chimera = require('./../../../../../images/creatures/mm/chimera.jpeg')
const chuul = require('./../../../../../images/creatures/mm/chuul.jpeg')
const clay_golem = require('./../../../../../images/creatures/mm/clay_golem.jpg')
const cloaker = require('./../../../../../images/creatures/mm/cloaker.jpeg')
const cockatrice = require('./../../../../../images/creatures/mm/cockatrice.jpg')
const couatl = require('./../../../../../images/creatures/mm/couatl.jpg')
const crawling_claw = require('./../../../../../images/creatures/mm/crawling_claw.jpeg')
const cult_fanatic = require('./../../../../../images/creatures/mm/cult_fanatic.jpeg')
const cultist = require('./../../../../../images/creatures/mm/cultist.png')
const cyclops = require('./../../../../../images/creatures/mm/cyclops.jpeg')

const chimera_2 = require('./../../../../../images/creatures/dnd3_5_pgtm/chimera_2.jpg')

const Carrion_Crawler_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Carrion_Crawler.jpg')
const Cat_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Cat.jpg')
const Chimera_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Chimera.jpg')
const Cloaker_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Cloaker.jpg')
const Cockatrice_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Cockatrice.jpg')
const Couatl_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Couatl.jpg')
const Crawling_Claw_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Crawling_Claw.jpg')
const Crocodile_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Crocodile.jpg')
const Cyclop_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Cyclop.jpg')
const Golem_Clay_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Golem_Clay.jpg')

const Carrion_crawler_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/carrion_crawler.jpg')

const Coatl_img_TCoE = require('./../../../../../images/creatures/tcoe/coatl.png')

const Couatl_DMG_img = require('./../../../../../images/creatures/dmg/couatl.png')

const Raphael_Cambion_GAME_BG3_img = require('./../../../../../images/creatures/bg3/raphael-cambion-bg3.jpeg')
const Raphael_Concept_GAME_BG3_img = require('./../../../../../images/creatures/bg3/raphael_concept_bg3.webp')
const Raphael_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/Raphael_CLB_alt.webp')
const Chain_devil_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/chain_devil.jpg')

module.exports = {
  [CREATURE_CAMBION]: [
    cambion,
    {
      src: Raphael_Cambion_GAME_BG3_img,
      text: 'Камбион Рафаэль',
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      src: Raphael_Concept_GAME_BG3_img,
      text: 'Камбион Рафаэль, концепт',
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      src: Raphael_MTG_CLB_img,
      text: 'Камбион Рафаэль, дьявольский спаситель',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_CARRION_CRAWLER]: [
    carrion_crawler,
    {
      src: Carrion_Crawler_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Carrion_crawler_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_CAT]: {
    src: Cat_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_CENTAUR]: [
    Centaur_Male_MM_data,
    Centaur_Female_GGTR_data,
    Centaur_Male_ADND2_data,
    Centaur_Male_AYAGMnC_data,
  ],
  [CREATURE_CHAIN_DEVIL]: [
    chain_devil,
    Princeps_Kovik_BGDIA_data,
    {
      src: Chain_devil_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_CHASME]: chasme,
  [CREATURE_CHIMERA]: [
    chimera,
    {
      src: chimera_2,
      source: {
        id: SOURCE_DND3_5_PGTM,
      },
    },
    {
      src: Chimera_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_CHUUL]: chuul,
  [CREATURE_CLAY_GOLEM]: [
    clay_golem,
    {
      src: Golem_Clay_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_CLOACKER]: [
    cloaker,
    {
      src: Cloaker_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_CLOUD_GIANT]: GiantCloudImgList,
  [CREATURE_COCKATRICE]: [
    cockatrice,
    {
      src: Cockatrice_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_COUATL]: [
    couatl,
    {
      src: Couatl_DMG_img,
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Couatl_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Coatl_img_TCoE,
      text: 'Прислужники готовятся к празднику коатля',
      source: {
        id: SOURCE_TCoE,
      },
    },
  ],
  [CREATURE_CRAWLING_CLAW]: [
    crawling_claw,
    {
      src: Crawling_Claw_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_CROCODILE]: {
    src: Crocodile_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_CULT_FANATIC]: cult_fanatic,
  [CREATURE_CULTIST]: cultist,
  [CREATURE_CYCLOPS]: [
    cyclops,
    {
      src: Cyclop_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
}
