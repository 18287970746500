import React from 'react'

import './MonetStyles.less'

const MonetComponent = ({ title, type, text, src, alt }) => (
  <span
    className={`Monet Monet-${type}`}
    title={title}
  >
    <span className='Monet_text'>{text}</span>
    <img
      className='Monet_image'
      src={src}
      alt={` ${alt}`}
    />
  </span>
)

export default MonetComponent
