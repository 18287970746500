import {
  SPELL_BESTOW_CURSE,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_COUNTERSPELL,
  SPELL_EYEBITE,
  SPELL_HOLD_PERSON,
  SPELL_IDENTIFY,
  SPELL_LIGHTNING_BOLT,
  SPELL_LOCATE_OBJECT,
  SPELL_PHANTASMAL_KILLER,
  SPELL_POLYMORPH,
  SPELL_RAY_OF_SICKNESS,
  SPELL_SCRYING,
} from '@/constants/spellIdList'
import {
  CREATURE_ANNIS_HAG,
  CREATURE_BHEUR_HAG,
  CREATURE_GREEN_HAG,
  CREATURE_NIGHT_HAG,
  CREATURE_SEA_HAG,
} from '@/constants/creatureIdList'
import {
  CR_4,
  CR_5,
  CR_7,
  CR_8,
  CR_9,
} from '@/constants/crList'
import arrify from 'arrify'
import calculateSpellAttackBonus from '@/utils/calculateSpellAttackBonus'
import DC_BASE from '@/constants/DC_BASE'
import formatBonus from '@/utils/formatBonus'
import {faBroom} from '@fortawesome/free-solid-svg-icons'
import {PARAM_INT} from '@/constants/paramList'
import {SOURCE_MM} from '@/constants/sourceList'

const calculateCr = ({ id, cr }) => {
  switch (id) {
    case CREATURE_ANNIS_HAG:
      return CR_8
    case CREATURE_BHEUR_HAG:
      return CR_9
    case CREATURE_GREEN_HAG:
      return CR_5
    case CREATURE_SEA_HAG:
      return CR_4
    case CREATURE_NIGHT_HAG:
      return CR_7
    default:
      return cr
  }
}

export default {
  templateName: 'Карга в шабаше',
  templateIcon: faBroom,
  templateColor: 'lime',

  templateLimitations: {
    include: {
      id: [
        CREATURE_ANNIS_HAG,
        CREATURE_BHEUR_HAG,
        CREATURE_GREEN_HAG,
        CREATURE_SEA_HAG,
        CREATURE_NIGHT_HAG,
      ],
    },
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 169,
    }],
    description: [
      {
        header: 'Шабаш карг',
        text: `Когда нужно работать сообща, несмотря на эгоизм, карги собираются в шабаши. В шабаш могут входить разные ведьмы, в нём они считаются равными. Тем не менее, каждая жаждет личной власти.

Шабаш — это три карги, и конфликт между двумя всегда может решить третья. Если встречается больше трёх карг, например, когда возникает конфликт между несколькими шабашами, всё погружается в хаос.`,
        source: {
          id: SOURCE_MM,
          page: 169,
        },
      },
    ],
    featureList: [
      {
        name: 'Глаз карги',
        description: `Шабаш может создать магический предмет, называемый _глазом карги_, который делают из настоящего глаза, покрытого лаком, подвешенного на манер кулона. Обычно глаз доверяют помощнику, для сохранения и переноски. Карга в шабаше может действием посмотреть через этот глаз, если он находится на одном с ней плане. У _глаза карги_ КД 10, 1 хит и тёмное зрение в радиусе 60 футов. Если его разрушить, все члены шабаша получают 3к10 психического урона и становятся ослеплёнными на 24 часа.

У шабаша может быть только один _глаз_ одновременно, и создание нового требует проведения ритуала всеми тремя участницами. Ритуал занимает 1 час, и карги не смогут совершить его, будучи слепыми. Если во время совершения ритуала какая-нибудь карга совершает любое лишнее действие, шабаш должен начинать ритуал заново.`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace('арга', `арга в шабаше`),
    cr: calculateCr,
    spellCast: ({ params, spellCast, id, cr }) => {
      const newCr = calculateCr({ id, cr})
      const spellAttackBonus = calculateSpellAttackBonus({baseStat: params[PARAM_INT], cr: newCr})
      const saveThrowDc = DC_BASE + spellAttackBonus

      const spellCastTogether = {
        title: 'Совместное колдовство',
        preText: `Если все три представительницы шабаша находятся в пределах 30 футов друг от друга, каждая может сотворять следующие заклинания из списка волшебника, но ячейки заклинаний у них общие на всех.`,
        postText: `При накладывании этих заклинаний карга считается заклинателем 12 уровня, использующим Интеллект в качестве базовой характеристики. Её СЛ испытания от заклинания ${saveThrowDc}, ${formatBonus(spellAttackBonus)} к попаданию атаками заклинаниями.`,
        spellIdList: [
          SPELL_BESTOW_CURSE,
          SPELL_CONTACT_OTHER_PLANE,
          SPELL_COUNTERSPELL,
          SPELL_EYEBITE,
          SPELL_HOLD_PERSON,
          SPELL_IDENTIFY,
          SPELL_LIGHTNING_BOLT,
          SPELL_LOCATE_OBJECT,
          SPELL_PHANTASMAL_KILLER,
          SPELL_POLYMORPH,
          SPELL_RAY_OF_SICKNESS,
          SPELL_SCRYING,
        ],
        slotCountList: [0, 4, 3, 3, 3, 2, 1],
      }

      return spellCast
        ? arrify(spellCast).concat(spellCastTogether)
        : spellCastTogether
    },
  },
}
