import {
  GEAR_GREATSWORD,
  GEAR_PLATE_ARMOR,
} from '@/constants/gearIdList'
import {CR_15} from '@/constants/crList'
import {CREATURE_VAMPIRE} from '@/constants/creatureIdList'
import {faGavel} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Вампир воитель',
  templateIcon: faGavel,
  templateColor: 'steelBlue',

  templateLimitations: {
    include: {
      id: [
        CREATURE_VAMPIRE,
      ],
    },
  },

  armor: {
    ac: 18,
    gearId: GEAR_PLATE_ARMOR,
  },
  cr: CR_15,
  extendPropCollection: {
    description: [{
      header: 'Вариант: Воители вампиров',
      text: `У некоторых вампиров есть опыт сражений. Вампир воитель носит [латы](GEAR:${GEAR_PLATE_ARMOR}) (КД 18) и использует двуручный меч.

У него показатель опасности 15 (13 000 опыта) и следующие дополнительные варианты действий:

**Мультиатака.** Вампир совершает две атаки [двуручным мечом](GEAR:${GEAR_GREATSWORD}).

**[Двуручный меч](GEAR:${GEAR_GREATSWORD}).** Рукопашная атака оружием: +9 к попаданию, досягаемость 5 фт., одно существо. Попадание: Рубящий урон 11 (2к6 + 4).`,
      source: {
        id: SOURCE_MM,
        page: 31,
      },
    }],
    source: [{
      id: SOURCE_MM,
      page: 31,
    }],
    actionList: [
      {
        name: 'Мультиатака',
        comment: 'только в облике вампира',
        description: `Вампир совершает две атаки _Двуручным мечом_.`,
      },
      {
        comment: 'только в облике вампира',
        gearId: GEAR_GREATSWORD,
      },
    ],
  },
  editPropCollection: {
    name: ({ name }) => `${name} воитель`,
  },
}
