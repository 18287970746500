import {
  crCollection,
  CR_3,
  CR_5,
} from '@/constants/crList'
import {
  CREATURE_TYPE_TIEFLING,
  CREATURE_TYPE_ANY_RACE,
} from '@/constants/creatureTypeIdList'
import {
  SPELL_DARKNESS,
  SPELL_HELLISH_REBUKE,
  SPELL_THAUMATURGY,
} from '@/constants/spellIdList'
import {
  PARAM_CHA,
  PARAM_INT,
} from '@/constants/paramList'
import {
  LANG_COMMON,
  LANG_INFERNAL,
} from '@/constants/languageIdList'
import arrify from 'arrify'
import calcParamBonus from '@/utils/calcParamBonus'
import SPELL_DC_BASE from '@/constants/SPELL_DC_BASE'
import {DAMAGE_FIRE} from '@/constants/damageTypeList'
import {faFreebsd} from '@fortawesome/free-brands-svg-icons'
import {SENSE_DARK_VISION} from '@/constants/senseList'
import {SOURCE_DMG} from '@/constants/sourceList'

const CHA_BONUS = 2

export default {
  templateName: 'Тифлинг',
  templateIcon: faFreebsd,
  templateColor: 'red',
  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_ANY_RACE,
      ],
    },
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 283,
    }],
    resistanceList: [
      DAMAGE_FIRE,
    ],
    languageList: [
      LANG_COMMON,
      LANG_INFERNAL,
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Тифлинг ${name}`,
    creatureTypeIdList: ({ creatureTypeIdList }) => creatureTypeIdList.map(
      creatureTypeId => creatureTypeId === CREATURE_TYPE_ANY_RACE
        ? CREATURE_TYPE_TIEFLING
        : creatureTypeId
    ),
    params: ({ params }) => ({
      ...params,
      [PARAM_INT]: params[PARAM_INT] + 1,
      [PARAM_CHA]: params[PARAM_CHA] + CHA_BONUS,
    }),
    senseList: ({ senseList = [] }) => [
      ...senseList.filter(
        ({id}) => id !== SENSE_DARK_VISION
      ),
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    spellCast: ({ cr, params, spellCast = [] }) => {
      const spellList = []
      const proficiencyBonus = crCollection[cr].profBonus
      const paramBonus = calcParamBonus(params[PARAM_CHA] + CHA_BONUS)
      const saveThrowDc = SPELL_DC_BASE + proficiencyBonus + paramBonus

      if (crCollection[cr].exp >= crCollection[CR_3].exp ) {
        spellList.push(SPELL_HELLISH_REBUKE)
      }
      if (crCollection[cr].exp >= crCollection[CR_5].exp ) {
        spellList.push(SPELL_DARKNESS)
      }

      return [
        ...arrify(spellCast),
        {
          title: 'Дьявольское наследие',
          baseStat: PARAM_CHA,
          saveThrowDc,
          spellIdByCountList: [
            {
              limit: Infinity,
              list: [
                SPELL_THAUMATURGY,
              ],
            },
            spellList.length
              ? {
                limit: {
                  count: 1,
                  period: 'день',
                },
                list: spellList,
              }
              : null
            ,
          ].filter(e => e),
        },
      ]
    },
  },
}
