const {
  CREATURE_HALF_OGRE,
  CREATURE_HARPY,
  CREATURE_HAWK,
  CREATURE_HELL_HOUND,
  CREATURE_HELMED_HORROR,
  CREATURE_HEZROU,
  CREATURE_HILL_GIANT,
  CREATURE_HIPPOGRIFF,
  CREATURE_HOBGOBLIN,
  CREATURE_HOBGOBLIN_CAPTAIN,
  CREATURE_HOBGOBLIN_WARLORD,
  CREATURE_HOMUNCULUS,
  CREATURE_HOOK_HORROR,
  CREATURE_HORNED_DEVIL,
  CREATURE_HUNTER_SHARK,
  CREATURE_HYDRA,
  CREATURE_HYENA,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  GiantHillImgList,
} = require('./../../../commonImageListCollection')

const {
  Hobgoblin_MM_data,
  Hobgoblin_War_camp_VGTM_data,
} = require('./../../../commonImageCollection')

const half_ogre = require('./../../../../../images/creatures/mm/half_ogre.jpeg')
const harpy = require('./../../../../../images/creatures/mm/harpy.jpg')
const hawk = require('./../../../../../images/creatures/mm/hawk.jpeg')
const hell_hound = require('./../../../../../images/creatures/mm/hell_hound.jpg')
const helmed_horror = require('./../../../../../images/creatures/mm/helmed_horror.jpeg')
const hezrou = require('./../../../../../images/creatures/mm/hezrou.jpeg')
const hippogriff = require('./../../../../../images/creatures/mm/hippogriff.jpg')
const homunculus = require('./../../../../../images/creatures/mm/homunculus.jpg')
const hook_horror = require('./../../../../../images/creatures/mm/hook_horror.jpg')
const horned_devil = require('./../../../../../images/creatures/mm/horned_devil.jpeg')
const hydra = require('./../../../../../images/creatures/mm/hydra.jpg')
const hyena = require('./../../../../../images/creatures/mm/hyena.jpg')

const Half_Ogre_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Half_Ogre.jpg')
const Harpy_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Harpy.jpg')
const Hell_Hound_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Hell_Hound.jpg')
const Hippogriff_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Hippogriff.jpg')
const Hobgoblin_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Hobgoblin.jpg')
const Homonculous_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Homonculous.jpg')
const Hook_Horror_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Hook_Horror.jpg')
const Hydra_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Hydra.jpg')
const Shark_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Shark.jpg')
const Hezrou_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/hezrou.webp')

const Hobgoblin_data = [
  Hobgoblin_MM_data,
  {
    src: Hobgoblin_ADND2,
    creatureId: CREATURE_HOBGOBLIN,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  Hobgoblin_War_camp_VGTM_data,
]

module.exports = {
  [CREATURE_HALF_OGRE]: [
    half_ogre,
    {
      src: Half_Ogre_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_HARPY]: [
    harpy,
    {
      src: Harpy_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_HAWK]: hawk,
  [CREATURE_HELL_HOUND]: [
    hell_hound,
    {
      src: Hell_Hound_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_HELMED_HORROR]: helmed_horror,
  [CREATURE_HEZROU]: [
    hezrou,
    {
      src: Hezrou_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_HILL_GIANT]: GiantHillImgList,
  [CREATURE_HIPPOGRIFF]: [
    hippogriff,
    {
      src: Hippogriff_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_HOBGOBLIN]: Hobgoblin_data,
  [CREATURE_HOBGOBLIN_CAPTAIN]: Hobgoblin_data,
  [CREATURE_HOBGOBLIN_WARLORD]: Hobgoblin_data,
  [CREATURE_HOMUNCULUS]: [
    homunculus,
    {
      src: Homonculous_ADND2,
      creatureId: CREATURE_HOMUNCULUS,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_HOOK_HORROR]: [
    hook_horror,
    {
      src: Hook_Horror_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_HORNED_DEVIL]: horned_devil,
  [CREATURE_HUNTER_SHARK]: {
    src: Shark_ADND2,
    text: 'Акула',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_HYDRA]: [
    hydra,
    {
      src: Hydra_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_HYENA]: hyena,
}
