const {
  CREATURE_DRAGON_COPPER_WYRMLING,
} = require('./../../../creatureIdList')

const {
  SOURCE_ADND2_MM,
  SOURCE_DND3_5_DoF,
  SOURCE_DND3_5_DTBoD,
  SOURCE_DND3_5_PGTD,
  SOURCE_MM,
} = require('./../../../sourceList')

const {
  Council_of_Metallic_Dragons_FTD_data,
  Ancient_Dragons_Hoard_data,
} = require('./../../commonImageCollection')

const Copper_Dragon_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Copper_Dragon.jpg')
const Copper_Dragon_MM_img = require('./../../../../images/creatures/mm/copper_dragon.jpg')
const Copper_Dragon_Wyrmling_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/copper-dragon-wyrmling.webp')
const Copper_Dragon_Wyrmling_DND3_5_PGTD_img = require('./../../../../images/creatures/dnd3_5_pgtd/copper_dragon_wyrmling.jpg')
const Galadaeros_DND3_5_DoF_img = require('./../../../../images/creatures/dnd3_5_dof/galadaeros.jpg')

module.exports = [
  {
    src: Copper_Dragon_MM_img,
    text: 'Медный дракон',
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Galadaeros_DND3_5_DoF_img,
    text: 'Галадэрос и приключенцы, известные как «Галадранова команда»',
    source: {
      id: SOURCE_DND3_5_DoF,
    },
  },
  {
    src: Copper_Dragon_Wyrmling_DND3_5_PGTD_img,
    creatureId: CREATURE_DRAGON_COPPER_WYRMLING,
    source: {
      id: SOURCE_DND3_5_PGTD,
    },
  },
  {
    src: Copper_Dragon_Wyrmling_DND3_5_DTBoD_img,
    text: 'Вирмлинг медного дракона, служащий фамильяром',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Copper_Dragon_ADND2_img,
    text: 'Медный дракон',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  Council_of_Metallic_Dragons_FTD_data,
  Ancient_Dragons_Hoard_data,
]
