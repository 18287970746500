import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import checkIsFromUA from "@/utils/checkIsFromUA"

import './PcClassTableStyles.less'

const PcClassTableComponent = ({ list, className }) => {
  return (
    <div className={`PcClassTable ${className}`}>
      <table className='PcClassTable_list'>
        <caption className='PcClassTable_header'>Классы</caption>
        <tbody className='PcClassTable_tbody'>
          <tr className='PcClassTable_row'>
            <th className='PcClassTable_cell PcClassTable_cell-head'>Класс</th>
            <th className='PcClassTable_cell PcClassTable_cell-head PcClassTable_cell-ability'>Умение</th>
            <th className='PcClassTable_cell PcClassTable_cell-head'>Уровень</th>
          </tr>
          {
            list.map(
              ({ name, lvl, text, pcSubClassId, pcClassId, source, url }) => (
                <tr
                  key={pcSubClassId || pcClassId}
                  className='PcClassTable_row'
                >
                  <td className={classNames(
                    `PcClassTable_cell PcClassTable_icon ${pcClassId}`,
                    pcSubClassId
                      ? `PcClassTable_icon-${pcSubClassId}`
                      : '',
                    {
                      'PcClassTable_cell-UA': checkIsFromUA(source),
                    }
                  )}>
                    {
                      url
                        ? (
                          <Link
                            className='PcClassTable_text PcClassTable_text-link'
                            to={url}
                          >
                            {text}
                          </Link>
                        )
                        : (
                          <span className='PcClassTable_text'>
                            {text}
                          </span>
                        )
                    }
                  </td>
                  <td className='PcClassTable_cell PcClassTable_cell-ability'>{name || ''}</td>
                  <td className='PcClassTable_cell PcClassTable_cell-lvl'>{lvl || ''}</td>
                </tr>
              ),
            )
          }
          </tbody>
      </table>
    </div>
  )
}

PcClassTableComponent.propTypes = {
  list: PropTypes.array,
}

PcClassTableComponent.defaultProps = {
  list: [],
  className: '',
}

export default PcClassTableComponent
