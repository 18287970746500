import generateTextLinks from '@/utils/generateTextLinks'
import {CREATURE_GAZER} from '@/constants/creatureIdList'
import {faUserFriends} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_VGTM} from '@/constants/sourceList'

export default {
  templateName: 'Смотрящий-фамильяр',
  templateIcon: faUserFriends,
  templateColor: 'indigo',

  templateLimitations: {
    include: {
      id: [
        CREATURE_GAZER,
      ],
    },
  },
  extendPropCollection: {
    description: [
      {
        header: `Вариант: Смотрящий-фамильяр`,
        text: generateTextLinks(`Заклинатели, заинтересованные в необычных фамильярах, обнаруживают, что [смотрящие](CREATURE:${CREATURE_GAZER}) готовы служить кому–то с магической силой, особенно тому, кто любит издеваться и измываться над другими. Смотрящий ведет себя агрессивно по отношению к существам меньше себя и старается иногда нападать на домашних питомцев, животных на ферме или даже детей в городе, если только хозяин не очень строг. Смотрящий, служащий фамильяром, имеет следующую особенность.

**Фамильяр.** Смотрящий может служить другим существам фамильяром, формируя телепатическую связь со своим хозяином, при условии, что хозяин – заклинатель не ниже 3 уровня. Пока двое связаны, хозяин может почувствовать то, что чувствует смотрящий, если они находятся не далее 1 мили друг от друга. Если хозяин причиняет ему физический вред, то смотряший перестает служить фамильяром, прерывая телепатическую связь.`),
        source: {
          id: SOURCE_VGTM,
          page: 128,
        },
      },
    ],
    featureList: [
      {
        name: 'Фамильяр',
        description: `Смотрящий может служить другим существам фамильяром, формируя телепатическую связь со своим хозяином, при условии, что хозяин – заклинатель не ниже 3 уровня. Пока двое связаны, хозяин может почувствовать то, что чувствует смотрящий, если они находятся не далее 1 мили друг от друга. Если хозяин причиняет ему физический вред, то смотряший перестает служить фамильяром, прерывая телепатическую связь.`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace('Смотрящий', 'Смотрящий-фамильяр'),
  },
}
