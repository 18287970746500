import React from "react"

import CreatureAc from "@/components/CreatureAc"
import HitPoints from "@/components/HitPoints"

import "./HullStyles.less"

const HullComponent = ({hull}) => {
  if (hull) {
    const {ac, hp, damageThreshold} = hull

    return (
      <section className='Hull'>
        <header className='Hull_header'>Корпус</header>
        <div className='Hull_content'>
          <CreatureAc armor={ac} />
          <HitPoints
            damageThreshold={damageThreshold}
            hp={hp}
          />
      </div>
      </section>
    )
  }

  return null
}

export default HullComponent
