const {
  CREATURE_QUADRONE,
  CREATURE_QUAGGOTH,
  CREATURE_QUASIT,
  CREATURE_QUIPPER,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
} = require('./../../../../sourceList')

const {
  Elder_brain_traveling_VGTM_data,
} = require('./../../../commonImageCollection')

const quadrone = require('./../../../../../images/creatures/mm/quadrone.jpg')
const quaggoth = require('./../../../../../images/creatures/mm/quaggoth.jpg')
const quasit = require('./../../../../../images/creatures/mm/quasit.jpg')
const quipper = require('./../../../../../images/creatures/mm/quipper.jpg')

const Quaggoth_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Quaggoth.jpg')

module.exports = {
  [CREATURE_QUADRONE]: quadrone,
  [CREATURE_QUAGGOTH]: [
    quaggoth,
    Elder_brain_traveling_VGTM_data,
    {
      src: Quaggoth_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_QUASIT]: quasit,
  [CREATURE_QUIPPER]: quipper,
}
