import templateList from './_lists'

import A from './A'
import B from './B'
import D from './D'
import E from './E'
import F from './F'
import H from './H'
import I from './I'
import L from './L'
import M from './M'
import Q from './Q'
import R from './R'
import S from './S'
import T from './T'
import V from './V'

export default [
  ...templateList,
  ...A,
  ...B,
  ...D,
  ...E,
  ...F,
  ...H,
  ...I,
  ...L,
  ...M,
  ...Q,
  ...R,
  ...S,
  ...T,
  ...V,
]
