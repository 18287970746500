const {
  CREATURE_ZOMBIE,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
} = require('./../../../../sourceList')

const zombie = require('./../../../../../images/creatures/mm/zombie.jpg')

const Zombie_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Zombie.jpg')

module.exports = {
  [CREATURE_ZOMBIE]: [
    zombie,
    {
      src: Zombie_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
}
