import React from 'react'
import PropTypes from 'prop-types'

import AbilityList from '@/components/AbilityList'

import generateAbilityFromAction from './../utils/generateAbilityFromAction'

const ActionList = (
  {
    actionsInfo,
    cr,
    isVehicle,
    list,
    params,
    spellCast,
    ...rest
  }
) => {
  if (list && list.length) {
    const abilityList = list.reduce(
      (resultList, action) => resultList.concat(generateAbilityFromAction(action, params, cr)),
      []
    )

    const entry = isVehicle
      ? actionsInfo
      : rest.entry

    return (
      <AbilityList
        list={abilityList}
        entry={entry}
        {...rest}
      />
    )
  }

  return null
}

ActionList.propTypes = {
  list: PropTypes.array,
  header: PropTypes.string,
  spellCast: PropTypes.object,
}

ActionList.defaultProps = {
  list: [],
  header: 'Действия',
}

export default ActionList
