import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import spellImageCollection from '@/constants/images/spellImageCollection'
import { spellCollection } from '@/constants/spellList'

const SpellImageListContainer = ({ spellId }) => {
  if (spellId) {
    const spell = spellCollection[spellId]
    const imageList = spellImageCollection[spellId]

    if (spell && imageList) {
      return (
        <ImageList
          imageList={imageList}
          {...spell}
        />
      )
    }

    return null
  }

  return null
}

SpellImageListContainer.propTypes = {
  spellId: PropTypes.string.isRequired,
}

export default SpellImageListContainer
