import React, {Component} from "react"

import {damageTypeCollection} from '@/constants/damageTypeList'

import DmgListComponent from "./DmgListComponent";

export default class DmgListContainer extends Component {
  render () {
    const {list, ...rest} = this.props

    if (list && list.length) {
      const joiner = list.some(
        item => typeof item === 'string'
          ? damageTypeCollection[item].isEnumeration
          : damageTypeCollection[item.id].isEnumeration
      )
        ? ';'
        : ','

      const textList = list.map(
        item => {
          const id = typeof item === 'string'
            ? item
            : item.id

          const {name} = damageTypeCollection[id]
          let commentText = ''

          if (item.comment) {
            commentText = ` (${item.comment})`
          }

          return `${name}${commentText}`
        }
      )

      return (
        <DmgListComponent
          list={textList}
          joiner={joiner}
          {...rest}
        />
      )
    }

    return null
  }
}
