import showDigitSign from '@/utils/showDigitSign'

const generateBonusText = bonus => {
  const {value, comment = ''} = typeof bonus === 'object'
    ? bonus
    : {value: bonus}

  const commentText = comment
    ? ` (${comment})`
    : ''

  if (value === Infinity) {
    return `автоматическое попадание${commentText}`
  }

  return typeof value === 'string'
    ? `${value}${commentText}`
    : `${showDigitSign(value)}${commentText} к попаданию`
}

export default generateBonusText
