import arrify from 'arrify'

const COEF_ITEM_PER_COLUMN = 10
const MAX_COLUMN = 4
const DESCRIPTION_COEF = 1/300
const NO_DESCRIPTION_WEIGNT = .25
const LEGENDARY_WEIGNT = 2
const HULL_WEIGNT = 1
const SPELLCAST_WEIGNT = 3
const BASIC_WEIGNT = 6
const ITEM_WEIGNT = .75
const VEHICLE_WEIGNT = 10

export default (
  {
    actionList = [],
    actionsInfo = '',
    controlList = [],
    featureList = [],
    hull = null,
    legendaryActionList = [],
    isVehicle = false,
    legendaryPoints = 0,
    movementList = [],
    reactionList = [],
    spellCast = null,
    weaponList = [],
  }
) => {
  let total = [
    actionList,
    controlList,
    featureList,
    legendaryActionList,
    movementList,
    reactionList,
    weaponList,
  ].reduce(
    (listSum, listItem) => listItem
      ? listSum + listItem.reduce(
        (sum, item) => ITEM_WEIGNT + sum + (
          item.description
            ? item.description.length * DESCRIPTION_COEF
            : NO_DESCRIPTION_WEIGNT
        ),
        0
      )
      : 0,
    BASIC_WEIGNT
  )

  if (actionsInfo.length) {
    total += ITEM_WEIGNT + actionsInfo.length * DESCRIPTION_COEF
  }

  if (hull) {
    total += HULL_WEIGNT
  }

  if (isVehicle) {
    total += VEHICLE_WEIGNT
  }

  if (legendaryActionList.length) {
    total += LEGENDARY_WEIGNT
  }

  if (spellCast) {
    total += arrify(spellCast).length * SPELLCAST_WEIGNT
  }

  for (let i = MAX_COLUMN; i > 1; i--) {
    if (total > (i - 1) * COEF_ITEM_PER_COLUMN) {
      return i
    }
  }

  if (legendaryPoints) {
    return 3
  }
}
