import React from 'react';

import spellList from '@/constants/spellList';

import Catalog from '@/components/Catalog';

import generateSpellPageUrlById from "@/utils/generateSpellPageUrlById"

import filterListOriginal from './constants/filterList';

const SpellCatalogContainer = () => (
  <Catalog
    filterListOriginal={filterListOriginal}
    itemList={spellList}
    itemNameList={spellList}
    pageTitle='Каталог заклинаний'
    pageUrlGenerator={generateSpellPageUrlById}
    showCount
  />
)

export default SpellCatalogContainer
