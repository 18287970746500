import React from 'react'

import Markdown from '@/components/Markdown'
import RenderList from "@/components/RenderList"
import StatInfoRow from '@/components/StatInfoRow'

import './CreatureAcStyles.less'

export default ({ list }) => (
  <StatInfoRow
    header='Класс доспеха'
    className='CreatureAc'
  >
    <RenderList
      list={list}
      renderer={(armorText, i) => (
        <span
          key={i}
          className='CreatureAc_item'
        >
          <Markdown>
            {armorText.toString()}
          </Markdown>
        </span>
      )}
    />
  </StatInfoRow>
)
