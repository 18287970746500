module.exports = {
  AarakokraList: require('./lists/Aarakokra'),
  BeholderkinList: require('./lists/Beholderkin'),
  CultOfDragonList: require('./lists/CultOfDragon'),
  DragonsBlackList: require('./lists/DragonsBlack'),
  DragonsBlueList: require('./lists/DragonsBlue'),
  DragonsBrassList: require('./lists/DragonsBrass'),
  DragonsBronzeList: require('./lists/DragonsBronze'),
  DragonsCopperList: require('./lists/DragonsCopper'),
  DragonsGoldList: require('./lists/DragonsGold'),
  DragonsGreenList: require('./lists/DragonsGreen'),
  DragonsRedList: require('./lists/DragonsRed'),
  DragonsSilverList: require('./lists/DragonsSilver'),
  DragonsWhiteList: require('./lists/DragonsWhite'),
  FaerieDragonsList: require('./lists/FaerieDragons'),
  FlamingFistList: require('./lists/FlamingFist'),
  GiantCloudImgList: require('./lists/GiantCloud'),
  GiantFireImgList: require('./lists/GiantFire'),
  GiantFrostImgList: require('./lists/GiantFrost'),
  GiantHillImgList: require('./lists/GiantHill'),
  GiantStoneImgList: require('./lists/GiantStone'),
  GiantStormImgList: require('./lists/GiantStorm'),
  GiffImgList: require('./lists/Giff'),
  GithyankiImgList: require('./lists/Githyanki'),
  GithzeraiImgList: require('./lists/Githzerai'),
  GnollImgList: require('./lists/Gnoll'),
  GoblinsImgList: require('./lists/Goblins'),
  KuoToaImgList: require('./lists/KuoToa'),
  LizardFolksList: require('./lists/LizardFolks'),
  MimicImgList: require('./lists/Mimic'),
  ThriKreenImgList: require('./lists/Thri_kreen'),
}
