import {
  CREATURE_TYPE_AARAKOCRA,
  CREATURE_TYPE_ABERRATION,
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_BULLYWUG,
  CREATURE_TYPE_CELESTIAL,
  CREATURE_TYPE_DEMON,
  CREATURE_TYPE_DEVIL,
  CREATURE_TYPE_DRAGON,
  CREATURE_TYPE_DWARF,
  CREATURE_TYPE_ELF,
  CREATURE_TYPE_FEY,
  CREATURE_TYPE_FIEND,
  CREATURE_TYPE_FIRENEWT,
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_GITH,
  CREATURE_TYPE_GNOLL,
  CREATURE_TYPE_GNOME,
  CREATURE_TYPE_GOBLINOID,
  CREATURE_TYPE_GRIMLOCK,
  CREATURE_TYPE_GRUNG,
  CREATURE_TYPE_HUMAN,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_KENKU,
  CREATURE_TYPE_KOBOLD,
  CREATURE_TYPE_KUO_TOA,
  CREATURE_TYPE_LIZARDFOLK,
  CREATURE_TYPE_MERFOLK,
  CREATURE_TYPE_MONSTROSITY,
  CREATURE_TYPE_ORC,
  CREATURE_TYPE_PLANT,
  CREATURE_TYPE_QUAGGOTH,
  CREATURE_TYPE_SAHUAGIN,
  CREATURE_TYPE_SHAPESHIFTER,
  CREATURE_TYPE_THRI_KREEN,
  CREATURE_TYPE_TITAN,
  CREATURE_TYPE_TROGLODYTE,
  CREATURE_TYPE_XVART,
  CREATURE_TYPE_YUAN_TI,
  CREATURE_TYPE_YUGOLOTH,
} from '@/constants/creatureTypeIdList'
import {
  SIZE_GARGANTUA,
  SIZE_HUGE,
  SIZE_LARGE,
  SIZE_MEDIUM,
  SIZE_SMALL,
  SIZE_TINY,
} from '@/constants/sizeList'
import {
  PARAM_CHA,
  PARAM_INT,
  PARAM_STR,
  PARAM_WIT,
} from '@/constants/paramList'
import {
  CONDITION_BLINDED,
  CONDITION_CHARMED,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
} from '@/constants/conditionList'
import {
  DAMAGE_BLUDGEONING,
  DAMAGE_PIERCING,
  DAMAGE_SLASHING,
} from '@/constants/damageTypeList'
import calcParamBonus from '@/utils/calcParamBonus'
import {ALIGNMENT_NO} from '@/constants/aligmentList'
import {ATTACK_MELEE_WEAPON} from '@/constants/attackTypeList'
import {crCollection} from '@/constants/crList'
import {CREATURE_MYCONID_SOVEREIGN} from '@/constants/creatureIdList'
import {faDizzy} from '@fortawesome/free-solid-svg-icons'
import {SENSE_BLIND_VISION} from '@/constants/senseList'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Споровый слуга',
  templateIcon: faDizzy,
  templateColor: 'darkOliveGreen',

  templateLimitations: {
    include: {
      sizeType: [
        SIZE_TINY,
        SIZE_SMALL,
        SIZE_MEDIUM,
        SIZE_LARGE,
      ],
      creatureTypeIdList: [
        CREATURE_TYPE_AARAKOCRA,
        CREATURE_TYPE_ANY_RACE,
        CREATURE_TYPE_ABERRATION,
        CREATURE_TYPE_BEAST,
        CREATURE_TYPE_BULLYWUG,
        CREATURE_TYPE_CELESTIAL,
        CREATURE_TYPE_DRAGON,
        CREATURE_TYPE_DWARF,
        CREATURE_TYPE_ELF,
        CREATURE_TYPE_FEY,
        CREATURE_TYPE_FIEND,
        CREATURE_TYPE_FIRENEWT,
        CREATURE_TYPE_GIANT,
        CREATURE_TYPE_GITH,
        CREATURE_TYPE_GNOLL,
        CREATURE_TYPE_GNOME,
        CREATURE_TYPE_GOBLINOID,
        CREATURE_TYPE_GRIMLOCK,
        CREATURE_TYPE_GRUNG,
        CREATURE_TYPE_HUMAN,
        CREATURE_TYPE_HUMANOID,
        CREATURE_TYPE_KENKU,
        CREATURE_TYPE_KOBOLD,
        CREATURE_TYPE_KUO_TOA,
        CREATURE_TYPE_LIZARDFOLK,
        CREATURE_TYPE_MERFOLK,
        CREATURE_TYPE_MONSTROSITY,
        CREATURE_TYPE_ORC,
        CREATURE_TYPE_QUAGGOTH,
        CREATURE_TYPE_SAHUAGIN,
        CREATURE_TYPE_SHAPESHIFTER,
        CREATURE_TYPE_THRI_KREEN,
        CREATURE_TYPE_TITAN,
        CREATURE_TYPE_TROGLODYTE,
        CREATURE_TYPE_XVART,
        CREATURE_TYPE_YUAN_TI,
      ],
    },
    exclude: {
      templateName: ['Теневой дракон'],
      swarmMemberSizeType: [
        SIZE_GARGANTUA,
        SIZE_HUGE,
        SIZE_LARGE,
        SIZE_MEDIUM,
        SIZE_SMALL,
        SIZE_TINY,
      ],
      creatureTypeIdList: [
        CREATURE_TYPE_DEVIL,
        CREATURE_TYPE_DEMON,
        CREATURE_TYPE_YUGOLOTH,
      ],
    },
  },

  creatureTypeIdList: [CREATURE_TYPE_PLANT],
  alignmentId: ALIGNMENT_NO,
  saveThrowList: null,
  skillCollection: null,
  featureList: [
    {
      name: 'Подчинение миконидам',
      description: `Слуга теряет знание всех известных ему языков, но может отвечать на приказы, отданные миконидами посредством спор взаимопонимания. Слуга отдаёт наивысший приоритет приказам, полученным от более сильных миконидов.`,
    },
  ],
  spellCast: null,
  languageList: [],
  senseList: [
    {
      id: SENSE_BLIND_VISION,
      value: 30,
      isBlindOutside: true,
    },
  ],

  editPropCollection: {
    name: ({ name }) => `${name} споровый слуга`,
    speed: ({ speed: speedCollection }) => Object
      .keys(speedCollection)
      .reduce(
        (resultSpeed, speedId) => {
          const speed = speedCollection[speedId]
          const isObj = typeof speed === 'object'
          const value = isObj ? speed.value : speed
          const editedValue = Math.max(5, value - 10)

          return {
            ...resultSpeed,
            [speedId]: isObj
              ? {
                ...speed,
                value: editedValue,
              }
              : editedValue,
          }
        },
        {},
      ),
  },

  filterPropCollection: {
    actionList: [
      {
        name: 'Мультиатака',
      },
      {
        attack: {
          type: [
            ATTACK_MELEE_WEAPON,
          ],
          hit: [
            {
              isDamageByEquipment: true,
            },
            {
              type: [
                DAMAGE_BLUDGEONING,
                DAMAGE_SLASHING,
                DAMAGE_PIERCING,
              ],
            },
          ],
        },
      },
    ],
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 209,
    }],
    description: [
      {
        header: 'Шаблон спорового слуги',
        text: `Споровым слугой становится любое существо с размером не больше Большого, оживлённое [верховным миконидом](CREATURE:${CREATURE_MYCONID_SOVEREIGN}). Существо без плоти и крови (например, конструкт, элементаль, слизь, растение или нежить) не может быть превращено в спорового слугу. У существа, которое становится споровым слугой, появляются или изменяются следующие параметры:

**Сохраняемые параметры.** Слуга сохраняет Класс Доспеха, хиты, Кости Хитов, Силу, Ловкость, Телосложение, уязвимости, сопротивления и иммунитеты.

**Теряемые параметры.** Слуга теряет свои исходные бонусы испытаний и навыков, особые чувства, а также особенности. Он теряет все действия кроме Мультиатаки и рукопашных атак оружием, причиняющих дробящий, колющий или рубящий урон. Если у него есть действие или рукопашная атака оружием, которая причиняет урон другого вида, он теряет способность причинять урон этого вида, если только урон не исходит от какого-то снаряжения, например, магического предмета.

**Вид.** Слуга получает вид «растение» и теряет все метки.

**Мировоззрение.** Слуга лишён мировоззрения.

**Скорость.** Уменьшите все значения скорости слуги на 10 футов при минимуме в 5 футов.

**Характеристики.** Значения характеристик слуги становятся такими: Инт 2 (−4), Мдр 6 (−2), Хар 1 (−5).

**Чувства.** Слуга обладает слепым зрением в радиусе 30 футов и слеп за пределами этого радиуса.

**Иммунитет к состоянию.** Слуга не может быть испуган, ослеплён, очарован или парализован.

**Языки.** Слуга теряет знание всех известных ему языков, но может отвечать на приказы, отданные миконидами посредством спор взаимопонимания. Слуга отдаёт наивысший приоритет приказам, полученным от более сильных миконидов.

**Атаки.** Если слуга не обладает иными возможностями причинения урона, он может использовать свои кулаки и конечности для совершения невооружённых ударов. При попадании невооружённый удар причиняет дробящий урон 1к4 + модификатор Силы слуги, или, если слуга Большого размера, то 2к4 + его модификатор Силы.`,
        source: {
          id: SOURCE_MM,
          page: 209,
        },
      },
    ],
    params: {
      [PARAM_INT]: 2,
      [PARAM_WIT]: 6,
      [PARAM_CHA]: 1,
    },
    immunityConditionList: [
      CONDITION_FRIGHTENED,
      CONDITION_BLINDED,
      CONDITION_CHARMED,
      CONDITION_PARALYZED,
    ],
  },

  replaceEmptyPropCollection: {
    actionList: ({ params, sizeType, cr }) => {
      const strBonus = calcParamBonus(params[PARAM_STR])
      const diceCount = sizeType === SIZE_LARGE ? 2 : 1
      const { profBonus } = crCollection[cr]
      const bonus = strBonus + profBonus

      return [
        {
          name: 'Безоружный удар',
          attack: {
            type: ATTACK_MELEE_WEAPON,
            bonus,
            range: 5,
            hit: {
              type: DAMAGE_BLUDGEONING,
              diceCount,
              diceType: 4,
              diceBonus: strBonus,
            },
          },
        },
      ]
    },
  },
}
