const {
  CREATURE_OCHRE_JELLY,
  CREATURE_OCTOPUS,
  CREATURE_OGRE,
  CREATURE_OGRE_ZOMBIE,
  CREATURE_ONI,
  CREATURE_ORC,
  CREATURE_ORC_EYE_OF_GRUUMSH,
  CREATURE_ORC_WAR_CHIEF,
  CREATURE_OROG,
  CREATURE_OTYUGH,
  CREATURE_OWL,
  CREATURE_OWLBEAR,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_DMG,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  Orc_eye_of_Gruumsh_VGTM_data,
  Orc_s_War_wagon_VGTM_data,
} = require('./../../../commonImageCollection')

const ochre_jelly = require('./../../../../../images/creatures/mm/ochre_jelly.jpg')
const octopus = require('./../../../../../images/creatures/mm/octopus.jpeg')
const ogre = require('./../../../../../images/creatures/mm/ogre.jpeg')
const ogre_zombie = require('./../../../../../images/creatures/mm/ogre_zombie.jpeg')
const oni = require('./../../../../../images/creatures/mm/oni.jpeg')
const orc = require('./../../../../../images/creatures/mm/orc.jpeg')
const orog = require('./../../../../../images/creatures/mm/orog.jpeg')
const otyugh = require('./../../../../../images/creatures/mm/otyugh.jpeg')
const owl = require('./../../../../../images/creatures/mm/owl.jpeg')
const owlbear = require('./../../../../../images/creatures/mm/owlbear.jpeg')

const Ogre_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Ogre.jpg')
const Ogre_Mage_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Ogre_Mage.jpg')
const Olive_Slime_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Olive_Slime.jpg')
const Orc_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Orc.jpg')
const Otyugh_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Otyugh.jpg')
const Owlbear_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Owlbear.jpg')

const Owlbear_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/owlbear.jpg')
const Owlbear2_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/owlbear2.jpg')

const Owlbear_DMG_img = require('./../../../../../images/creatures/dmg/owlbear.jpg')

const Owlbear_cub_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/owlbear_cub_mtg_clb.png')

module.exports = {
  [CREATURE_OCHRE_JELLY]: [
    ochre_jelly,
    {
      src: Olive_Slime_ADND2,
      text: 'Olive Slime',
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_OCTOPUS]: [
    {
      src: octopus,
      text: 'Щупальце осьминога',
    },
  ],
  [CREATURE_OGRE]: [
    ogre,
    {
      src: Ogre_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_OGRE_ZOMBIE]: ogre_zombie,
  [CREATURE_ONI]: [
    oni,
    {
      src: Ogre_Mage_ADND2,
      text: 'Огр-маг',
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_ORC]: [
    orc,
    {
      src: Orc_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    Orc_s_War_wagon_VGTM_data,
  ],
  [CREATURE_ORC_EYE_OF_GRUUMSH]: [
    Orc_eye_of_Gruumsh_VGTM_data,
    {
      src: orc,
      creatureId: CREATURE_ORC,
    },
    {
      src: Orc_ADND2,
      creatureId: CREATURE_ORC,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    Orc_s_War_wagon_VGTM_data,
  ],
  [CREATURE_ORC_WAR_CHIEF]: [
    {
      src: orc,
      creatureId: CREATURE_ORC,
    },
    {
      src: Orc_ADND2,
      creatureId: CREATURE_ORC,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    Orc_s_War_wagon_VGTM_data,
  ],
  [CREATURE_OROG]: orog,
  [CREATURE_OTYUGH]: [
    otyugh,
    {
      src: Otyugh_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_OWL]: owl,
  [CREATURE_OWLBEAR]: [
    owlbear,
    {
      src: Owlbear_DMG_img,
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Owlbear_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Owlbear_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Owlbear2_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Owlbear_cub_MTG_CLB_img,
      text: 'Медвесычонок',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
}
