import calcDiceAverage from '@/utils/calcDiceAverage'

export default cube => {
  if (cube) {
    if (
      typeof cube === 'string'
      || typeof cube === 'number'
    ) {
      return cube
    }

    const {
      diceType = 0,
      diceCount = 0,
      diceBonus = 0,
    } = cube

    const average = calcDiceAverage(cube)

    const count = diceCount
      ? `${diceCount}к${diceType}`
      : ''
    const bonusSign = diceBonus < 0
      ? ' − '
      : ' + '
    const bonusSignText = diceCount && diceBonus
      ? bonusSign
      : ''
    const bonus = diceBonus
      ? typeof diceBonus === 'string'
        ? diceBonus
        : Math.abs(diceBonus)
      : ''

    return `${average} (${count}${bonusSignText}${bonus})`
  }

  return  ''
}
