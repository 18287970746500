const {
  CREATURE_VAMPIRE,
  CREATURE_VAMPIRE_SPAWN,
  CREATURE_VINE_BLIGHT,
  CREATURE_VIOLET_FUNGUS,
  CREATURE_VROCK,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const vampire = require('./../../../../../images/creatures/mm/vampire.jpg')
const vampire_spawn = require('./../../../../../images/creatures/mm/vampire_spawn.jpeg')
const vine_blight = require('./../../../../../images/creatures/mm/vine_blight.jpg')
const violet_fungus = require('./../../../../../images/creatures/mm/violet_fungus.jpeg')
const vrock = require('./../../../../../images/creatures/mm/vrock.jpg')

const Vampire_2_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Vampire_2.jpg')
const Vampire_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Vampire.jpg')

const Count_strahd_von_zarovich_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/count_strahd_von_zarovich.jpg')
const Vampire_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/vampire.jpg')
const Vampire_lord_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/vampire_lord.jpg')
const Vampire_spawn_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/vampire_spawn.jpg')
const Vampire_spawn_2_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/vampire_spawn_2.jpg')

const Vrock_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/vrock-mtg_clb.jpg')
const Astarion_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/astarion_mtg_clb.jpg')

module.exports = {
  [CREATURE_VAMPIRE]: [
    vampire,
    {
      src: Vampire_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Vampire_2_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Vampire_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Vampire_lord_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Count_strahd_von_zarovich_img_AYAGMnC,
      text: 'Граф Страд фон Зарович',
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_VAMPIRE_SPAWN]: [
    vampire_spawn,
    {
      src: Astarion_MTG_CLB_img,
      text: 'Астарион, декадент',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
    {
      src: Vampire_spawn_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Vampire_spawn_2_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_VINE_BLIGHT]: vine_blight,
  [CREATURE_VIOLET_FUNGUS]: violet_fungus,
  [CREATURE_VROCK]: [
    vrock,
    {
      src: Vrock_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
}
