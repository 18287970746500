import React from "react"

import "./VehicleProportionsStyles.less"

const VehicleProportions = ({proportions}) => proportions
  ? (
    <span className='VehicleProportions'>
      ({proportions[0]} × {proportions[1]} футов)
    </span>
  )
  : null

export default VehicleProportions
