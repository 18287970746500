import React from 'react'

import RenderList from '@/components/RenderList'

import './CreatureAlignmentStyles.less'

export default ({ list, justTypical }) => (
  <span className='CreatureAlignment'>
      {
        justTypical
          ? `${list.length ? ',' : ''} обычно `
          : ''
      }
      <RenderList
        list={list}
        or
        renderer={({ id, text, title }) => (
          <span
            className='CreatureAlignment_item'
            title={title}
            key={id}
          >
            {text}
          </span>
        )}
      />
    </span>
)
