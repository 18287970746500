const listToCollectionById = require('./../../../utils/listToCollectionById')
const {
  SOURCE_EGTW,
} = require('./../../sourceList')
const {
  SPELL_DARK_STAR,
  SPELL_REALITY_BREAK,
} = require('./../../spellIdList')

const Dark_star_EGTW_img = require('./../../../images/spells/egtw/dark_star.png')
const Reality_break_EGTW_img = require('./../../../images/spells/egtw/reality_break.jpg')

const spellImageList_EGTW = [
  {
    id: SPELL_DARK_STAR,
    src: Dark_star_EGTW_img,
    text: 'Красного слаада затягивает в Тёмную звезду',
  },
  {
    id: SPELL_REALITY_BREAK,
    src: Reality_break_EGTW_img,
  },
]
  .map(
    item => ({
      ...item,
      source: {
        id: SOURCE_EGTW,
      },
    })
  )

module.exports = listToCollectionById(spellImageList_EGTW)
