import generateTextLinks from '@/utils/generateTextLinks'
import {CREATURE_IMP} from '@/constants/creatureIdList'
import {faUserFriends} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Бес-фамильяр',
  templateIcon: faUserFriends,
  templateColor: 'indigo',

  templateLimitations: {
    include: {
      id: [
        CREATURE_IMP,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 143,
    }],
    description: [
      {
        header: 'Вариант: Фамильяр бес',
        text: generateTextLinks(`Бывает, что [бесы](CREATURE:${CREATURE_IMP}) состоят на службе у смертных заклинателей, помогая им как советники, шпионы или фамильяры. Бес подталкивает хозяина к совершению злых поступков, зная, что в результате душа смертного может достаться ему. Бесы демонстрируют необыкновенную верность своим хозяевам, и могут быть довольно опасными, если хозяину что-то угрожает. У некоторых из таких бесов имеется следующая особенность:

**Фамильяр.** Бес может служить другому существу фамильяром, создав телепатическую связь с желающим того хозяином. Пока они связаны, хозяин может ощущать то же, что и бес, пока они находятся в пределах мили друг от друга. Пока бес находится в пределах 10 футов от хозяина, на хозяина распространяется особенность беса _Сопротивление магии_. Если хозяин нарушит условия договора с бесом, последний может прекратить службу, оборвав телепатическую связь. `),
        source: {
          id: SOURCE_MM,
          page: 143,
        },
      },
    ],
    featureList: [
      {
        name: 'Фамильяр',
        description: `Бес может служить другому существу фамильяром, создав телепатическую связь с желающим того хозяином. Пока они связаны, хозяин может ощущать то же, что и бес, пока они находятся в пределах мили друг от друга. Пока бес находится в пределах 10 футов от хозяина, на хозяина распространяется особенность беса Сопротивление магии. Если хозяин нарушит условия договора с бесом, последний может прекратить службу, оборвав телепатическую связь.`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace('Бес', 'Бес-фамильяр'),
  },
}
