const {
  Githyanki_Gish_MTOF_data,
  Githyanki_Kithrak_MTOF_data,
  Githyanki_Supreme_Commander_MTOF_data,
} = require('./../../commonImageCollection')
const {
  SOURCE_ADND2_MM,
  SOURCE_MM,
  SOURCE_MTG_CLB,
  SOURCE_SJA,
} = require('./../../../sourceList')
const Githyanki_ADND2 = require('./../../../../images/creatures/adnd2_mm/Githyanki.jpg')
const Githyanki_MM_img = require('./../../../../images/creatures/mm/githyanki.jpg')
const Cover_SJA_img = require('./../../../../images/creatures/sja/sja-cover-art.jpg')
const Laezel_SJA_img = require('./../../../../images/races/laezel-mtg_clb.jpg')

module.exports = [
  {
    src: Githyanki_MM_img,
    text: 'Гитьянки',
    source: {
      id: SOURCE_MM,
    },
  },
  Githyanki_Gish_MTOF_data,
  Githyanki_Kithrak_MTOF_data,
  Githyanki_Supreme_Commander_MTOF_data,
  {
    src: Githyanki_ADND2,
    text: 'Гитьянки',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  {
    src: Laezel_SJA_img,
    text: 'Лаэ’зель, чемпионка Влаакит',
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
  {
    src: Cover_SJA_img,
    text: 'Гитьянки-пираты захватывают судно',
    source: {
      id: SOURCE_SJA,
    },
  },
]
