import arrify from 'arrify'
import React from 'react'
import PropTypes from 'prop-types'

import upLetter from '@/utils/upLetter'
import sortByText from '@/utils/sortByText'
import generatePcClassPageUrlById from "@/utils/generatePcClassPageUrlById"
import generatePcSubClassPageUrlById from "@/utils/generatePcSubClassPageUrlById"

import { pcClassCollection } from '@/constants/pcClassList'
import { pcSubClassCollection } from '@/constants/pcSubClassList'
import { featureCollection, featureIdListBySpellId } from '@/constants/featureList'

import PcClassTableComponent from './PcClassTableComponent'

const PcClassTableContainer = (
  {
    spellId,
    ...rest
  },
) => {
  const list = featureIdListBySpellId[spellId].reduce(
    (resultList, featureId) => {
      const { pcClassId, pcSubClassId, name, source, lvl: lvlToHandle } = featureCollection[featureId]
      const lvl = Math.min(...arrify(lvlToHandle))

      if (pcClassId) {
        return [
          ...resultList,
          {
            name,
            lvl,
            pcClassId,
            source,
            text: upLetter(pcClassCollection[pcClassId].name.singular.nominative),
            url: generatePcClassPageUrlById(pcClassId),
          },
        ]
      }

      if (pcSubClassId) {
        const { isReady, pcClassId: pcClassIdList, source, name: pcSubClassName } = pcSubClassCollection[pcSubClassId]

        return [
          ...resultList,
          ...arrify(pcClassIdList).map(
            pcClassId => {
              const url = isReady
                ? generatePcSubClassPageUrlById(pcSubClassId, pcClassId)
                : null
              const pcClassName = upLetter(pcClassCollection[pcClassId].name.singular.nominative)
              const text = `${pcClassName}: ${pcSubClassName}`

              return {
                name,
                lvl,
                text,
                pcSubClassId,
                pcClassId,
                source,
                url,
              }
            },
          ),
        ]
      }

      return resultList
    },
    []
  )
    .sort(sortByText)

  return list && list.length
    ? (
      <PcClassTableComponent
        list={list}
        {...rest}
      />
    )
    : null
}

PcClassTableContainer.propTypes = {
  spellId: PropTypes.string,
}

PcClassTableContainer.defaultProps = {
  spellId: null,
}

export default PcClassTableContainer
