import {ABILITY_GRUNG_POISON_SKIN} from '@/constants/creatureAbilityList'
import {CREATURE_GRUNG} from '@/constants/creatureIdList'
import {faTint} from '@fortawesome/free-solid-svg-icons'

import grungPoisonSkinPostText from './constants/grungPoisonSkinPostText'

export default {
  templateName: 'Яд зелёного грунга',
  templateIcon: faTint,
  templateColor: 'green',
  templateLimitations: {
    include: {
      id: [
        CREATURE_GRUNG,
      ],
    },
  },

  name: 'Зелёный грунг',

  editPropCollection: {
    featureList: ({ featureList }) => featureList.map(
      feature => feature.id === ABILITY_GRUNG_POISON_SKIN
        ? {
          ...feature,
          name: `${feature.name} зелёного грунга`,
          description: `${feature.description} ${grungPoisonSkinPostText}`.replace(
            `Отравленное существо`,
            'Отравленное существо не может двигаться, а только карабкаться или прыгать без разбега, с места. Если существо летает, то не может совершать действия или реакции, пока не приземлится. Отравленное существо'
          )
        }
        : feature,
    ),
  },
}
