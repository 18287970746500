import {
  PARAM_STR,
  PARAM_WIT,
  PARAM_CON,
} from '@/constants/paramList'
import {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_DWARF,
} from '@/constants/creatureTypeIdList'
import {
  LANG_COMMON,
  LANG_DWARVISH,
} from '@/constants/languageIdList'
import {ABILITY_DWARVEN_RESILIENCE} from '@/constants/creatureAbilityList'
import {DAMAGE_POISON} from '@/constants/damageTypeList'
import {faGavel} from '@fortawesome/free-solid-svg-icons'
import {SENSE_DARK_VISION} from '@/constants/senseList'
import {SOURCE_DMG} from '@/constants/sourceList'
import {SPEED_WALK} from '@/constants/speedList'

export default {
  templateName: 'Дварф',
  templateIcon: faGavel,
  templateColor: 'brown',
  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_ANY_RACE,
      ],
    },
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 283,
    }],
    languageList: [
      LANG_COMMON,
      LANG_DWARVISH,
    ],
    featureList: [
      ABILITY_DWARVEN_RESILIENCE,
    ],
    resistanceList: [
      DAMAGE_POISON,
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Дварф ${name}`,
    creatureTypeIdList: ({ creatureTypeIdList }) => creatureTypeIdList.map(
      creatureTypeId => creatureTypeId === CREATURE_TYPE_ANY_RACE
        ? CREATURE_TYPE_DWARF
        : creatureTypeId
    ),
    params: ({ params }) => {
      const paramWithRaceBonus = params[PARAM_STR] > params[PARAM_WIT]
        ? PARAM_STR
        : PARAM_WIT

      return {
        ...params,
        [paramWithRaceBonus]: params[paramWithRaceBonus] + 2,
        [PARAM_CON]: params[PARAM_CON] + 2,
      }
    },
    senseList: ({ senseList = [] }) => [
      ...senseList.filter(
        ({id}) => id !== SENSE_DARK_VISION
      ),
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    speed: ({ speed }) => ({
    ...speed,
      [SPEED_WALK]: speed[SPEED_WALK] - 5,
    }),
  },
}
