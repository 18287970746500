const listToCollectionById = require('./../../../utils/listToCollectionById')
const {
  SOURCE_TCoE,
} = require('./../../sourceList')
const {
  SPELL_INTELLECT_FORTRESS,
  SPELL_MIND_SLIVER,
  SPELL_SUMMON_CELESTIAL,
  SPELL_SUMMON_UNDEAD,
} = require('./../../spellIdList')
const {
  Summon_undead_MTG_data,
} = require('./../commonImageCollection')

const Intellect_fortress_TCoE_img = require('./../../../images/spells/tcoe/intellect_fortress.png')
const Mind_sliver_TCoE_img = require('./../../../images/spells/tcoe/mind_sliver.png')
const Summon_celestial_TCoE_img = require('./../../../images/spells/tcoe/summon_celestial.png')

const spellImageList_TCoE = [
  {
    id: SPELL_INTELLECT_FORTRESS,
    src: Intellect_fortress_TCoE_img,
    source: {
      id: SOURCE_TCoE,
    },
  },
  {
    id: SPELL_MIND_SLIVER,
    src: Mind_sliver_TCoE_img,
    source: {
      id: SOURCE_TCoE,
    },
  },
  {
    id: SPELL_SUMMON_CELESTIAL,
    src: Summon_celestial_TCoE_img,
    source: {
      id: SOURCE_TCoE,
    },
  },
  {
    id: SPELL_SUMMON_UNDEAD,
    ...Summon_undead_MTG_data,
  },
]

module.exports = listToCollectionById(spellImageList_TCoE)
