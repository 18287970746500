const {
  SOURCE_ADND2_MM,
} = require('./../../../sourceList')

const {
  Beholder_keen_VGTM_data,
} = require('./../../commonImageCollection')

const Beholder_kin_2_ADND2 = require('./../../../../images/creatures/adnd2_mm/Beholder_kin_2.jpg')
const Beholder_kin_ADND2 = require('./../../../../images/creatures/adnd2_mm/Beholder_kin.jpg')

module.exports = [
  {
    src: Beholder_kin_ADND2,
    text: 'Родственники злобоглазов',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  {
    src: Beholder_kin_2_ADND2,
    text: 'Родственники злобоглазов',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  Beholder_keen_VGTM_data,
]
