import {
  SPELL_ANIMATE_DEAD,
  SPELL_BESTOW_CURSE,
  SPELL_BLIGHT,
  SPELL_COMPREHEND_LANGUAGES,
  SPELL_DETECT_THOUGHTS,
  SPELL_DOMINATE_PERSON,
  SPELL_FOG_CLOUD,
  SPELL_GREATER_INVISIBILITY,
  SPELL_GUST_OF_WIND,
  SPELL_MAGE_HAND,
  SPELL_MIRROR_IMAGE,
  SPELL_NONDETECTION,
  SPELL_PRESTIDIGITATION,
  SPELL_RAY_OF_FROST,
  SPELL_SLEEP,
} from '@/constants/spellIdList'
import {CR_15} from '@/constants/crList'
import {CREATURE_VAMPIRE} from '@/constants/creatureIdList'
import {faHatWizard} from '@fortawesome/free-solid-svg-icons'
import {PARAM_INT} from '@/constants/paramList'
import {PC_CLASS_WIZARD} from '@/constants/pcClassIdList'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Вампир заклинатель',
  templateIcon: faHatWizard,
  templateColor: 'blueViolet',

  templateLimitations: {
    include: {
      id: [
        CREATURE_VAMPIRE,
      ],
    },
  },

  cr: CR_15,
  spellCast: {
    spellCasterLevel: 9,
    spellCasterClass: PC_CLASS_WIZARD,
    spellIdList: [
      SPELL_MAGE_HAND,
      SPELL_RAY_OF_FROST,
      SPELL_PRESTIDIGITATION,
      SPELL_COMPREHEND_LANGUAGES,
      SPELL_FOG_CLOUD,
      SPELL_SLEEP,
      SPELL_DETECT_THOUGHTS,
      SPELL_MIRROR_IMAGE,
      SPELL_GUST_OF_WIND,
      SPELL_ANIMATE_DEAD,
      SPELL_NONDETECTION,
      SPELL_BESTOW_CURSE,
      SPELL_GREATER_INVISIBILITY,
      SPELL_BLIGHT,
      SPELL_DOMINATE_PERSON,
    ],
    slotCountList: [
      Infinity,
      4,
      3,
      3,
      3,
      1,
    ],
  },
  extendPropCollection: {
    description: [{
      header: 'Вариант: Заклинатели вампиров',
      text: `Вампир заклинатель обладает показателем опасности 15 (13000 опыта) и следующей особенностью:

**Использование заклинаний.** Вампир является заклинателем 9 уровня. Его базовой характеристикой является Интеллект (СЛ испытания от заклинания 16, +8 к попаданию атаками заклинаниями). У него подготовлены следующие заклинания волшебника:

* Заговоры (неограниченно): [Волшебная рука](SPELL:${SPELL_MAGE_HAND}), [Луч холода](SPELL:${SPELL_RAY_OF_FROST}), [Фокусы](SPELL:${SPELL_PRESTIDIGITATION})

* 1 уровень (4 ячейки): [Понимание языков](SPELL:${SPELL_COMPREHEND_LANGUAGES}), [Туманное облако](SPELL:${SPELL_FOG_CLOUD}), [Усыпление](SPELL:${SPELL_SLEEP})

* 2 уровень (3 ячейки): [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}), [Отражения](SPELL:${SPELL_MIRROR_IMAGE}), [Порыв ветра](SPELL:${SPELL_GUST_OF_WIND})

* 3 уровень (3 ячейки): [Восставший труп](SPELL:${SPELL_ANIMATE_DEAD}), [Необнаружимость](SPELL:${SPELL_NONDETECTION}), [Проклятие](SPELL:${SPELL_BESTOW_CURSE})

* 4 уровень (3 ячейки): [Высшая невидимость](SPELL:${SPELL_GREATER_INVISIBILITY}), [Усыхание](SPELL:${SPELL_BLIGHT})

* 5 уровень (1 ячейка): [Подчинение личности](SPELL:${SPELL_DOMINATE_PERSON})`,
      source: {
        id: SOURCE_MM,
        page: 31,
      },
    }],
    source: [{
      id: SOURCE_MM,
      page: 31,
    }],
  },
  editPropCollection: {
    name: ({ name }) => `${name} заклинатель`,
  },
}
