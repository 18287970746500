import chunk from 'lodash/chunk'

const SEPARATOR = ' '

const formatNumPart = (numStr, doRevert = false) => {
  const intArr = numStr.split('')

  const intReverted = doRevert
    ? intArr.reverse()
    : intArr

  const intFormattedStr = chunk(intReverted, 3).reduce(
    (str, part) => {
      return str
        ? `${str}${SEPARATOR}${part.join('')}`
        : part.join('')
    },
    ''
  )

  return doRevert
    ? intFormattedStr
      .split('')
      .reverse()
      .join('')
    : intFormattedStr
}

const formatNumberByParts = num => {
  const [int, fract] = num.toString().replace(',', '.').split('.')

  const intStr = formatNumPart(int, true)
  let fractStr = ''

  if (fract) {
    fractStr = `,${formatNumPart(fract, false)}`
  }

  return `${intStr}${fractStr}`
}

export default formatNumberByParts
