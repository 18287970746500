import React, { Component } from 'react';
import PropTypes from "prop-types"

import {creatureCollection} from "@/constants/creatureList"

import CreatureComponent from "./CreatureComponent"

class CreatureContainer extends Component {
  state = {
    creature: creatureCollection[this.props.id],
  }

  componentDidUpdate(prevProps) {
    const { id, name } = this.props;

    if (id !== prevProps.id || name !== prevProps.name) {
      const creature = creatureCollection[id];
      this.setState({creature})
    }
  }

  extendCreature = creature => {
    this.setState({creature})
  }

  render () {
    const {creature} = this.state

    return (
      <CreatureComponent
        creature={creature}
        extendCreature={this.extendCreature}
      />
    )
  }
}

CreatureContainer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default CreatureContainer
