import {gearCollection} from '@/constants/gearList'
import {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} from '@/constants/attackTypeList'
import {
  CAT_MELEE_WEAPON,
  CAT_RANGE_WEAPON,
} from '@/constants/gearCategoryList'

const pickAtkTypeByGear = (gearId) => {
  const gear = gearCollection[gearId]

  if (gear) {
    const { category = [] } = gear

    if (category.includes(CAT_MELEE_WEAPON)) {
      return ATTACK_MELEE_WEAPON
    } else if (category.includes(CAT_RANGE_WEAPON)) {
      return ATTACK_RANGE_WEAPON
    }
  }

  return null
}

export default pickAtkTypeByGear
