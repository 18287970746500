import declint from 'declint-ru'
import arrify from 'arrify'

import joinText from '@/utils/joinText'

import numberList from '@/constants/numberList'
import { targetCollection } from '@/constants/targetList'
import { sizeCollection } from '@/constants/sizeList'
import { conditionCollection } from '@/constants/conditionList'
import { creatureTypeCollection } from '@/constants/creatureTypeList'
import { GENDER_FEMALE, GENDER_MALE, GENDER_MIDDLE } from '@/constants/genderList'

export default targetData => joinText(
  arrify(targetData).map(
    target => {
      if (typeof target !== 'string') {
        const targetCount = typeof target === 'object'
          ? target.count
          : target
        const numberWordObj = numberList[targetCount]

        let targetWordList = ['цель', 'цели', 'целей']

        let conditionText = ''
        let genderId = ''
        let limitSizeSingleText = ''
        let limitSizeMultipleText = ''
        let commentText = ''
        let numberGenderId = GENDER_FEMALE

        if (target.limit) {
          if (target.limit.type) {
            const dataObj = targetCollection[target.limit.type]
            const {
              name: {
                singular: {
                  nominative: singularTypeWord,
                  genitive: dualTypeWord,
                },
                plural: {
                  genitive: multipleTypeWord,
                },
              },
            } = dataObj

            numberGenderId = dataObj.genderId
            targetWordList = [singularTypeWord, dualTypeWord, multipleTypeWord]
          }

          if (target.limit.creatureType) {
            const dataObj = creatureTypeCollection[target.limit.creatureType]
            const {
              genderId: creatureTypeGenderId,
              name: {
                singular,
                plural: {
                  genitive: multipleTypeWord,
                },
              },
            } = creatureTypeCollection[target.limit.creatureType]

            const {
              nominative: singularTypeWord,
              genitive: dualTypeWord,
            } = (singular[creatureTypeGenderId] || singular[GENDER_MALE])


            numberGenderId = dataObj.genderId
            targetWordList = [singularTypeWord, dualTypeWord, multipleTypeWord]
          }

          if (target.limit.size) {
            const { size } = target.limit

            if (Array.isArray(size)) {
              const sizeText = joinText(
                size.map(
                  sizeItem => sizeCollection[sizeItem].name.singular[GENDER_MIDDLE].nominative,
                ),
              )
              limitSizeMultipleText = ` ${sizeText}`
            }

            if (size.max) {
              const sizeText = sizeCollection[size.max].name.singular[GENDER_MALE].genitive
              limitSizeSingleText = ` c размером не больше ${sizeText}`
            }
          }

          if (target.limit.condition) {
            const {
              singular,
              plural,
            } = conditionCollection[target.limit.condition].targetName
            const {
              nominative: singularConditionWord,
              genitive: dualConditionWord,
            } = genderId
              ? singular[genderId]
              : singular[GENDER_MALE]
            const {
              genitive: multipleConditionWord,
            } = plural

            conditionText = declint(targetCount, [singularConditionWord, dualConditionWord, multipleConditionWord])
          }
        }

        if (target.comment) {
          commentText = target.comment
        }

        const numberText = numberWordObj[numberGenderId]
        const targetText = declint(targetCount, targetWordList)

        return [
          numberText,
          conditionText,
          limitSizeMultipleText,
          targetText,
          limitSizeSingleText,
          commentText,
        ]
          .filter(e => e)
          .join(' ')
      }

      return target
    },
  )
)
