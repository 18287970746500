const {SOURCE_TCoE} = require('./../../sourceList')
const {
  CREATURE_GHOSTLY_UNDEAD_SPIRIT,
  CREATURE_JUVENILE_MIMIC,
  CREATURE_REFLECTION,
  CREATURE_WILDFIRE_SPIRIT,
} = require('./../../creatureIdList')
const {
  Summon_undead_MTG_data,
} = require('./../commonImageCollection')

const {MimicImgList} = require('./../commonImageListCollection')
const Reflection_img_TCoE = require('./../../../images/creatures/tcoe/reflection.png')
const Spirit_of_wildfire_img_TCoE = require('./../../../images/creatures/tcoe/spirit_of_wildfire.png')

module.exports = {
  [CREATURE_GHOSTLY_UNDEAD_SPIRIT]: Summon_undead_MTG_data,
  [CREATURE_JUVENILE_MIMIC]: MimicImgList,
  [CREATURE_REFLECTION]: {
    src: Reflection_img_TCoE,
    text: 'Портняжная мастерская в зеркальной зоне',
    source: {
      id: SOURCE_TCoE,
    },
  },
  [CREATURE_WILDFIRE_SPIRIT]: Spirit_of_wildfire_img_TCoE,
}
