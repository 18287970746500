import React from "react"
import declint from "declint-ru"

import StatInfoRow from "@/components/StatInfoRow";

const word = {
  knot: ['узел', 'узла', 'узлов'],
  mile: ['миля', 'мили', 'миль'],
}

const TravelSpeed = ({travelSpeed}) => {
  if (travelSpeed) {
    const {day = 0, hour = 0} = typeof travelSpeed === 'object'
      ? travelSpeed
      : {
        day: travelSpeed * 24,
        hour: travelSpeed,
      }
    const knotWord = declint(hour, word.knot)
    const mileWord = declint(day, word.mile)

    return (
      <StatInfoRow header='Темп'>
        {hour} {knotWord}, {day} {mileWord} в день
      </StatInfoRow>
    )
  }

  return null
}

export default TravelSpeed
