import {ABILITY_TURN_IMMUNITY} from '@/constants/creatureAbilityList'
import {ALIGNMENT_N} from '@/constants/aligmentList'
import {CREATURE_SHADOW} from '@/constants/creatureIdList'
import {faFlushed} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_DMG} from '@/constants/sourceList'

export default {
  templateName: 'Эльфийская тень',
  templateIcon: faFlushed,
  templateColor: 'black',

  templateLimitations: {
    include: {
      id: CREATURE_SHADOW,
    },
  },

  name: 'Эльфийская тень',
  nameEn: 'Elf Shadow',
  alignmentId: ALIGNMENT_N,

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 218,
    }],
    featureList: [
      ABILITY_TURN_IMMUNITY,
    ],
  },

  editPropCollection: {
    actionList: ({ actionList }) => actionList.map(
      action => action.name === 'Вытягивание силы'
        ? {
          ...action,
          description: action.description.replace(
            'Если от этой атаки умрёт гуманоид с незлым мировоззрением, через 1к4 часа из трупа выйдет ★новая★ ★СУЩЕСТВО★.',
            ''
          ),
        }
        : action
    ),
  },
}
