import React from 'react'
import PropTypes from 'prop-types'
import arrify from 'arrify'

import { spellCollection } from '@/constants/spellList'

import generateTextLinks from '@/utils/generateTextLinks'

import SpellComponent from './SpellComponent'

const SpellContainer = ({ id }) => {
  const spell = spellCollection[id]

  if (spell) {
    const {
      description,
      lvl,
      materialText,
      name,
      nameAlt = '',
      nameEn,
      source: sourceOfSpell,
      ...rest
    } = spell

    const lvlText = lvl
      ? `${lvl} круг`
      : 'Заговор'

    const materialHandledText = materialText
      ? generateTextLinks(materialText)
      : ''

    const descriptionList = arrify(description).map(
      (item, i) => {
        let header
        let text
        let source

        if (typeof item === 'string') {
          text = item
          source = sourceOfSpell
        } else {
          header = item.header
          text = item.text
          source = item.source
        }

        return {
          header,
          text,
          source,
        }
      }
    )

    return (
      <SpellComponent
        descriptionList={descriptionList}
        lvlText={lvlText}
        materialText={materialHandledText}
        spell={spell}
        {...rest}
      />
    )
  }

  return null
}

SpellContainer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default SpellContainer
