import AC_BASE from '@/constants/AC_BASE'
import { gearCollection } from '@/constants/gearList'

import calcParamBonus from '@/utils/calcParamBonus'

export default ({ dex, armorId }) => {
  const {
    acChangeTo = AC_BASE,
    acUseDexMod = false,
    acDexModMax = Infinity,
  } = armorId
    ? gearCollection[armorId]
    : {
      acUseDexMod: true,
    }

  return acChangeTo + (
    acUseDexMod
      ? Math.min(calcParamBonus(dex), acDexModMax)
      : 0
  )
}
