import arrify from 'arrify'
import React from 'react'

import generateTextLinks from '@/utils/generateTextLinks'
import getTextFromDescription from '@/utils/getTextFromDescription'

import {armorTypeCollection} from '@/constants/armorTypeList'
import {gearCollection} from '@/constants/gearList'
import {magicItemCollection} from '@/constants/magicItemList'

import CreatureAcComponent from './CreatureAcComponent'

const generateItemStrList = (idList, collection, slug) => idList
  ? arrify(idList).map(
    idItem => {
      if (idItem) {
        const name = collection[idItem].name
        const description = getTextFromDescription(arrify(collection[idItem].description)[0]).split('\n')[0]

        const descriptionTitle = description
          ? `${name}\n${description}`
          : ''

        return `[${name.toLowerCase()}](${slug}:${idItem} "${descriptionTitle}")`
      }

      return null
    }
  )
  : []

export default ({ armor }) => {
  if (armor) {
    const armorList = arrify(armor).reduce(
      (list, item) => {
        let text = ''

        switch (typeof item) {
          case 'string': {
            text = item
            break
          }

          case 'number': {
            text = item.toString()
            break
          }

          case 'object': {
            const { ac, type, gearId, magicItemId, comment = '' } = item
            const typeStrList = type
              ? arrify(type).map(
                typeItem => {
                  const armorType = armorTypeCollection[typeItem]

                  return armorType
                    ? armorType.name.toLowerCase()
                    : String(typeItem)
                }
              )
              : []

            const magicItemStrList = generateItemStrList(magicItemId, magicItemCollection, 'MAGIC_ITEM')
            const gearStrList = generateItemStrList(gearId, gearCollection, 'GEAR')

            const commentStr = comment
              ? ` ${comment}`
              : ''
            const armorStr = typeStrList.length || magicItemStrList.length || gearStrList.length
              ? [
                ...typeStrList,
                ...magicItemStrList,
                ...gearStrList,
              ]
                .filter(e => e)
                .join(', ')
                .replace(/^/, ' (')
                .replace(/$/, ')')
              : ''

            text = generateTextLinks(`${ac}${armorStr}${commentStr}`)
            break
          }
        }

        return text
          ? [
            ...list,
            text,
          ]
          : list
      },
      [],
    )

    return (
      <CreatureAcComponent list={armorList}/>
    )
  }

  return null
}
