import React from "react"

import arrify from "arrify"

import {languageCollection} from "@/constants/languageList"
import {GENDER_FEMALE} from "@/constants/genderList"

import LanguageName from "@/components/LanguageName"

import "./LanguageItemStyles.less"

const LanguageItem = ({ lang, use5eOfficialName = false }) => {
  const langIdList = arrify(
    typeof lang === 'string'
      ? lang
      : lang.id
  )

  const pronoun = langIdList.length === 1
    ? languageCollection[langIdList[0]].genderId === GENDER_FEMALE
      ? 'ней'
      : 'нём'
    : 'них'

  const rangeText = lang.range
    ? typeof lang.range === 'number'
      ? ` ${lang.range} фт.`
      : lang.range
    : ''
  const doNotSpeakText1st = lang.onlyUnderstands || lang.doNotSpeak || lang.featureName || lang.onlyCommands
    ? lang.onlyCommands
      ? `понимает команды, отданные на `
      : `понимает `
    : ''
  const doNotSpeakText2nd = lang.doNotSpeak
    ? `, но не говорит на ${pronoun}`
    : ''
  const commentText = lang.comment
    ? ` (${lang.comment})`
    : ''
  const featureText = lang.featureName
    ? `, но говорит только с помощью особенности ${lang.featureName}`
    : ''
  const rarelySpeaksText = lang.butRarelySpeaks
    ? `, но редко говорит`
    : ''
  const creatureRangeText = lang.creatureRange
    ? `, известные любому существу в пределах ${lang.creatureRange} футов`
    : ''
  const preText = doNotSpeakText1st
  const postText = `${rangeText}${creatureRangeText}${doNotSpeakText2nd}${rarelySpeaksText}${featureText}${commentText}`

  return (
    <span className='LanguageItem'>
      {preText ? (
        <span className='LanguageItem_preText'>{preText}</span>
      ) : null}
      <span className='LanguageItem_nameList'>
        {
          langIdList.map(
            (langId, i) => (
              <LanguageName
                count={lang.count}
                key={i}
                langId={langId}
                onlyCommands={lang.onlyCommands}
                use5eOfficialName={use5eOfficialName}
              />
            )
          )
        }
      </span>
      {postText ? (
        <span className='LanguageItem_postText'>{postText}</span>
      ) : null}
    </span>
  )
}

export default LanguageItem
