const {
  SOURCE_MTG_CLB,
} = require('./../../../sourceList')

const Dragon_cultist_MTG_CLB_img = require('./../../../../images/creatures/mtg_clb/dragon_cultist_mtg_clb.jpg')

module.exports = [
  {
    src: Dragon_cultist_MTG_CLB_img,
    text: 'Культист дракона',
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
]
