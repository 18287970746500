const {
  SOURCE_BGDIA,
  SOURCE_MTG_CLB,
} = require('./../../sourceList')
const {
  CREATURE_ABYSSAL_CHICKEN,
  CREATURE_BLACK_GAUNTLET_OF_BANE,
  CREATURE_BONE_WHELK,
  CREATURE_BEL,
  CREATURE_CROKEKTOECK,
  CREATURE_HELL_WASP,
  CREATURE_KOSTCHTCHIE,
  CREATURE_HOLLYPHANT,
  CREATURE_NECROMITE_OF_MYRKUL,
  CREATURE_REAPER_OF_BHAAL,
} = require('./../../creatureIdList')

const Abyssal_chicken_img_BGDIA = require('./../../../images/creatures/bgdia/abyssal_chicken.jpeg')
const Bel_img_BGDIA = require('./../../../images/creatures/bgdia/bel.jpeg')
const Bone_whelk_img_BGDIA = require('./../../../images/creatures/bgdia/bone_whelk.jpeg')
const Crokektoeck_img_BGDIA = require('./../../../images/creatures/bgdia/crokektoeck.jpeg')
const Hellwasp_img_BGDIA = require('./../../../images/creatures/bgdia/hellwasp.jpeg')
const Hollyphant_img_BGDIA = require('./../../../images/creatures/bgdia/hollyphant.jpeg')
const Kostchtchie_img_BGDIA = require('./../../../images/creatures/bgdia/Kostchtchie.jpeg')
const Necromite_of_myrkul_img_BGDIA = require('./../../../images/creatures/bgdia/necromite_of_myrkul.jpeg')
const Reaper_of_bhaal_img_BGDIA = require('./../../../images/creatures/bgdia/reaper_of_bhaal.jpeg')
const Black_gauntlet_of_bane_img_BGDIA = require('./../../../images/creatures/bgdia/black_gauntlet_of_bane.jpeg')

const Lulu_loyal_hollyphant_MTG_CLB_img = require('./../../../images/creatures/mtg_clb/lulu-loyal-hollyphant.jpg')

module.exports = {
  [CREATURE_ABYSSAL_CHICKEN]: Abyssal_chicken_img_BGDIA,
  [CREATURE_BONE_WHELK]: Bone_whelk_img_BGDIA,
  [CREATURE_BEL]: Bel_img_BGDIA,
  [CREATURE_CROKEKTOECK]: Crokektoeck_img_BGDIA,
  [CREATURE_HELL_WASP]: Hellwasp_img_BGDIA,
  [CREATURE_BLACK_GAUNTLET_OF_BANE]: Black_gauntlet_of_bane_img_BGDIA,
  [CREATURE_HOLLYPHANT]: [
    {
      src: Hollyphant_img_BGDIA,
      text: 'Оллифант Лулу',
      source: {
        id: SOURCE_BGDIA,
      },
    },
    {
      src: Lulu_loyal_hollyphant_MTG_CLB_img,
      text: 'Лулу, верный оллифант',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_KOSTCHTCHIE]: Kostchtchie_img_BGDIA,
  [CREATURE_NECROMITE_OF_MYRKUL]: Necromite_of_myrkul_img_BGDIA,
  [CREATURE_REAPER_OF_BHAAL]: Reaper_of_bhaal_img_BGDIA,
}
