import React from 'react'
import declint from 'declint-ru'

import {monetCollection, MONET_GOLD} from '@/constants/monetTypeList'
import formatNumberByParts from '@/utils/formatNumberByParts'

import imageCollection from './constants/imageCollection'

import MonetComponent from './MonetComponent'

const MonetContainer = ({ count, type: monetId = MONET_GOLD }) => {
  const {
    fullName: {
      singular: {
        nominative: singular,
      },
      dual: {
        nominative: dual,
      },
      plural: {
        nominative: plural,
      },
    },
  } = monetCollection[monetId]

  const monetWord = typeof count === 'number'
    ? declint(count, [singular, dual, plural])
    : plural
  const text = typeof count === 'number'
    ? formatNumberByParts(count)
    : count
  const title = `${text} ${monetWord}`

  return (
    <MonetComponent
      title={title}
      type={monetId}
      text={text}
      src={imageCollection[monetId]}
      alt={monetCollection[monetId].name}
    />
  )
}

export default MonetContainer
