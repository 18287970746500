const {
  CREATURE_CLAWFOOT,
  CREATURE_FASTIETH,
  CREATURE_HOMUNCULUS_SERVANT,
  CREATURE_DOLGAUNT,
  CREATURE_DOLGRIM,
  CREATURE_IRON_DEFENDER,
} = require('./../../creatureIdList')

const {
  SOURCE_ERFTLW,
  SOURCE_TCoE,
} = require('./../../sourceList')

const {
  Clawfoot_Raptor_WGtE_data,
} = require('./../commonImageCollection')

const Fastieth_ERFTLW_img = require('./../../../images/creatures/erftlw/fastieth.png')
const Dolgaunt_ERFTLW_img = require('./../../../images/creatures/erftlw/dolgaunt.png')
const Dolgrim_ERFTLW_img = require('./../../../images/creatures/erftlw/dolgrim.png')
const Iron_Defender_ERFTLW_img = require('./../../../images/creatures/erftlw/iron_defender.png')
const Homunculus_servant_img_TCoE = require('./../../../images/creatures/tcoe/homunculus_servant.png')

module.exports = {
  [CREATURE_HOMUNCULUS_SERVANT]: {
    src: Homunculus_servant_img_TCoE,
    text: 'Слуги-гомункулы',
    source: {
      id: SOURCE_TCoE,
    },
  },
  [CREATURE_CLAWFOOT]: Clawfoot_Raptor_WGtE_data,
  [CREATURE_FASTIETH]: {
    src: Fastieth_ERFTLW_img,
    creatureId: CREATURE_FASTIETH,
    source: {
      id: SOURCE_ERFTLW,
    },
  },
  [CREATURE_DOLGAUNT]: Dolgaunt_ERFTLW_img,
  [CREATURE_DOLGRIM]: {
    src: Dolgrim_ERFTLW_img,
    creatureId: CREATURE_DOLGRIM,
    source: {
      id: SOURCE_ERFTLW,
    },
  },
  [CREATURE_IRON_DEFENDER]: Iron_Defender_ERFTLW_img,
}
