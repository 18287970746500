const {
  SOURCE_ADND2_MM,
  SOURCE_MM,
} = require('./../../../sourceList')

const {
  CREATURE_DRAGON_BRONZE_WYRMLING,
} = require('./../../../creatureIdList')

const {
  Council_of_Metallic_Dragons_FTD_data,
  Ancient_Dragons_Hoard_data,
} = require('./../../commonImageCollection')

const Bronze_Dragon_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Bronze_Dragon.jpg')
const Bronze_Dragon_MM_img = require('./../../../../images/creatures/mm/bronze_dragon.jpg')
const Bronze_Dragon_Wyrmling_MM_img = require('./../../../../images/creatures/mm/bronze_dragon_wyrmling.jpg')

module.exports = [
  {
    src: Bronze_Dragon_MM_img,
    text: 'Бронзовый дракон',
  },
  {
    src: Bronze_Dragon_Wyrmling_MM_img,
    creatureId: CREATURE_DRAGON_BRONZE_WYRMLING,
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Bronze_Dragon_ADND2_img,
    text: 'Бронзовый дракон',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  Council_of_Metallic_Dragons_FTD_data,
  Ancient_Dragons_Hoard_data,
]
