const { gearCollection } = require('./../constants/gearList')

const pickWeaponPropFromGear = (weaponPropId, gearId) => {
  const gear = gearCollection[gearId]

  if (gear) {
    const { weaponPropList = [] } = gear

    return weaponPropList.find(
      ({ id }) => weaponPropId === id
    )
  }

  return null
}

module.exports = pickWeaponPropFromGear
