import declint from 'declint-ru'
import React from "react"

import {massUnitCollection} from "@/constants/massUnitList"

import StatInfoRow from "@/components/StatInfoRow";

const Cargo = ({cargo}) => {
  if (cargo) {
    let text = ''
    let valueText = ''

    if (typeof cargo === 'object') {
      const {unitId, value} = cargo
      const {name: {singular, dual, plural}} = massUnitCollection[unitId]
      text = declint(value, [singular, dual, plural])
      valueText = value

      switch (value) {
        case 0.25: {
          valueText = '¼'
          break
        }
        case 0.5: {
          valueText = '½'
          break
        }
      }
    } else {
      text = cargo
    }

    return (
      <StatInfoRow header='Грузоподъёмность'>
        {valueText} {text}
      </StatInfoRow>
    )
  }

  return null
}

export default Cargo
