const {
  CREATURE_FAERIE_DRAGON_LIGHT_BLUE,
  CREATURE_FAERIE_DRAGON_YELLOW,
} = require('./../../../creatureIdList')

const {
  SOURCE_ADND2_MM,
  SOURCE_FTD,
  SOURCE_DND3_5_DTBoD,
} = require('./../../../sourceList')

const {
  Faerie_Dragon_Light_Blue_MM_data,
} = require('./../../commonImageCollection')

const Faerie_Dragon_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Faerie_Dragon.jpg')
const Faerie_Dragon_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/faerie-dragon.jpg')
const Faerie_Dragon_FTD_img = require('./../../../../images/creatures/ftd/faerie_dragon.png')

module.exports = [
  Faerie_Dragon_Light_Blue_MM_data,
  {
    src: Faerie_Dragon_FTD_img,
    creatureId: CREATURE_FAERIE_DRAGON_LIGHT_BLUE,
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Faerie_Dragon_DND3_5_DTBoD_img,
    creatureId: CREATURE_FAERIE_DRAGON_LIGHT_BLUE,
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Faerie_Dragon_ADND2_img,
    creatureId: CREATURE_FAERIE_DRAGON_YELLOW,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
