import React from "react"

import updateNameAtDescription from '@/utils/updateNameAtDescription'

import CreatureAc from "@/components/CreatureAc"
import HitPoints from "@/components/HitPoints"
import Markdown from '@/components/Markdown'

import './ControlListStyles.less'

const ControlList = ({controlList, name: vehicleName, genderId}) => {
  if (controlList) {
    return controlList.map(
      ({ac, name, hp, damageThreshold, description}, i) => (
        <section
          className='ControlList'
          key={i}
        >
          <header className='ControlList_header'>Управление: {name}</header>
          <div className='ControlList_content'>
            <CreatureAc armor={ac} />
            <HitPoints
              damageThreshold={damageThreshold}
              hp={hp}
            />
            <Markdown className='ControlList_description'>
              {updateNameAtDescription({genderId, description, name: vehicleName})}
            </Markdown>
          </div>
        </section>
      )
    )
  }

  return null
}

export default ControlList
