const {
  CREATURE_ALBINO_DWARF_WARRIOR,
  CREATURE_ALBINO_DWARF_SPIRIT_WARRIOR,
  CREATURE_ALDANI,
  CREATURE_ALMIRAJ,
  CREATURE_CHWINGA,
  CREATURE_EBLIS,
  CREATURE_FLAMING_FIST,
  CREATURE_FLAMING_FIST_GAUNTLET,
  CREATURE_GIANT_FOUR_ARMED_GARGOYLE,
  CREATURE_GIRALLON_ZOMBIE,
  CREATURE_JACULI,
  CREATURE_KAMADAN,
  CREATURE_LIARA_PORTYR,
  CREATURE_PTERAFOLK,
  CREATURE_STONE_JUGGERNAUT,
  CREATURE_TABAXI_MINSTREL,
  CREATURE_TRI_FLOWER_FROND,
  CREATURE_YELLOW_MUSK_CREEPER,
  CREATURE_YELLOW_MUSK_ZOMBIE,
  CREATURE_ZORBO,
} = require('./../../creatureIdList')
const {
  SOURCE_IDRotF,
  SOURCE_MTG_CLB,
  SOURCE_TOA,
} = require('./../../sourceList')
const {
  FlamingFistList,
} = require('./../commonImageListCollection')

const Albino_dwarf_img_TOA = require('./../../../images/creatures/toa/albino_dwarf.png')
const Aldani_img_TOA = require('./../../../images/creatures/toa/aldani.png')
const Almiraj_img_TOA = require('./../../../images/creatures/toa/almiraj.png')
const Chwinga_img_TOA = require('./../../../images/creatures/toa/chwinga.png')
const Chwingas_img_IDROTF = require('./../../../images/creatures/idrotf/chwingas.jpg')
const Chwinga_snowman_img_IDROTF = require('./../../../images/creatures/idrotf/Chwinga-3.webp')
const Eblis_img_TOA = require('./../../../images/creatures/toa/eblis.png')
const Giant_four_armed_gargoyle_img_TOA = require('./../../../images/creatures/toa/giant_four_armed_gargoyle.jpeg')
const Girallon_zombie_img_TOA = require('./../../../images/creatures/toa/girallon_zombie.png')
const Jaculi_img_TOA = require('./../../../images/creatures/toa/jaculi.png')
const Kamadan_img_TOA = require('./../../../images/creatures/toa/kamadan.png')
const Pterafolk_img_TOA = require('./../../../images/creatures/toa/pterafolk.png')
const Stone_juggernaut_img_TOA = require('./../../../images/creatures/toa/stone_juggernaut.png')
const Tabaxi_minstrel_img_TOA = require('./../../../images/creatures/toa/tabaxi_minstrel.png')
const Tri_flower_frond_img_TOA = require('./../../../images/creatures/toa/tri_flower_frond.png')
const Yellow_musk_creeper_img_TOA = require('./../../../images/creatures/toa/yellow_musk_creeper.png')
const Yellow_musk_zombie_img_TOA = require('./../../../images/creatures/toa/yellow_musk_zombie.png')
const Zorbo_img_TOA = require('./../../../images/creatures/toa/zorbo.png')
const Liara_portyr_TOA_img = require('./../../../images/creatures/toa/liara_portyr_toa.jpeg')

const Liara_portyr_MTG_CLB_img = require('./../../../images/creatures/mtg_clb/liara-portyr-mtg_clb.webp')

const Albino_dwarf = {
  src: Albino_dwarf_img_TOA,
  text: 'Дварф-альбинос',
}

module.exports = {
  [CREATURE_ALBINO_DWARF_WARRIOR]: Albino_dwarf,
  [CREATURE_ALBINO_DWARF_SPIRIT_WARRIOR]: Albino_dwarf,
  [CREATURE_ALDANI]: Aldani_img_TOA,
  [CREATURE_ALMIRAJ]: Almiraj_img_TOA,
  [CREATURE_CHWINGA]: [
    {
      src: Chwinga_img_TOA,
      source: {
        id: SOURCE_TOA,
      },
    },
    {
      src: Chwingas_img_IDROTF,
      source: {
        id: SOURCE_IDRotF,
      },
    },
    {
      src: Chwinga_snowman_img_IDROTF,
      source: {
        id: SOURCE_IDRotF,
      },
    },
  ],
  [CREATURE_GIANT_FOUR_ARMED_GARGOYLE]: Giant_four_armed_gargoyle_img_TOA,
  [CREATURE_GIRALLON_ZOMBIE]: Girallon_zombie_img_TOA,
  [CREATURE_EBLIS]: Eblis_img_TOA,
  [CREATURE_FLAMING_FIST]: FlamingFistList,
  [CREATURE_FLAMING_FIST_GAUNTLET]: FlamingFistList,
  [CREATURE_JACULI]: Jaculi_img_TOA,
  [CREATURE_KAMADAN]: Kamadan_img_TOA,
  [CREATURE_LIARA_PORTYR]: [
    Liara_portyr_TOA_img,
    {
      src: Liara_portyr_MTG_CLB_img,
      text: 'Командир Лиара Портир',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_PTERAFOLK]: Pterafolk_img_TOA,
  [CREATURE_STONE_JUGGERNAUT]: Stone_juggernaut_img_TOA,
  [CREATURE_TABAXI_MINSTREL]: Tabaxi_minstrel_img_TOA,
  [CREATURE_TRI_FLOWER_FROND]: Tri_flower_frond_img_TOA,
  [CREATURE_YELLOW_MUSK_CREEPER]: Yellow_musk_creeper_img_TOA,
  [CREATURE_YELLOW_MUSK_ZOMBIE]: Yellow_musk_zombie_img_TOA,
  [CREATURE_ZORBO]: Zorbo_img_TOA,
}
