import {ABILITY_SPIDER_CLIMB} from '@/constants/creatureAbilityList'
import {CREATURE_GIANT_LIZARD} from '@/constants/creatureIdList'
import {faSpider} from '@fortawesome/free-solid-svg-icons'

import giantLizardDescription from './constants/giantLizardDescription'

export default {
  templateName: 'Лазающая гигантская ящерица',
  templateIcon: faSpider,
  templateColor: 'black',

  templateLimitations: {
    include: {
      id: [
        CREATURE_GIANT_LIZARD,
      ],
    },
  },

  extendPropCollection: {
    description: giantLizardDescription,
    featureList: [
      ABILITY_SPIDER_CLIMB,
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Лазающая ${name.toLowerCase()}`,
  },
}
