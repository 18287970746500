const {
  CREATURE_MIMIC,
} = require('./../../../creatureIdList')

const {
  SOURCE_ADND2_MM,
  SOURCE_MM,
  SOURCE_MTG_CLB,
  SOURCE_TCoE,
} = require('./../../../sourceList')

const Mimic_ADND2 = require('./../../../../images/creatures/adnd2_mm/Mimic.jpg')
const Mimic_img_TCoE = require('./../../../../images/creatures/tcoe/mimic.png')
const Mimic_MM = require('./../../../../images/creatures/mm/mimic.jpeg')
const Dire_mimic_MTG_CLB_img = require('./../../../../images/creatures/mtg_clb/dire-mimic-mtg_clb.webp')

module.exports = [
  {
    creatureId: CREATURE_MIMIC,
    src: Mimic_MM,
    source: SOURCE_MM,
  },
  {
    creatureId: CREATURE_MIMIC,
    src: Mimic_ADND2,
    source: SOURCE_ADND2_MM,
  },
  {
    src: Mimic_img_TCoE,
    text: 'Колония мимиков',
    source: SOURCE_TCoE,
  },
  {
    src: Dire_mimic_MTG_CLB_img,
    text: 'Мимик-шкаф',
    source: SOURCE_MTG_CLB,
  },
]
