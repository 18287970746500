import React from "react"

import AbilityList from '@/components/AbilityList'

export default props => (
  <AbilityList
    header='Реакции'
    {...props}
  />
)
