import React from 'react'
import PropTypes from 'prop-types'

import { GENDER_MALE } from '@/constants/genderList'

import Ability from '@/components/Ability'

const SpellCastComponent = ({ list }) => (
  <>
    {
      list.map(
        ({ header, text }, i) => (
          <Ability
            key={i}
            header={header}
          >
            {text}
          </Ability>
        ),
      )
    }
  </>
)

SpellCastComponent.propTypes = {
  genderId: PropTypes.string,
  name: PropTypes.string,
  spellCast: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

SpellCastComponent.defaultProps = {
  genderId: GENDER_MALE,
  name: '',
  spellCast: null,
}

export default SpellCastComponent
