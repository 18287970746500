const {
  SOURCE_ADND2_MM,
  SOURCE_DND3_5_DTBoD,
  SOURCE_FTD,
  SOURCE_MM,
  SOURCE_MTG_CLB,
} = require('./../../../sourceList')

const {
  Council_of_Metallic_Dragons_FTD_data,
  Ancient_Dragons_Hoard_data,
} = require('./../../commonImageCollection')

const Brass_Dragon_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Brass_Dragon.jpg')
const Brass_Dragon_MM_img = require('./../../../../images/creatures/mm/brass_dragon.jpg')
const Brass_Dragon_watches_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/brass-dragon.jpg')
const Brass_Dragons_Digging_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/brass-dragon-digging.jpg')
const Fizban_Argues_with_Statue_FTD_img = require('./../../../../images/creatures/ftd/fizban-argues-with-statue.jpg')
const Dragon_Brass_Ancient_MTG_CLB_img = require('./../../../../images/creatures/mtg_clb/dragon-brass-ancient-mtg_cls.jpg')

module.exports = [
  {
    src: Brass_Dragon_MM_img,
    text: 'Латунный дракон',
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Brass_Dragons_Digging_DND3_5_DTBoD_img,
    text: 'Латунный дракон прокапывает землю',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Brass_Dragon_watches_DND3_5_DTBoD_img,
    text: 'Латунный дракон бесстрастно взирает на драконье кладбище',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Fizban_Argues_with_Statue_FTD_img,
    text: 'Физбан спорит с чопорной статуей в логове озадаченного латунного дракона',
    source: {
      id: SOURCE_FTD,
    },
  },
  Council_of_Metallic_Dragons_FTD_data,
  Ancient_Dragons_Hoard_data,
  {
    src: Brass_Dragon_ADND2_img,
    text: 'Латунный дракон',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  {
    src: Dragon_Brass_Ancient_MTG_CLB_img,
    text: 'Древний латунный дракон',
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
]
