import {
  SIZE_MEDIUM,
  SIZE_SMALL,
} from '@/constants/sizeList'
import {ATTACK_MELEE_WEAPON} from '@/constants/attackTypeList'
import {CREATURE_ETTERCAP} from '@/constants/creatureIdList'
import {DAMAGE_BLUDGEONING} from '@/constants/damageTypeList'
import {faSpider} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'
import {TARGET_CREATURE} from '@/constants/targetList'

export default {
  templateName: 'Разновидность: Паутинная удавка',
  templateIcon: faSpider,
  templateColor: 'silver',
  templateLimitations: {
    include: {
      id: [
        CREATURE_ETTERCAP,
      ],
    },
  },

  extendPropCollection: {
    description: [
      {
        header: `Разновидность: Паутинная удавка`,
        text: `Некоторые эттеркапы любят душить жертву, используя удавку, созданную из тонких нитей паутины. Эттеркап с таким оружием получает описанный ниже вариант действия, которое использует вместо атаки когтями.

**Паутинная удавка.** Рукопашная атака оружием: +4 к попаданию, досягаемость 5 фт., одно Среднее или Маленькое существо, бросок атаки по которому эттеркап совершает с преимуществом. Попадание: Дробящий урон 4 (1к4 + 2), и цель становится схваченной (Сл. высвобождения 12). Пока цель схвачена, она не может дышать, и эттеркап совершает броски атаки по ней с преимуществом.`,
        source: {
          id: SOURCE_MM,
          page: 310,
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name} с паутинной удавкой`,
    actionList: ({ actionList }) => actionList.map(
      action => action.name === 'Когти'
        ? {
          name: 'Паутинная удавка',
          description: `Цель становится схваченной (Сл. высвобождения 12). Пока цель схвачена, она не может дышать, и эттеркап совершает броски атаки по ней с преимуществом.`,
          attack: {
            type: ATTACK_MELEE_WEAPON,
            bonus: 4,
            range: 5,
            target: {
              count: 1,
              limit: {
                type: TARGET_CREATURE,
                size: [
                  SIZE_SMALL,
                  SIZE_MEDIUM,
                ],
                comment: `, бросок атаки по которому эттеркап совершает с преимуществом`,
              },
            },
            hit: {
              type: DAMAGE_BLUDGEONING,
              diceCount: 1,
              diceType: 4,
              diceBonus: 0,
            },
          },
        }
        : action,
    ),
  },
}
