import {
  SPELL_BLADE_WARD,
  SPELL_BLUR,
  SPELL_CLAIRVOYANCE,
  SPELL_CONFUSION,
  SPELL_DANCING_LIGHTS,
  SPELL_DETECT_MAGIC,
  SPELL_DISGUISE_SELF,
  SPELL_HALLUCINATORY_TERRAIN,
  SPELL_INVISIBILITY,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGE_HAND,
  SPELL_RAY_OF_SICKNESS,
  SPELL_SENDING,
  SPELL_SHIELD,
  SPELL_SHOCKING_GRASP,
  SPELL_SLEEP,
  SPELL_TELEKINESIS,
  SPELL_WALL_OF_FORCE,
} from '@/constants/spellIdList'
import {CR_8} from '@/constants/crList'
import {CREATURE_MIND_FLAYER} from '@/constants/creatureIdList'
import {faHatWizard} from '@fortawesome/free-solid-svg-icons'
import {PARAM_INT} from '@/constants/paramList'
import {PC_CLASS_WIZARD} from '@/constants/pcClassIdList'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Свежеватель разума заклинатель',
  templateIcon: faHatWizard,
  templateColor: 'blueViolet',
  cr: CR_8,

  templateLimitations: {
    include: {
      id: [
        CREATURE_MIND_FLAYER,
      ],
    },
  },

  extendPropCollection: {
    description: [
      {
        header: 'Вариант: Свежеватель разума заклинатель',
        text: `Некоторые свежеватели разума дополняют свою псионическую силу магическими заклинаниями. Однако другие иллитиды воспринимают их как психически больных и стараются избегать. Свежеватель разума заклинатель обладает показателем опасности 8 (3900 опыта) и имеет следующую особенность:

**Использование заклинаний.** Свежеватель разума является заклинателем 10 уровня. Его базовой характеристикой является Интеллект (СЛ испытания от заклинания 15, +7 к попаданию атаками заклинаниями). У него подготовлены следующие заклинания волшебника:

* Заговоры (неограниченно): _Волшебная рука_ (Mage hand), _Защита от оружия_ (Blade ward), _Пляшущие огоньки_ (Dancing lights), _Электрошок_ (Shocking grasp)

* 1 уровень (4 ячейки): _Маскировка_ (Disguise self), _Обнаружение магии_ (Detect magic), _Усыпление_ (Sleep), _Щит_ (Shield)

* 2 уровень (3 ячейки): _Размытый образ_ (Blur), _Луч слабости_ (Ray of enfeeblement), _Невидимость_ (Invisibility)

* 3 уровень (3 ячейки): _Молния_ (Lightning bolt), _Подсматривание_ (Clairvoyance), _Послание_ (Sending)

* 4 уровень (3 ячейки): _Мираж_ (Hallucinatory terrain), _Смятение_ (Confusion)

* 5 уровень (2 ячейки): _Силовая стена_ (Wall of force), _Телекинез_ (Telekinesis)`,
        source: {
          id: SOURCE_MM,
          page: 260,
        },
      },
    ],
    spellCast: [
      {
        spellCasterLevel: 10,
        spellCasterClass: PC_CLASS_WIZARD,
        spellIdList: [
          SPELL_MAGE_HAND,
          SPELL_BLADE_WARD,
          SPELL_DANCING_LIGHTS,
          SPELL_SHOCKING_GRASP,
          SPELL_DISGUISE_SELF,
          SPELL_DETECT_MAGIC,
          SPELL_SLEEP,
          SPELL_SHIELD,
          SPELL_BLUR,
          SPELL_RAY_OF_SICKNESS,
          SPELL_INVISIBILITY,
          SPELL_LIGHTNING_BOLT,
          SPELL_CLAIRVOYANCE,
          SPELL_SENDING,
          SPELL_HALLUCINATORY_TERRAIN,
          SPELL_CONFUSION,
          SPELL_WALL_OF_FORCE,
          SPELL_TELEKINESIS,
        ],
        slotCountList: [
          Infinity,
          4,
          3,
          3,
          3,
          2,
        ],
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace('Свежеватель разума', `Свежеватель разума заклинатель`),
  },
}
