import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import creatureImageCollection from '@/constants/images/creatureImageCollection'

const CreatureImageListContainer = ({ id, ...rest }) => (
  <ImageList
    imageList={creatureImageCollection[id]}
    {...rest}
  />
)

CreatureImageListContainer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default CreatureImageListContainer
