import React from "react"
import PropTypes from "prop-types"

import AbilityListComponent from "./AbilityListComponent"

const AbilityListContainer = (
  {
    entry,
    list,
    spellCast,
    ...rest
  }
) => entry || spellCast || list.length
  ? (
    <AbilityListComponent
      entry={entry}
      spellCast={spellCast}
      list={list}
      {...rest}
    />
  )
  : null

AbilityListContainer.propTypes = {
  list: PropTypes.array,
}

AbilityListContainer.defaultProps = {
  list: [],
}

export default AbilityListContainer
