const {
  CREATURE_DRAGON_SAPPHIRE_ADULT,
} = require('./../../creatureIdList')
const {
  SOURCE_DnD_SADS_CLE,
} = require('./../../sourceList')

const Adult_Sapphire_Dragon_DND_SADS_CLE_img = require('./../../../images/creatures/dnd_sads_cle/adult_sapphire_dragon.jpg')
const Sapphire_Dragon_sketch_DND_SADS_CLE_img = require('./../../../images/creatures/dnd_sads_cle/Sapphire_Dragon_sketch.webp')

module.exports = {
  [CREATURE_DRAGON_SAPPHIRE_ADULT]: [
    {
      src: Adult_Sapphire_Dragon_DND_SADS_CLE_img,
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
    {
      src: Sapphire_Dragon_sketch_DND_SADS_CLE_img,
      text: 'Сапфировый дракон, набросок',
      source: {
        id: SOURCE_DnD_SADS_CLE,
      },
    },
  ],
}
