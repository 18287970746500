import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon'

import './CreatureExtenderStyles.less'

const CreatureExtenderComponent = ({ list, className, extend }) => (
  <section className={`CreatureExtender ${className}`}>
    <header className='CreatureExtender_header'>Доступные шаблоны</header>
    <ul className='CreatureExtender_list'>
      {
        list.map(
          (template, i) => (
            <li
              className='CreatureExtender_item'
              key={`${i} ${template.templateName}`}
            >
              <button
                className='CreatureExtender_btn'
                style={template.templateColor ? {color: template.templateColor} : {}}
                onClick={() => extend(template)}
                type='button'
                title={`Шаблон: ${template.templateName}`}
              >
                <Icon
                  className='CreatureExtender_icon'
                  icon={template.templateIcon}
                />
                <span className='CreatureExtender_templateName'>
              {template.templateName}
            </span>
              </button>
            </li>
          )
        )
      }
    </ul>
  </section>
);

CreatureExtenderComponent.defaultProps = {
  className: '',
  list: []
};

CreatureExtenderComponent.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
};

export default CreatureExtenderComponent;
