import React, {Component} from 'react';
import PropTypes from 'prop-types';

import calculateColumnCount from './utils/calculateColumnCount';

import StatBlockComponent from './StatBlockComponent';

export default class StatBlockContainer extends Component {
  static propTypes = {
    data: PropTypes.object,
  }

  static defaultProps = {
    data: null,
  }

  state = {
    header: '',
  }

  constructor(props) {
    super(props)

    const { data } = props

    if (data) {
      this.state.header = data.name
    }
  }

  restoreName = () => {
    const { data } = this.props

    this.setState({
      header: data.name,
    })
  }

  onChangeHeader = ({target: {value: header}}) => {
    this.setState({header})
  }

  static getDerivedStateFromProps (state, { data }) {
    if (data) {
      return {
        header: data.name,
      }
    }

    return null
  }

  render () {
    const { header } = this.state
    const { data, ...rest } = this.props

    if (data) {
      const columnCount = calculateColumnCount(data);
      const showRestoreBtn = header !== data.name;

      return (
        <StatBlockComponent
          header={header}
          data={data}
          columnCount={columnCount}
          showRestoreBtn={showRestoreBtn}
          restoreName={this.restoreName}
          onChangeHeader={this.onChangeHeader}
          {...rest}
        />
      );
    }

    return null;
  }
}
