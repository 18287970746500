import React from 'react'
import PropTypes from 'prop-types'

import { GENDER_MALE } from '@/constants/genderList'
import {senseCollection, SENSE_PASSIVE_PERCEPTION} from '@/constants/senseList'

import upLetter from '@/utils/upLetter'
import calcPassivePerception from '@/utils/calcPassivePerception'

import pickTextByGender from './utils/pickTextByGender'

import SenseListComponent from './SenseListComponent'

const SenseListContainer = ({ senseList = [], params,  cr, skillCollection, genderId, isVehicle }) => {
  if (!isVehicle) {
    const { name: passivePerceptionName, description: passivePerceptionDescription } = senseCollection[SENSE_PASSIVE_PERCEPTION]
    const passivePerceptionValue = calcPassivePerception({ params,  cr, skillCollection })

    const list = [
      ...senseList.map(
        ({ id, value, isBlindOutside, comment = '' }) => {
          const { name, description } = senseCollection[id]
          const blindText = isBlindOutside
            ? pickTextByGender(genderId)
            : ''

          const parenthesisText = comment || blindText
            ? [comment, blindText]
              .filter(e => e)
              .join(' ')
              .replace(/^/, ' (')
              .replace(/$/, ')')
            : ''


          const title = description
            ? `${upLetter(name)}\n\n${description}`
            : null

          return {
            id,
            title,
            text: `${name} ${value} фт.${parenthesisText}`,
          }
        }
      ),
      {
        id: SENSE_PASSIVE_PERCEPTION,
        title: `${upLetter(passivePerceptionName)}\n\n${passivePerceptionDescription}`,
        text: `${passivePerceptionName} ${passivePerceptionValue}`,
      },
    ]

    return list.length
      ? <SenseListComponent list={list}/>
      : null
  }

  return null
}

SenseListContainer.propTypes = {
  genderId: PropTypes.string,
  senseList: PropTypes.array,
}

SenseListContainer.defaultProps = {
  genderId: GENDER_MALE,
  senseList: [],
}

export default SenseListContainer
