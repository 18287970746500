import generateTextLinks from '@/utils/generateTextLinks'

import { CREATURE_GIANT_LIZARD } from '@/constants/creatureIdList'
import { SOURCE_MM } from '@/constants/sourceList'

export default [
  {
    header: 'Вариант: Особенности гигантских ящериц',
    text: generateTextLinks(`У некоторых [гигантских ящериц](CREATURE:${CREATURE_GIANT_LIZARD}) есть одна или обе из следующих особенностей:

**Задержка дыхания.** Ящерица может задержать дыхание на 15 минут (ящерица с этой особенностью также обладает скоростью плавания 30 футов).

**Паучье лазание.** Ящерица может лазать по сложным поверхностям, включая потолки, без совершения проверок характеристик.`),
    source: {
      id: SOURCE_MM,
      page: 325,
    },
  },
]
