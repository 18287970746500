import { faShieldAlt } from '@fortawesome/free-solid-svg-icons'

import { gearCollection } from '@/constants/gearList'
import { CREATURE_WARHORSE } from '@/constants/creatureIdList'
import { SOURCE_MM } from '@/constants/sourceList'

import calcCreatureAc from '@/utils/calcCreatureAc'
import generateTextLinks from '@/utils/generateTextLinks'

import warhorseArmorTable from './warhorseArmorTable'

export default ({templateName, namePostfix, armorId, color}) => {
  const ac = calcCreatureAc({creatureId: CREATURE_WARHORSE, armorId})
  const {name: armorName} = gearCollection[armorId]

  return {
    templateName,
    templateIcon: faShieldAlt,
    templateColor: color,

    alreadyHasWarHorseArmor: true,

    templateLimitations: {
      include: {
        id: [
          CREATURE_WARHORSE,
        ],
      },
      exclude: {
        alreadyHasWarHorseArmor: [true],
      },
    },

    armor: {
      ac,
      type: armorName.toLowerCase(),
    },

    extendPropCollection: {
      description: [
        {
          header: 'Вариант: Доспехи боевого коня',
          text: generateTextLinks(`У [боевого коня](CREATURE:${CREATURE_WARHORSE}) в доспехах КД зависит от того, какие именно доспехи на него надеты. В КД коня уже включён модификатор Ловкости, если он применим. Доспехи не меняют показатель опасности коня.

| КД | Доспех           |
|----|------------------|
${warhorseArmorTable}`),
          source: {
            id: SOURCE_MM,
            page: 320,
          },
        },
      ],
    },

    editPropCollection: {
      name: ({ name }) => `${name} ${namePostfix}`,
    },
  }
}
