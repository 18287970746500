import generateTextLinks from '@/utils/generateTextLinks'
import {CREATURE_SATYR} from '@/constants/creatureIdList'
import {faMusic} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Сатир с панфлейтой',
  templateIcon: faMusic,
  templateColor: 'blueViolet',

  templateLimitations: {
    include: {
      id: [
        CREATURE_SATYR,
      ],
    },
  },
  extendPropCollection: {
    description: [
      {
        header: 'Вариант: Панфлейта сатира',
        text: generateTextLinks(`Сатир может носить панфлейту, на которой он может играть, чтобы создать магические воздействия. Обычно в группе один сатир носит такую флейту. Если у сатира есть такая, он получает следующее дополнительное действие:

**Панфлейта.** Сатир играет на панфлейте и выбирает один из следующих магических эффектов: нежная колыбельная, пугающий аккорд или чарующая мелодия. Все существа в пределах 60 футов от него, слышащие флейту, должны пройти испытание Мудрости СЛ 13, иначе они попадают под действие описанного ниже эффекта. Другие сатиры и существа, которых нельзя очаровать, не попадают под действие эффекта.

Попавшее под эффект существо может повторять испытание в конце каждого своего хода, оканчивая на себе эффект при успехе. Если существо прошло испытание, или эффект закончился для него, то оно получает иммунитет к этой панфлейте на 24 часа.

_Нежная колыбельная._ Существо засыпает и лишается сознания на 1 минуту. Эффект заканчивается, если существо получает урон, или если кто-то действием разбудит его.

_Пугающий аккорд._ Существо испугано в течение 1 минуты.

_Чарующая мелодия._ Существо становится очарованным сатиром на 1 минуту. Если сатир или кто-то из его спутников причиняет вред существу, эффект на нём немедленно заканчивается.`),
        source: {
          id: SOURCE_MM,
          page: 256,
        },
      },
    ],
    actionList: [
      {
        name: 'Панфлейта',
        description: `Сатир играет на панфлейте и выбирает один из следующих магических эффектов: нежная колыбельная, пугающий аккорд или чарующая мелодия. Все существа в пределах 60 футов от него, слышащие флейту, должны пройти испытание Мудрости СЛ 13, иначе они попадают под действие описанного ниже эффекта. Другие сатиры и существа, которых нельзя очаровать, не попадают под действие эффекта.

Попавшее под эффект существо может повторять испытание в конце каждого своего хода, оканчивая на себе эффект при успехе. Если существо прошло испытание, или эффект закончился для него, то оно получает иммунитет к этой панфлейте на 24 часа.

* _Нежная колыбельная._ Существо засыпает и лишается сознания на 1 минуту. Эффект заканчивается, если существо получает урон, или если кто-то действием разбудит его.

* _Пугающий аккорд._ Существо испугано в течение 1 минуты.

* _Чарующая мелодия._ Существо становится очарованным сатиром на 1 минуту. Если сатир или кто-то из его спутников причиняет вред существу, эффект на нём немедленно заканчивается.`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace('Сатир', 'Сатир c панфлейтой'),
  },
}
