const {
  CREATURE_EAGLE,
  CREATURE_FROG,
  CREATURE_GALEB_DUHR,
  CREATURE_GARGOYLE,
  CREATURE_GAS_SPORE,
  CREATURE_GELATINOUS_CUBE,
  CREATURE_GHAST,
  CREATURE_GHOST,
  CREATURE_GHOUL,
  CREATURE_GIANT_BADGER,
  CREATURE_GIANT_BAT,
  CREATURE_GIANT_CENTIPEDE,
  CREATURE_GIANT_EAGLE,
  CREATURE_GIANT_FIRE_BEETLE,
  CREATURE_GIANT_FROG,
  CREATURE_GIANT_OCTOPUS,
  CREATURE_GIANT_OWL,
  CREATURE_GIANT_POISONOUS_SNAKE,
  CREATURE_GIANT_RAT,
  CREATURE_GIANT_SCORPION,
  CREATURE_GIANT_SHARK,
  CREATURE_GIANT_SPIDER,
  CREATURE_GIANT_TOAD,
  CREATURE_GIANT_VULTURE,
  CREATURE_GIBBERING_MOUTHER,
  CREATURE_GITHYANKI_KNIGHT,
  CREATURE_GITHYANKI_WARRIOR,
  CREATURE_GITHZERAI_MONK,
  CREATURE_GITHZERAI_ZERTH,
  CREATURE_GLABREZU,
  CREATURE_GNOLL,
  CREATURE_GNOLL_FANG_OF_YEENOGHU,
  CREATURE_GNOLL_PACK_LORD,
  CREATURE_GNOME_DEEP,
  CREATURE_GOBLIN,
  CREATURE_GOBLIN_BOSS,
  CREATURE_GORGON,
  CREATURE_GORISTRO,
  CREATURE_GRAY_OOZE,
  CREATURE_GREEN_HAG,
  CREATURE_GRELL,
  CREATURE_GRICK,
  CREATURE_GRICK_ALPHA,
  CREATURE_GRIFFON,
  CREATURE_GRIMLOCK,
  CREATURE_GUARDIAN_NAGA,
  CREATURE_GYNOSPHINX,
  CREATURE_OCTOPUS,
  CREATURE_OWL,
  CREATURE_POISONOUS_SNAKE,
  CREATURE_RAT,
  CREATURE_TOAD,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_MTG_AFR,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  GithyankiImgList,
  GithzeraiImgList,
  GnollImgList,
  GoblinsImgList,
} = require('./../../../commonImageListCollection')

const {
  Grick_MM_data,
  Hag_lair_VGTM_data,
  Orc_on_giant_bat_VGTM_data,
} = require('./../../../commonImageCollection')

const eagle = require('./../../../../../images/creatures/mm/eagle.jpeg')
const frog = require('./../../../../../images/creatures/mm/frog.jpeg')
const galeb_duhr = require('./../../../../../images/creatures/mm/galeb_duhr.jpg')
const gargoyle = require('./../../../../../images/creatures/mm/gargoyle.jpg')
const gas_spore = require('./../../../../../images/creatures/mm/gas_spore.jpg')
const gelatinous_cube = require('./../../../../../images/creatures/mm/gelatinous_cube.jpeg')
const ghast = require('./../../../../../images/creatures/mm/ghast.jpg')
const ghost = require('./../../../../../images/creatures/mm/ghost.jpeg')
const ghoul = require('./../../../../../images/creatures/mm/ghoul.png')
const giant_fire_bittle = require('./../../../../../images/creatures/mm/giant_fire_bittle.jpeg')
const giant_spider = require('./../../../../../images/creatures/mm/giant_spider.jpg')
const gibbering_mouther = require('./../../../../../images/creatures/mm/gibbering_mouther.jpg')
const glabrezu = require('./../../../../../images/creatures/mm/glabrezu.jpg')
const gnome_deep = require('./../../../../../images/creatures/mm/gnome_deep.jpg')
const gorgon = require('./../../../../../images/creatures/mm/gorgon.jpg')
const goristro = require('./../../../../../images/creatures/mm/goristro.jpg')
const gray_ooze = require('./../../../../../images/creatures/mm/gray_ooze.jpeg')
const green_hag = require('./../../../../../images/creatures/mm/green_hag.jpg')
const grell = require('./../../../../../images/creatures/mm/grell.jpg')
const griffon = require('./../../../../../images/creatures/mm/griffon.jpg')
const grimlock = require('./../../../../../images/creatures/mm/grimlock.jpg')
const guardian_naga = require('./../../../../../images/creatures/mm/guardian_naga.jpeg')
const gynosphinx = require('./../../../../../images/creatures/mm/gynosphinx.jpeg')
const octopus = require('./../../../../../images/creatures/mm/octopus.jpeg')
const owl = require('./../../../../../images/creatures/mm/owl.jpeg')
const rat = require('./../../../../../images/creatures/mm/rat.jpg')

const Centipede_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Centipede.jpg')
const Galeb_Duhr_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Galeb_Duhr.jpg')
const Gargoyle_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Gargoyle.jpg')
const Gelatinous_Cube_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Gelatinous_Cube.jpg')
const Ghost_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Ghost.jpg')
const Ghoul_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Ghoul.jpg')
const Gorgon_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Gorgon.jpg')
const Grell_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Grell.jpg')
const Griffon_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Griffon.jpg')
const Grimlock_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Grimlock.jpg')
const Octopus_Giant_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Octopus_Giant.jpg')
const Scorpion_Giant_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Scorpion_Giant.jpg')
const Shark_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Shark.jpg')
const Snake_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Snake.jpg')
const Sphinx_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Sphinx.jpg')
const Toad_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Toad.jpg')

const Griffon_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/griffon.jpg')

const Giant_Vulture_SKT_img = require('./../../../../../images/creatures/skt/giant_vulture.jpg')

const Ghoul_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/ghoul-mtg_afr.jpg')
const Targ_Nar_Fang_Gnoll_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/targ-nar-fang-gnoll-mtg_afr.jpg')
const Ghast_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/ghast-mtg_afr.jpg')

const Guardian_naga_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/guardian_naga.jpg')
const Giant_badger_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/giant_badger_mtg_clb.jpg')

module.exports = {
  [CREATURE_GALEB_DUHR]: [
    galeb_duhr,
    {
      src: Galeb_Duhr_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_GARGOYLE]: [
    gargoyle,
    {
      src: Gargoyle_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_GAS_SPORE]: gas_spore,
  [CREATURE_GELATINOUS_CUBE]: [
    gelatinous_cube,
    {
      src: Gelatinous_Cube_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_GHAST]: [
    ghast,
    {
      src: Ghast_MTG_AFR_img,
      source: {
        id: SOURCE_MTG_AFR,
      },
    },
  ],
  [CREATURE_GHOST]: [
    ghost,
    {
      src: Ghost_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_GHOUL]: [
    ghoul,
    {
      src: Ghoul_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Ghoul_MTG_AFR_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_GIANT_BADGER]: {
    src: Giant_badger_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  [CREATURE_GIANT_BAT]: Orc_on_giant_bat_VGTM_data,
  [CREATURE_GIANT_CENTIPEDE]: {
    src: Centipede_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_GIANT_POISONOUS_SNAKE]: {
    src: Snake_ADND2,
    creatureId: CREATURE_POISONOUS_SNAKE,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_GIANT_EAGLE]: {
    src: eagle,
    creatureId: CREATURE_EAGLE,
  },
  [CREATURE_GIANT_FIRE_BEETLE]: giant_fire_bittle,
  [CREATURE_GIANT_FROG]: {
    src: frog,
    creatureId: CREATURE_FROG,
  },
  [CREATURE_GIANT_OCTOPUS]: [
    {
      src: octopus,
      text: 'Щупальце осьминога',
      creatureId: CREATURE_OCTOPUS,
    },
    {
      src: Octopus_Giant_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_GIANT_OWL]: {
    src: owl,
    creatureId: CREATURE_OWL,
  },
  [CREATURE_GIANT_RAT]: {
    src: rat,
    creatureId: CREATURE_RAT,
  },
  [CREATURE_GIANT_SCORPION]: [
    {
      src: Scorpion_Giant_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_GIANT_SHARK]: {
    src: Shark_ADND2,
    text: 'Акула',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_GIANT_SPIDER]: giant_spider,
  [CREATURE_GIANT_TOAD]: {
    src: Toad_ADND2,
    creatureId: CREATURE_TOAD,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_GIANT_VULTURE]: Giant_Vulture_SKT_img,
  [CREATURE_GIBBERING_MOUTHER]: gibbering_mouther,
  [CREATURE_GITHYANKI_KNIGHT]: GithyankiImgList,
  [CREATURE_GITHYANKI_WARRIOR]: GithyankiImgList,
  [CREATURE_GITHZERAI_MONK]: GithzeraiImgList,
  [CREATURE_GITHZERAI_ZERTH]: GithzeraiImgList,
  [CREATURE_GLABREZU]: glabrezu,
  [CREATURE_GNOLL]: GnollImgList,
  [CREATURE_GNOLL_FANG_OF_YEENOGHU]: [
    {
      src: Targ_Nar_Fang_Gnoll_MTG_AFR_img,
      text: 'Тарг Нар, клык Йеногу',
      source: {
        id: SOURCE_MTG_AFR,
      },
    },
    ...GnollImgList,
  ],
  [CREATURE_GNOLL_PACK_LORD]: GnollImgList,
  [CREATURE_GNOME_DEEP]: gnome_deep,
  [CREATURE_GOBLIN]: GoblinsImgList,
  [CREATURE_GOBLIN_BOSS]: GoblinsImgList,
  [CREATURE_GORGON]: [
    gorgon,
    {
      src: Gorgon_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_GORISTRO]: goristro,
  [CREATURE_GRAY_OOZE]: gray_ooze,
  [CREATURE_GREEN_HAG]: [
    green_hag,
    Hag_lair_VGTM_data,
  ],
  [CREATURE_GRELL]: [
    grell,
    {
      src: Grell_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_GRICK]: Grick_MM_data,
  [CREATURE_GRICK_ALPHA]: Grick_MM_data,
  [CREATURE_GRIFFON]: [
    griffon,
    {
      src: Griffon_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Griffon_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_GRIMLOCK]: [
    grimlock,
    {
      src: Grimlock_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_GUARDIAN_NAGA]: [
    guardian_naga,
    {
      src: Guardian_naga_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_GYNOSPHINX]: [
    gynosphinx,
    {
      src: Sphinx_ADND2,
      text: 'Сфинкс',
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
}
