import React from 'react'

import StatInfoRow from '@/components/StatInfoRow'
import RenderList from '@/components/RenderList'

import './SenseListStyles.less'

export default ({ list }) => (
  <StatInfoRow header='Чувства'>
    <RenderList
      list={list}
      renderer={({ id, title, text }) => (
        <span
          key={id}
          title={title}
          className='SenseList_item'
        >
          {text}
        </span>
      )}
    />
  </StatInfoRow>
)
