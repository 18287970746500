const listToCollectionById = require('./../utils/listToCollectionById')

const MASS_UNIT_TON = 'ton'

const massUnitList = [
  {
    id: MASS_UNIT_TON,
    name: {
      singular: 'тонна',
      dual: 'тонны',
      plural: 'тонн',
    },
  },
]

module.exports = massUnitList

module.exports.massUnitCollection = listToCollectionById(massUnitList)

module.exports.MASS_UNIT_TON = MASS_UNIT_TON
