const {
  SOURCE_ADND2_MM,
  SOURCE_DND3_5_DTBoD,
  SOURCE_MM,
} = require('./../../../sourceList')

const {
  Metallic_Dragons_ROT_data,
} = require('./../../commonImageCollection')

const {
  Council_of_Metallic_Dragons_FTD_data,
  Ancient_Dragons_Hoard_data,
} = require('./../../commonImageCollection')

const Silver_Dragon_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Silver_Dragon.jpg')
const Silver_Dragon_Breath_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/silver_dragon_breath.jpg')
const Silver_dragon_MM_img = require('./../../../../images/creatures/mm/silver_dragon.jpg')

module.exports = [
  {
    src: Silver_dragon_MM_img,
    text: 'Серебряный дракон',
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Silver_Dragon_Breath_DND3_5_DTBoD_img,
    text: 'Дыхание серебряного дракона замораживает мгновенно',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Silver_Dragon_ADND2_img,
    text: 'Серебряный дракон',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  Metallic_Dragons_ROT_data,
  Council_of_Metallic_Dragons_FTD_data,
  Ancient_Dragons_Hoard_data,
]
