import React from 'react'

import Spell from '@/components/Spell'
import SpellCatalog from '@/components/SpellCatalog'

import {spellCollection} from '@/constants/spellList'
import spellImageCollection from '@/constants/images/spellImageCollection'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Заклинания',
  description: 'Каталог всех заклинаний в Dungeons & Dragons',
  img: null,
}

const SpellPageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={spellCollection}
    ItemComponent={Spell}
    itemImageCollection={spellImageCollection}
    ListComponent={SpellCatalog}
    {...props}
  />
)

export default SpellPageTemplate
