const enchantWeapon = ({ gearId, enchantBonus, actionList, magicItemId = null }) => actionList.map(
  action => action.gearId === gearId
    ? {
      ...action,
      ...(magicItemId ? { magicItemId, name: null } : {}),
      attack: {
        ...action.attack,
        bonus: (action.attack.bonus || 0) + enchantBonus,
        hit: Array.isArray(action.attack.hit)
          ? action.attack.hit.map(
            hit => Array.isArray(hit)
              ? hit.map(
                hitItem => ({
                  ...hitItem,
                  diceBonus: (hitItem.diceBonus || 0) + enchantBonus,
                }),
              )
              : {
                ...hit,
                diceBonus: (hit.diceBonus || 0) + enchantBonus,
              },
          )
          : {
            ...action.attack.hit,
            diceBonus: (action.attack.hit.diceBonus || 0) + enchantBonus,
          },
      },
    }
    : action,
)

export default enchantWeapon
