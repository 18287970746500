const { gearCollection } = require('./../constants/gearList')

const checkIfGearHasProp = (weaponPropId) => (gearId) => {
  const gear = gearCollection[gearId]

  if (gear) {
    const { weaponPropList = [] } = gear

    return weaponPropList.some(
      weaponProp => weaponPropId === (
        typeof weaponProp === 'string'
          ? weaponProp
          : weaponProp.id
      )
    )
  }

  return false
}

module.exports = checkIfGearHasProp
