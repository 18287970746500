const {
  CREATURE_AERISI_KALINOTH,
  CREATURE_BLACK_EARTH_GUARD,
  CREATURE_BLACK_EARTH_PRIEST,
  CREATURE_BURROWSHARK,
  CREATURE_ETERNAL_FLAME_GUARDIAN,
  CREATURE_ETERNAL_FLAME_PRIEST,
  CREATURE_HOWLING_HATRED_PRIEST,
  CREATURE_MARLOS_URNRAYLE,
  CREATURE_RAZERBLAST,
  CREATURE_ELIZAR_DRYFLAGON,
  CREATURE_VANIFER,
} = require('./../../creatureIdList')

const Howling_hatred_priest_PotA_img = require('./../../../images/creatures/pota/howling_hatred_priest.jpeg')
const Aerisi_kalinoth_PotA_img = require('./../../../images/creatures/pota/aerisi_kalinoth.jpeg')
const Burrowshark_PotA_img = require('./../../../images/creatures/pota/burrowshark.jpeg')
const Black_earth_guard_PotA_img = require('./../../../images/creatures/pota/black_earth_guard.jpeg')
const Black_earth_priest_PotA_img = require('./../../../images/creatures/pota/black_earth_priest.jpeg')
const Marlos_urnrayle_PotA_img = require('./../../../images/creatures/pota/marlos_urnrayle.jpeg')
const Eternal_flame_priest_PotA_img = require('./../../../images/creatures/pota/eternal_flame_priest.jpeg')
const Razerblast_PotA_img = require('./../../../images/creatures/pota/razerblast.jpeg')
const Eternal_flame_guardian_PotA_img = require('./../../../images/creatures/pota/eternal_flame_guardian.jpeg')
const Elizar_dryflagon_PotA_img = require('./../../../images/creatures/pota/elizar_dryflagon.jpeg')
const Vanifer_PotA_img = require('./../../../images/creatures/pota/vanifer.jpeg')

const {
  Marlos_urnrayle_with_ironfang_PotA_data,
} = require('./../commonImageCollection')

module.exports = {
  [CREATURE_AERISI_KALINOTH]: Aerisi_kalinoth_PotA_img,
  [CREATURE_BLACK_EARTH_GUARD]: Black_earth_guard_PotA_img,
  [CREATURE_BLACK_EARTH_PRIEST]: Black_earth_priest_PotA_img,
  [CREATURE_BURROWSHARK]: Burrowshark_PotA_img,
  [CREATURE_ELIZAR_DRYFLAGON]: Elizar_dryflagon_PotA_img,
  [CREATURE_ETERNAL_FLAME_GUARDIAN]: Eternal_flame_guardian_PotA_img,
  [CREATURE_ETERNAL_FLAME_PRIEST]: Eternal_flame_priest_PotA_img,
  [CREATURE_HOWLING_HATRED_PRIEST]: Howling_hatred_priest_PotA_img,
  [CREATURE_RAZERBLAST]: Razerblast_PotA_img,
  [CREATURE_MARLOS_URNRAYLE]: [
    Marlos_urnrayle_PotA_img,
    Marlos_urnrayle_with_ironfang_PotA_data,
  ],
  [CREATURE_VANIFER]: Vanifer_PotA_img,
}
