import {skillCollection} from "@/constants/skillList"
import {
  PROF_DOUBLE,
  PROF_HALF,
  PROF_NONE,
  PROF_NORMAL,
} from "@/constants/proficiencyList"

import formatBonus from "@/utils/formatBonus"
import calcParamBonus from "@/utils/calcParamBonus"

const calculateSkillBonus = ({ value, id, profBonus, params, formatAsString = true }) => {
  const {paramId} = skillCollection[id]
  const paramBonus = calcParamBonus(params[paramId])
  const skillProfLvl = typeof value === 'object'
    ? value.value
    : value

  let bonus

  switch (skillProfLvl) {
    case PROF_DOUBLE: {
      bonus = 2 * profBonus + paramBonus
      break
    }
    case PROF_HALF: {
      bonus = Math.floor(0.5 * profBonus + paramBonus)
      break
    }
    case PROF_NORMAL: {
      bonus = profBonus + paramBonus
      break
    }
    default:
    case PROF_NONE: {
      bonus = paramBonus
    }
  }

  return formatAsString
    ? formatBonus(bonus)
    : bonus
}

export default calculateSkillBonus
