import checkIfGearHasProp from '@/utils/checkIfGearHasProp'
import {PARAM_STR, PARAM_DEX} from '@/constants/paramList'
import {WEAPON_FINESSE} from '@/constants/weaponPropList'
import {gearCollection} from '@/constants/gearList'
import {
  CAT_MELEE_WEAPON,
  CAT_RANGE_WEAPON,
} from '@/constants/gearCategoryList'

const checkIfFinesse = checkIfGearHasProp(WEAPON_FINESSE)

const pickParamToUse = (gearId, params) => {
  const gear = gearCollection[gearId]

  if (gear) {
    const { category = [] } = gear

    if (checkIfFinesse(gearId)) {
      return params[PARAM_STR] > params[PARAM_DEX]
        ? PARAM_STR
        : PARAM_DEX
    } else if (category.includes(CAT_MELEE_WEAPON)) {
      return PARAM_STR
    } else if (category.includes(CAT_RANGE_WEAPON)) {
      return PARAM_DEX
    }
  }

  return null
}

export default pickParamToUse
