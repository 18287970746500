import React from 'react'
import PropTypes from 'prop-types'

import RitualComponent from './RitualComponent'

const RitualContainer = ({ isRitual }) => isRitual
  ? <RitualComponent/>
  : null

RitualContainer.propTypes = {
  isRitual: PropTypes.bool,
}

RitualContainer.defaultProps = {
  isRitual: false,
}

export default RitualContainer
