const {
  CREATURE_STONE_GIANT,
  CREATURE_STONE_GIANT_DREAMWALKER,
} = require('./../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_SKT,
  SOURCE_MM,
  SOURCE_VGTM,
} = require('./../../../sourceList')

const Stone_Giant_MM_img = require('./../../../../images/creatures/mm/stone_giant.jpg')
const Giant_Stone_ADND2 = require('./../../../../images/creatures/adnd2_mm/Giant_Stone.jpg')
const Stone_Giant_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/stone_giant.jpg')
const Stone_Giant_rune_scribe_VGTM_img = require('./../../../../images/creatures/vgtm/stone_giant_rune_scribe.png')
const Stone_Giant_Dreamwalker_VGTM_img = require(`./../../../../images/creatures/vgtm/stone_giant_dreamwalker.jpeg`)
const Stone_Giant_Raid_SKT_img = require(`./../../../../images/creatures/skt/stone-giant-raid.jpg`)
const Tan_Kayalithica_SKT_img = require(`./../../../../images/creatures/skt/tan_kayalithica.jpg`)

module.exports = [
  {
    src: Stone_Giant_MM_img,
    creatureId: CREATURE_STONE_GIANT,
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Stone_Giant_AYAGMnC_img,
    creatureId: CREATURE_STONE_GIANT,
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Stone_Giant_rune_scribe_VGTM_img,
    text: 'Каменный великан, вырезающий руны в камне',
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: Stone_Giant_Dreamwalker_VGTM_img,
    creatureId: CREATURE_STONE_GIANT_DREAMWALKER,
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: Stone_Giant_Raid_SKT_img,
    text: 'Каменные великаны изучают мир поверхности',
    source: {
      id: SOURCE_SKT,
    },
  },
  {
    src: Tan_Kayalithica_SKT_img,
    text: 'Каялитика, тан каменных великанов',
    source: {
      id: SOURCE_SKT,
    },
  },
  {
    src: Giant_Stone_ADND2,
    creatureId: CREATURE_STONE_GIANT,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
