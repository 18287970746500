import generateTextLinks from '@/utils/generateTextLinks'
import {CREATURE_PSEUDODRAGON} from '@/constants/creatureIdList'
import {faUserFriends} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Псевдодракон-фамильяр',
  templateIcon: faUserFriends,
  templateColor: 'indigo',

  templateLimitations: {
    include: {
      id: [
        CREATURE_PSEUDODRAGON,
      ],
    },
  },
  extendPropCollection: {
    description: [
      {
        header: `Вариант: Фамильяр псевдодракон`,
        text: generateTextLinks(`Некоторые [псевдодраконы](CREATURE:${CREATURE_PSEUDODRAGON}) желают служить заклинателям в качестве фамильяра. Такие псевдодраконы обладают следующей особенностью:

**Фамильяр.** Псевдодракон может служить другому существу фамильяром, создав магическую телепатическую связь с желающим того хозяином. Пока они связаны, хозяин может ощущать то же, что и псевдодракон, пока они находятся в пределах мили друг от друга. Пока псевдодракон находится в пределах 10 футов от хозяина, на хозяина распространяется особенность псевдодракона _Сопротивление магии_. В любое время и по любой причине псевдодракон может прекратить службу, оборвав телепатическую связь.`),
        source: {
          id: SOURCE_MM,
          page: 245,
        },
      },
    ],
    featureList: [
      {
        name: 'Фамильяр',
        description: `Псевдодракон может служить другому существу фамильяром, создав магическую телепатическую связь с желающим того хозяином. Пока они связаны, хозяин может ощущать то же, что и псевдодракон, пока они находятся в пределах мили друг от друга. Пока псевдодракон находится в пределах 10 футов от хозяина, на хозяина распространяется особенность псевдодракона _Сопротивление магии_. В любое время и по любой причине псевдодракон может прекратить службу, оборвав телепатическую связь.`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace('Псевдодракон', 'Псевдодракон-фамильяр'),
  },
}
