import {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
} from '@/constants/attackTypeList'
import {
  DAMAGE_BLUDGEONING,
  DAMAGE_THUNDER,
} from '@/constants/damageTypeList'
import {
  PARAM_CON,
  PARAM_STR,
} from '@/constants/paramList'
import {
  CONDITION_CHARMED,
  CONDITION_EXHAUSTION,
  CONDITION_FRIGHTENED,
  CONDITION_PARALYZED,
  CONDITION_PETRIFIED,
  CONDITION_POISONED,
} from '@/constants/conditionList'
import {ABILITY_FALSE_APPEARANCE} from '@/constants/creatureAbilityList'
import {CREATURE_EIDOLON} from '@/constants/creatureIdList'
import {CREATURE_TYPE_CONSTRUCT} from '@/constants/creatureTypeIdList'
import {faAndroid} from '@fortawesome/free-brands-svg-icons'
import {PROF_NONE} from '@/constants/proficiencyList'
import {SIZE_LARGE} from '@/constants/sizeList'
import {SKILL_PERCEPTION} from '@/constants/skillList'
import {SPEED_WALK} from '@/constants/speedList'
import {SPELL_DISPEL_EVIL_AND_GOOD} from '@/constants/spellIdList'

export default {
  name: 'Эйдолон в священной статуе',
  templateName: 'Вселиться в статую',
  templateIcon: faAndroid,
  templateColor: 'steelBlue',

  templateLimitations: {
    include: {
      id: [
        CREATURE_EIDOLON,
      ],
    },
  },

  sizeType: SIZE_LARGE,
  creatureTypeIdList: [
    CREATURE_TYPE_CONSTRUCT,
  ],
  armor: {
    ac: 19,
    type: `природный доспех`,
  },
  hp: {
    diceCount: 10,
    diceType: 10,
    diceBonus: 40,
  },
  speed: {
    [SPEED_WALK]: 25,
  },
  skillCollection: null,
  immunityConditionList: [
    CONDITION_CHARMED,
    CONDITION_EXHAUSTION,
    CONDITION_FRIGHTENED,
    CONDITION_PARALYZED,
    CONDITION_PETRIFIED,
    CONDITION_POISONED,
  ],
  featureList: [
    {
      id: ABILITY_FALSE_APPEARANCE,
      appearance: `обычной статуи`,
    },
    {
      name: 'Призрачный обитатель',
      description: `Эйдолон, вошедший в статую, остаётся в ней до того момента, пока статуя не опустится в 0 хитов. Бонусным действием эйдолон может покинуть статую, или эйдолон может быть силой изгнан из статуи эффектом, аналогичным эффекту заклинания [Рассеивание добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}). Когда эйдолон покидает статую, он появляется в пределах 5 фт. от неё в незанятом пространстве.`,
    },
    {
      name: 'Неактивный',
      description: `Когда в статуе нет эйдолона, она считается объектом.`,
    },
  ],
  actionList: [
    {
      name: 'Мультиатака',
      description: `Статуя совершает две атаки _Ударом_.`,
    },
    {
      name: 'Удар',
      attack: {
        type: ATTACK_MELEE_WEAPON,
        bonus: 8,
        range: 10,
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 6,
          diceType: 12,
          diceBonus: 4,
        },
      },
    },
    {
      name: 'Камень',
      attack: {
        type: ATTACK_RANGE_WEAPON,
        bonus: 8,
        range: {
          normal: 60,
          max: 240,
        },
        hit: {
          type: DAMAGE_BLUDGEONING,
          diceCount: 6,
          diceType: 10,
          diceBonus: 4,
        },
      },
    },
  ],

  extendPropCollection: {
    params: {
      [PARAM_STR]: 19,
      [PARAM_CON]: 19,
    },
  },
  editPropCollection: {
    resistanceList: ({ resistanceList }) => resistanceList.filter(
      damageId => damageId !== DAMAGE_THUNDER
    ),
    skillCollection: ({ skillCollection }) => ({
      ...skillCollection,
      [SKILL_PERCEPTION]: PROF_NONE,
    }),
  },

}
