const {
  CREATURE_AVATAR_OF_DEATH,
} = require('./../../creatureIdList')

const {
  SOURCE_DoMT,
} = require('./../../sourceList')

const Avatar_of_death_DOMT = require('./../../../images/creatures/domt/avatar_of_death.png')

module.exports = {
  [CREATURE_AVATAR_OF_DEATH]: {
    src: Avatar_of_death_DOMT,
    source: {
      id: SOURCE_DoMT,
      page: 2,
    },
  },
}
