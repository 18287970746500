import generateTextLinks from '@/utils/generateTextLinks'
import {ATTACK_MELEE_WEAPON} from '@/constants/attackTypeList'
import {CREATURE_BONE_DEVIL} from '@/constants/creatureIdList'
import {DAMAGE_PIERCING} from '@/constants/damageTypeList'
import {faCandyCane} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Костяной дьявол c копьём',
  templateIcon: faCandyCane,
  templateColor: 'steelBlue',

  templateLimitations: {
    include: {
      id: [
        CREATURE_BONE_DEVIL,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 148,
    }],
    description: [
      {
        header: 'Вариант: Копья костяных дьяволов',
        text: generateTextLinks(`
Некоторые [костяные дьяволы](CREATURE:${CREATURE_BONE_DEVIL}) обладают следующими вариантами действий:

**Мультиатака.** Дьявол совершает две атаки: одну гвизармой, и одну жалом.

**Гвизарма.** Рукопашная атака оружием: +8 к попаданию, досягаемость 10 фт., одна цель. Попадание: Колющий урон 17 (2к12 + 4). Если цель — существо с размером не больше Огромного, оно становится схваченным (СЛ высвобождения 14). Пока цель схвачена, дьявол не может использовать свою гвизарму против другой цели.`),
        source: {
          id: SOURCE_MM,
          page: 148,
        },
      },
    ],
    actionList: [
      {
        name: 'Гвизарма',
        description: `Если цель — существо с размером не больше Огромного, оно становится схваченным (СЛ высвобождения 14). Пока цель схвачена, дьявол не может использовать свою гвизарму против другой цели.`,
        attack: {
          type: ATTACK_MELEE_WEAPON,
          bonus: 8,
          range: 10,
          hit: {
            type: DAMAGE_PIERCING,
            diceCount: 2,
            diceType: 12,
            diceBonus: 4,
          },
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => name.replace('дьявол', 'дьявол c копьём'),
    actionList: ({ actionList }) => actionList.map(
      action => action.name === 'Мультиатака'
        ? {
          ...action,
          description: `Дьявол совершает три атаки: две когтями, и одну жалом. Он может совершить одну атаку гвизармой вместо двух атак когтями`,
        }
        : action,
    ),
  },
}
