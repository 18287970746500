import {
  CREATURE_DRAGON_BLACK_ADULT,
  CREATURE_DRAGON_BLUE_ADULT,
  CREATURE_DRAGON_BRASS_ADULT,
  CREATURE_DRAGON_BRONZE_ADULT,
  CREATURE_DRAGON_COPPER_ADULT,
  CREATURE_DRAGON_GOLD_ADULT,
  CREATURE_DRAGON_GREEN_ADULT,
  CREATURE_DRAGON_RED_ADULT,
  CREATURE_DRAGON_SILVER_ADULT,
  CREATURE_DRAGON_WHITE_ADULT,
  CREATURE_DRAGON_BLACK_YOUNG,
  CREATURE_DRAGON_BLUE_YOUNG,
  CREATURE_DRAGON_BRASS_YOUNG,
  CREATURE_DRAGON_BRONZE_YOUNG,
  CREATURE_DRAGON_COPPER_YOUNG,
  CREATURE_DRAGON_GOLD_YOUNG,
  CREATURE_DRAGON_GREEN_YOUNG,
  CREATURE_DRAGON_RED_YOUNG,
  CREATURE_DRAGON_SILVER_YOUNG,
  CREATURE_DRAGON_WHITE_YOUNG,
  CREATURE_DRAGON_BLACK_WYRMLING,
  CREATURE_DRAGON_BLUE_WYRMLING,
  CREATURE_DRAGON_BRASS_WYRMLING,
  CREATURE_DRAGON_BRONZE_WYRMLING,
  CREATURE_DRAGON_COPPER_WYRMLING,
  CREATURE_DRAGON_GOLD_WYRMLING,
  CREATURE_DRAGON_GREEN_WYRMLING,
  CREATURE_DRAGON_RED_WYRMLING,
  CREATURE_DRAGON_SILVER_WYRMLING,
  CREATURE_DRAGON_WHITE_WYRMLING,
} from '@/constants/creatureIdList'
import {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_POISON,
} from '@/constants/damageTypeList'
import {
  SIZE_GARGANTUA,
  SIZE_HUGE,
  SIZE_LARGE,
} from '@/constants/sizeList'

const halfDragonTemplateList = [
  {
    templateName: 'Красный полудракон',
    resistanceId: DAMAGE_FIRE,
    color: 'red',
    breathWeapon: {
      [SIZE_GARGANTUA]: CREATURE_DRAGON_RED_ADULT,
      [SIZE_HUGE]: CREATURE_DRAGON_RED_YOUNG,
      [SIZE_LARGE]: CREATURE_DRAGON_RED_WYRMLING,
    },
  },
  {
    templateName: 'Синий полудракон',
    resistanceId: DAMAGE_ELECTRICITY,
    color: 'blue',
    breathWeapon: {
      [SIZE_GARGANTUA]: CREATURE_DRAGON_BLUE_ADULT,
      [SIZE_HUGE]: CREATURE_DRAGON_BLUE_YOUNG,
      [SIZE_LARGE]: CREATURE_DRAGON_BLUE_WYRMLING,
    },
  },
  {
    templateName: 'Зелёный полудракон',
    resistanceId: DAMAGE_POISON,
    color: 'green',
    breathWeapon: {
      [SIZE_GARGANTUA]: CREATURE_DRAGON_GREEN_ADULT,
      [SIZE_HUGE]: CREATURE_DRAGON_GREEN_YOUNG,
      [SIZE_LARGE]: CREATURE_DRAGON_GREEN_WYRMLING,
    },
  },
  {
    templateName: 'Белый полудракон',
    resistanceId: DAMAGE_COLD,
    color: 'gray',
    breathWeapon: {
      [SIZE_GARGANTUA]: CREATURE_DRAGON_WHITE_ADULT,
      [SIZE_HUGE]: CREATURE_DRAGON_WHITE_YOUNG,
      [SIZE_LARGE]: CREATURE_DRAGON_WHITE_WYRMLING,
    },
  },
  {
    templateName: 'Чёрный полудракон',
    resistanceId: DAMAGE_ACID,
    color: 'black',
    breathWeapon: {
      [SIZE_GARGANTUA]: CREATURE_DRAGON_BLACK_ADULT,
      [SIZE_HUGE]: CREATURE_DRAGON_BLACK_YOUNG,
      [SIZE_LARGE]: CREATURE_DRAGON_BLACK_WYRMLING,
    },
  },
  {
    templateName: 'Золотой полудракон',
    resistanceId: DAMAGE_FIRE,
    color: 'gold',
    breathWeapon: {
      [SIZE_GARGANTUA]: CREATURE_DRAGON_GOLD_ADULT,
      [SIZE_HUGE]: CREATURE_DRAGON_GOLD_YOUNG,
      [SIZE_LARGE]: CREATURE_DRAGON_GOLD_WYRMLING,
    },
  },
  {
    templateName: 'Серебряный полудракон',
    resistanceId: DAMAGE_COLD,
    color: 'silver',
    breathWeapon: {
      [SIZE_GARGANTUA]: CREATURE_DRAGON_SILVER_ADULT,
      [SIZE_HUGE]: CREATURE_DRAGON_SILVER_YOUNG,
      [SIZE_LARGE]: CREATURE_DRAGON_SILVER_WYRMLING,
    },
  },
  {
    templateName: 'Медный полудракон',
    resistanceId: DAMAGE_ACID,
    color: 'orange',
    breathWeapon: {
      [SIZE_GARGANTUA]: CREATURE_DRAGON_COPPER_ADULT,
      [SIZE_HUGE]: CREATURE_DRAGON_COPPER_YOUNG,
      [SIZE_LARGE]: CREATURE_DRAGON_COPPER_WYRMLING,
    },
  },
  {
    templateName: 'Латунный полудракон',
    resistanceId: DAMAGE_FIRE,
    color: 'darkorange',
    breathWeapon: {
      [SIZE_GARGANTUA]: CREATURE_DRAGON_BRASS_ADULT,
      [SIZE_HUGE]: CREATURE_DRAGON_BRASS_YOUNG,
      [SIZE_LARGE]: CREATURE_DRAGON_BRASS_WYRMLING,
    },
  },
  {
    templateName: 'Бронзовый полудракон',
    resistanceId: DAMAGE_ELECTRICITY,
    color: 'goldenrod',
    breathWeapon: {
      [SIZE_GARGANTUA]: CREATURE_DRAGON_BRONZE_ADULT,
      [SIZE_HUGE]: CREATURE_DRAGON_BRONZE_YOUNG,
      [SIZE_LARGE]: CREATURE_DRAGON_BRONZE_WYRMLING,
    },
  },
]

export default halfDragonTemplateList
