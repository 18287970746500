const listToCollectionById = require('./../../../utils/listToCollectionById')
const {
  SOURCE_MTG_AFR,
  SOURCE_MTG_AHBG,
  SOURCE_MTG_CLB,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_TCoE,
} = require('./../../sourceList')
const {
  SPELL_ARMS_OF_HADAR,
  SPELL_BANISHMENT,
  SPELL_BIGBY_S_HAND,
  SPELL_BLUR,
  SPELL_BURNING_HANDS,
  SPELL_CALL_LIGHTNING,
  SPELL_CHROMATIC_ORB,
  SPELL_CLOUDKILL,
  SPELL_CONE_OF_COLD,
  SPELL_CONTACT_OTHER_PLANE,
  SPELL_FIREBALL,
  SPELL_GATE,
  SPELL_GUARDIAN_OF_FAITH,
  SPELL_GUIDING_BOLT,
  SPELL_HYPNOTIC_PATTERN,
  SPELL_IMMOLATION,
  SPELL_INSECT_PLAGUE,
  SPELL_LIGHTNING_BOLT,
  SPELL_MAGIC_MISSILE,
  SPELL_METEOR_SWARM,
  SPELL_MODIFY_MEMORY,
  SPELL_MORDENKAINEN_S_FAITHFUL_HOUND,
  SPELL_MORDENKAINEN_S_SWORD,
  SPELL_PRISMATIC_SPRAY,
  SPELL_RAY_OF_ENFEEBLEMENT,
  SPELL_RAY_OF_FROST,
  SPELL_STONESKIN,
  SPELL_TASHA_S_HIDEOUS_LAUGHTER,
  SPELL_THUNDERWAVE,
  SPELL_WEB,
} = require('./../../spellIdList')

const Burning_hands_PHB_img = require('./../../../images/spells/phb/burning_hands.jpg')
const Chromatic_orb_PHB_img = require('./../../../images/spells/phb/chromatic_orb.jpg')
const Gate_PHB_img = require('./../../../images/spells/phb/gate.jpg')
const Guardian_of_faith_PHB_img = require('./../../../images/spells/phb/guardian_of_faith.jpg')
const Immolation_PHB_img = require('./../../../images/spells/phb/immolation.jpg')
const Insect_plague_PHB_img = require('./../../../images/spells/phb/insect_plague.jpg')
const Magic_missile_PHB_img = require('./../../../images/spells/phb/magic_missile.jpg')
const Magic_missile_TCoE_img = require('./../../../images/spells/tcoe/magic_missile.png')
const Mordenkainens_sword_PHB_img = require('./../../../images/spells/phb/mordenkainens_sword.jpg')
const Prismatic_spray_PHB_img = require('./../../../images/spells/phb/prismatic_spray.jpg')
const Web_PHB_img = require('./../../../images/spells/phb/web.jpg')

const Call_Lightning_SCAG_img = require('./../../../images/spells/scag/call_lightning.webp')

const Blur_MTG_CLB_img = require('./../../../images/spells/mtg_clb/blur-mtg_clb.jpg')
const Fireball_MTG_CLB_img = require('./../../../images/spells/mtg_clb/fireball-mtg_clb.jpg')

const Herald_Of_Hadar_MTG_AFR_img = require('./../../../images/spells/mtg_afr/herald-of-hadar-mtg_afr.jpg')
const Ray_of_enfeeblement_MTG_AFR_img = require('./../../../images/spells/mtg_afr/ray-of-enfeeblement-mtg_afr.jpg')
const Meteor_Swarm_MTG_AFR_img = require('./../../../images/spells/mtg_afr/meteor-swarm-mtg_afr.jpg')
const Ray_of_frost_MTG_AFR_img = require('./../../../images/spells/mtg_afr/ray-of-frost-mtg_afr.jpg')
const Mordenkainen_s_faithful_hound_MTG_AFR_img = require('./../../../images/spells/mtg_afr/mordenkainen_s_faithful_hound_mtg_afr.jpg')
const Tasha_s_hideous_laughter_MTG_AFR_img = require('./../../../images/spells/mtg_afr/tasha_s_hideous_laughter-mtg_afr.jpg')
const Contact_other_plane_MTG_CLB_img = require('./../../../images/spells/mtg_clb/contact_other_plane.jpg')
const Cone_of_cold_MTG_CLB_img = require('./../../../images/spells/mtg_clb/cone_of_cold.webp')
const Stoneskin_MTG_CLB_img = require('./../../../images/spells/mtg_clb/stoneskin.webp')
const Modify_memory_MTG_CLB_img = require('./../../../images/spells/mtg_clb/modify_memory.jpg')
const Guiding_bolt_MTG_CLB_img = require('./../../../images/spells/mtg_clb/guiding_bolt.jpg')
const Bigby_s_hand_MTG_CLB_img = require('./../../../images/spells/mtg_clb/bigby_s_hand.webp')
const Cloudkill_MTG_CLB_img = require('./../../../images/spells/mtg_clb/cloudkill.jpg')
const Arms_of_hadar_MTG_CLB_img = require('./../../../images/spells/mtg_clb/arms_of_hadar.jpg')
const Thunderwave_MTG_CLB_img = require('./../../../images/spells/mtg_clb/thunderwave_mtg_clb.webp')
const Lighting_bolt_MTG_CLB_img = require('./../../../images/spells/mtg_clb/lighting_bolt_mtg_clb.jpg')
const Lighting_bolt_2_MTG_CLB_img = require('./../../../images/spells/mtg_clb/lighting_bolt_2_mtg_clb.jpg')
const Banishment_MTG_CLB_img = require('./../../../images/spells/mtg_clb/banishment_mtg_clb.webp')
const Hypnotic_pattern_MTG_AHBG_img = require('./../../../images/spells/mtg_ahbg/hypnotic_pattern_mtg_ahbg.webp')

const spellImageList_PHB = [
  {
    id: SPELL_HYPNOTIC_PATTERN,
    src: Hypnotic_pattern_MTG_AHBG_img,
    source: SOURCE_MTG_AHBG,
  },
  {
    id: SPELL_BANISHMENT,
    src: Banishment_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_CLOUDKILL,
    src: Cloudkill_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_THUNDERWAVE,
    src: Thunderwave_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_BIGBY_S_HAND,
    src: Bigby_s_hand_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_GUIDING_BOLT,
    src: Guiding_bolt_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_MODIFY_MEMORY,
    src: Modify_memory_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_STONESKIN,
    src: Stoneskin_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_CONE_OF_COLD,
    src: Cone_of_cold_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_CONTACT_OTHER_PLANE,
    src: Contact_other_plane_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_BURNING_HANDS,
    src: Burning_hands_PHB_img,
  },
  {
    id: SPELL_CHROMATIC_ORB,
    src: Chromatic_orb_PHB_img,
  },
  {
    id: SPELL_GATE,
    src: Gate_PHB_img,
  },
  {
    id: SPELL_GUARDIAN_OF_FAITH,
    src: Guardian_of_faith_PHB_img,
  },
  {
    id: SPELL_IMMOLATION,
    src: Immolation_PHB_img,
  },
  {
    id: SPELL_INSECT_PLAGUE,
    src: Insect_plague_PHB_img,
  },
  {
    id: SPELL_LIGHTNING_BOLT,
    src: Lighting_bolt_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_LIGHTNING_BOLT,
    src: Lighting_bolt_2_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_MAGIC_MISSILE,
    src: Magic_missile_PHB_img,
  },
  {
    id: SPELL_MORDENKAINEN_S_SWORD,
    src: Mordenkainens_sword_PHB_img,
  },
  {
    id: SPELL_PRISMATIC_SPRAY,
    src: Prismatic_spray_PHB_img,
  },
  {
    id: SPELL_WEB,
    src: Web_PHB_img,
  },
  {
    id: SPELL_MAGIC_MISSILE,
    src: Magic_missile_TCoE_img,
    text: 'Фермер-чародей выпускает волшебные стрелы в виде куриц',
    source: SOURCE_TCoE,
  },
  {
    id: SPELL_FIREBALL,
    src: Fireball_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_CALL_LIGHTNING,
    src: Call_Lightning_SCAG_img,
    source: SOURCE_SCAG,
  },
  {
    id: SPELL_BLUR,
    src: Blur_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_ARMS_OF_HADAR,
    src: Arms_of_hadar_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: SPELL_ARMS_OF_HADAR,
    src: Herald_Of_Hadar_MTG_AFR_img,
    source: SOURCE_MTG_AFR,
  },
  {
    id: SPELL_RAY_OF_ENFEEBLEMENT,
    src: Ray_of_enfeeblement_MTG_AFR_img,
    source: SOURCE_MTG_AFR,
  },
  {
    id: SPELL_METEOR_SWARM,
    src: Meteor_Swarm_MTG_AFR_img,
    source: SOURCE_MTG_AFR,
  },
  {
    id: SPELL_RAY_OF_FROST,
    src: Ray_of_frost_MTG_AFR_img,
    source: SOURCE_MTG_AFR,
  },
  {
    id: SPELL_MORDENKAINEN_S_FAITHFUL_HOUND,
    src: Mordenkainen_s_faithful_hound_MTG_AFR_img,
    source: SOURCE_MTG_AFR,
  },
  {
    id: SPELL_TASHA_S_HIDEOUS_LAUGHTER,
    src: Tasha_s_hideous_laughter_MTG_AFR_img,
    source: SOURCE_MTG_AFR,
  },
]
  .map(
    spell => spell.source
      ? spell
      : {
        ...spell,
        source: {
          id: SOURCE_PHB,
        },
      },
  )

module.exports = listToCollectionById(spellImageList_PHB)
