import React from "react"
import PropTypes from 'prop-types'

import {skillCollection} from "@/constants/skillList"
import RenderList from '@/components/RenderList'
import calculateSkillBonus from "@/utils/calculateSkillBonus"

import SkillCollection from "./../SkillCollection";

import "./SkillStyles.less";

const SkillCollectionComponent = ({list, Wrapper, params, profBonus, cr}) => (
  <Wrapper header='Навыки'>
    <RenderList
      list={list}
      renderer={([id, value]) => {
        if (id === 'pickOne') {
          return (
            <>
              один из навыков: <SkillCollection
              cr={cr}
              params={params}
              skillCollection={value}
              useWrapper={false}
            />
            </>
          )
        }

        const {name, description} = skillCollection[id]
        const title = `${name}\n\n${description}`
        const bonus = calculateSkillBonus({ value, id, profBonus, params })

        const commentText = value.comment
          ? ` (${value.comment})`
          : ''

        return (
          <span
            className='Skill_item'
            key={id}
            title={title}
          >
            {name} {bonus}{commentText}
          </span>
        )
      }}
    />
  </Wrapper>
)

SkillCollectionComponent.propTypes = {
  list: PropTypes.array,
  Wrapper: PropTypes.func,
}

SkillCollectionComponent.defaultProps = {
  list: [],
  Wrapper: React.Fragment,
}

export default SkillCollectionComponent
