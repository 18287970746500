const formatSpellRange = range => {
  if (typeof range === 'number') {
    switch (range) {
      case -1:
        return 'На себя'

      case 0:
        return 'Касание'

      case Infinity:
        return 'Неограниченная'

      default:
        return `${range} футов`
    }
  }

  return range
}

export default formatSpellRange
