import {
  CREATURE_BANDIT_CAPTAIN,
  CREATURE_BERSERKER,
  CREATURE_CULTIST,
  CREATURE_CULT_FANATIC,
  CREATURE_BLACKGUARD,
  CREATURE_CONJURER,
  CREATURE_ILLUSIONIST,
  CREATURE_WARLOCK_OF_THE_GREAT_OLD_ONE,
} from '@/constants/creatureIdList'
import {SOURCE_AI} from '@/constants/sourceList'

export default {
  templateName: 'Образцовый прихвостень Шестерых',
  templateIcon: 'Six',
  templateColor: 'black',
  templateLimitations: {
    include: {
      id: [
        CREATURE_BANDIT_CAPTAIN,
        CREATURE_BERSERKER,
        CREATURE_CULTIST,
        CREATURE_CULT_FANATIC,
        CREATURE_BLACKGUARD,
        CREATURE_CONJURER,
        CREATURE_ILLUSIONIST,
        CREATURE_WARLOCK_OF_THE_GREAT_OLD_ONE,
      ],
    },
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_AI,
      page: 216,
    }],
    featureList: [
      {
        name: `Проблески Запределья`,
        limit: {
          count: 1,
          period: 'день',
        },
        description: `★СУЩЕСТВО★ действием раскрывает свою истинную суть, восторгаясь безумием Дальнего Предела. Все видящие и слышащие его существа в пределах 30 футов, должны пройти испытание Интеллекта СЛ 13, ослепнув при провале. Ослепшее существо может повторять испытание в конце каждого своего хода, прекращая эффект при успехе.`,
      },
      {
        name: `Касание безумия`,
        description: `★СУЩЕСТВО★ получает преимущество к испытаниям от очарования или испуга.`,
      },
    ],
    reactionList: [
      {
        name: `Что-то не так`,
        limit: {
          count: 1,
          period: 'день',
        },
        description: `Когда атакующий, которого видит ★СУЩЕСТВО★ в пределах 5 футов, атакует ★его★, ★он★ может реакцией испустить отвлекающий всплеск потусторонней энергии, создавая помеху к этой атаке.`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name}, прихвостень Шестерых`,
  },
}
