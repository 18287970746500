import arrify from 'arrify'
import calcHitBonusByParam from '@/utils/calcHitBonusByParam'
import formatAttackRangeText from '@/utils/formatAttackRangeText'
import generateBonusText from '@/utils/generateBonusText'
import generateHitText from '@/utils/generateHitText'
import generateTargetLimitText from '@/utils/generateTargetLimitText'
import pickWeaponPropFromGear from '@/utils/pickWeaponPropFromGear'
import {gearCollection} from '@/constants/gearList'
import {
  ATTACK_MELEE_WEAPON,
  ATTACK_RANGE_WEAPON,
  attackTypeCollection,
} from '@/constants/attackTypeList'
import {
  WEAPON_RANGE,
  WEAPON_VERSATILE,
} from '@/constants/weaponPropList'

import checkHasRange from './utils/checkHasRange'
import checkHasReach from './utils/checkHasReach'
import checkIfThrownable from './utils/checkIfThrownable'
import pickAtkTypeByGear from './utils/pickAtkTypeByGear'
import pickParamToUse from './utils/pickParamToUse'

const generateAttackDescription = (
  {
    attack,
    gearId = null,
    params = null,
    cr = null,
    isThrown = false,
  }
) => {
  if (attack || (gearId && params && cr)) {
    const {
      bonus,
      hit = [],
      hitOrMiss,
      miss,
      range,
      target = 1,
      type,
    } = attack || {
      bonus: 0,
      hit: [],
      hitOrMiss: null,
      miss: null,
      range: 5,
      target: 1,
      type: ATTACK_MELEE_WEAPON,
    }
    let weaponHitData = null
    let atkType = type
    let atkRange = range
    let bonusAtk = bonus

    if (gearId) {
      const gear = gearCollection[gearId]
      if (gear) {
        const paramToUse = pickParamToUse(gearId, params)
        const hitBonusCollection = calcHitBonusByParam(params, cr)[paramToUse]

        bonusAtk = hitBonusCollection.attackBonus
        atkType = isThrown
          ? ATTACK_RANGE_WEAPON
          : pickAtkTypeByGear(gearId)

        if (checkHasReach(gearId)) {
          atkRange = 10
        } else if (
          (
            checkHasRange(gearId)
            && checkIfThrownable(gearId)
            && isThrown
          ) || (
            checkHasRange(gearId)
            && !checkIfThrownable(gearId)
          )
        ) {
          const rangeData = pickWeaponPropFromGear(WEAPON_RANGE, gearId)
          atkRange = rangeData.value
        } else {
          atkRange = 5
        }

        const { damage, damageType } = gear
        if (damage && damageType) {
          const diceBonus = hitBonusCollection.dmgBonus
          const weaponHit = {
            type: damageType,
            ...damage,
            diceBonus,
          }

          const versatileData = pickWeaponPropFromGear(WEAPON_VERSATILE, gearId)
          if (versatileData && versatileData.value && !isThrown) {
            weaponHitData = [
              weaponHit,
              {
                type: damageType,
                ...versatileData.value,
                diceBonus,
                use2Hand: true,
              },
            ]
          } else {
            weaponHitData = weaponHit
          }
        }
      }
    }

    const typeText = `*${attackTypeCollection[atkType].name}*:`
    const hitList = weaponHitData
      ? [
        weaponHitData,
        ...arrify(hit),
      ]
      : hit

    const hitText = generateHitText(hitList)
    const missText = generateHitText(miss)
    const hitOrMissText = generateHitText(hitOrMiss)
    const bonusText = generateBonusText(bonusAtk)
    const targetText = generateTargetLimitText(target)
    const rangeText = formatAttackRangeText(atkType, atkRange)

    const missFullText = missText
      ? ` *Промах:* ${missText}.`
      : ''
    const hitOrMissFullText = hitOrMissText
      ? ` *Попадание или промах:* ${hitOrMissText}.`
      : ''

    return `
${typeText} ${bonusText}, ${rangeText}, ${targetText}.
*Попадание:* ${hitText}.${missFullText}${hitOrMissFullText}`
  }

  return ''
}

export default generateAttackDescription
