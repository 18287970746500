const {
  CREATURE_REMORHAZ,
  CREATURE_YETI,
  CREATURE_YOCHLOL,
  CREATURE_YOUNG_REMORHAZ,
  CREATURE_YUAN_TI_ABOMINATION,
  CREATURE_YUAN_TI_MALISON_1,
  CREATURE_YUAN_TI_MALISON_2,
  CREATURE_YUAN_TI_MALISON_3,
  CREATURE_YUAN_TI_PUREBLOOD,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_VGTM,
} = require('./../../../../sourceList')

const {
  Yuan_ti_mind_whisperer_VGTM_data,
  Yuan_ti_nightmare_speaker_VGTM_data,
  Yuan_ti_pit_master_VGTM_data,
  Yuan_ti_sacrifice_VGTM_data,
} = require('./../../../commonImageCollection')

const remorhaz = require('./../../../../../images/creatures/mm/remorhaz.jpeg')
const yeti = require('./../../../../../images/creatures/mm/yeti.jpg')
const yochlol = require('./../../../../../images/creatures/mm/yochlol.jpg')
const yuan_ti_abomination = require('./../../../../../images/creatures/mm/yuan_ti_abomination.jpeg')
const yuan_ti_pureblood = require('./../../../../../images/creatures/mm/yuan_ti_pureblood.jpeg')

const Remorhaz_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Remorhaz.jpg')
const Yeti_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Yeti.jpg')
const Yuan_ti_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Yuan_ti.jpg')

const Yuan_ti_abomination_VGTM_img = require('./../../../../../images/creatures/vgtm/yuan_ti_abomination.jpg')

module.exports = {
  [CREATURE_YETI]: [
    yeti,
    {
      src: Yeti_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_YOCHLOL]: yochlol,
  [CREATURE_YOUNG_REMORHAZ]: [
    {
      src: remorhaz,
      creatureId: CREATURE_REMORHAZ,
    },
    {
      src: Remorhaz_ADND2,
      creatureId: CREATURE_REMORHAZ,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_YUAN_TI_ABOMINATION]: [
    yuan_ti_abomination,
    {
      src: Yuan_ti_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Yuan_ti_abomination_VGTM_img,
      source: {
        id: SOURCE_VGTM,
      },
    },
    Yuan_ti_sacrifice_VGTM_data,
  ],
  [CREATURE_YUAN_TI_MALISON_1]: [
    Yuan_ti_mind_whisperer_VGTM_data,
    Yuan_ti_sacrifice_VGTM_data,
  ],
  [CREATURE_YUAN_TI_MALISON_2]: [
    Yuan_ti_pit_master_VGTM_data,
    Yuan_ti_sacrifice_VGTM_data,
  ],
  [CREATURE_YUAN_TI_MALISON_3]: [
    Yuan_ti_nightmare_speaker_VGTM_data,
    Yuan_ti_sacrifice_VGTM_data,
  ],
  [CREATURE_YUAN_TI_PUREBLOOD]: [
    yuan_ti_pureblood,
    Yuan_ti_sacrifice_VGTM_data,
  ],
}
