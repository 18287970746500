import React from "react"

import './StatInfoRowStyles.less'

export default ({header, children, className = ''}) => (
  <p className={`StatInfoRow ${className}`}>
    <b className='StatInfoRow_header'>
      {header}
    </b>
    {children}
  </p>
)
