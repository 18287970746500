import templateList from './_lists'

import E from './E'
import S from './S'

export default [
  ...templateList,
  ...E,
  ...S,
]
