import React from 'react'

import StatInfoRow from '@/components/StatInfoRow'

import generateCube from '@/utils/generateCube'
import generateHpDiceFormula from '@/utils/generateHpDiceFormula'

const HitPoints = ({ hp, sizeType, params, damageThreshold }) => {
  if (hp) {
    const { comment } = hp
    const hpDiceFormula = generateHpDiceFormula({ hp, sizeType, params })

    return (
      <StatInfoRow header="Хиты">
        {generateCube(hpDiceFormula)}
        {
          damageThreshold
            ? ` (порог урона ${damageThreshold})`
            : null
        }
        {
          comment
            ? `; ${comment}`
            : null
        }
      </StatInfoRow>
    )
  }

  return null
}

export default HitPoints
