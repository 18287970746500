const {
  CREATURE_KOBOLD,
  CREATURE_WATER_ELEMENTAL,
  CREATURE_WATER_WEIRD,
  CREATURE_WEREBEAR,
  CREATURE_WEREBOAR,
  CREATURE_WERERAT,
  CREATURE_WERETIGER,
  CREATURE_WEREWOLF,
  CREATURE_WIGHT,
  CREATURE_WILL_O_WISP,
  CREATURE_WINGED_KOBOLD,
  CREATURE_WINTER_WOLF,
  CREATURE_WOLF,
  CREATURE_WORG,
  CREATURE_WRAITH,
  CREATURE_WYVERN,
} = require('./../../../../creatureIdList')
const {
  SOURCE_DND3_5_PGTM,
  SOURCE_ADND2_MM,
  SOURCE_MTG_AFR,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  Blue_Dragon_Lightning_DND3_5_DTBoD_data,
} = require('./../../../commonImageCollection')

const kobold = require('./../../../../../images/creatures/mm/kobold.jpg')
const water_elemental = require('./../../../../../images/creatures/mm/water_elemental.jpg')
const water_weird = require('./../../../../../images/creatures/mm/water_weird.jpeg')
const werebear = require('./../../../../../images/creatures/mm/werebear.jpg')
const wereboar = require('./../../../../../images/creatures/mm/wereboar.jpg')
const wererat = require('./../../../../../images/creatures/mm/wererat.jpg')
const weretiger = require('./../../../../../images/creatures/mm/weretiger.jpg')
const werewolf = require('./../../../../../images/creatures/mm/werewolf.jpg')
const wight = require('./../../../../../images/creatures/mm/wight.jpeg')
const will_o_wisp = require('./../../../../../images/creatures/mm/will_o_wisp.jpg')
const winter_wolf = require('./../../../../../images/creatures/mm/winter_wolf.jpeg')
const worg = require('./../../../../../images/creatures/mm/worg.jpg')
const wraith = require('./../../../../../images/creatures/mm/wraith.jpeg')
const wyvern = require('./../../../../../images/creatures/mm/wyvern.jpg')

const werewolfTransformation = require('./../../../../../images/creatures/dnd3_5_pgtm/WerewolfTransformation.jpg')

const Elemental_Water_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Elemental_Water.jpg')
const Water_Weird_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Water_Weird.jpg')
const Werebear_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Werebear.jpg')
const Wereboar_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Wereboar.jpg')
const Wererat_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Wererat.jpg')
const Weretiger_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Weretiger.jpg')
const Werewolf_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Werewolf.jpg')
const Wight_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Wight.jpg')
const Will_o_wisp_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Will_o_wisp.jpg')
const Wolf_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Wolf.jpg')
const Wraith_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Wraith.jpg')
const Wyvern_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Wyvern.jpg')

const Werewolf_MTG_AFR = require('./../../../../../images/creatures/mtg_afr/werewolf-mtg_afr.jpg')
const Beckoning_will_o_wisp_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/beckoning-will-o-wisp_mtg_clb.webp')

module.exports = {
  [CREATURE_WATER_ELEMENTAL]: [
    water_elemental,
    {
      src: Elemental_Water_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_WATER_WEIRD]: [
    water_weird,
    {
      src: Water_Weird_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_WEREBEAR]: [
    werebear,
    {
      src: Werebear_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_WEREBOAR]: [
    wereboar,
    {
      src: Wereboar_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_WERERAT]: [
    wererat,
    {
      src: Wererat_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_WERETIGER]: [
    weretiger,
    {
      src: Weretiger_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_WEREWOLF]: [
    werewolf,
    {
      src: Werewolf_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: werewolfTransformation,
      source: {
        id: SOURCE_DND3_5_PGTM,
      },
    },
    {
      src: Werewolf_MTG_AFR,
      source: {
        id: SOURCE_MTG_AFR,
      },
    },
  ],
  [CREATURE_WIGHT]: [
    wight,
    {
      src: Wight_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_WILL_O_WISP]: [
    will_o_wisp,
    {
      src: Will_o_wisp_ADND2,
      source: SOURCE_ADND2_MM,
    },
    {
      src: Beckoning_will_o_wisp_MTG_CLB_img,
      text: 'Завлекающий огонёк',
      source: SOURCE_MTG_CLB,
    },
  ],
  [CREATURE_WOLF]: {
    src: Wolf_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_WINGED_KOBOLD]: {
    src: kobold,
    creatureId: CREATURE_KOBOLD,
  },
  [CREATURE_WINTER_WOLF]: winter_wolf,
  [CREATURE_WORG]: worg,
  [CREATURE_WRAITH]: [
    wraith,
    {
      src: Wraith_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    Blue_Dragon_Lightning_DND3_5_DTBoD_data,
  ],
  [CREATURE_WYVERN]: [
    wyvern,
    {
      src: Wyvern_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
}
