const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_FTD,
  SOURCE_MM,
  SOURCE_MTG_AFR,
} = require('./../../../sourceList')
const {
  Ancient_Dragons_Hoard_data,
} = require('./../../commonImageCollection')

const Black_Dragon_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Black_Dragon.jpg')
const Black_dragon_img_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/black_dragon.jpg')
const Black_Dragon_MM_img = require('./../../../../images/creatures/mm/black_dragon.jpg')
const Rhashaak_FTD_img = require('./../../../../images/creatures/ftd/rhashaak.jpg')
const Black_Dragon_FTD_img = require('./../../../../images/creatures/ftd/black_dragon.png')
const Black_Dragon_MTG_AFR_img = require('./../../../../images/creatures/mtg_afr/black-dragon-variant-mtg_afr.jpg')

module.exports = [
  {
    src: Black_Dragon_MM_img,
    text: 'Чёрный дракон',
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Black_dragon_img_AYAGMnC_img,
    text: 'Чёрный дракон',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Rhashaak_FTD_img,
    text: 'В сердце джунглей великий чёрный вирм Рашаак присматривает за разрушенным городом',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Black_Dragon_FTD_img,
    text: 'Чёрный дракон выдыхает кислоту',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Black_Dragon_ADND2_img,
    text: 'Чёрный дракон',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  {
    src: Black_Dragon_MTG_AFR_img,
    text: 'Чёрный дракон',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  Ancient_Dragons_Hoard_data,
]
