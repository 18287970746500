import {
  ABILITY_AMPHIBIOUS,
  ABILITY_SPEAK_WITH_FROGS_AND_TOADS,
  ABILITY_STANDING_LEAP,
  ABILITY_SWAMP_CAMOUFLAGE,
} from '@/constants/creatureAbilityList'
import {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_BULLYWUG,
} from '@/constants/creatureTypeIdList'
import {
  PARAM_INT,
  PARAM_CHA,
} from '@/constants/paramList'
import {
  SPEED_WALK,
  SPEED_SWIM,
} from '@/constants/speedList'
import {faFrog} from '@fortawesome/free-solid-svg-icons'
import {LANG_BULLYWUG} from '@/constants/languageIdList'
import {SOURCE_DMG} from '@/constants/sourceList'

export default {
  templateName: 'Жаболюд',
  templateIcon: faFrog,
  templateColor: '#D0D14F',
  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_ANY_RACE,
      ],
    },
  },

  languageList: [
    LANG_BULLYWUG,
  ],

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 283,
    }],
    speed: {
      [SPEED_WALK]: 20,
      [SPEED_SWIM]: 40,
    },
    featureList: [
      ABILITY_AMPHIBIOUS,
      ABILITY_SPEAK_WITH_FROGS_AND_TOADS,
      {
        id: ABILITY_STANDING_LEAP,
        jumpLength: 20,
        jumpHeight: 10,
      },
      ABILITY_SWAMP_CAMOUFLAGE,
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Жаболюд ${name}`,
    creatureTypeIdList: ({ creatureTypeIdList }) => creatureTypeIdList.map(
      creatureTypeId => creatureTypeId === CREATURE_TYPE_ANY_RACE
        ? CREATURE_TYPE_BULLYWUG
        : creatureTypeId
    ),
    params: ({ params }) => ({
      ...params,
      [PARAM_INT]: params[PARAM_INT] - 2,
      [PARAM_CHA]: params[PARAM_CHA] - 2,
    }),
  },
}
