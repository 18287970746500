const {
  CREATURE_ANCIENT_DEEP_CROW,
  CREATURE_DEEP_CROW,
  CREATURE_KEG_ROBOT,
} = require('./../../creatureIdList')

const Keg_robot_img_AI = require('./../../../images/creatures/ai/keg_robot.png')
const Deep_crow_img_AI = require('./../../../images/creatures/ai/deep_crow.png')

module.exports = {
  [CREATURE_ANCIENT_DEEP_CROW]: Deep_crow_img_AI,
  [CREATURE_DEEP_CROW]: Deep_crow_img_AI,
  [CREATURE_KEG_ROBOT]: Keg_robot_img_AI,
}
