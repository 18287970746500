const {
  CREATURE_FAERIE_DRAGON_BLUE,
  CREATURE_FAERIE_DRAGON_GREEN,
  CREATURE_FAERIE_DRAGON_LIGHT_BLUE,
  CREATURE_FAERIE_DRAGON_ORANGE,
  CREATURE_FAERIE_DRAGON_RED,
  CREATURE_FAERIE_DRAGON_VIOLET,
  CREATURE_FAERIE_DRAGON_YELLOW,
  CREATURE_FIRE_ELEMENTAL,
  CREATURE_FIRE_GIANT,
  CREATURE_FIRE_SNAKE,
  CREATURE_FLAMESKULL,
  CREATURE_FLESH_GOLEM,
  CREATURE_FLUMPH,
  CREATURE_FLYING_SNAKE,
  CREATURE_FLYING_SWORD,
  CREATURE_FOMORIAN,
  CREATURE_FROG,
  CREATURE_FROST_GIANT,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_SKT,
} = require('./../../../../sourceList')

const {
  GiantFireImgList,
  GiantFrostImgList,
  FaerieDragonsList,
} = require('./../../../commonImageListCollection')

const {
  Orc_feywild_caretaker_MTG_CLB_data,
} = require('./../../../commonImageCollection')

const fire_elemental = require('./../../../../../images/creatures/mm/fire_elemental.jpeg')
const fire_snake = require('./../../../../../images/creatures/mm/fire_snake.jpeg')
const flameskull = require('./../../../../../images/creatures/mm/flameskull.png')
const flesh_golem = require('./../../../../../images/creatures/mm/flesh_golem.jpeg')
const flumph = require('./../../../../../images/creatures/mm/flumph.jpeg')
const flying_snake = require('./../../../../../images/creatures/mm/flying_snake.jpeg')
const flying_sword = require('./../../../../../images/creatures/mm/flying_sword.png')
const fomorian = require('./../../../../../images/creatures/mm/fomorian.jpeg')
const frog = require('./../../../../../images/creatures/mm/frog.jpeg')

const Elemental_Fire_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Elemental_Fire.jpg')
const Fomorian_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Fomorian.jpg')
const Frog_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Frog.jpg')
const Golem_Flesh_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Golem_Flesh.jpg')
const Snake_Winged_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Snake_Winged.jpg')

const Flumph_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/flumph.jpg')

const Zhentarim_Agent_with_Flying_Snake_SKT_img = require('./../../../../../images/creatures/skt/zhentarim_agent_with_flying_snake.png')

module.exports = {
  [CREATURE_FAERIE_DRAGON_BLUE]: [
    ...FaerieDragonsList,
    Orc_feywild_caretaker_MTG_CLB_data,
  ],
  [CREATURE_FAERIE_DRAGON_GREEN]: FaerieDragonsList,
  [CREATURE_FAERIE_DRAGON_LIGHT_BLUE]: FaerieDragonsList,
  [CREATURE_FAERIE_DRAGON_ORANGE]: FaerieDragonsList,
  [CREATURE_FAERIE_DRAGON_RED]: FaerieDragonsList,
  [CREATURE_FAERIE_DRAGON_VIOLET]: FaerieDragonsList,
  [CREATURE_FAERIE_DRAGON_YELLOW]: FaerieDragonsList,
  [CREATURE_FIRE_ELEMENTAL]: [
    fire_elemental,
    {
      src: Elemental_Fire_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_FIRE_GIANT]: GiantFireImgList,
  [CREATURE_FIRE_SNAKE]: fire_snake,
  [CREATURE_FLAMESKULL]: flameskull,
  [CREATURE_FLESH_GOLEM]: [
    flesh_golem,
    {
      src: Golem_Flesh_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_FLUMPH]: [
    flumph,
    {
      src: Flumph_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_FLYING_SNAKE]: [
    flying_snake,
    {
      src: Zhentarim_Agent_with_Flying_Snake_SKT_img,
      text: 'Агент Жентарима с ручной летающей змеёй',
      source: {
        id: SOURCE_SKT,
      },
    },
    {
      src: Snake_Winged_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_FLYING_SWORD]: flying_sword,
  [CREATURE_FOMORIAN]: [
    fomorian,
    {
      src: Fomorian_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_FROG]: [
    frog,
    {
      src: Frog_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_FROST_GIANT]: GiantFrostImgList,
}
