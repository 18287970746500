import {
  SPELL_BLUR,
  SPELL_INVISIBILITY,
  SPELL_MAGE_HAND,
  SPELL_MAGIC_WEAPON,
} from '@/constants/spellIdList'
import generateTextLinks from '@/utils/generateTextLinks'
import {CAST_NONE} from '@/constants/castComponentList'
import {CREATURE_THRI_KREEN} from '@/constants/creatureIdList'
import {faBrain} from '@fortawesome/free-solid-svg-icons'
import {LANG_TELEPATHY} from '@/constants/languageIdList'
import {PARAM_WIT} from '@/constants/paramList'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Три-кин с псионикой',
  templateIcon: faBrain,
  templateColor: 'indigo',
  templateLimitations: {
    include: {
      id: [
        CREATURE_THRI_KREEN,
      ],
    },
  },

  name: 'Три-кин с псионикой',
  spellCast: {
    comment: 'псионика',
    baseStat: PARAM_WIT,
    componentExclude: CAST_NONE,
    spellIdByCountList: [
      {
        limit: Infinity,
        list: [
          {
            id: SPELL_MAGE_HAND,
            comment: 'рука невидима',
          },
        ],
      },
      {
        limit: {
          count: 2,
          period: 'день',
        },
        list: [
          SPELL_MAGIC_WEAPON,
          SPELL_BLUR,
        ],
      },
      {
        limit: {
          count: 2,
          period: 'день',
        },
        list: [
          {
            id: SPELL_INVISIBILITY,
            comment: 'только на себя',
          },
        ],
      },
    ],
  },

  extendPropCollection: {
    description: [
      {
        header: `Вариант: Псионика три-кринов`,
        text: generateTextLinks(`У некоторых [три-кринов](CREATURE:${CREATURE_THRI_KREEN}) есть псионические способности, помогающие им охотиться и общаться с чужаками.

У псионического три-крина есть телепатия с радиусом 60 футов и следующая дополнительная особенность:

**Врождённое колдовство (псионика).** Базовой характеристикой три-крина является Мудрость. Три-крин может сотворять следующие заклинания, не нуждаясь ни в каких компонентах:

* Неограниченно: _Волшебная рука_ (Mage hand) (рука невидима)

* 2/день каждое: _Магическое оружие_ (Magic weapon), _Размытый образ_ (Blur)

* 1/день: _Невидимость_ (Invisibility) (только на себя)`),
        source: {
          id: SOURCE_MM,
          page: 284,
        },
      },
    ],
    languageList: [
      {
        id: LANG_TELEPATHY,
        range: 60,
      },
    ],
  },
}
