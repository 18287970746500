import React from 'react'
import PropTypes from 'prop-types'

import './HighLvlCastStyles.less'

const HighLvlCastComponent = ({ highLvlCast }) => highLvlCast
  ? (
    <section className="HighLvlCast">
      <header className="HighLvlCast_header">Усиление</header>
      <div className="HighLvlCast_description">
        {highLvlCast}
      </div>
    </section>
  )
  : null

HighLvlCastComponent.propTypes = {
  highLvlCast: PropTypes.string,
}

HighLvlCastComponent.defaultProps = {
  highLvlCast: '',
}

export default HighLvlCastComponent
