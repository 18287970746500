const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_DND3_5_DoF,
  SOURCE_FTD,
  SOURCE_MM,
  SOURCE_MTG_AFR,
} = require('./../../../sourceList')

const {
  Color_Dragons_Attack_ROT_data,
  Rime_s_Binding_Ice_FTD_data,
  White_Dragon_ROT_data,
  Ancient_Dragons_Hoard_data,
} = require('./../../commonImageCollection')

const Arveiaturace_DND3_5_DoF_img = require('./../../../../images/creatures/dnd3_5_dof/arveiaturace.jpg')
const White_Dragon_ADND2_img = require('./../../../../images/creatures/adnd2_mm/White_Dragon.jpg')
const White_Dragon_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/white_dragon.jpg')
const White_Dragon_MM_img = require('./../../../../images/creatures/mm/white_dragon.jpg')
const White_Dragon_on_a_Cliff_FTD_img = require('./../../../../images/creatures/ftd/white_dragon_on_a_cliff.png')

const Icingdeath_MTG_AFR_img = require('./../../../../images/creatures/mtg_afr/icingdeath-mtg_afr.jpg')

module.exports = [
  {
    src: White_Dragon_MM_img,
    text: 'Белый дракон',
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: White_Dragon_AYAGMnC_img,
    text: 'Белый дракон',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: White_Dragon_on_a_Cliff_FTD_img,
    text: 'Белый дракон на утёсе',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Arveiaturace_DND3_5_DoF_img,
    text: 'Арвейатьюрас и её таинственный наездник неустанно патрулируют свои владения',
    source: {
      id: SOURCE_DND3_5_DoF,
    },
  },
  White_Dragon_ROT_data,
  {
    src: White_Dragon_ADND2_img,
    text: 'Белый дракон',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  Rime_s_Binding_Ice_FTD_data,
  Color_Dragons_Attack_ROT_data,
  Ancient_Dragons_Hoard_data,
  {
    src: Icingdeath_MTG_AFR_img,
    text: 'Ингелоакастимизилиан, Ледяная Смерть',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
]
