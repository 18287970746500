import {
  CREATURE_DRAGON_BLACK_ANCIENT,
  CREATURE_DRAGON_BLUE_ANCIENT,
  CREATURE_DRAGON_BRASS_ANCIENT,
  CREATURE_DRAGON_BRONZE_ANCIENT,
  CREATURE_DRAGON_COPPER_ANCIENT,
  CREATURE_DRAGON_GOLD_ANCIENT,
  CREATURE_DRAGON_GREEN_ANCIENT,
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_DRAGON_SILVER_ANCIENT,
  CREATURE_DRAGON_WHITE_ANCIENT,
  CREATURE_ASTRAL_DREADNOUGHT,
  CREATURE_XVART,
  CREATURE_XVART_SPEAKER,
  CREATURE_XVART_WARLOCK_OF_RAXIVORT,
  CREATURE_GUARD_DRAKE,
  CREATURE_GUARD_DRAKE_BLACK,
  CREATURE_GUARD_DRAKE_BLUE,
  CREATURE_GUARD_DRAKE_GREEN,
  CREATURE_GUARD_DRAKE_RED,
  CREATURE_GUARD_DRAKE_WHITE,
} from '@/constants/creatureIdList'
import {
  CREATURE_TYPE_BEAST,
  CREATURE_TYPE_GIANT,
  CREATURE_TYPE_HUMANOID,
  CREATURE_TYPE_MONSTROSITY,
} from '@/constants/creatureTypeIdList'
import { dragonBreatheWeaponCollection } from '@/constants/creatureList'
import { faDragon } from '@fortawesome/free-solid-svg-icons'
import { LANG_DRACONIC } from '@/constants/languageIdList'
import { SENSE_BLIND_VISION, SENSE_DARK_VISION } from '@/constants/senseList'
import { SIZE_GARGANTUA, SIZE_HUGE, SIZE_LARGE, SIZE_MEDIUM, SIZE_SMALL, SIZE_TINY } from '@/constants/sizeList'
import { SOURCE_MM } from '@/constants/sourceList'

const halfDragonTemplateGenerator = ({ templateName, resistanceId, breathWeapon, color }) => ({
  templateName: templateName,
  templateIcon: faDragon,
  templateColor: color,

  isAlreadyHalfDragon: true,

  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_BEAST,
        CREATURE_TYPE_GIANT,
        CREATURE_TYPE_HUMANOID,
        CREATURE_TYPE_MONSTROSITY,
      ],
    },
    exclude: {
      isAlreadyHalfDragon: true,
      swarmMemberSizeType: [
        SIZE_GARGANTUA,
        SIZE_HUGE,
        SIZE_LARGE,
        SIZE_MEDIUM,
        SIZE_SMALL,
        SIZE_TINY,
      ],
      creatureId: [
        CREATURE_ASTRAL_DREADNOUGHT,
        CREATURE_XVART,
        CREATURE_XVART_SPEAKER,
        CREATURE_XVART_WARLOCK_OF_RAXIVORT,
        CREATURE_GUARD_DRAKE,
        CREATURE_GUARD_DRAKE_BLACK,
        CREATURE_GUARD_DRAKE_BLUE,
        CREATURE_GUARD_DRAKE_GREEN,
        CREATURE_GUARD_DRAKE_RED,
        CREATURE_GUARD_DRAKE_WHITE,
      ],
    },
  },
  description: [
    {
      header: 'Полудракон',
      text: `Если дракон в изменённом облике спаривается с другим существом, их союз иногда порождает полудракона. Существо может также трансформироваться в него в результате заклинания сумасшедшего волшебника, или ритуала омовения в драконьей крови. Во всех этих случаях результатом становится существо, объединяющее сущность дракона с обликом его изначальной расы. Каким бы ни было происхождение, все полудраконы имеют общие особенности и внешний вид, получают особые чувства, устойчивость к разрушительной энергии и смертоносное дыхание.

# Драконья натура

Полудраконы не способны давать потомство естественным путём. Те из них, что намерены размножаться, вынуждены прибегать к сторонним методам, почти всегда задействующим магию. Своего рода компенсацией является долгая жизнь полудраконов. Если не принимать во внимание несчастные случаи, типичный полудракон живёт примерно в два раза дольше обычных представителей недраконьей половины своего вида, то есть полудракон-получеловек может прожить более полутора сотен лет.

Полудраконы наследуют черты характера своих драконьих предков, так что золотые полудраконы зачастую скромны и скрытны, в то время как медные полудраконы проказливы и игривы. Зелёные полудраконы лживы, а белые являются тупыми громилами. Эти черты сдерживаются второй половиной природы полудракона, но жадность, высокомерие и паранойя зачастую свойственны даже добрым полудраконам.`,
      source: {
        id: SOURCE_MM,
        page: 241,
      },
    },
    {
      header: 'Шаблон полудракона',
      text: `Полудраконом могут стать великаны, гуманоиды, звери и монстры. Когда существо становится полудраконом, оно сохраняет всю свою статистику, кроме того, что описано ниже.

# Чувства

Полудракон получает слепое зрение в радиусе 10 футов и тёмное зрение в радиусе 60 футов.

# Сопротивление

Полудракон получает сопротивление к виду урона, определяемому его цветом.

| Цвет                                                                                                            | Вид урона     |
|-----------------------------------------------------------------------------------------------------------------|---------------|
| [Белый](CREATURE:${CREATURE_DRAGON_WHITE_ANCIENT}) или [серебряный](CREATURE:${CREATURE_DRAGON_SILVER_ANCIENT}) | Холод         |
| [Бронзовый](CREATURE:${CREATURE_DRAGON_BRONZE_ANCIENT}) или [синий](CREATURE:${CREATURE_DRAGON_BLUE_ANCIENT})   | Электричество |
| [Зелёный](CREATURE:${CREATURE_DRAGON_GREEN_ANCIENT})                                                            | Яд            |
| [Золотой](CREATURE:${CREATURE_DRAGON_GOLD_ANCIENT}), [красный](CREATURE:${CREATURE_DRAGON_RED_ANCIENT}) или [латунный](CREATURE:${CREATURE_DRAGON_BRASS_ANCIENT}) | Огонь |
| [Медный](CREATURE:${CREATURE_DRAGON_COPPER_ANCIENT}) или [чёрный](CREATURE:${CREATURE_DRAGON_BLACK_ANCIENT})    | Кислота       |

# Языки

Полудраконы говорят на [Драконьем](LANG:${LANG_DRACONIC}) вдобавок ко всем другим языкам, которыми владеют.

# Показатель опасности

Для того чтобы избежать пересчёта показателя опасности существа, применяйте этот шаблон только к существам, выполняющим опциональное требование в приведённой ниже таблице оружия дыхания. В противном случае после применения шаблона высчитайте новый показатель опасности, используя правила из Руководства Мастера.

# Новое действие: Оружие дыхания

Полудраконы располагают дыхательным оружием своей драконьей родни. То, как это оружие действует, зависит от размера полудракона.

| Размер             | Оружие дыхания          | Опциональное требование |
|--------------------|-------------------------|-------------------------|
| Большой или меньше | Как у вирмлинга         | Опасность 2 или выше    |
| Огромный           | Как у молодого дракона  | Опасность 7 или выше    |
| Громадный          | Как у взрослого дракона | Опасность 8 или выше    |
`,
      source: {
        id: SOURCE_MM,
        page: 241,
      },
    },
  ],
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 241,
    }],
    languageList: [LANG_DRACONIC],
    resistanceList: [resistanceId],
  },

  editPropCollection: {
    name: ({ name }) => `${templateName}-${name.toLowerCase()}`,
    senseList: ({ senseList = [] }) => {
      const oldDarkVision = senseList.find(({ id }) => id === SENSE_DARK_VISION)
      const oldBlindVision = senseList.find(({ id }) => id === SENSE_BLIND_VISION)

      const darkVisionValue = oldDarkVision
        ? Math.max(oldDarkVision.value, 60)
        : 60

      const blindVisionValue = oldBlindVision
        ? Math.max(oldBlindVision.value, 10)
        : 10

      const senseListFiltered = senseList.filter(
        ({ id }) => id !== SENSE_DARK_VISION && id !== SENSE_BLIND_VISION,
      )

      return [
        ...senseListFiltered,
        {
          id: SENSE_DARK_VISION,
          value: darkVisionValue,
        },
        {
          id: SENSE_BLIND_VISION,
          value: blindVisionValue,
        },
      ]
    },
    actionList: ({ actionList, sizeType }) => {
      let breathWeaponAction = null

      // TODO: handle CR
      switch (sizeType) {
        case SIZE_GARGANTUA:
        case SIZE_HUGE: {
          breathWeaponAction = dragonBreatheWeaponCollection[breathWeapon[sizeType]]
          break
        }

        case SIZE_LARGE:
        default: {
          breathWeaponAction = dragonBreatheWeaponCollection[breathWeapon[SIZE_LARGE]]
          break
        }
      }

      return breathWeaponAction
        ? [
          ...actionList,
          breathWeaponAction,
        ]
        : actionList
    },
  },
})

export default halfDragonTemplateGenerator
