const {
  SOURCE_MFFV_1_MMB,
} = require('./../../sourceList')
const {
  CREATURE_ASSASSIN_BUG,
  CREATURE_BLINDHEIM,
  CREATURE_CRAB_FOLK,
  CREATURE_DIRE_CORBY,
  CREATURE_EYE_OF_FEAR_AND_FLAME,
  CREATURE_FORLARREN,
  CREATURE_FOG_GIANT,
  CREATURE_JERMLAINE,
  CREATURE_KHARGRA,
  CREATURE_KILLMOULIS,
  CREATURE_MITE,
  CREATURE_NEEDLE_LORD,
  CREATURE_NEEDLE_SPAWN,
  CREATURE_NORKER,
  CREATURE_NORKER_WAR_LEADER,
  CREATURE_SCREAMING_DEVILKIN,
  CREATURE_XILL,
} = require('./../../creatureIdList')

const Assassin_bug_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/assassin_bug.jpeg')
const Blindheim_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/blindheim.jpeg')
const Crab_folk_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/crab_folk.jpeg')
const Dire_corby_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/dire_corby.jpeg')
const Eye_of_fear_and_flame_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/eye_of_fear_and_flame.jpeg')
const Forlarren_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/forlarren.jpeg')
const Fog_giant_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/fog_giant.jpeg')
const Jermlaine_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/jermlaine.jpeg')
const Khargra_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/khargra.jpeg')
const Killmoulis_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/killmoulis.jpeg')
const Mite_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/mite.jpeg')
const Needle_lord_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/needle_lord.jpeg')
const Norker_img_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/norker.jpeg')
const Screaming_devilkin_img_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/screaming_devilkin.jpeg')
const Xill_img_img_MFFV_1_MMB = require('./../../../images/creatures/mffv_1_mmb/xill.jpeg')

const Needle_img = {
  src: Needle_lord_img_MFFV_1_MMB,
  creatureId: CREATURE_NEEDLE_LORD,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 16,
  },
}

const Norker_img = {
  src: Norker_img_img_MFFV_1_MMB,
  creatureId: CREATURE_NORKER,
  source: {
    id: SOURCE_MFFV_1_MMB,
    page: 17,
  },
}

module.exports = {
  [CREATURE_ASSASSIN_BUG]: Assassin_bug_img_MFFV_1_MMB,
  [CREATURE_BLINDHEIM]: Blindheim_img_MFFV_1_MMB,
  [CREATURE_CRAB_FOLK]: Crab_folk_img_MFFV_1_MMB,
  [CREATURE_DIRE_CORBY]: Dire_corby_img_MFFV_1_MMB,
  [CREATURE_EYE_OF_FEAR_AND_FLAME]: Eye_of_fear_and_flame_img_MFFV_1_MMB,
  [CREATURE_FORLARREN]: Forlarren_img_MFFV_1_MMB,
  [CREATURE_FOG_GIANT]: Fog_giant_img_MFFV_1_MMB,
  [CREATURE_JERMLAINE]: Jermlaine_img_MFFV_1_MMB,
  [CREATURE_KHARGRA]: Khargra_img_MFFV_1_MMB,
  [CREATURE_KILLMOULIS]: Killmoulis_img_MFFV_1_MMB,
  [CREATURE_MITE]: Mite_img_MFFV_1_MMB,
  [CREATURE_NEEDLE_LORD]: Needle_img,
  [CREATURE_NEEDLE_SPAWN]: Needle_img,
  [CREATURE_NORKER]: Norker_img,
  [CREATURE_NORKER_WAR_LEADER]: Norker_img,
  [CREATURE_SCREAMING_DEVILKIN]: Screaming_devilkin_img_img_MFFV_1_MMB,
  [CREATURE_XILL]: Xill_img_img_MFFV_1_MMB,
}
