import React from 'react'
import PropTypes from 'prop-types'

import { CAST_MATERIAL, CAST_ROYALTY, castComponentCollection } from '@/constants/castComponentList'

import Price from '@/components/Price'

import upLetter from '@/utils/upLetter'

import CastConstituantComponent from './CastConstituantComponent'

const CastConstituantContainer = ({ componentIdList, materialText, royaltyCost }) => {
  if (componentIdList && componentIdList.length) {

    const componentList = componentIdList.map(
      id => {
        const {iconName, description, name: {singular: {nominative}}} = castComponentCollection[id]

        const componentName = upLetter(nominative)
        const text = id === CAST_MATERIAL
          ? `${componentName} (${materialText})`
          : id === CAST_ROYALTY
            ? <span>{componentName} (<Price price={royaltyCost}/>)</span>
            : componentName

        return {
          id,
          iconName,
          text,
          title: `${componentName}\n\n${description}`,
        }
      }
    )

    return (
      <CastConstituantComponent list={componentList}/>
    )
  }

  return null
}

CastConstituantContainer.propTypes = {
  componentIdList: PropTypes.array,
  materialText: PropTypes.string,
}

CastConstituantContainer.defaultProps = {
  componentIdList: [],
  materialText: '',
}

export default CastConstituantContainer
