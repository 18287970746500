const listToCollectionById = require('./../../../utils/listToCollectionById')
const {
  SOURCE_AI,
} = require('./../../sourceList')
const {
  SPELL_DISTORT_VALUE,
} = require('./../../spellIdList')

const Distort_value_AI_img = require('./../../../images/spells/ai/distort-value.jpg')

const spellImageList_AI = [
  {
    id: SPELL_DISTORT_VALUE,
    src: Distort_value_AI_img,
    source: {
      id: SOURCE_AI,
    },
  },
]

module.exports = listToCollectionById(spellImageList_AI)
