const { paramIdList } = require('./../constants/paramList')

const calcParamBonus = require('./calcParamBonus')
const calcProfBonusByCr = require('./calcProfBonusByCr')

const calcHitBonusByParam = (params, cr) => {
  const profBonus = calcProfBonusByCr(cr)

  return paramIdList.reduce(
    (obj, paramId) => {
      const paramBonus = calcParamBonus(params[paramId])

      return {
        ...obj,
        [paramId]: {
          attackBonus: paramBonus + profBonus,
          dmgBonus: paramBonus,
        },
      }
    },
    {}
  )
}

module.exports = calcHitBonusByParam
