import React from 'react'
import arrify from 'arrify'
import { Link } from 'gatsby'

import {languageCollection} from '@/constants/languageList'
import Span from '@/components/Span'

import generateLanguagePageUrlById from '@/utils/generateLanguagePageUrlById'

import './LanguageNameStyles.less'

const LanguageName = ({ langId, use5eOfficialName = false, count = 1, onlyCommands = false }) => {
  const lang = languageCollection[langId]
  const { isReady, description = '' } = lang
  const name = use5eOfficialName
    ? lang.name5eOfficial
    : lang.nameByCase
  const nameObj = typeof name === 'function'
    ? name(count || 1)
    : name
  const caseName = onlyCommands
    ? 'prepositional'
    : 'nominative'
  const text = nameObj[caseName]
  const descriptionMain = arrify(description)[0]
  const title = descriptionMain
    ? `${text}\n\n${descriptionMain.text || descriptionMain}`
    : null
  const Renderer = isReady
    ? Link
    : Span

  return (
    <Renderer
      className='LanguageName'
      title={title}
      to={generateLanguagePageUrlById(langId)}
    >
      {text}
    </Renderer>
  )
}

export default LanguageName
