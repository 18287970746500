const {
  CREATURE_XORN,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
} = require('./../../../../sourceList')

const xorn = require('./../../../../../images/creatures/mm/xorn.jpg')

const Xorn_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Xorn.jpg')

module.exports = {
  [CREATURE_XORN]: [
    xorn,
    {
      src: Xorn_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
}
