import arrify from 'arrify'

const checkInCollection = ({ creature, collection }) =>
  propName =>
    arrify(creature[propName])
      .some(
        id => {
          if (Array.isArray(collection[propName]) || typeof collection[propName] === 'string') {
            return collection[propName].includes(id)
          }

          if (typeof collection[propName] === 'boolean') {
            return collection[propName] === id
          }

          return false
        },
      )

export default checkInCollection
