const {
  SOURCE_BGDIA,
  SOURCE_MTG_AFR,
  SOURCE_MTG_AHBG,
  SOURCE_MTG_CLB,
} = require('./../../../sourceList')
const {
  Flaming_fist_shield_GAME_BG_3_data,
} = require('./../../commonImageCollection')

const Legion_loyalty_MTG_CLB_img = require('./../../../../images/creatures/mtg_clb/legion_loyalty_mtg_clb.jpg')
const Legion_loyalty_2_MTG_CLB_img = require('./../../../../images/creatures/mtg_clb/legion_loyalty_2_mtg_clb.webp')
const Flaming_fist_coat_of_arms_BGDIA_img = require('./../../../../images/creatures/bgdia/flaming_fist_coat_of_arms.webp')
const Flaming_fist_MTG_CLB_img = require('./../../../../images/creatures/mtg_clb/flaming_fist_mtg_clb.jpg')
const Flaming_fist_duskguard_MTG_AHBG_img = require('./../../../../images/creatures/mtg_ahbg/flaming_fist_duskguard_mtg_ahbg.webp')
const Recruitment_drive_MTG_CLB_img = require('./../../../../images/creatures/mtg_clb/recruitment_drive_mtg_clb.webp')
const Price_of_loyalty_MTG_AFR_img = require('./../../../../images/creatures/mtg_afr/price_of_loyalty_mtg_afr.webp')

module.exports = [
  {
    src: Flaming_fist_MTG_CLB_img,
    text: 'Пылающий кулак',
    source: SOURCE_MTG_CLB,
  },
  {
    src: Flaming_fist_duskguard_MTG_AHBG_img,
    text: 'Ночная патрульная Пылающего Кулака',
    source: SOURCE_MTG_AHBG,
  },
  {
    src: Recruitment_drive_MTG_CLB_img,
    text: 'Вербовочная кампания Пылающего Кулака',
    source: SOURCE_MTG_CLB,
  },
  {
    src: Price_of_loyalty_MTG_AFR_img,
    text: 'Пылающий кулак берёт взятку у гоблина',
    source: SOURCE_MTG_AFR,
  },
  {
    src: Legion_loyalty_MTG_CLB_img,
    text: 'Клятва Пылающих Кулаков',
    source: SOURCE_MTG_CLB,
  },
  {
    src: Legion_loyalty_2_MTG_CLB_img,
    text: 'Клятва Пылающих Кулаков',
    source: SOURCE_MTG_CLB,
  },
  {
    src: Flaming_fist_coat_of_arms_BGDIA_img,
    text: 'Герб Пылающего Кулака',
    source: SOURCE_BGDIA,
  },
  Flaming_fist_shield_GAME_BG_3_data,
]
