import {timePeriodCollection} from '@/constants/timePeriodList'

import upLetter from '@/utils/upLetter'

const timeListFormatter = timeId => {
  const text = timeId in timePeriodCollection
    ? timePeriodCollection[timeId].name.plural.nominative
    : timeId

  return {
    value: timeId,
    text: upLetter(text),
  }
}

export default timeListFormatter
