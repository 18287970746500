const {
    CREATURE_KRUTHIK_ADULT,
    CREATURE_OBLEX_ADULT,
    CREATURE_ELEMENTAL_MYRMIDON_AIR,
    CREATURE_ALKILITH,
    CREATURE_ALLIP,
    CREATURE_AMNIZU,
    CREATURE_ARMANITE,
    CREATURE_ASTRAL_DREADNOUGHT,
    CREATURE_ELADRIN_AUTUMN,
    CREATURE_BAEL,
    CREATURE_BALHANNOTH,
    CREATURE_BAPHOMET,
    CREATURE_BERBALANG,
    CREATURE_ABISHAI_BLACK,
    CREATURE_ABISHAI_BLUE,
    CREATURE_BONECLAW,
    CREATURE_BRONZE_SCOUT,
    CREATURE_BULEZAU,
    CREATURE_CADAVER_COLLECTOR,
    CREATURE_CANOLOTH,
    CREATURE_CHOKER,
    CREATURE_CORPSE_FLOWER,
    CREATURE_DEATHLOCK,
    CREATURE_DEATHLOCK_MASTERMIND,
    CREATURE_DEATHLOCK_WIGHT,
    CREATURE_DEMOGORGON,
    CREATURE_DERRO,
    CREATURE_DERRO_SAVANT,
    CREATURE_DHERGOLOTH,
    CREATURE_DIRE_TROLL,
    CREATURE_DROW_ARACHNOMANCER,
    CREATURE_DROW_MATRON_MOTHER,
    CREATURE_DUERGAR_KAVALRACHNI,
    CREATURE_DUERGAR_MIND_MASTER,
    CREATURE_DUERGAR_STONE_GUARD,
    CREATURE_DYBBUK,
    CREATURE_ELEMENTAL_MYRMIDON_EARTH,
    CREATURE_EIDOLON,
    CREATURE_OBLEX_ELDER,
    CREATURE_ELDER_TEMPEST,
    CREATURE_STEEDER_FEMALE,
    CREATURE_ELEMENTAL_MYRMIDON_FIRE,
    CREATURE_FRAZ_URBLUU,
    CREATURE_FROST_SALAMANDER,
    CREATURE_GERYON,
    CREATURE_GIFF,
    CREATURE_GITHYANKI_GISH,
    CREATURE_GITHYANKI_KITHRAK,
    CREATURE_GITHYANKI_SUPREME_COMMANDER,
    CREATURE_GITHZERAI_ANARCH,
    CREATURE_GITHZERAI_ENLIGHTENED,
    CREATURE_GLOOM_WEAVER,
    CREATURE_GRAY_RENDER,
    CREATURE_GRAZZT,
    CREATURE_ABISHAI_GREEN,
    CREATURE_HELLFIRE_ENGINE,
    CREATURE_HOWLER,
    CREATURE_HUTIJIN,
    CREATURE_HYDROLOTH,
    CREATURE_IRON_COBRA,
    CREATURE_JUIBLEX,
    CREATURE_KRUTHIK_HIVE_LORD,
    CREATURE_LEVIATHAN,
    CREATURE_STEEDER_MALE,
    CREATURE_MARUT,
    CREATURE_MAUREZHI,
    CREATURE_MEAZEL,
    CREATURE_MERREGON,
    CREATURE_MERRENOLOTH,
    CREATURE_MOLOCH,
    CREATURE_NABASSU,
    CREATURE_NAGPA,
    CREATURE_NARZUGON,
    CREATURE_NIGHTWALKER,
    CREATURE_NUPPERIBO,
    CREATURE_OAKEN_BOLTER,
    CREATURE_OBLEX_SPAWN,
    CREATURE_OGRE_BATTERING_RAM,
    CREATURE_OGRE_HOWDAH,
    CREATURE_OINOLOTH,
    CREATURE_ORCUS,
    CREATURE_ORTHON,
    CREATURE_PHOENIX,
    CREATURE_ABISHAI_RED,
    CREATURE_RETRIEVER,
    CREATURE_ROT_TROLL,
    CREATURE_RUTTERKIN,
    CREATURE_SHADOW_DANCER,
    CREATURE_SIBRIEX,
    CREATURE_SKULK,
    CREATURE_SKULL_LORD,
    CREATURE_SOUL_MONGER,
    CREATURE_SPIRIT_TROLL,
    CREATURE_ELADRIN_SPRING,
    CREATURE_STAR_SPAWN_LARVA_MAGE,
    CREATURE_STEEL_PREDATOR,
    CREATURE_STONE_CURSED,
    CREATURE_STONE_DEFENDER,
    CREATURE_ELADRIN_SUMMER,
    CREATURE_SWORD_WRAITH_COMMANDER,
    CREATURE_SWORD_WRAITH_WARRIOR,
    CREATURE_THE_ANGRY,
    CREATURE_THE_HUNGRY,
    CREATURE_THE_LONELY,
    CREATURE_THE_LOST,
    CREATURE_THE_WRETCHED,
    CREATURE_TITIVILUS,
    CREATURE_TORTLE,
    CREATURE_TORTLE_DRUID,
    CREATURE_VAMPIRIC_MIST,
    CREATURE_VENOM_TROLL,
    CREATURE_WASTRILITH,
    CREATURE_ELEMENTAL_MYRMIDON_WATER,
    CREATURE_ABISHAI_WHITE,
    CREATURE_ELADRIN_WINTER,
    CREATURE_YAGNOLOTH,
    CREATURE_YEENOGHU,
    CREATURE_KRUTHIK_YOUNG,
    CREATURE_ZARATAN,
    CREATURE_ZARIEL,
    CREATURE_ZUGGTMOY,
  } = require('./../../creatureIdList'),
  {
    SOURCE_ADND2_MM,
    SOURCE_ADND_PHB,
    SOURCE_AYAGMnC,
    SOURCE_BGDIA,
    SOURCE_MTG_CLB,
    SOURCE_OotA,
    SOURCE_TCoE,
  } = require('./../../sourceList')

const {
  GiffImgList,
} = require('./../commonImageListCollection')

const {
  Cover_MPMotM_data,
  Duergar_ADND2_data,
  Drow_Arachnomancer_MTOF_data,
  Drow_Matron_mother_MTOF_data,
  Githyanki_Gish_MTOF_data,
  Githyanki_Kithrak_MTOF_data,
  Githyanki_Supreme_Commander_MTOF_data,
  Githzerai_Anarch_MTOF_data,
  Githzerai_Enlightened_MTOF_data,
  Narzugon_MTOF_data,
  Tortle_TTP_data,
} = require('./../commonImageCollection')

const alkilith = require('./../../../images/creatures/mtof/alkilith.png')
const allip = require('./../../../images/creatures/mtof/allip.png')
const air_elemental_myrmidon = require('./../../../images/creatures/mtof/air_elemental_myrmidon.png')
const amnizu = require('./../../../images/creatures/mtof/amnizu.png')
const armanite = require('./../../../images/creatures/mtof/armanite.png')
const astral_dreadnought = require('./../../../images/creatures/mtof/astral_dreadnought.png')
const autumn_eladrin = require('./../../../images/creatures/mtof/autumn_eladrin.png')
const bael = require('./../../../images/creatures/mtof/bael.png')
const balhannoth = require('./../../../images/creatures/mtof/balhannoth.png')
const baphomet = require('./../../../images/creatures/mtof/baphomet.png')
const berbalang = require('./../../../images/creatures/mtof/berbalang.png')
const black_abishai = require('./../../../images/creatures/mtof/black_abishai.png')
const blue_abishai = require('./../../../images/creatures/mtof/blue_abishai.png')
const boneclaw = require('./../../../images/creatures/mtof/boneclaw.png')
const bronze_scout = require('./../../../images/creatures/mtof/bronze_scout.png')
const bulezau = require('./../../../images/creatures/mtof/bulezau.png')
const cadaver_collector = require('./../../../images/creatures/mtof/cadaver_collector.png')
const canoloth = require('./../../../images/creatures/mtof/canoloth.png')
const choker = require('./../../../images/creatures/mtof/choker.png')
const corpse_flower = require('./../../../images/creatures/mtof/corpse_flower.png')
const deathlock = require('./../../../images/creatures/mtof/deathlock.jpeg')
const deathlock_wight = require('./../../../images/creatures/mtof/deathlock_wight.png')
const demogorgon = require('./../../../images/creatures/mtof/demogorgon.png')
const derro = require('./../../../images/creatures/mtof/derro.png')
const derro_savant = require('./../../../images/creatures/mtof/derro_savant.png')
const dhergoloth = require('./../../../images/creatures/mtof/dhergoloth.png')
const dire_troll = require('./../../../images/creatures/mtof/dire_troll.png')
const duergar_mind_master = require('./../../../images/creatures/mtof/duergar_mind_master.png')
const duergar_stone_guard = require('./../../../images/creatures/mtof/duergar_stone_guard.png')
const dybbuk = require('./../../../images/creatures/mtof/dybbuk.png')
const earth_elemental_myrmidon = require('./../../../images/creatures/mtof/earth_elemental_myrmidon.png')
const elder_oblex = require('./../../../images/creatures/mtof/elder_oblex.png')
const elder_tempest = require('./../../../images/creatures/mtof/elder_tempest.png')
const female_steeder = require('./../../../images/creatures/mtof/female_steeder.png')
const fire_elemental_myrmidon = require('./../../../images/creatures/mtof/fire_elemental_myrmidon.png')
const fraz_urbluu = require('./../../../images/creatures/mtof/fraz_urbluu.png')
const frost_salamander = require('./../../../images/creatures/mtof/frost_salamander.png')
const geryon = require('./../../../images/creatures/mtof/geryon.png')
const gloom_weaver = require('./../../../images/creatures/mtof/gloom_weaver.png')
const gray_render = require('./../../../images/creatures/mtof/gray_render.png')
const grazzt = require('./../../../images/creatures/mtof/grazzt.png')
const Grazzt_tasha_TCoE = require('./../../../images/creatures/tcoe/grazzt_tasha.jpg')
const green_abishai = require('./../../../images/creatures/mtof/green_abishai.png')
const hellfire_engine = require('./../../../images/creatures/mtof/hellfire_engine.png')
const howler = require('./../../../images/creatures/mtof/howler.png')
const hutijin = require('./../../../images/creatures/mtof/hutijin.png')
const hydroloth = require('./../../../images/creatures/mtof/hydroloth.png')
const iron_cobra = require('./../../../images/creatures/mtof/iron_cobra.png')
const juiblex = require('./../../../images/creatures/mtof/juiblex.png')
const kruthik = require('./../../../images/creatures/mtof/kruthik.png')
const leviathan = require('./../../../images/creatures/mtof/leviathan.png')
const marut = require('./../../../images/creatures/mtof/marut.png')
const maurezhi = require('./../../../images/creatures/mtof/maurezhi.png')
const meazel = require('./../../../images/creatures/mtof/meazel.png')
const merregon = require('./../../../images/creatures/mtof/merregon.png')
const merrenoloth = require('./../../../images/creatures/mtof/merrenoloth.png')
const moloch = require('./../../../images/creatures/mtof/moloch.png')
const nabassu = require('./../../../images/creatures/mtof/nabassu.png')
const nagpa = require('./../../../images/creatures/mtof/nagpa.png')
const nightwalker = require('./../../../images/creatures/mtof/nightwalker.png')
const nupperibo = require('./../../../images/creatures/mtof/nupperibo.png')
const oaken_bolter = require('./../../../images/creatures/mtof/oaken_bolter.png')
const oblex_spawn = require('./../../../images/creatures/mtof/oblex_spawn.png')
const ogre_battering_ram = require('./../../../images/creatures/mtof/ogre_battering_ram.png')
const ogre_howdah = require('./../../../images/creatures/mtof/ogre_howdah.png')
const oinoloth = require('./../../../images/creatures/mtof/oinoloth.png')
const orcus = require('./../../../images/creatures/mtof/orcus.png')
const orthon = require('./../../../images/creatures/mtof/orthon.png')
const phoenix = require('./../../../images/creatures/mtof/phoenix.png')
const red_abishai = require('./../../../images/creatures/mtof/red_abishai.png')
const retriever = require('./../../../images/creatures/mtof/retriever.png')
const rot_troll = require('./../../../images/creatures/mtof/rot_troll.png')
const rutterkin = require('./../../../images/creatures/mtof/rutterkin.png')
const sacred_statue = require('./../../../images/creatures/mtof/sacred_statue.png')
const shadow_dancer = require('./../../../images/creatures/mtof/shadow_dancer.png')
const sibriex = require('./../../../images/creatures/mtof/sibriex.png')
const skulk = require('./../../../images/creatures/mtof/skulk.png')
const skull_lord = require('./../../../images/creatures/mtof/skull_lord.png')
const soul_monger = require('./../../../images/creatures/mtof/soul_monger.png')
const spirit_troll = require('./../../../images/creatures/mtof/spirit_troll.png')
const spring_eladrin = require('./../../../images/creatures/mtof/spring_eladrin.png')
const star_spawn_larva_mage = require('./../../../images/creatures/mtof/star_spawn_larva_mage.png')
const steel_predator = require('./../../../images/creatures/mtof/steel_predator.png')
const stone_cursed = require('./../../../images/creatures/mtof/stone_cursed.png')
const stone_defender = require('./../../../images/creatures/mtof/stone_defender.png')
const summer_eladrin = require('./../../../images/creatures/mtof/summer_eladrin.png')
const sword_wraith = require('./../../../images/creatures/mtof/sword_wraith.png')
const the_angry = require('./../../../images/creatures/mtof/the_angry.png')
const the_hungry = require('./../../../images/creatures/mtof/the_hungry.png')
const the_lonely = require('./../../../images/creatures/mtof/the_lonely.png')
const the_lost = require('./../../../images/creatures/mtof/the_lost.png')
const the_wretched = require('./../../../images/creatures/mtof/the_wretched.png')
const titivilus = require('./../../../images/creatures/mtof/titivilus.png')
const vampiric_mist = require('./../../../images/creatures/mtof/vampiric_mist.png')
const venom_troll = require('./../../../images/creatures/mtof/venom_troll.png')
const wastrilith = require('./../../../images/creatures/mtof/wastrilith.png')
const water_elemental_myrmidon = require('./../../../images/creatures/mtof/water_elemental_myrmidon.png')
const white_abishai = require('./../../../images/creatures/mtof/white_abishai.png')
const winter_eladrin = require('./../../../images/creatures/mtof/winter_eladrin.png')
const yagnoloth = require('./../../../images/creatures/mtof/yagnoloth.png')
const yeenoghu = require('./../../../images/creatures/mtof/yeenoghu.png')
const zaratan = require('./../../../images/creatures/mtof/zaratan.png')
const zariel = require('./../../../images/creatures/mtof/zariel.png')
const zuggtmoy = require('./../../../images/creatures/mtof/zuggtmoy.png')

const zariel_bgdia = require('./../../../images/creatures/bgdia/zariel.jpeg')

const moloch_phb_adnd = require('./../../../images/creatures/phb_adnd/moloch.jpg')

const Mist_Vampiric_ADND2 = require('./../../../images/creatures/adnd2_mm/Mist_Vampiric.jpg')
const Phoenix_ADND2 = require('./../../../images/creatures/adnd2_mm/Phoenix.jpg')
const Tempest_ADND2 = require('./../../../images/creatures/adnd2_mm/Tempest.jpg')
const Zaratan_ADND2 = require('./../../../images/creatures/adnd2_mm/Zaratan.jpg')
const Derro_ADND2 = require('./../../../images/creatures/adnd2_mm/derro.png')

const Demogorgon_img_AYAGMnC = require('./../../../images/creatures/ayagmc/demogorgon.jpg')

const Zuggtmoy_img_OotA = require('./../../../images/creatures/oota/zuggtmoy.jpg')

const Marut_MTG_CLB_img = require('./../../../images/creatures/mtg_clb/marut-mtg_clb.jpg')

const swordWraithData = {
  src: sword_wraith,
  text: 'Призрак меча',
}
const kruthikData = {
  src: kruthik,
  text: 'Крутик',
}
const steederRiderData = {
  src: female_steeder,
  text: 'Двергар кавалрахни верхом на самке стидера',
}
const derroData = {
  creatureId: CREATURE_DERRO,
  src: Derro_ADND2,
  source: {
    id: SOURCE_ADND2_MM,
  },
}

module.exports = {
  [CREATURE_KRUTHIK_ADULT]: kruthikData,
  [CREATURE_OBLEX_ADULT]: {
    src: elder_oblex,
    creatureId: CREATURE_OBLEX_ELDER,
  },
  [CREATURE_ELEMENTAL_MYRMIDON_AIR]: air_elemental_myrmidon,
  [CREATURE_ELEMENTAL_MYRMIDON_EARTH]: earth_elemental_myrmidon,
  [CREATURE_ALKILITH]: alkilith,
  [CREATURE_ALLIP]: allip,
  [CREATURE_ARMANITE]: armanite,
  [CREATURE_AMNIZU]: amnizu,
  [CREATURE_ASTRAL_DREADNOUGHT]: [
    astral_dreadnought,
    Cover_MPMotM_data,
  ],
  [CREATURE_ELADRIN_AUTUMN]: autumn_eladrin,
  [CREATURE_BAEL]: bael,
  [CREATURE_BALHANNOTH]: balhannoth,
  [CREATURE_BAPHOMET]: baphomet,
  [CREATURE_BERBALANG]: berbalang,
  [CREATURE_ABISHAI_BLACK]: black_abishai,
  [CREATURE_ABISHAI_BLUE]: blue_abishai,
  [CREATURE_BONECLAW]: boneclaw,
  [CREATURE_BRONZE_SCOUT]: bronze_scout,
  [CREATURE_BULEZAU]: bulezau,
  [CREATURE_CADAVER_COLLECTOR]: cadaver_collector,
  [CREATURE_CANOLOTH]: canoloth,
  [CREATURE_CHOKER]: choker,
  [CREATURE_CORPSE_FLOWER]: corpse_flower,
  [CREATURE_DEATHLOCK]: deathlock,
  [CREATURE_DEATHLOCK_MASTERMIND]: {
    src: deathlock,
    creatureId: CREATURE_DEATHLOCK,
  },
  [CREATURE_DEATHLOCK_WIGHT]: [
    deathlock_wight,
    {
      src: deathlock,
      creatureId: CREATURE_DEATHLOCK,
    },
  ],
  [CREATURE_DEMOGORGON]: [
    demogorgon,
    {
      src: Demogorgon_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_DERRO]: [
    derro,
    derroData,
  ],
  [CREATURE_DERRO_SAVANT]: [
    derro_savant,
    derroData,
  ],
  [CREATURE_DHERGOLOTH]: dhergoloth,
  [CREATURE_DIRE_TROLL]: dire_troll,
  [CREATURE_DROW_ARACHNOMANCER]: Drow_Arachnomancer_MTOF_data,
  [CREATURE_DROW_MATRON_MOTHER]: Drow_Matron_mother_MTOF_data,
  [CREATURE_DUERGAR_MIND_MASTER]: {
    duergar_mind_master,
    Duergar_ADND2_data,
  },
  [CREATURE_DUERGAR_STONE_GUARD]: {
    duergar_stone_guard,
    Duergar_ADND2_data,
  },
  [CREATURE_DUERGAR_KAVALRACHNI]: {
    steederRiderData,
    Duergar_ADND2_data,
  },
  [CREATURE_DYBBUK]: dybbuk,
  [CREATURE_ELDER_TEMPEST]: [
    elder_tempest,
    {
      src: Tempest_ADND2,
      text: 'Шторм',
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_OBLEX_ELDER]: elder_oblex,
  [CREATURE_STEEDER_FEMALE]: steederRiderData,
  [CREATURE_ELEMENTAL_MYRMIDON_FIRE]: fire_elemental_myrmidon,
  [CREATURE_FRAZ_URBLUU]: fraz_urbluu,
  [CREATURE_FROST_SALAMANDER]: frost_salamander,
  [CREATURE_GERYON]: geryon,
  [CREATURE_GIFF]: GiffImgList,
  [CREATURE_GITHYANKI_GISH]: Githyanki_Gish_MTOF_data,
  [CREATURE_GITHYANKI_KITHRAK]: Githyanki_Kithrak_MTOF_data,
  [CREATURE_GITHYANKI_SUPREME_COMMANDER]: Githyanki_Supreme_Commander_MTOF_data,
  [CREATURE_GITHZERAI_ANARCH]: Githzerai_Anarch_MTOF_data,
  [CREATURE_GITHZERAI_ENLIGHTENED]: Githzerai_Enlightened_MTOF_data,
  [CREATURE_GLOOM_WEAVER]: gloom_weaver,
  [CREATURE_GRAY_RENDER]: gray_render,
  [CREATURE_GRAZZT]: [
    grazzt,
    {
      src: Grazzt_tasha_TCoE,
      text: 'Таша обращается к лорду демонов Граз’зту через волшебное зеркало в своей лаборатории',
      source: {
        id: SOURCE_TCoE,
      },
    },
  ],
  [CREATURE_ABISHAI_GREEN]: green_abishai,
  [CREATURE_HELLFIRE_ENGINE]: hellfire_engine,
  [CREATURE_HOWLER]: howler,
  [CREATURE_HUTIJIN]: hutijin,
  [CREATURE_HYDROLOTH]: hydroloth,
  [CREATURE_IRON_COBRA]: iron_cobra,
  [CREATURE_JUIBLEX]: juiblex,
  [CREATURE_KRUTHIK_HIVE_LORD]: kruthikData,
  [CREATURE_LEVIATHAN]: leviathan,
  [CREATURE_STEEDER_MALE]: steederRiderData,
  [CREATURE_MARUT]: [
    marut,
    {
      src: Marut_MTG_CLB_img,
      source: SOURCE_MTG_CLB,
    },
  ],
  [CREATURE_MAUREZHI]: maurezhi,
  [CREATURE_MEAZEL]: meazel,
  [CREATURE_MERREGON]: merregon,
  [CREATURE_MERRENOLOTH]: merrenoloth,
  [CREATURE_MOLOCH]: [
    moloch,
    {
      src: moloch_phb_adnd,
      text: 'Идол Молоха',
      source: {
        id: SOURCE_ADND_PHB,
      },
    },
  ],
  [CREATURE_EIDOLON]: {
    src: sacred_statue,
    text: 'Эйдолон, вселившийся в статую Молоха',
  },
  [CREATURE_NABASSU]: nabassu,
  [CREATURE_NAGPA]: nagpa,
  [CREATURE_NARZUGON]: Narzugon_MTOF_data,
  [CREATURE_NIGHTWALKER]: nightwalker,
  [CREATURE_NUPPERIBO]: nupperibo,
  [CREATURE_OAKEN_BOLTER]: oaken_bolter,
  [CREATURE_OBLEX_SPAWN]: oblex_spawn,
  [CREATURE_OGRE_BATTERING_RAM]: ogre_battering_ram,
  [CREATURE_OGRE_HOWDAH]: ogre_howdah,
  [CREATURE_OINOLOTH]: oinoloth,
  [CREATURE_ORCUS]: orcus,
  [CREATURE_ORTHON]: orthon,
  [CREATURE_PHOENIX]: [
    phoenix,
    {
      src: Phoenix_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_ABISHAI_RED]: red_abishai,
  [CREATURE_RETRIEVER]: retriever,
  [CREATURE_ROT_TROLL]: rot_troll,
  [CREATURE_RUTTERKIN]: rutterkin,
  [CREATURE_SHADOW_DANCER]: shadow_dancer,
  [CREATURE_SIBRIEX]: sibriex,
  [CREATURE_SKULK]: skulk,
  [CREATURE_SKULL_LORD]: skull_lord,
  [CREATURE_SOUL_MONGER]: soul_monger,
  [CREATURE_SPIRIT_TROLL]: spirit_troll,
  [CREATURE_ELADRIN_SPRING]: spring_eladrin,
  [CREATURE_ELADRIN_SUMMER]: summer_eladrin,
  [CREATURE_STAR_SPAWN_LARVA_MAGE]: star_spawn_larva_mage,
  [CREATURE_STEEL_PREDATOR]: steel_predator,
  [CREATURE_STONE_CURSED]: stone_cursed,
  [CREATURE_STONE_DEFENDER]: stone_defender,
  [CREATURE_SWORD_WRAITH_COMMANDER]: swordWraithData,
  [CREATURE_SWORD_WRAITH_WARRIOR]: swordWraithData,
  [CREATURE_THE_ANGRY]: the_angry,
  [CREATURE_THE_HUNGRY]: the_hungry,
  [CREATURE_THE_LONELY]: the_lonely,
  [CREATURE_THE_LOST]: the_lost,
  [CREATURE_THE_WRETCHED]: the_wretched,
  [CREATURE_TITIVILUS]: titivilus,
  [CREATURE_TORTLE]: Tortle_TTP_data,
  [CREATURE_TORTLE_DRUID]: Tortle_TTP_data,
  [CREATURE_VAMPIRIC_MIST]: [
    vampiric_mist,
    {
      src: Mist_Vampiric_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_VENOM_TROLL]: venom_troll,
  [CREATURE_WASTRILITH]: wastrilith,
  [CREATURE_ELEMENTAL_MYRMIDON_WATER]: water_elemental_myrmidon,
  [CREATURE_ABISHAI_WHITE]: white_abishai,
  [CREATURE_ELADRIN_WINTER]: winter_eladrin,
  [CREATURE_YAGNOLOTH]: yagnoloth,
  [CREATURE_YEENOGHU]: yeenoghu,
  [CREATURE_KRUTHIK_YOUNG]: kruthikData,
  [CREATURE_ZARATAN]: [
    zaratan,
    {
      src: Zaratan_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_ZARIEL]: [
    zariel,
    {
      src: zariel_bgdia,
      source: {
        id: SOURCE_BGDIA,
      },
    },
  ],
  [CREATURE_ZUGGTMOY]: [
    zuggtmoy,
    {
      src: Zuggtmoy_img_OotA,
      source: {
        id: SOURCE_OotA,
      },
    },
  ],
}
