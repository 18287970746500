import {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} from '@/constants/paramList'
import {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HUMAN,
} from '@/constants/creatureTypeIdList'
import {faHandRock} from '@fortawesome/free-solid-svg-icons'
import {LANG_COMMON} from '@/constants/languageIdList'
import {SOURCE_DMG} from '@/constants/sourceList'

export default {
  templateName: 'Человек',
  templateIcon: faHandRock,
  templateColor: 'brown',
  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_ANY_RACE,
      ],
    },
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 283,
    }],
    languageList: [
      LANG_COMMON,
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Человек ${name}`,
    creatureTypeIdList: ({ creatureTypeIdList }) => creatureTypeIdList.map(
      creatureTypeId => creatureTypeId === CREATURE_TYPE_ANY_RACE
        ? CREATURE_TYPE_HUMAN
        : creatureTypeId
    ),
    params: ({ params }) => ({
      ...params,
      [PARAM_STR]: params[PARAM_STR] + 1,
      [PARAM_DEX]: params[PARAM_DEX] + 1,
      [PARAM_CON]: params[PARAM_CON] + 1,
      [PARAM_INT]: params[PARAM_INT] + 1,
      [PARAM_WIT]: params[PARAM_WIT] + 1,
      [PARAM_CHA]: params[PARAM_CHA] + 1,
    }),
  },
}
