import React from "react"

import SourceInfo from "@/components/SourceInfo"
import StatInfoRow from "@/components/StatInfoRow";

export default ({source}) => (
  <StatInfoRow header='Источник'>
    <SourceInfo
      source={source}
      useFullName
    />
  </StatInfoRow>
)
