import React from 'react'
import PropTypes from 'prop-types'

import RenderList from '@/components/RenderList'

import './CreatureTypeStyles.less'

const CreatureTypeComponent = ({ list }) => (
  <span className='CreatureType'>
    <RenderList
      bracketsAfterFirst
      list={list}
      renderer={({title, id, text}) => (
        <span
          key={id}
          title={title}
          className='CreatureType_item'
        >
          {text}
        </span>
      )}
    />
  </span>
)

CreatureTypeComponent.propTypes = {
  list: PropTypes.array.isRequired,
}

export default CreatureTypeComponent
