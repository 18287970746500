import arrify from 'arrify'
import declint from 'declint-ru'

import {timePeriodCollection} from '@/constants/timePeriodList'

const formatTimePeriod = (period, useGenitive = false) => {
  const caseName = useGenitive
    ? 'genitive'
    : 'nominative'

  return arrify(period)
    .map(
      periodItem => {
        if (typeof periodItem === 'string') {
          return timePeriodCollection[periodItem]
            ? timePeriodCollection[periodItem].name.singular.nominative
            : periodItem
        }

        if (periodItem === Infinity) {
          return 'Пока не рассеется'
        }

        const {timeId, count = 1} = periodItem

        const singular = timePeriodCollection[timeId].name.singular[caseName]
        const dual = timePeriodCollection[timeId].name.dual[caseName]
        const plural = timePeriodCollection[timeId].name.plural[caseName]

        const text = declint(count, [singular, dual, plural])

        return `${count} ${text}`
      }
    )
    .join(' или ')
}

export default formatTimePeriod
