import {CR_21} from '@/constants/crList'
import {CREATURE_DEMILICH} from '@/constants/creatureIdList'
import {faSkull} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Последователь Ацерерака',
  templateIcon: faSkull,
  templateColor: 'green',

  templateLimitations: {
    include: {
      id: [
        CREATURE_DEMILICH,
      ],
    },
  },

  cr: CR_21,
  extendPropCollection: {
    description: [{
      header: 'Ацерерак и его последователи',
      text: `Превращение в демилича не для всех становится проклятием. Как сознательный шаг, трансформация в демилича становится следующей ступенью в тёмном развитии. Лич Ацерерак — могущественный волшебник, демонолог, а также печально известный владыка Гробницы Ужасов, — предчувствуя своё превращение, подготовился к нему, вставив зачарованные камни в глазницы и вместо зубов черепа. Каждый из этих камней мог ловить души, чтобы потом скармливать их филактерию.

Ацерерак оставил своё физическое тело, приняв то, что он разрушится в пыль, пока его бестелесное сознание странствовало по планам. Если череп, его последнее физическое пристанище, будет потревожен, внедрённые в него камни вырвут души нарушителей границ гробницы и магически передадут их филактерию.

Личи, последовавшие за Ацерераком, считают, что освободившись от тел, они смогут продолжать поиски власти над миром смертных. Как и их покровитель, они хоронят останки в прекрасно охраняемых местах, используют камни душ для сохранения филактерия и уничтожают искателей приключений, потревоживших их.

Ацерерак или другой демилич, подобный ему, имеет показатель опасности 21 (33000 опыта) или 23 (50000 опыта) в логове, и получает следующее дополнительное действие:

**Ловля душ.** Демилич нацеливается на одно существо, видимое в пределах 30 футов. Цель должна пройти испытание Харизмы СЛ 19. При провале душа цели магическим образом заключается внутри одного из камней демилича. Пока душа похищена, тело и несомое снаряжение цели не существует. При успехе цель получает урон некротической энергией 24 (7к6), и если этот урон снижает хиты цели до 0, её душа похищается, как если бы она провалила испытание. Пойманная в камень душа пожирается через 24 часа, после чего прекращает существование.

Если хиты демилича падают до 0, он разрушается в прах, оставляя камни. Разрушение камня освобождает пойманную душу, после чего тело восстанавливается в свободном пространстве, ближайшем к камню, в том же состоянии, что и до пленения.`,
      source: {
        id: SOURCE_MM,
        page: 79,
      },
    }],
    source: [{
      id: SOURCE_MM,
      page: 79,
    }],
    actionList: [
      {
        name: 'Ловля душ',
        description: `Демилич нацеливается на одно существо, видимое в пределах 30 футов. Цель должна пройти испытание Харизмы СЛ 19. При провале душа цели магическим образом заключается внутри одного из камней демилича. Пока душа похищена, тело и несомое снаряжение цели не существует. При успехе цель получает урон некротической энергией 24 (7к6), и если этот урон снижает хиты цели до 0, её душа похищается, как если бы она провалила испытание. Пойманная в камень душа пожирается через 24 часа, после чего прекращает существование.`,
      },
    ],
  },
  editPropCollection: {
    name: ({ name }) => `${name}-ацерерачник`,
  },
}
