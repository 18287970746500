import React from "react"
import PropTypes from "prop-types"

import upLetter from "@/utils/upLetter"

import { speedCollection } from "@/constants/speedList"
import {
  SPEED_CLIMB,
  SPEED_DIG,
  SPEED_FLY,
  SPEED_SWIM,
  SPEED_WALK,
} from '@/constants/speedList'

import CreatureSpeedComponent from "./CreatureSpeedComponent";
import arrify from 'arrify'

const CreatureSpeedContainer = ({ speed, ...rest }) => {
  if (speed) {
    const list = Object
      .keys(speed)
      .filter(
        type => speed[type] || speed[type] === 0,
      )
      .map(
        type => {
          const {name, titleName, description} = speedCollection[type]

          const text = arrify(speed[type]).reduce(
            (result, item, i) => {
              const range = item.value || item
              const comment = (item && typeof item === 'object' && item.comment)
                ? ` (${item.comment})`
                : ''
              const floatingText = item && item.isFloating
                ? ' (парит)'
                : ''

              return `${result}${i ? ';' : ''} ${range} фт.${comment}${floatingText}`
            },
            name
          )

          return {
            id: type,
            title: `${upLetter(titleName)}\n\n${description}`,
            text,
          }
        },
      )

    return (
      <CreatureSpeedComponent
        list={list}
        {...rest}
      />
    )
  }

  return null
}

CreatureSpeedContainer.propTypes = {
  speed: PropTypes.shape({
    [SPEED_CLIMB]: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    [SPEED_DIG]: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    [SPEED_FLY]: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    [SPEED_SWIM]: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    [SPEED_WALK]: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  }),
}

CreatureSpeedContainer.defaultProps = {
  speed: null,
}

export default CreatureSpeedContainer
