import React from "react"

import Markdown from '@/components/Markdown'
import generateTextLinks from '@/utils/generateTextLinks'

import "./AbilityStyles.less"

const Ability = ({header, children}) => (
  <li
    key={header}
    className='Ability'
  >
    <Markdown className='Ability_header'>
      {`${generateTextLinks(header)} `}
    </Markdown>
    <Markdown className='Ability_description'>
      {generateTextLinks(children)}
    </Markdown>
  </li>
)

export default Ability
