module.exports = {
  ...require('./list/creatureImageCollection_MM_a'),
  ...require('./list/creatureImageCollection_MM_b'),
  ...require('./list/creatureImageCollection_MM_c'),
  ...require('./list/creatureImageCollection_MM_d'),
  ...require('./list/creatureImageCollection_MM_e'),
  ...require('./list/creatureImageCollection_MM_f'),
  ...require('./list/creatureImageCollection_MM_g'),
  ...require('./list/creatureImageCollection_MM_h'),
  ...require('./list/creatureImageCollection_MM_i'),
  ...require('./list/creatureImageCollection_MM_j'),
  ...require('./list/creatureImageCollection_MM_k'),
  ...require('./list/creatureImageCollection_MM_l'),
  ...require('./list/creatureImageCollection_MM_m'),
  ...require('./list/creatureImageCollection_MM_n'),
  ...require('./list/creatureImageCollection_MM_o'),
  ...require('./list/creatureImageCollection_MM_p'),
  ...require('./list/creatureImageCollection_MM_q'),
  ...require('./list/creatureImageCollection_MM_r'),
  ...require('./list/creatureImageCollection_MM_s'),
  ...require('./list/creatureImageCollection_MM_t'),
  ...require('./list/creatureImageCollection_MM_u'),
  ...require('./list/creatureImageCollection_MM_v'),
  ...require('./list/creatureImageCollection_MM_w'),
  ...require('./list/creatureImageCollection_MM_x'),
  ...require('./list/creatureImageCollection_MM_y'),
  ...require('./list/creatureImageCollection_MM_z'),
}
