import {
  CREATURE_DRAGON_BLACK_ADULT,
  CREATURE_DRAGON_BLUE_ADULT,
  CREATURE_DRAGON_BRASS_ADULT,
  CREATURE_DRAGON_BRONZE_ADULT,
  CREATURE_DRAGON_COPPER_ADULT,
  CREATURE_DRAGON_GOLD_ADULT,
  CREATURE_DRAGON_GREEN_ADULT,
  CREATURE_DRAGON_RED_ADULT,
  CREATURE_DRAGON_SILVER_ADULT,
  CREATURE_DRAGON_WHITE_ADULT,
  CREATURE_DRAGON_BLACK_ANCIENT,
  CREATURE_DRAGON_BLUE_ANCIENT,
  CREATURE_DRAGON_BRASS_ANCIENT,
  CREATURE_DRAGON_BRONZE_ANCIENT,
  CREATURE_DRAGON_COPPER_ANCIENT,
  CREATURE_DRAGON_GOLD_ANCIENT,
  CREATURE_DRAGON_GREEN_ANCIENT,
  CREATURE_DRAGON_RED_ANCIENT,
  CREATURE_DRAGON_SILVER_ANCIENT,
  CREATURE_DRAGON_WHITE_ANCIENT,
  CREATURE_DRAGON_BLACK_WYRMLING,
  CREATURE_DRAGON_BLUE_WYRMLING,
  CREATURE_DRAGON_BRASS_WYRMLING,
  CREATURE_DRAGON_BRONZE_WYRMLING,
  CREATURE_DRAGON_COPPER_WYRMLING,
  CREATURE_DRAGON_GOLD_WYRMLING,
  CREATURE_DRAGON_GREEN_WYRMLING,
  CREATURE_DRAGON_RED_WYRMLING,
  CREATURE_SHADOW,
  CREATURE_DRAGON_SILVER_WYRMLING,
  CREATURE_DRAGON_WHITE_WYRMLING,
  CREATURE_DRAGON_BLACK_YOUNG,
  CREATURE_DRAGON_BLUE_YOUNG,
  CREATURE_DRAGON_BRASS_YOUNG,
  CREATURE_DRAGON_BRONZE_YOUNG,
  CREATURE_DRAGON_COPPER_YOUNG,
  CREATURE_DRAGON_GOLD_YOUNG,
  CREATURE_DRAGON_GREEN_YOUNG,
  CREATURE_DRAGON_RED_YOUNG,
  CREATURE_DRAGON_SILVER_YOUNG,
  CREATURE_DRAGON_WHITE_YOUNG,
} from '@/constants/creatureIdList'
import {
  DAMAGE_ACID,
  DAMAGE_COLD,
  DAMAGE_ELECTRICITY,
  DAMAGE_FIRE,
  DAMAGE_NECROTIC,
  DAMAGE_POISON,
} from '@/constants/damageTypeList'
import arrify from 'arrify'
import calcParamBonus from '@/utils/calcParamBonus'
import generateTextLinks from '@/utils/generateTextLinks'
import {ABILITY_SHADOW_STEALTH} from '@/constants/creatureAbilityList'
import {crCollection} from '@/constants/crList'
import {CREATURE_TYPE_DRAGON} from '@/constants/creatureTypeIdList'
import {faMehBlank} from '@fortawesome/free-solid-svg-icons'
import {PARAM_DEX} from '@/constants/paramList'
import {SKILL_STEALTH} from '@/constants/skillList'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Теневой дракон',
  templateIcon: faMehBlank,
  templateColor: 'black',
  isAlreadyDarknessDragon: true,

  templateLimitations: {
    include: {
      id: [
        CREATURE_DRAGON_BLACK_ADULT,
        CREATURE_DRAGON_BLUE_ADULT,
        CREATURE_DRAGON_BRASS_ADULT,
        CREATURE_DRAGON_BRONZE_ADULT,
        CREATURE_DRAGON_COPPER_ADULT,
        CREATURE_DRAGON_GOLD_ADULT,
        CREATURE_DRAGON_GREEN_ADULT,
        CREATURE_DRAGON_RED_ADULT,
        CREATURE_DRAGON_SILVER_ADULT,
        CREATURE_DRAGON_WHITE_ADULT,
        CREATURE_DRAGON_BLACK_ANCIENT,
        CREATURE_DRAGON_BLUE_ANCIENT,
        CREATURE_DRAGON_BRASS_ANCIENT,
        CREATURE_DRAGON_BRONZE_ANCIENT,
        CREATURE_DRAGON_COPPER_ANCIENT,
        CREATURE_DRAGON_GOLD_ANCIENT,
        CREATURE_DRAGON_GREEN_ANCIENT,
        CREATURE_DRAGON_RED_ANCIENT,
        CREATURE_DRAGON_SILVER_ANCIENT,
        CREATURE_DRAGON_WHITE_ANCIENT,
        CREATURE_DRAGON_BLACK_WYRMLING,
        CREATURE_DRAGON_BLUE_WYRMLING,
        CREATURE_DRAGON_BRASS_WYRMLING,
        CREATURE_DRAGON_BRONZE_WYRMLING,
        CREATURE_DRAGON_COPPER_WYRMLING,
        CREATURE_DRAGON_GOLD_WYRMLING,
        CREATURE_DRAGON_GREEN_WYRMLING,
        CREATURE_DRAGON_RED_WYRMLING,
        CREATURE_DRAGON_SILVER_WYRMLING,
        CREATURE_DRAGON_WHITE_WYRMLING,
        CREATURE_DRAGON_BLACK_YOUNG,
        CREATURE_DRAGON_BLUE_YOUNG,
        CREATURE_DRAGON_BRASS_YOUNG,
        CREATURE_DRAGON_BRONZE_YOUNG,
        CREATURE_DRAGON_COPPER_YOUNG,
        CREATURE_DRAGON_GOLD_YOUNG,
        CREATURE_DRAGON_GREEN_YOUNG,
        CREATURE_DRAGON_RED_YOUNG,
        CREATURE_DRAGON_SILVER_YOUNG,
        CREATURE_DRAGON_WHITE_YOUNG,
      ],
      creatureTypeIdList: [CREATURE_TYPE_DRAGON],
    },
    exclude: {
      templateName: ['Драколич'],
      isAlreadyDarknessDragon: true,
      isAlreadyDracoLich: true,
    },
  },

  editPropCollection: {
    name: ({ name }) => name.includes('Вирмлинг')
      ? name.replace('Вирмлинг', 'Теневой вирмлинг')
      : name.replace('дракон', 'теневой дракон'),
    skillCollection: ({ skillCollection, cr, params }) => {
      const { profBonus } = crCollection[cr]
      const dexBonus = calcParamBonus(params[PARAM_DEX])

      return {
        ...(skillCollection || {}),
        [SKILL_STEALTH]: dexBonus + profBonus * 2,
      }
    },
    actionList: ({ actionList }) => actionList.map(
      action => {
        if (action.name === 'Укус') {
          const hit = arrify(action.attack.hit)
            .map(
              hitItem => arrify(hitItem)
                .map(
                  item => {
                    if (
                      [
                        DAMAGE_ACID,
                        DAMAGE_COLD,
                        DAMAGE_ELECTRICITY,
                        DAMAGE_FIRE,
                        DAMAGE_POISON,
                      ]
                        .includes(item.type)
                    ) {
                      return {
                        ...item,
                        type: DAMAGE_NECROTIC,
                      }
                    }

                    return item
                  },
                ),
            )

          return {
            ...action,
            attack: {
              ...action.attack,
              hit,
            },
          }
        }

        if (action.name.toLowerCase().includes('дыхание')) {
          const changedDescription = action.description
            .replace(
              /(огонь)|(волну ледяного воздуха)|(молнию)|(кислоту)|(ядовитый газ)/g,
              'некротическую энергию',
            )
            .replace(
              /(огнём)|(холодом)|(электричеством)|(кислотой)|(ядом)/g,
              'некротической энергией',
            )
          const description = generateTextLinks(`${changedDescription}. Гуманоид, хиты которого уменьшились до 0 от этого урона, умирает, и из его трупа выходит [тень](CREATURE:${CREATURE_SHADOW}) — нежить, действующая сразу после дракона по инициативе. Эта тень находится под контролем дракона.`)

          return {
            ...action,
            name: 'Тёмное дыхание',
            description,
          }
        }

        return action
      },
    ),
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 100,
    }],
    description: [
      {
        header: 'Шаблон теневого дракона',
        text: `Только истинный дракон может превратиться в теневого дракона, и только в том случае, если рождается в Царстве Теней или проживает там несколько лет. Драколич не может стать теневым драконом, так как он уже потерял свою драконью природу и стал нежитью.

Когда дракон становится теневым драконом, он сохраняет свою статистику, за исключением пунктов, описанных ниже. Теневой дракон может сохранить или потерять некоторые или все свои действия логова, или унаследовать новые, в зависимости от решения Мастера.

**Сопротивление к урону.** Теневой дракон получает сопротивление к урону некротической энергией.

**Владение навыком: Скрытность.** Бонус мастерства дракона удваивается для его проверок Ловкости (Скрытность).

**Живая тень.** Находясь в области тусклого света или тьмы, дракон обладает сопротивлением ко всем видам урона кроме урона излучением, психической энергией и силовым полем.

**Скрытность в тени.** Находясь в области тусклого света или тьмы, дракон может совершать действие Засада бонусным действием.

**Чувствительность к солнечному свету.** Находясь на солнечном свету, дракон совершает с помехой броски атаки, а также проверки Мудрости (Внимательность), полагающиеся на зрение.

**Новое действие: Укус.** Если дракон причиняет укусом урон кислотой, огнём, холодом, электричеством или ядом, измените этот урон на урон некротической энергией.

**Новое действие: Теневое дыхание.** Любое причиняющее урон оружие дыхания, имеющееся у дракона, теперь причиняет урон некротической энергией, вместо первоначального вида урона. Гуманоид, хиты которого уменьшились до 0 от этого урона, умирает, и из его трупа выходит [тень](CREATURE:${CREATURE_SHADOW}) — нежить, действующая сразу после дракона по инициативе. Эта тень находится под контролем дракона.`,
        source: {
          id: SOURCE_MM,
          page: 100,
        },
      },
      {
        header: 'Теневой дракон',
        text: `Теневые драконы это истинные драконы, которые либо родились в Царстве Теней, либо трансформировались за годы, проведённые в его мрачных пределах. Некоторые теневые драконы остаются в Царстве Теней, так как им нравятся его безрадостные пейзажи и запустение. Другие стремятся вернуться на Материальный план, желая распространить там тьму и зло Царства Теней.

**Тёмные порталы.** Порталы в Царство Теней появляются в покинутых местах и глубочайшем мраке подземных каверн. Драконы, устраивающие логова в подобных местах, часто находят такие порталы и переносятся на новый план. Древние драконы, которые спят в своих берлогах на протяжении месяцев и лет, могут проснуться и понять, что пока они видели сны, без их ведома сформировался перенёсший их портал.

**Перерождение в тень.** Превращение дракона из обычного в теневого происходит за нескольких лет, в течение которых чешуйки постепенно утрачивают свой блеск и выцветают до тёмных, угольных оттенков. Его кожистые крылья становятся полупрозрачными, его глаза превращаются в омуты, переливающиеся серым. Теневые драконы считают солнечный свет отвратительным; при ярком свете они слабее, чем во тьме, а в темноте они принимают облик, более похожий на их былую внешность.

Магическая природа драконов привлекательна для Царства Теней, которое, как кажется, каким-то образом жаждет мощь и величие этих гигантских рептилий. Царство Теней также оказывает угнетающее влияние на своих обитателей, так что чем дольше существо остаётся на этом плане, тем больше оно перенимает общую тоску. Месяцы и годы проходят для дракона в Царстве Теней, он осознаёт свою трансформацию, но всё же ничего не делает, чтобы предотвратить её.

**Назад на Материальный план.** Теневые драконы переполнены силой Царства Теней настолько, что даже возврат на Материальный план не может отменить их трансформацию. Некоторые теневые драконы пытаются заманить других существ из мира смертных обратно в Царство Теней, чтобы пообщаться с ними, по крайней мере, пока они не устанут от своих гостей и не съедят их. Другие рады оставить Царство Теней навсегда позади, понимая, что сокровища и власть легче найти на Материальном плане.`,
        source: {
          id: SOURCE_MM,
          page: 100,
        },
      },
    ],
    resistanceList: [
      DAMAGE_NECROTIC,
    ],
    featureList: [
      {
        name: 'Чувствительность к солнечному свету',
        description: `Находясь на солнечном свету, дракон совершает с помехой броски атаки, а также проверки Мудрости (Внимательность), полагающиеся на зрение.`,
      },
      {
        name: 'Живая тень',
        description: `Находясь в области тусклого света или тьмы, дракон обладает сопротивлением ко всем видам урона кроме урона излучением, психической энергией и силовым полем.`,
      },
      ABILITY_SHADOW_STEALTH,
    ],
  },
}
