import React from "react"
import PropTypes from "prop-types"

import mentalParamIdList from "@/constants/mentalParamIdList"

import Param from "../Param"

import "./ParamListStyles.less"

const ParamListComponent = ({ params, className, hideMentalParamModifiers }) => (
  <section className={`ParamList ${className}`}>
    {
      Object
        .keys(params)
        .map(
          id => {
            const hideModifier = hideMentalParamModifiers && mentalParamIdList.includes(id)

            return (
              <Param
                hideModifier={hideModifier}
                id={id}
                key={id}
                value={params[id]}
              />
            )
          }
        )
    }
  </section>
)

ParamListComponent.propTypes = {
  params: PropTypes.object.isRequired,
  className: PropTypes.string,
  hideMentalParamModifiers: PropTypes.bool,
}

ParamListComponent.defaultProps = {
  className: '',
  hideMentalParamModifiers: false,
}

export default ParamListComponent
