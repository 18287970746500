import {
  ATTACK_MELEE_WEAPON,
  ATTACK_MELEE_SPELL,
  ATTACK_RANGE_WEAPON,
  ATTACK_RANGE_SPELL,
} from '@/constants/attackTypeList'

const formatMelee = range => `досягаемость ${range} фт.`

const formatRange = range => {
  const distanceText = typeof range === 'object'
    ? `${range.normal}/${range.max}`
    : range
  const minText = range.min
    ? ` (не может поражать цели ближе ${range.min} футов)`
    : ''

  return `дистанция ${distanceText} фт.${minText}`
}

const formatRangeSpell = range => `дистанция ${range} фт.`

const formatRangeText = (type, range) => {
  switch (type) {
    case ATTACK_MELEE_SPELL:
    case ATTACK_MELEE_WEAPON:
      return formatMelee(range)

    case ATTACK_RANGE_WEAPON:
      return formatRange(range)

    case ATTACK_RANGE_SPELL:
      return formatRangeSpell(range)

    default:
      return ''
  }
}

export default formatRangeText
