import generateTextLinks from '@/utils/generateTextLinks'
import {ATTACK_MELEE_WEAPON} from '@/constants/attackTypeList'
import {CREATURE_GIANT_RAT} from '@/constants/creatureIdList'
import {DAMAGE_PIERCING} from '@/constants/damageTypeList'
import {faPills} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Больная гигантская крыса',
  templateIcon: faPills,
  templateColor: 'olive',

  templateLimitations: {
    include: {
      id: [
        CREATURE_GIANT_RAT,
      ],
    },
  },

  extendPropCollection: {
    description: [
      {
        header: 'Вариант: Больные гигантские крысы',
        text: generateTextLinks(`Некоторые [гигантские крысы](CREATURE:${CREATURE_GIANT_RAT}) переносят болезни, распространяющиеся через укус. У больной гигантской крысы показатель опасности 1/8 (25 опыта) и описанное ниже действие вместо обычной атаки укусом.

**Укус.** Рукопашная атака оружием: +4 к попаданию, досягаемость 5 фт., одна цель. Попадание: Колющий урон 4 (1к4 + 2). Если цель — существо, она должна пройти испытание Телосложения СЛ 10, иначе заболеет. Пока болезнь не вылечена, цель не может восстанавливать хиты кроме как магией, и максимум её хитов уменьшается на 3 (1к6) каждые 24 часа. Если из-за этой болезни максимум хитов цели уменьшается до 0, она умирает.`),
        source: {
          id: SOURCE_MM,
          page: 323,
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Больная ${name.toLowerCase()}`,
    actionList: ({ actionList }) => actionList.map(
      action => action.name === 'Укус'
        ? {
          ...action,
          description: `Если цель — существо, она должна пройти испытание Телосложения СЛ 10, иначе заболеет. Пока болезнь не вылечена, цель не может восстанавливать хиты кроме как магией, и максимум её хитов уменьшается на 3 (1к6) каждые 24 часа. Если из-за этой болезни максимум хитов цели уменьшается до 0, она умирает.`,
          attack: {
            type: ATTACK_MELEE_WEAPON,
            bonus: 3,
            range: 5,
            hit: {
              type: DAMAGE_PIERCING,
              diceCount: 1,
              diceType: 4,
              diceBonus: 1,
            },
          },
        }
        : action,
    ),
  },
}
