const {
  CREATURE_ICE_DEVIL,
  CREATURE_ICE_MEPHIT,
  CREATURE_IMP,
  CREATURE_INCUBUS,
  CREATURE_INTELLECT_DEVOURER,
  CREATURE_INVISIBLE_STALKER,
  CREATURE_IRON_GOLEM,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_MTG_CLB,
  SOURCE_XGTE,
} = require('./../../../../sourceList')

const ice_devil = require('./../../../../../images/creatures/mm/ice_devil.jpg')
const ice_mephit = require('./../../../../../images/creatures/mm/ice_mephit.jpg')
const imp = require('./../../../../../images/creatures/mm/imp.jpg')
const incubus = require('./../../../../../images/creatures/mm/incubus.png')
const intellect_devourer = require('./../../../../../images/creatures/mm/intellect_devourer.jpeg')
const invisible_stalker = require('./../../../../../images/creatures/mm/invisible_stalker.jpeg')
const iron_golem = require('./../../../../../images/creatures/mm/iron_golem.jpg')

const Golem_Iron_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Golem_Iron.jpg')
const Imp_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Imp.jpg')
const Intellect_Devourer_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Intellect_Devourer.jpg')
const Mephit_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Mephit.jpg')

const Imp_img_XGTE = require('./../../../../../images/creatures/xgte/imp.jpg')

const Intellect_Devourer_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/intellect-devourer-mtg_clb.jpg')

const Iron_Golem_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/iron-golem-variant-mtg_afr.jpg')

const Mephit_data = {
  src: Mephit_ADND2,
  text: 'Мефиты',
  source: {
    id: SOURCE_ADND2_MM,
  },
}

module.exports = {
  [CREATURE_ICE_DEVIL]: ice_devil,
  [CREATURE_ICE_MEPHIT]: [
    ice_mephit,
    Mephit_data,
  ],
  [CREATURE_IMP]: [
    imp,
    {
      src: Imp_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Imp_img_XGTE,
      source: {
        id: SOURCE_XGTE,
      },
    },
  ],
  [CREATURE_INCUBUS]: incubus,
  [CREATURE_INTELLECT_DEVOURER]: [
    intellect_devourer,
    {
      src: Intellect_Devourer_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Intellect_Devourer_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_INVISIBLE_STALKER]: invisible_stalker,
  [CREATURE_IRON_GOLEM]: [
    iron_golem,
    {
      src: Golem_Iron_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Iron_Golem_MTG_AFR_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
}
