import {
  ABILITY_BRAVE,
  ABILITY_HALFLING_NIMBLENESS,
  ABILITY_LUCKY,
} from '@/constants/creatureAbilityList'
import {
  PARAM_CHA,
  PARAM_CON,
  PARAM_DEX,
} from '@/constants/paramList'
import {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_HALFLING,
} from '@/constants/creatureTypeIdList'
import {
  LANG_COMMON,
  LANG_HALFLING,
} from '@/constants/languageIdList'
import {faHamburger} from '@fortawesome/free-solid-svg-icons'
import {SENSE_DARK_VISION} from '@/constants/senseList'
import {SIZE_SMALL} from '@/constants/sizeList'
import {SOURCE_DMG} from '@/constants/sourceList'
import {SPEED_WALK} from '@/constants/speedList'

export default {
  templateName: 'Полурослик',
  templateIcon: faHamburger,
  templateColor: 'orange',
  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_ANY_RACE,
      ],
    },
  },

  sizeType: SIZE_SMALL,

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 283,
    }],
    languageList: [
      LANG_COMMON,
      LANG_HALFLING,
    ],
    featureList: [
      ABILITY_BRAVE,
      ABILITY_HALFLING_NIMBLENESS,
      ABILITY_LUCKY,
    ],
  },

  editPropCollection: {
    name: ({ name }) => `Полурослик ${name}`,
    creatureTypeIdList: ({ creatureTypeIdList }) => creatureTypeIdList.map(
      creatureTypeId => creatureTypeId === CREATURE_TYPE_ANY_RACE
        ? CREATURE_TYPE_HALFLING
        : creatureTypeId
    ),
    params: ({ params }) => {
      const paramWithRaceBonus = params[PARAM_CON] > params[PARAM_CHA]
        ? PARAM_CON
        : PARAM_CHA

      return {
        ...params,
        [paramWithRaceBonus]: params[paramWithRaceBonus] + 1,
        [PARAM_DEX]: params[PARAM_DEX] + 2,
      }
    },
    senseList: ({ senseList = [] }) => [
      ...senseList.filter(
        ({id}) => id !== SENSE_DARK_VISION
      ),
      {
        id: SENSE_DARK_VISION,
        value: 60,
      },
    ],
    speed: ({ speed }) => ({
    ...speed,
      [SPEED_WALK]: speed[SPEED_WALK] - 5,
    }),
  },
}
