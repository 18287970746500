module.exports = {
  ...require('./creatureImageCollection_AI'),
  ...require('./creatureImageCollection_BGDIA'),
  ...require('./creatureImageCollection_DnD_SADS_CLE'),
  ...require('./creatureImageCollection_DoMT'),
  ...require('./creatureImageCollection_ERFTLW'),
  ...require('./creatureImageCollection_HOTDQ'),
  ...require('./creatureImageCollection_IDRotF'),
  ...require('./creatureImageCollection_MFFV_1_MMB'),
  ...require('./creatureImageCollection_MM'),
  ...require('./creatureImageCollection_MOOT'),
  ...require('./creatureImageCollection_MTOF'),
  ...require('./creatureImageCollection_PotA'),
  ...require('./creatureImageCollection_ROT'),
  ...require('./creatureImageCollection_SKT'),
  ...require('./creatureImageCollection_TCOE'),
  ...require('./creatureImageCollection_TOA'),
  ...require('./creatureImageCollection_VGTM'),
  ...require('./creatureImageCollection_WDH'),
  ...require('./creatureImageCollection_WGtE'),
  ...require('./creatureImageCollection_XGTE'),
}
