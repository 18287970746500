const {
  CREATURE_NALFESHNEE,
  CREATURE_NEEDLE_BLIGHT,
  CREATURE_NIGHT_HAG,
  CREATURE_NIGHTMARE,
  CREATURE_NOBLE,
  CREATURE_NOTHIC,
  CREATURE_NYCALOTH,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  Narzugon_MTOF_data,
  Hag_lair_VGTM_data,
} = require('./../../../commonImageCollection')

const nalfeshnee = require('./../../../../../images/creatures/mm/nalfeshnee.jpeg')
const needle_blight = require('./../../../../../images/creatures/mm/needle_blight.jpg')
const night_hag = require('./../../../../../images/creatures/mm/night_hag.jpeg')
const nightmare = require('./../../../../../images/creatures/mm/nightmare.jpg')
const noble = require('./../../../../../images/creatures/mm/noble.jpg')
const nothic = require('./../../../../../images/creatures/mm/nothic.png')
const nycaloth = require('./../../../../../images/creatures/mm/nycaloth.jpeg')

const Nightmare_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Nightmare.jpg')

const Baba_Lysaga_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/baba-lysaga-mtg_clsjpg.jpg')

module.exports = {
  [CREATURE_NALFESHNEE]: nalfeshnee,
  [CREATURE_NEEDLE_BLIGHT]: needle_blight,
  [CREATURE_NIGHT_HAG]: [
    night_hag,
    Hag_lair_VGTM_data,
    {
      src: Baba_Lysaga_MTG_CLB_img,
      text: 'Баба Лысяга, ночная карга',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_NIGHTMARE]: [
    nightmare,
    Narzugon_MTOF_data,
    {
      src: Nightmare_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_NOBLE]: noble,
  [CREATURE_NOTHIC]: nothic,
  [CREATURE_NYCALOTH]: nycaloth,
}
