const {
  CREATURE_GRAY_OOZE,
  CREATURE_PEGASUS,
  CREATURE_PENTADRONE,
  CREATURE_PERYTON,
  CREATURE_PHASE_SPIDER,
  CREATURE_PIERCER,
  CREATURE_PIT_FIEND,
  CREATURE_PIXIE,
  CREATURE_PLANETAR,
  CREATURE_PLESIOSAURUS,
  CREATURE_POISONOUS_SNAKE,
  CREATURE_POLTERGEIST,
  CREATURE_PSEUDODRAGON,
  CREATURE_PSYCHIC_GRAY_OOZE,
  CREATURE_PTERANODON,
  CREATURE_PURPLE_WORM,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_DMG,
  SOURCE_MTG_AFR,
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')

const {
  Pteranodon_Halfling_WGtE_data,
} = require('./../../../commonImageCollection')

const gray_ooze = require('./../../../../../images/creatures/mm/gray_ooze.jpeg')
const pegasus = require('./../../../../../images/creatures/mm/pegasus.png')
const pentadrone = require('./../../../../../images/creatures/mm/pentadrone.jpeg')
const peryton = require('./../../../../../images/creatures/mm/peryton.jpeg')
const phase_spider = require('./../../../../../images/creatures/mm/phase_spider.jpg')
const piercer = require('./../../../../../images/creatures/mm/piercer.jpeg')
const pit_fiend = require('./../../../../../images/creatures/mm/pit_fiend.jpg')
const pixie = require('./../../../../../images/creatures/mm/pixie.jpeg')
const planetar = require('./../../../../../images/creatures/mm/planetar.jpeg')
const plesiosaurus = require('./../../../../../images/creatures/mm/plesiosaurus.jpeg')
const pseudodragon = require('./../../../../../images/creatures/mm/pseudodragon.jpeg')
const purple_worm = require('./../../../../../images/creatures/mm/purple_worm.jpeg')

const Pegasus_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Pegasus.jpg')
const Peryton_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Peryton.jpg')
const Piercer_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Piercer.jpg')
const Pit_Fiend_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Pit_Fiend.jpg')
const Poltergeist_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Poltergeist.jpg')
const Pseudodragon_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Pseudodragon.jpg')
const Purple_Worm_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Purple_Worm.jpg')
const Snake_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Snake.jpg')

const Pegasus_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/pegasus.jpg')

const PhaseSpider_DMG_img = require('./../../../../../images/creatures/dmg/phase_spider.webp')

const Pegasus_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/pegasus-mtg_afr.jpg')

const Windshaper_planetar_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/windshaper-planetar.webp')
const Pseudodragon_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/pseudodragon.jpg')

module.exports = {
  [CREATURE_PEGASUS]: [
    pegasus,
    {
      src: Pegasus_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Pegasus_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: Pegasus_MTG_AFR_img,
      text: 'Арборейский пегас',
      source: {
        id: SOURCE_MTG_AFR,
      },
    },
  ],
  [CREATURE_PENTADRONE]: pentadrone,
  [CREATURE_PERYTON]: [
    peryton,
    {
      src: Peryton_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_PHASE_SPIDER]: [
    phase_spider,
    {
      src: PhaseSpider_DMG_img,
      source: {
        id: SOURCE_DMG,
      },
    },
  ],
  [CREATURE_PIERCER]: [
    piercer,
    {
      src: Piercer_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_PIT_FIEND]: [
    pit_fiend,
    {
      src: Pit_Fiend_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_PIXIE]: pixie,
  [CREATURE_PLANETAR]: [
    planetar,
    {
      src: Windshaper_planetar_MTG_CLB_img,
      text: 'Управляющий ветром планетар',
      source: {
        id: SOURCE_MTG_CLB,
      },
    }
  ],
  [CREATURE_PLESIOSAURUS]: plesiosaurus,
  [CREATURE_POISONOUS_SNAKE]: {
    src: Snake_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_POLTERGEIST]: {
    src: Poltergeist_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_PTERANODON]: Pteranodon_Halfling_WGtE_data,
  [CREATURE_PSEUDODRAGON]: [
    pseudodragon,
    {
      src: Pseudodragon_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Pseudodragon_MTG_CLB_img,
      text: 'Псевдодракон-фамильяр',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_PSYCHIC_GRAY_OOZE]: {
    src: gray_ooze,
    creatureId: CREATURE_GRAY_OOZE,
  },
  [CREATURE_PURPLE_WORM]: [
    purple_worm,
    {
      src: Purple_Worm_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
}
