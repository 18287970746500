const {
  CREATURE_ULTROLOTH,
  CREATURE_UMBER_HULK,
  CREATURE_UNICORN,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
} = require('./../../../../sourceList')

const ultroloth = require('./../../../../../images/creatures/mm/ultroloth.jpeg')
const umber_hulk = require('./../../../../../images/creatures/mm/umber_hulk.jpg')
const unicorn = require('./../../../../../images/creatures/mm/unicorn.jpg')

const Umber_Hulk_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Umber_Hulk.jpg')
const Unicorn_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Unicorn.jpg')

const Unicorn_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/unicorn.jpg')

module.exports = {
  [CREATURE_ULTROLOTH]: ultroloth,
  [CREATURE_UMBER_HULK]: [
    umber_hulk,
    {
      src: Umber_Hulk_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_UNICORN]: [
    unicorn,
    {
      src: Unicorn_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Unicorn_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
}
