import {
  CREATURE_BABAU,
  CREATURE_CHASME,
  CREATURE_MARILITH,
  CREATURE_MOLYDEUS,
} from '@/constants/creatureIdList'
import generateTextLinks from '@/utils/generateTextLinks'
import {faUserPlus} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MTOF} from '@/constants/sourceList'

export default {
  templateName: 'Молидей-призыватель',
  templateIcon: faUserPlus,
  templateColor: 'purple',

  templateLimitations: {
    include: {
      id: [
        CREATURE_MOLYDEUS,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MTOF,
      page: 134,
    }],
    description: [
      {
        header: 'Вариант: Призыв демона',
        text: `Вы можете дать молидею способность к призыву других демонов.

**Призыв демона (1/день)**.   Молидей, потратив действие, может призвать с 50-ти % шансом: 1к6 [бабау](CREATURE:${CREATURE_BABAU}) babaus, 1к4 [чазм](CREATURE:${CREATURE_CHASME}), или 1 [марилита](CREATURE:${CREATURE_MARILITH}). Призванный демон появляется в незанятом пространстве, которое может видеть молидей в пределах 60 футов от себя, и выступает в качестве союзника молидея, но не может призывать других демонов. Демон призывается на 1 минуту, либо пока не умрёт молидей, либо пока молидей не отзовёт его, потратив действие.`,
        source: {
          id: SOURCE_MTOF,
          page: 134,
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name}-призыватель`,
    actionList: ({ actionList }) => [
      ...actionList,
      {
        name: 'Призыв демона',
        limit: {
          count: 1,
          period: 'день',
        },
        description: generateTextLinks(`Молидей, потратив действие, может призвать с 50% шансом: 1к6 [бабау](CREATURE:${CREATURE_BABAU}), 1к4 [чазма](CREATURE:${CREATURE_CHASME}), или 1 [марилит](CREATURE:${CREATURE_MARILITH}). Призванный демон появляется в незанятом пространстве, которое может видеть молидей в пределах 60 футов от себя, и выступает в качестве союзника молидея, но не может призывать других демонов. Демон призывается на 1 минуту, либо пока не умрёт молидей, либо пока молидей не отзовёт его, потратив действие.`),
      },
    ],
  },
}
