import {
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_BLUR,
  SPELL_DISGUISE_SELF,
  SPELL_NONDETECTION,
} from '@/constants/spellIdList'
import {
  PARAM_STR,
  PARAM_DEX,
  PARAM_INT,
} from '@/constants/paramList'
import {
  LANG_GNOMISH,
  LANG_UNDERCOMMON,
  LANG_TERRAN,
} from '@/constants/languageIdList'
import {
  ABILITY_GNOME_CUNNING,
  ABILITY_STONE_CAMOUFLAGE,
} from '@/constants/creatureAbilityList'
import {
  CREATURE_TYPE_ANY_RACE,
  CREATURE_TYPE_GNOME,
} from '@/constants/creatureTypeIdList'
import {CAST_MATERIAL} from '@/constants/castComponentList'
import {faTools} from '@fortawesome/free-solid-svg-icons'
import {SENSE_DARK_VISION} from '@/constants/senseList'
import {SIZE_SMALL} from '@/constants/sizeList'
import {SOURCE_DMG} from '@/constants/sourceList'
import {SPEED_WALK} from '@/constants/speedList'

export default {
  templateName: 'Свирфнеблин',
  templateIcon: faTools,
  templateColor: '#75797C',
  templateLimitations: {
    include: {
      creatureTypeIdList: [
        CREATURE_TYPE_ANY_RACE,
      ],
    },
  },

  sizeType: SIZE_SMALL,
  languageList: [
    LANG_GNOMISH,
    LANG_UNDERCOMMON,
    LANG_TERRAN,
  ],

  extendPropCollection: {
    source: [{
      id: SOURCE_DMG,
      page: 283,
    }],
    featureList: [
      ABILITY_GNOME_CUNNING,
      ABILITY_STONE_CAMOUFLAGE,
    ],

    spellCast: [{
      baseStat: PARAM_INT,
      componentExclude: CAST_MATERIAL,
      spellIdByCountList: [
        {
          limit: Infinity,
          list: [
            {
              id: SPELL_NONDETECTION,
              comment: 'только на себя',
            },
          ],
        },
        {
          limit: {
            count: 1,
            period: 'день',
          },
          list: [
            SPELL_BLINDNESS_DEAFNESS,
            SPELL_DISGUISE_SELF,
            SPELL_BLUR,
          ],
        },
      ],
    }],
  },

  editPropCollection: {
    name: ({ name }) => `Свирфнеблин ${name}`,
    creatureTypeIdList: ({ creatureTypeIdList }) => creatureTypeIdList.map(
      creatureTypeId => creatureTypeId === CREATURE_TYPE_ANY_RACE
        ? CREATURE_TYPE_GNOME
        : creatureTypeId
    ),
    params: ({ params }) => ({
      ...params,
      [PARAM_STR]: params[PARAM_STR] + 1,
      [PARAM_DEX]: params[PARAM_DEX] + 2,
    }),
    senseList: ({ senseList = [] }) => [
      ...senseList.filter(
        ({id}) => id !== SENSE_DARK_VISION
      ),
      {
        id: SENSE_DARK_VISION,
        value: 120,
      },
    ],
    speed: ({ speed }) => ({
      ...speed,
      [SPEED_WALK]: speed[SPEED_WALK] - 10,
    }),
  },
}
