const {
  CREATURE_AARAKOCRA,
} = require('./../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
} = require('./../../../sourceList')

const {
  Aarakocra_EE_data,
  Aarakocra_MM_data,
  Aarakocra_Cloud_Castle_SKT_data,
} = require('./../../commonImageCollection')

const Aarakocra_ADND2 = require('./../../../../images/creatures/adnd2_mm/Aarakocra.jpg')

module.exports = [
  Aarakocra_MM_data,
  Aarakocra_EE_data,
  Aarakocra_Cloud_Castle_SKT_data,
  {
    src: Aarakocra_ADND2,
    creatureId: CREATURE_AARAKOCRA,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
