const {
  CREATURE_DRAGON_RED_ADULT,
} = require('./../../../creatureIdList')

const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_DMG,
  SOURCE_DND3_5_DoF,
  SOURCE_DND3_5_DTBoD,
  SOURCE_FTD,
  SOURCE_MM,
  SOURCE_MTG_AFR,
  SOURCE_MTG_CLB,
} = require('./../../../sourceList')

const {
  Ashardalon_s_Stride_FTD_data,
  Bahamut_Young_Monk_FTD_data,
  Bahamut_as_Fizban_Helps_FTD_data,
  Color_Dragons_Attack_ROT_data,
  Ancient_Dragons_Hoard_data,
  Tiefling_Burglar_FTD_data,
} = require('./../../commonImageCollection')

const AdultRedDragon_DMG_img = require('./../../../../images/creatures/dmg/adult_red_dragon.png')
const Dragon_red_attack_img_AYAGMnC = require('./../../../../images/creatures/ayagmc/dragon_red_attack.jpg')
const Red_Dragon_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Red_Dragon.jpg')
const Red_Dragon_at_hoard_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/red_dragon_at_hoard.jpg')
const Red_Dragon_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/red_dragon.jpg')
const Red_Dragon_DND3_5_DoF_img = require('./../../../../images/creatures/dnd3_5_dof/red_dragon.jpg')
const Red_Dragon_MM_img = require('./../../../../images/creatures/mm/red_dragon.jpg')
const Red_Dragon_very_old_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/red-dragon-very-old.jpg')
const Tchazzar_DND3_5_DoF_img = require('./../../../../images/creatures/dnd3_5_dof/tchazzar.webp')
const Loot_Red_Dragon_Hoard_FTD_img = require('./../../../../images/creatures/ftd/trying-to-loot.webp')
const Crystal_Red_Dragon_Fight_FTD_img = require('./../../../../images/creatures/ftd/crystal-red-dragon-fight.jpg')
const Red_Dragon_difference_FTD_img = require('./../../../../images/creatures/ftd/dragon-difference.png')
const Red_Dragon_FTD_img = require('./../../../../images/creatures/ftd/red_dragon.png')
const Red_Dragon_Exales_Flame_FTD_img = require('./../../../../images/creatures/ftd/red_dragon_exales_flame.png')
const Encounter_With_Red_Dragon_FTD_img = require('./../../../../images/creatures/ftd/encounter-with-a-dragon.png')

const Firkraag_MTG_CLB_img = require('./../../../../images/creatures/mtg_clb/firkraag-mtg_cls.jpg')

const Inrvaernarhro_MTG_AFR_img = require('./../../../../images/creatures/mtg_afr/inrvaernarhro_mtg_afr.jpg')

module.exports = [
  {
    src: Red_Dragon_MM_img,
    text: 'Красный дракон',
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: AdultRedDragon_DMG_img,
    creatureId: CREATURE_DRAGON_RED_ADULT,
    source: {
      id: SOURCE_DMG,
    },
  },
  {
    src: Red_Dragon_AYAGMnC_img,
    text: 'Красный дракон',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Dragon_red_attack_img_AYAGMnC,
    text: 'Красный дракон',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Red_Dragon_DND3_5_DoF_img,
    text: 'Красный дракон',
    source: {
      id: SOURCE_DND3_5_DoF,
    },
  },
  {
    src: Tchazzar_DND3_5_DoF_img,
    text: 'Чаззар, новый Скептенар Симбара',
    source: {
      id: SOURCE_DND3_5_DoF,
    },
  },
  {
    src: Red_Dragon_at_hoard_DND3_5_DTBoD_img,
    text: 'Красный дракон и его клад',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Red_Dragon_Exales_Flame_FTD_img,
    text: 'Красный дракон дышит огнём',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Red_Dragon_very_old_DND3_5_DTBoD_img,
    text: 'Очень старый красный дракон',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Red_Dragon_ADND2_img,
    text: 'Красный дракон',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  {
    src: Loot_Red_Dragon_Hoard_FTD_img,
    text: 'Провал безрассудной попытки обокрасть спящего красного дракона',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Crystal_Red_Dragon_Fight_FTD_img,
    text: 'Битва хрустального и красного драконов',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Red_Dragon_difference_FTD_img,
    text: 'Драконы одного вида могут отличаться по форме, размеру, цвету и украшениям',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Red_Dragon_FTD_img,
    text: 'Красный дракон',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    src: Firkraag_MTG_CLB_img,
    text: 'Фиркрааг, хитрый подстрекатель',
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
  {
    src: Inrvaernarhro_MTG_AFR_img,
    text: 'Древний красный дракон Инрвэрнархро, по прозвищу «Инферно звёздных гор»',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  {
    src: Encounter_With_Red_Dragon_FTD_img,
    text: 'Встреча с драконом может быть разрушительна как для людей, так и для имущества',
    source: {
      id: SOURCE_FTD,
    },
  },
  Color_Dragons_Attack_ROT_data,
  Bahamut_Young_Monk_FTD_data,
  Ashardalon_s_Stride_FTD_data,
  Bahamut_as_Fizban_Helps_FTD_data,
  Ancient_Dragons_Hoard_data,
  Tiefling_Burglar_FTD_data,
]
