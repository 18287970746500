import { gearCollection } from '@/constants/gearList'
import { CREATURE_WARHORSE } from '@/constants/creatureIdList'

import calcCreatureAc from '@/utils/calcCreatureAc'

import warhorseTemplateDataList from './warhorseTemplateDataList'

const warhorseArmorTable = warhorseTemplateDataList.reduce(
  (tableText, { armorId }) => {
    const { name } = gearCollection[armorId]
    const ac = calcCreatureAc({ creatureId: CREATURE_WARHORSE, armorId })

    return `${tableText}| ${ac} | ${name} |
`
  },
  '',
)

export default warhorseArmorTable
