import { gearCollection } from '@/constants/gearList'
import { CAT_MELEE_WEAPON, CAT_RANGE_WEAPON } from '@/constants/gearCategoryList'

const checkIfRangeOnly = (gearId) => {
  const gear = gearCollection[gearId]

  if (gear) {
    const { category = [] } = gear
    return category.includes(CAT_RANGE_WEAPON) && !category.includes(CAT_MELEE_WEAPON)
  }

  return false
}

export default checkIfRangeOnly
