import {
  SPELL_BANE,
  SPELL_CLAIRVOYANCE,
  SPELL_DETECT_MAGIC,
  SPELL_DISPEL_MAGIC,
  SPELL_DIVINATION,
  SPELL_FREEDOM_OF_MOVEMENT,
  SPELL_HOLD_PERSON,
  SPELL_POISON_SPRAY,
  SPELL_SANCTUARY,
  SPELL_SILENCE,
  SPELL_THAUMATURGY,
} from '@/constants/spellIdList'
import {CREATURE_DRIDER} from '@/constants/creatureIdList'
import {faHatWizard} from '@fortawesome/free-solid-svg-icons'
import {PARAM_WIT} from '@/constants/paramList'
import {PC_CLASS_CLERIC} from '@/constants/pcClassIdList'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Драук-заклинатель',
  templateIcon: faHatWizard,
  templateColor: 'blueViolet',

  templateLimitations: {
    include: {
      id: [
        CREATURE_DRIDER,
      ],
    },
  },

  extendPropCollection: {
    description: [{
      header: 'Вариант: Колдовство драуков',
      text: `Драуки, которые когда-то были заклинателями, могут сохранить способность колдовать. Такие драуки обычно обладают более высокой базовой характеристикой (15 или 16). Кроме того, драук получает особенность _Использование заклинаний_. Таким образом, драук, который был божественным заклинателем, может иметь значение Мудрости 16 (+3) и следующую особенность:

**Использование заклинаний.** Драук является заклинателем 7 уровня. Его базовой характеристикой является Мудрость (СЛ испытания от заклинаний 14, +6 к атакам заклинаниями). У него подготовлены следующие заклинания жреца:

* Заговоры (неограниченно): [Чудотворство](SPELL:${SPELL_THAUMATURGY}), [Ядовитые брызги](SPELL:${SPELL_POISON_SPRAY})
* 1 уровень (4 ячейки): [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}), [Порча](SPELL:${SPELL_BANE}), [Убежище](SPELL:${SPELL_SANCTUARY})
* 2 уровень (3 ячейки): [Тишина](SPELL:${SPELL_SILENCE}), [Удержание личности](SPELL:${SPELL_HOLD_PERSON})
* 3 уровень (3 ячейки): [Подсматривание](SPELL:${SPELL_CLAIRVOYANCE}), [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC})
* 4 уровень (2 ячейки): [Предсказание](SPELL:${SPELL_DIVINATION}), [Свобода перемещения](SPELL:${SPELL_FREEDOM_OF_MOVEMENT})`,
      source: {
        id: SOURCE_MM,
        page: 137,
      },
    }],
    source: [{
      id: SOURCE_MM,
      page: 137,
    }],
    params: {
      [PARAM_WIT]: 16,
    },

    spellCast: [{
      spellCasterLevel: 7,
      spellCasterClass: PC_CLASS_CLERIC,
      spellIdList: [
        SPELL_BANE,
        SPELL_CLAIRVOYANCE,
        SPELL_DETECT_MAGIC,
        SPELL_DISPEL_MAGIC,
        SPELL_DIVINATION,
        SPELL_FREEDOM_OF_MOVEMENT,
        SPELL_HOLD_PERSON,
        SPELL_POISON_SPRAY,
        SPELL_SANCTUARY,
        SPELL_SILENCE,
        SPELL_THAUMATURGY,
      ],
      slotCountList: [
        Infinity,
        4,
        3,
        3,
        2,
      ],
    }],
  },
  editPropCollection: {
    name: ({ name }) => `${name}-заклинатель`,
  },
}
