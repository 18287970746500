const {
  CREATURE_THRI_KREEN,
} = require('./../../../creatureIdList')

const {
  SOURCE_ADND2_MM,
  SOURCE_MM,
} = require('./../../../sourceList')

const Thri_Kreen_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Thri_Kreen.jpg')
const Thri_Kreen_MM_img = require('./../../../../images/creatures/mm/thri-kreen.jpeg')

module.exports = [
  {
    src: Thri_Kreen_MM_img,
    creatureId: CREATURE_THRI_KREEN,
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Thri_Kreen_ADND2_img,
    creatureId: CREATURE_THRI_KREEN,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
