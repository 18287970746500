import NORMAL_PARAM from "@/constants/NORMAL_PARAM"
import { crCollection } from '@/constants/crList'
import { PROF_NONE } from '@/constants/proficiencyList'
import { SKILL_PERCEPTION } from '@/constants/skillList'

import calculateSkillBonus from '@/utils/calculateSkillBonus'

const calcPassivePerception = ({ params, cr, skillCollection }) => {
  const {profBonus} = cr && crCollection[cr] || {profBonus: 0}
  const passivePerceptionProfLvl = (skillCollection || {})[SKILL_PERCEPTION] || PROF_NONE
  const passivePerceptionBonus = calculateSkillBonus({
    value: passivePerceptionProfLvl,
    id: SKILL_PERCEPTION,
    profBonus,
    params,
    formatAsString: false,
  })

  return NORMAL_PARAM + passivePerceptionBonus
}

export default calcPassivePerception
