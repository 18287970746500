const {
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./paramList')

module.exports = [
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
]
