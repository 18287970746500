const {
  PC_CLASS_ARTIFICER,
  PC_CLASS_BARD,
  PC_CLASS_CLERIC,
  PC_CLASS_DRUID,
  PC_CLASS_PALADIN,
  PC_CLASS_RANGER,
  PC_CLASS_SORCERER,
  PC_CLASS_WARLOCK,
  PC_CLASS_WIZARD,
} = require('./pcClassIdList')
const {
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./paramList')

module.exports = {
  [PC_CLASS_ARTIFICER]: PARAM_INT,
  [PC_CLASS_BARD]: PARAM_CHA,
  [PC_CLASS_CLERIC]: PARAM_WIT,
  [PC_CLASS_DRUID]: PARAM_WIT,
  [PC_CLASS_PALADIN]: PARAM_CHA,
  [PC_CLASS_RANGER]: PARAM_WIT,
  [PC_CLASS_SORCERER]: PARAM_CHA,
  [PC_CLASS_WARLOCK]: PARAM_CHA,
  [PC_CLASS_WIZARD]: PARAM_INT,
}
