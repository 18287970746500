import generateAttackDescription from '@/utils/generateAttackDescription'
import { attackTypeCollection } from '@/constants/attackTypeList'
import joinText from '@/utils/joinText'

import checkIfRangeOnly from './checkIfRangeOnly'
import checkIfThrown from './checkIfThrown'
import genGearLink from './genGearLink'
import genMagicItemLink from './genMagicItemLink'

const generateAbilityFromAction = (
  {
    attack = null,
    gearId = null,
    description = '',
    magicItemId = null,
    name = '',
    ...rest
  },
  params = null,
  cr = null,
) => {
  let isThrown = false
  let nameFirst = ''
  const nameOtherList = []

  if (name) {
    nameFirst = name
    if (magicItemId) {
      nameOtherList.push(genMagicItemLink(magicItemId))
    }
    if (gearId) {
      nameOtherList.push(genGearLink(gearId))
    }
  } else if (magicItemId) {
    nameFirst = genMagicItemLink(magicItemId)
    if (gearId) {
      nameOtherList.push(genGearLink(gearId))
    }
  } else if (gearId) {
    nameFirst = genGearLink(gearId)
  } else if (attack) {
    nameFirst = attackTypeCollection[attack.type].name
  } else {
    nameFirst = ''
  }

  if (gearId) {
    isThrown = checkIfThrown(gearId)
  }

  const nameToUse = nameOtherList.length
    ? `${nameFirst} (${joinText(nameOtherList)})`
    : nameFirst

  const generateDescription = isThrownParam => `${
    generateAttackDescription({ attack, gearId, params, cr, isThrown: isThrownParam })
  } ${description}`

  const isRangeOnly = checkIfRangeOnly(gearId)

  const descriptionList = [
    (attack || (gearId && params && cr))
      ? generateDescription(isThrown && isRangeOnly)
      : description
  ]

  if (isThrown && gearId && params && cr && !isRangeOnly) {
    descriptionList.push(
      generateDescription(true)
    )
  }

  return descriptionList.reduce(
    (originalAbility, descriptionItem) => originalAbility
      ? [
        originalAbility,
        {
          ...rest,
          name: nameToUse,
          description: descriptionItem,
        },
      ]
      : {
        ...rest,
        name: nameToUse,
        description: descriptionItem,
      },
    null
  )
}

export default generateAbilityFromAction
