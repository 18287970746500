const {
  SOURCE_MTG_CLB,
} = require('./../../sourceList')
const {
  CREATURE_BRAIN_IN_A_JAR,
  CREATURE_CHARDALYN_BERSERKER,
  CREATURE_CHARDALYN_DRAGON,
  CREATURE_COLDLIGHT_WALKER,
  CREATURE_DEMOS_MAGEN,
  CREATURE_FOX,
  CREATURE_FROST_DRUID,
  CREATURE_FROST_GIANT_SKELETON,
  CREATURE_GALVAN_MAGEN,
  CREATURE_HYPNOS_MAGEN,
  CREATURE_GOLIATH_WARRIOR,
  CREATURE_GOLIATH_WEREBEAR,
} = require('./../../creatureIdList')

const Brain_in_a_jar_img_IDROTF = require('./../../../images/creatures/idrotf/brain_in_a_jar.jpg')
const Chardalyn_berserker_img_IDROTF = require('./../../../images/creatures/idrotf/chardalyn_berserker.jpeg')
const Chardalyn_dragon_img_IDROTF = require('./../../../images/creatures/idrotf/chardalyn_dragon.jpeg')
const Coldlight_walker_img_IDROTF = require('./../../../images/creatures/idrotf/coldlight_walker.jpeg')
const Demos_magen_img_IDROTF = require('./../../../images/creatures/idrotf/demos_magen.jpg')
const Fox_img_IDROTF = require('./../../../images/creatures/idrotf/fox.jpg')
const Frost_giant_skeleton_img_IDROTF = require('./../../../images/creatures/idrotf/frost_giant_skeleton.jpg')
const Galvan_magen_img_IDROTF = require('./../../../images/creatures/idrotf/galvan_magen.jpg')
const Hypnos_magen_img_IDROTF = require('./../../../images/creatures/idrotf/hypnos_magen.jpg')
const Goliath_werebear_img_IDROTF = require('./../../../images/creatures/idrotf/goliath_werebear.jpg')
const Frost_druid_img_IDROTF = require('./../../../images/creatures/idrotf/frost_druid.jpeg')

const Chardalyn_dragon_MTG_CLB_img = require('./../../../images/creatures/mtg_clb/chardalyn_dragon_mtg_clb.webp')

const {
  Goliath_VGTM_data,
  Goliath_Warrior_and_Spellcaster_EGTW_data,
  Goliath_Warrior_IDRotF_data,
} = require('./../commonImageCollection')

module.exports = {
  [CREATURE_BRAIN_IN_A_JAR]: Brain_in_a_jar_img_IDROTF,
  [CREATURE_CHARDALYN_BERSERKER]: Chardalyn_berserker_img_IDROTF,
  [CREATURE_CHARDALYN_DRAGON]: [
    Chardalyn_dragon_img_IDROTF,
    {
      src: Chardalyn_dragon_MTG_CLB_img,
      source: SOURCE_MTG_CLB,
    },
  ],
  [CREATURE_COLDLIGHT_WALKER]: Coldlight_walker_img_IDROTF,
  [CREATURE_DEMOS_MAGEN]: Demos_magen_img_IDROTF,
  [CREATURE_FOX]: {
    text: 'Песец',
    src: Fox_img_IDROTF,
  },
  [CREATURE_FROST_DRUID]: Frost_druid_img_IDROTF,
  [CREATURE_FROST_GIANT_SKELETON]: Frost_giant_skeleton_img_IDROTF,
  [CREATURE_GALVAN_MAGEN]: Galvan_magen_img_IDROTF,
  [CREATURE_GOLIATH_WARRIOR]: [
    Goliath_Warrior_IDRotF_data,
    Goliath_Warrior_and_Spellcaster_EGTW_data,
    Goliath_VGTM_data,
  ],
  [CREATURE_GOLIATH_WEREBEAR]: Goliath_werebear_img_IDROTF,
  [CREATURE_HYPNOS_MAGEN]: Hypnos_magen_img_IDROTF,
}
