const {
  CREATURE_KUO_TOA,
} = require('./../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_MGZN_DRGN_281,
  SOURCE_MM,
} = require('./../../../sourceList')

const Kuo_Toa_MM_img = require('./../../../../images/creatures/mm/kuo_toa.jpg')
const Kuo_Toa_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Kuo_Toa.jpg')
const Kuo_Toa_DM_281_img = require('./../../../../images/creatures/dm_281/kuo-toa.jpg')

module.exports = [
  {
    src: Kuo_Toa_MM_img,
    creatureId: CREATURE_KUO_TOA,
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Kuo_Toa_DM_281_img,
    creatureId: CREATURE_KUO_TOA,
    source: {
      id: SOURCE_MGZN_DRGN_281,
    },
  },
  {
    src: Kuo_Toa_ADND2_img,
    creatureId: CREATURE_KUO_TOA,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
