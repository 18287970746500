const {
  CREATURE_CLOUD_GIANT,
  CREATURE_CLOUD_GIANT_SMILING_ONE,
} = require('./../../../creatureIdList')
const {
  SOURCE_MM,
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_SKT,
  SOURCE_VGTM,
} = require('./../../../sourceList')

const {
  Cloud_giant_Castle_VGTM_data,
  Aarakocra_Cloud_Castle_SKT_data,
} = require('./../../commonImageCollection')

const Cloud_Giant_MM_img = require('./../../../../images/creatures/mm/cloud_giant.jpeg')
const Cloud_Giant_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Giant_Cloud.png')
const Cloud_Giant_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/cloud_giant.jpg')
const Cloud_Giant_Smiling_One_VGTM_img = require(`./../../../../images/creatures/vgtm/cloud_giant_smiling_one.jpeg`)
const Countess_Sansuri_SKT_img = require(`./../../../../images/creatures/skt/countess_sansuri.jpeg`)

module.exports = [
  {
    src: Cloud_Giant_MM_img,
    creatureId: CREATURE_CLOUD_GIANT,
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Cloud_Giant_AYAGMnC_img,
    creatureId: CREATURE_CLOUD_GIANT,
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Cloud_Giant_Smiling_One_VGTM_img,
    creatureId: CREATURE_CLOUD_GIANT_SMILING_ONE,
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: Countess_Sansuri_SKT_img,
    text: 'Сансури, графиня облачных великанов',
    source: {
      id: SOURCE_SKT,
    },
  },
  Cloud_giant_Castle_VGTM_data,
  Aarakocra_Cloud_Castle_SKT_data,
  {
    src: Cloud_Giant_ADND2_img,
    creatureId: CREATURE_CLOUD_GIANT,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
