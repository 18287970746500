import React from "react"

import {paramCollection} from "@/constants/paramList"

import StatInfoRow from "@/components/StatInfoRow"

import calcParamBonus from '@/utils/calcParamBonus'
import formatBonus from "@/utils/formatBonus"

export default ({list, params, profBonus}) => (
  <StatInfoRow header='Испытания'>
    {
      list
        .map(
          id => {
            const {shortName} = paramCollection[id]
            const paramBonus = calcParamBonus(params[id])
            const bonus = formatBonus(profBonus + paramBonus)

            return `${shortName} ${bonus}`
          }
        )
        .join(', ')
    }
  </StatInfoRow>
)
