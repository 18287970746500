import {
  CREATURE_DUST_MEPHIT,
  CREATURE_ICE_MEPHIT,
  CREATURE_MAGMA_MEPHIT,
  CREATURE_MUD_MEPHIT,
  CREATURE_SMOKE_MEPHIT,
  CREATURE_STEAM_MEPHIT,
} from '@/constants/creatureIdList'
import {faUserPlus} from '@fortawesome/free-solid-svg-icons'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Мефит-призыватель',
  templateIcon: faUserPlus,
  templateColor: 'purple',

  templateLimitations: {
    include: {
      id: [
        CREATURE_MUD_MEPHIT,
        CREATURE_SMOKE_MEPHIT,
        CREATURE_ICE_MEPHIT,
        CREATURE_MAGMA_MEPHIT,
        CREATURE_STEAM_MEPHIT,
        CREATURE_DUST_MEPHIT,
      ],
    },
  },
  extendPropCollection: {
    source: [{
      id: SOURCE_MM,
      page: 206,
    }],
    description: [
      {
        header: 'Вариант: Призыв мефита',
        text: `У некоторых мефитов может быть действие, позволяющее им призывать других мефитов.

**Призыв мефитов (1/день)**. У мефита есть 25% шанс призвать 1к4 мефита одной с ним разновидности. Призванные мефиты появляются в свободном пространстве в пределах 60 футов от призывателя, действуют как союзники призывателя, и не могут призывать других мефитов. Они остаются в течение 1 минуты, либо до тех пор, пока не убьют их или призвавшего их, или пока призыватель не отпустит их действием.`,
        source: {
          id: SOURCE_MM,
          page: 206,
        },
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name}-призыватель`,
    actionList: ({ actionList }) => [
      ...actionList,
      {
        name: 'Призыв мефитов',
        limit: {
          count: 1,
          period: 'день',
        },
        description: `У мефита есть 25% шанс призвать 1к4 одной с ним разновидности. Призванные мефиты появляются в свободном пространстве в пределах 60 футов от призывателя, действуют как союзники призывателя, и не могут призывать других мефитов. Они остаются в течение 1 минуты, либо до тех пор, пока не убьют их или призвавшего их, или пока призыватель не отпустит их действием.`,
      },
    ],
  },
}
