const {
  CREATURE_AMPHISBAENA,
  CREATURE_ANVILWROUGHT_RAPTOR,
  CREATURE_ARCHON_OF_FALLING_STARS,
  CREATURE_ASHEN_RIDER,
  CREATURE_BRONZE_SABLE,
  CREATURE_BURNISHED_HART,
  CREATURE_GOLD_FORGED_SENTINEL,
  CREATURE_TWO_HEADED_CERBERUS,
  CREATURE_WINGED_BULL,
  CREATURE_WINGED_LION,
} = require('./../../creatureIdList')
const {
  SOURCE_MTG_THS,
  SOURCE_MOOT,
} = require('./../../sourceList')

const Amphisbaena_img_MOOT = require('./../../../images/creatures/moot/amphisbaena.jpeg')
const Anvilwrought_Raptor_img_MOOT = require('./../../../images/creatures/moot/anvilwrought_raptor.jpeg')
const Archon_of_Falling_Stars_img_MOOT = require('./../../../images/creatures/moot/archon_of_falling_stars.jpg')
const Ashen_Rider_img_MOOT = require('./../../../images/creatures/moot/ashen_rider.jpg')
const Bronze_Sable_img_MOOT = require('./../../../images/creatures/moot/bronze_sable.jpeg')
const Burnished_Hart_img_MTG_THS = require('./../../../images/creatures/moot/burnished_hart.jpg')
const Gold_Forged_Sentinel_img_MOOT = require('./../../../images/creatures/moot/gold_forged_sentinel.jpeg')
const Two_Headed_Cerberus_img_MOOT = require('./../../../images/creatures/moot/two_headed_cerberus.jpeg')

module.exports = {
  [CREATURE_AMPHISBAENA]: Amphisbaena_img_MOOT,
  [CREATURE_ANVILWROUGHT_RAPTOR]: Anvilwrought_Raptor_img_MOOT,
  [CREATURE_ARCHON_OF_FALLING_STARS]: Archon_of_Falling_Stars_img_MOOT,
  [CREATURE_ASHEN_RIDER]: Ashen_Rider_img_MOOT,
  [CREATURE_BRONZE_SABLE]: Bronze_Sable_img_MOOT,
  [CREATURE_BURNISHED_HART]: {
    src: Burnished_Hart_img_MTG_THS,
    creatureId: CREATURE_BURNISHED_HART,
    source: {
      id: SOURCE_MTG_THS,
    },
  },
  [CREATURE_TWO_HEADED_CERBERUS]: Two_Headed_Cerberus_img_MOOT,
  [CREATURE_WINGED_BULL]: {
    src: Archon_of_Falling_Stars_img_MOOT,
    text: 'Архонт верхом на крылатом быке',
    source: {
      id: SOURCE_MOOT,
    },
  },
  [CREATURE_WINGED_LION]: {
    src: Ashen_Rider_img_MOOT,
    text: 'Архонт верхом на крылатом льве',
    source: {
      id: SOURCE_MOOT,
    },
  },
  [CREATURE_GOLD_FORGED_SENTINEL]: Gold_Forged_Sentinel_img_MOOT,
}
