import {
  CREATURE_BERSERKER,
  CREATURE_KNIGHT,
  CREATURE_PRIEST,
  CREATURE_DIVINER,
  CREATURE_WAR_PRIEST,
} from '@/constants/creatureIdList'
import {SOURCE_AI} from '@/constants/sourceList'

export default {
  templateName: 'Образцовый фанатик Серебряных Змей',
  templateIcon: 'SilverSliver',
  templateColor: 'black',
  templateLimitations: {
    include: {
      id: [
        CREATURE_BERSERKER,
        CREATURE_KNIGHT,
        CREATURE_PRIEST,
        CREATURE_DIVINER,
        CREATURE_WAR_PRIEST,
      ],
    },
  },

  extendPropCollection: {
    source: [{
      id: SOURCE_AI,
      page: 215,
    }],
    featureList: [
      {
        name: `Бросок маски`,
        limit: {
          count: 1,
          period: 'день',
        },
        description: `★СУЩЕСТВО★ действием бросает свою маску в лицо другому существу в пределах 5 футов, заставляя его пройти испытание Ловкости СЛ 13. При провале, цель оглушается до конца её следующего хода. Существа, у которых нет лица или чьё лицо полностью закрыто маской или шлемом невосприимчивы к этому эффекту.`,
      },
      {
        name: `Правосудие фанатика`,
        limit: {
          count: 1,
          period: 'день',
        },
        description: `★СУЩЕСТВО★ может действием выкрикнуть набор лозунгов и банальностей, повергая всех в ступор. Все слышащие ★его★ существа по ★его★ выбору в пределах 30 футов должны пройти испытание Мудрости СЛ 13, или обездвижиться. Обездвиженное существо может повторять испытание в конце каждого своего хода, прекращая эффект при успехе.`,
      },
    ],
    reactionList: [
      {
        name: `Истинно верующий`,
        limit: {
          count: 1,
          period: 'день',
        },
        description: `Когда ★СУЩЕСТВО★ проваливает испытание от очарования, ★он★ может реакцией перебросить его.`,
      },
    ],
  },

  editPropCollection: {
    name: ({ name }) => `${name}, фанатик Серебряных Змей`,
  },
}
