import React from "react"

import CreatureAc from "@/components/CreatureAc"
import HitPoints from "@/components/HitPoints"

import CreatureSpeed from './../CreatureSpeed'

import './MovementListStyles.less'

const MovementList = ({controlAndMovementList, movementList}) => {
  const list = controlAndMovementList || movementList

  if (list) {
    return list.map(
      ({ac, name, hp, speedList }) => (
        <section className='MovementList'>
          <header className='MovementList_header'>
            {
              controlAndMovementList
                ? 'Управление и передвижение'
                : 'Передвижение'
            }: {name}
          </header>
          <div className='MovementList_content'>
            <CreatureAc armor={ac} />
            <HitPoints hp={hp}/>

            {
              speedList.map(
                ({comment, speed}, i) => (
                  <p
                    className='MovementList_speedItem'
                    key={i}
                  >
                    <b>
                      Скорость
                      {
                        comment
                          ? ` (${comment})`
                          : ''
                      }
                    </b>

                    <CreatureSpeed
                      speed={speed}
                      stringView={true}
                    />
                  </p>
                )
              )
            }
          </div>
        </section>
      )
    )
  }

  return null
}

export default MovementList
