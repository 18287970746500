import React from 'react'
import declint from 'declint-ru'
import PropTypes from 'prop-types'

import ActionList from './../ActionList'
import updateNameAtDescription from '@/utils/updateNameAtDescription'

const LegendaryActionListContainer = (
  {
    name,
    points,
    description,
    list,
    ...rest
  }
) => {
  const variantText = list.length === 1
    ? `используя описанный ниже ${list[0].name}`
    : 'выбирая из представленных ниже вариантов'
  const legActionText = declint(points, ['легендарное действие', 'легендарных действия', 'легендарных действий'])
  const entryText = `★СУЩЕСТВО★ может совершить ${points} ${legActionText}, ${variantText}. За один раз можно использовать только одно легендарное действие, и только в конце хода другого существа. ★СУЩЕСТВО★ восстанавливает использованные легендарные действия в начале своего хода.`
  const postText = description
    ? `\n\n${description}`
    : ``
  const entry = updateNameAtDescription({...rest, description: `${entryText}${postText}`, name })

  return points
    ? (
      <ActionList
        entry={entry}
        header='Легендарные действия'
        points={points}
        name={name}
        list={list}
        {...rest}
      />
    )
    : null
}

LegendaryActionListContainer.defaultProps = {
  list: [],
}

LegendaryActionListContainer.propTypes = {
  name: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  list: PropTypes.array,
}

export default LegendaryActionListContainer
