import React from 'react'
import PropTypes from 'prop-types'
import {
  faHourglass,
  faMagic,
  faRulerCombined,
} from '@fortawesome/free-solid-svg-icons'

import Icon from '@/components/Icon'
import Markdown from '@/components/Markdown'

import Ritual from './../Ritual'

import './CastInfoStyles.less'

const CastInfoComponent = ({durationText, rangeText, castTimeText, isRitual}) => (
  <section className='CastInfo'>
    <header className='CastInfo_header'>Сотворение</header>
    <p className='CastInfo_duration'>
      <Icon
        icon={faHourglass}
        className='CastInfo_icon'
      />
      Длительность: {durationText}
    </p>
    <p className='CastInfo_range'>
      <Icon
        icon={faRulerCombined}
        className='CastInfo_icon'
      />
      Дистанция: {rangeText}
    </p>
    <p className='CastInfo_castTime'>
      <Icon
        icon={faMagic}
        className='CastInfo_icon'
      />
        Время сотворения: <Ritual isRitual={isRitual}/>
      <Markdown>
        {castTimeText}
      </Markdown>
    </p>
  </section>
)

CastInfoComponent.propTypes = {
  durationText: PropTypes.string.isRequired,
  rangeText: PropTypes.string.isRequired,
  castTimeText: PropTypes.string.isRequired,
  isRitual: PropTypes.bool,
}

CastInfoComponent.defaultProps = {
  isRitual: false,
}

export default CastInfoComponent
