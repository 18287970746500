const {
  SOURCE_ADND2_MM,
  SOURCE_DMG,
  SOURCE_DND3_5_DoF,
  SOURCE_DND3_5_DTBoD,
  SOURCE_FTD,
  SOURCE_MM,
  SOURCE_MTG_CLB,
} = require('./../../../sourceList')

const {
  Council_of_Metallic_Dragons_FTD_data,
  Metallic_Dragons_ROT_data,
  Torm_DND3_FnP_data,
  Ancient_Dragons_Hoard_data,
} = require('./../../commonImageCollection')

const Gold_Dragon_ADND2 = require('./../../../../images/creatures/adnd2_mm/Gold_Dragon.jpg')
const Gold_Dragon_Aerosclughpalar_DND3_5_DoF_img = require('./../../../../images/creatures/dnd3_5_dof/gold-dragon-aerosclughpalar.jpg')
const Gold_Dragon_Ascendant_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/gold-dragon-ascendant.jpg')
const Gold_Dragon_Hidecarved_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/gold-dragon-hidecarved.png')
const Gold_Dragon_MM_img = require('./../../../../images/creatures/mm/gold_dragon.jpg')
const Gold_Dragon_Wyrmling_With_Paladin_DND3_5_DTBoD_img = require('./../../../../images/creatures/dnd3_5_dtbod/gold-dragon-wyrmling-with-paladin.jpg')
const GoldDragonTreasure_DMG_img = require('./../../../../images/creatures/dmg/gold_dragon_treasure.jpg')
const Gold_Dragon_Fly_FTD_img = require('./../../../../images/creatures/ftd/gold_dragon_fly.png')
const Gold_Dragon_Ancient_MTG_CLB_img = require('./../../../../images/creatures/mtg_clb/dragon_gold_action_mtg_clb.png')

module.exports = [
  {
    src: Gold_Dragon_MM_img,
    text: 'Золотой дракон',
    source: {
      id: SOURCE_MM,
    },
  },
  Torm_DND3_FnP_data,
  {
    src: GoldDragonTreasure_DMG_img,
    text: 'Золотой дракон в своей сокровищнице',
    source: {
      id: SOURCE_DMG,
    },
  },
  {
    src: Gold_Dragon_Fly_FTD_img,
    text: 'Золотой дракон в полёте',
    source: {
      id: SOURCE_FTD,
    },
  },
  Metallic_Dragons_ROT_data,
  {
    src: Gold_Dragon_Aerosclughpalar_DND3_5_DoF_img,
    text: 'Золотой дракон Аэросклугпалар обновляет Высокий лес',
    source: {
      id: SOURCE_DND3_5_DoF,
    },
  },
  {
    src: Gold_Dragon_Hidecarved_DND3_5_DTBoD_img,
    text: 'Золотой дракон с вырезанными на шкуре магическими отметинами',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Gold_Dragon_Ascendant_DND3_5_DTBoD_img,
    text: 'Господствующий золотой дракон',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Gold_Dragon_Wyrmling_With_Paladin_DND3_5_DTBoD_img,
    text: 'Вирмлинг золотого дракона сотрудничает с паладином',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
  {
    src: Gold_Dragon_ADND2,
    text: 'Золотой дракон',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  {
    src: Gold_Dragon_Ancient_MTG_CLB_img,
    text: 'Древний золотой дракон',
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
  Council_of_Metallic_Dragons_FTD_data,
  Ancient_Dragons_Hoard_data,
]
