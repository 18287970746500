const {
  CREATURE_HILL_GIANT,
  CREATURE_MOUTH_OF_GROLANTOR,
} = require('./../../../creatureIdList')
const {
  SOURCE_MM,
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_SKT,
  SOURCE_VGTM,
} = require('./../../../sourceList')

const Chief_Guh_SKT_img = require(`./../../../../images/creatures/skt/chief_guh.jpeg`)
const Hill_Giant_ADND2_img = require('./../../../../images/creatures/adnd2_mm/Giant_Hill.jpg')
const Hill_giant_AYAGMnC_img = require('./../../../../images/creatures/ayagmc/hill_giant.jpg')
const Hill_Giant_MM_img = require('./../../../../images/creatures/mm/hill_giant.jpeg')
const Hill_Giant_Thief_SKT_img = require(`./../../../../images/creatures/skt/hill_giant_thief.jpg`)
const Hill_giant_VGTM_img = require('./../../../../images/creatures/vgtm/hill_giant.png')
const Mouth_of_Grolantor_VGTM_img = require(`./../../../../images/creatures/vgtm/mouth_of_grolantor.jpeg`)

module.exports = [
  {
    src: Hill_Giant_MM_img,
    creatureId: CREATURE_HILL_GIANT,
    source: {
      id: SOURCE_MM,
    },
  },
  {
    src: Hill_giant_AYAGMnC_img,
    creatureId: CREATURE_HILL_GIANT,
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  {
    src: Hill_giant_VGTM_img,
    creatureId: CREATURE_HILL_GIANT,
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: Mouth_of_Grolantor_VGTM_img,
    creatureId: CREATURE_MOUTH_OF_GROLANTOR,
    source: {
      id: SOURCE_VGTM,
    },
  },
  {
    src: Hill_Giant_Thief_SKT_img,
    text: 'Холмовой великан крадёт еду',
    source: {
      id: SOURCE_SKT,
    },
  },
  {
    src: Chief_Guh_SKT_img,
    text: 'Гух, вождь холмовых великанов',
    source: {
      id: SOURCE_SKT,
    },
  },
  {
    src: Hill_Giant_ADND2_img,
    creatureId: CREATURE_HILL_GIANT,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
]
