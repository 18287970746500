import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import RenderList from "@/components/RenderList"
import StatInfoRow from "@/components/StatInfoRow";

import "./CreatureSpeedStyles.less";

const CreatureSpeedComponent = ({ list, stringView = false }) => {
  const Wrapper = stringView
    ? React.Fragment
    : StatInfoRow

  return (
    <Wrapper header={stringView ? '' : 'Скорость'}>
      <RenderList
        list={list}
        renderer={({text, title, id}) => (
          <span
            key={id}
            className={classNames(
              'CreatureSpeed_item',
              {
                'CreatureSpeed_item-string': stringView,
              }
            )}
            title={title}
          >
              {text}
            </span>
        )}
      />
    </Wrapper>
  )

  // return (
  //   <Wrapper header={stringView ? '' : 'Скорость'}>
  //     {
  //       list.map(
  //         ({text, title, id}) => (
  //           <span
  //             key={id}
  //             className={classNames(
  //               'CreatureSpeed_item',
  //               {
  //                 'CreatureSpeed_item-string': stringView,
  //               }
  //             )}
  //             title={title}
  //           >
  //           {text}
  //         </span>
  //         )
  //       )
  //     }
  //   </Wrapper>
  // )
}

CreatureSpeedComponent.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
}

export default CreatureSpeedComponent
