const {
  CREATURE_MAGMA_MEPHIT,
  CREATURE_MAGMIN,
  CREATURE_MANES,
  CREATURE_MANTICORE,
  CREATURE_MARID,
  CREATURE_MARILITH,
  CREATURE_MASTIFF,
  CREATURE_MEDUSA,
  CREATURE_MERFOLK,
  CREATURE_MERROW,
  CREATURE_MEZZOLOTH,
  CREATURE_MIMIC,
  CREATURE_MIND_FLAYER,
  CREATURE_MINOTAUR,
  CREATURE_MINOTAUR_SKELETON,
  CREATURE_MONODRONE,
  CREATURE_MUD_MEPHIT,
  CREATURE_MUMMY,
  CREATURE_MUMMY_LORD,
  CREATURE_MYCONID_ADULT,
  CREATURE_MYCONID_SOVEREIGN,
  CREATURE_MYCONID_SPROUT,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_DMG,
  SOURCE_TCoE,
} = require('./../../../../sourceList')

const {
  MimicImgList,
} = require('./../../../commonImageListCollection')

const {
  Ceremorphosis_VGTM_data,
  Elder_brain_traveling_VGTM_data,
  Fire_Giants_Attack_SKT_data,
  MindFlayer_ElderBrain_AYAGMnC_data,
} = require('./../../../commonImageCollection')

const magma_mephit = require('./../../../../../images/creatures/mm/magma_mephit.jpeg')
const magmin = require('./../../../../../images/creatures/mm/magmin.jpeg')
const manes = require('./../../../../../images/creatures/mm/manes.jpeg')
const manticore = require('./../../../../../images/creatures/mm/manticore.jpeg')
const marid = require('./../../../../../images/creatures/mm/marid.jpeg')
const marilith = require('./../../../../../images/creatures/mm/marilith.jpeg')
const mastiff = require('./../../../../../images/creatures/mm/mastiff.jpeg')
const medusa = require('./../../../../../images/creatures/mm/medusa.jpeg')
const merfolk = require('./../../../../../images/creatures/mm/merfolk.jpeg')
const merrow = require('./../../../../../images/creatures/mm/merrow.jpg')
const mezzoloth = require('./../../../../../images/creatures/mm/mezzoloth.png')
const mind_flayer = require('./../../../../../images/creatures/mm/mind_flayer.jpeg')
const minotaur = require('./../../../../../images/creatures/mm/minotaur.jpeg')
const minotaur_skeleton = require('./../../../../../images/creatures/mm/minotaur_skeleton.jpeg')
const monodrone = require('./../../../../../images/creatures/mm/monodrone.jpeg')
const mud_mephit = require('./../../../../../images/creatures/mm/mud_mephit.jpg')
const mummy_lord = require('./../../../../../images/creatures/mm/mummy_lord.jpeg')
const myconid_adult = require('./../../../../../images/creatures/mm/myconid_adult.jpg')
const myconid_sovereign = require('./../../../../../images/creatures/mm/myconid_sovereign.jpg')
const myconid_sprout = require('./../../../../../images/creatures/mm/myconid_sprout.jpg')
const myconids = require('./../../../../../images/creatures/mm/myconids.jpg')

const Illithid_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Illithid.jpg')
const Manticore_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Manticore.jpg')
const Marilith_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Marilith.jpg')
const Medusa_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Medusa.jpg')
const Mephit_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Mephit.jpg')
const Minotaur_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Minotaur.jpg')
const Mummy_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Mummy.jpg')
const Mummy_Greater_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Mummy_Greater.jpg')
const Myconid_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Myconid.jpg')

const Merrow_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/merrow.jpg')
const Myconid_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/myconid.jpg')

const Merfolk_img_TCoE = require('./../../../../../images/creatures/tcoe/merfolk-1.png')

const Medusa_DMG_img = require('./../../../../../images/creatures/dmg/medusa.png')

const Mephit_data = {
  src: Mephit_ADND2,
  text: 'Мефиты',
  source: {
    id: SOURCE_ADND2_MM,
  },
}

module.exports = {
  [CREATURE_MAGMA_MEPHIT]: [
    magma_mephit,
    Mephit_data,
  ],
  [CREATURE_MAGMIN]: [
    magmin,
    Fire_Giants_Attack_SKT_data,
  ],
  [CREATURE_MANES]: manes,
  [CREATURE_MANTICORE]: [
    manticore,
    {
      src: Manticore_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_MARID]: marid,
  [CREATURE_MARILITH]: [
    marilith,
    {
      src: Marilith_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_MASTIFF]: mastiff,
  [CREATURE_MEDUSA]: [
    medusa,
    {
      src: Medusa_DMG_img,
      text: 'Медуза использует окаменяющий взгляд',
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Medusa_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_MERFOLK]: [
    merfolk,
    {
      src: Merfolk_img_TCoE,
      text: 'Правитель мерфолков',
      source: {
        id: SOURCE_TCoE,
      },
    },
  ],
  [CREATURE_MERROW]: [
    merrow,
    {
      src: Merrow_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_MEZZOLOTH]: mezzoloth,
  [CREATURE_MIMIC]: MimicImgList,
  [CREATURE_MIND_FLAYER]: [
    mind_flayer,
    {
      src: Illithid_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    MindFlayer_ElderBrain_AYAGMnC_data,
    Elder_brain_traveling_VGTM_data,
    Ceremorphosis_VGTM_data,
  ],
  [CREATURE_MINOTAUR]: [
    minotaur,
    {
      src: Minotaur_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_MINOTAUR_SKELETON]: minotaur_skeleton,
  [CREATURE_MONODRONE]: monodrone,
  [CREATURE_MUD_MEPHIT]: [
    mud_mephit,
    Mephit_data,
  ],
  [CREATURE_MUMMY]: {
    src: Mummy_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_MUMMY_LORD]: [
    mummy_lord,
    {
      src: Mummy_Greater_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_MYCONID_ADULT]: [
    myconid_adult,
    {
      src: myconids,
      text: 'Микониды',
    },
    {
      src: Myconid_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Myconid_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_MYCONID_SOVEREIGN]: [
    myconid_sovereign,
    {
      src: myconids,
      text: 'Микониды',
    },
  ],
  [CREATURE_MYCONID_SPROUT]: [
    myconid_sprout,
    {
      src: myconids,
      text: 'Микониды',
    },
  ],
}
