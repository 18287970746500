import {
  PARAM_CHA,
  PARAM_INT,
} from '@/constants/paramList'
import generateTextLinks from '@/utils/generateTextLinks'
import {CREATURE_TREANT} from '@/constants/creatureIdList'
import {faTree} from '@fortawesome/free-solid-svg-icons'
import {GENDER_MIDDLE} from '@/constants/genderList'
import {SOURCE_MM} from '@/constants/sourceList'

export default {
  templateName: 'Оживлённое дерево',
  templateIcon: faTree,
  templateColor: 'darkGreen',
  templateLimitations: {
    include: {
      id: [
        CREATURE_TREANT,
      ],
    },
  },

  name: 'Оживлённое дерево',

  languageList: null,
  genderId: GENDER_MIDDLE,

  extendPropCollection: {
    params: {
      [PARAM_INT]: 1,
      [PARAM_CHA]: 1,
    },
    description: [
      {
        header: `Оживление деревьев`,
        text: generateTextLinks(`[Трент](CREATURE:${CREATURE_TREANT}) магическим образом оживляет до двух деревьев, которые видит в пределах 60 футов от себя. Деревья обладают статистикой трента, за исключением того, что у них Интеллект 1 и Харизма 1, они не могут говорить, и у них только один вариант действия — Удар. Оживлённые деревья действуют как союзники трента. Дерево перестаёт быть живым через 1 день, когда умирает, когда трент умирает или отдаляется от него более чем на 120 футов, а также когда трент бонусным действием снова делает его неживым. Став снова неживым, дерево, при возможности, пускает корни.`),
        source: {
          id: SOURCE_MM,
          page: 283,
        },
      },
    ],
  },

  editPropCollection: {
    actionList: ({ actionList }) => actionList.filter(
      ({ name }) => !['Мультиатака', 'Камень', 'Оживление деревьев'].includes(name),
    ),
  },
}
