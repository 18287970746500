const {
  CREATURE_BADGER,
  CREATURE_BALOR,
  CREATURE_BANDIT,
  CREATURE_BANDIT_CAPTAIN,
  CREATURE_BANSHEE,
  CREATURE_BARBED_DEVIL,
  CREATURE_BARLGURA,
  CREATURE_BASILISK,
  CREATURE_BAT,
  CREATURE_BEARDED_DEVIL,
  CREATURE_BEHIR,
  CREATURE_BEHOLDER,
  CREATURE_BEHOLDER_ZOMBIE,
  CREATURE_BLACK_PUDDING,
  CREATURE_BLINK_DOG,
  CREATURE_BOAR,
  CREATURE_BONE_DEVIL,
  CREATURE_BONE_NAGA_GUARDIAN,
  CREATURE_BONE_NAGA_SPIRIT,
  CREATURE_BROWN_BEAR,
  CREATURE_BUGBEAR,
  CREATURE_BUGBEAR_CHIEF,
  CREATURE_BULETTE,
  CREATURE_BULLYWUG,
} = require('./../../../../creatureIdList')
const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_AYAGWnW,
  SOURCE_DMG,
  SOURCE_MTG_AFR,
  SOURCE_MTG_CLB,
  SOURCE_PotA,
  SOURCE_TCoE,
  SOURCE_VGTM,
  SOURCE_XGTE,
} = require('./../../../../sourceList')

const {
  BeholderkinList,
} = require('./../../../commonImageListCollection')

const {
  Bugbear_ADND2_data,
  Bugbear_AYAGMnC_data,
  Goblins_bugbears_AYAGWnW_data,
} = require('./../../../commonImageCollection')

const badger = require('./../../../../../images/creatures/mm/badger.jpeg')
const bandit_captain = require('./../../../../../images/creatures/mm/bandit_captain.jpg')
const banshee = require('./../../../../../images/creatures/mm/banshee.jpg')
const barbed_devil = require('./../../../../../images/creatures/mm/barbed_devil.jpeg')
const barlgura = require('./../../../../../images/creatures/mm/barlgura.jpg')
const basilisk = require('./../../../../../images/creatures/mm/basilisk.jpg')
const bat = require('./../../../../../images/creatures/mm/bat.jpeg')
const bearded_devil = require('./../../../../../images/creatures/mm/bearded_devil.jpg')
const behir = require('./../../../../../images/creatures/mm/behir.jpg')
const beholder = require('./../../../../../images/creatures/mm/beholder.jpg')
const beholder_zombie = require('./../../../../../images/creatures/mm/beholder_zombie.jpg')
const black_pudding = require('./../../../../../images/creatures/mm/black_pudding.jpeg')
const blink_dog = require('./../../../../../images/creatures/mm/blink_dog.jpeg')
const bone_devil = require('./../../../../../images/creatures/mm/bone_devil.jpg')
const bone_naga = require('./../../../../../images/creatures/mm/bone_naga.jpg')
const bugbear = require('./../../../../../images/creatures/mm/bugbear.jpg')
const bulette = require('./../../../../../images/creatures/mm/bulette.jpeg')
const bullywug = require('./../../../../../images/creatures/mm/bullywug.jpg')

const Balor_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Balor.jpg')
const Bandit_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Bandit.jpg')
const Banshee_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Banshee.jpg')
const Basilisk_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Basilisk.jpg')
const Bat_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Bat.jpg')
const Bear_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Bear.jpg')
const Behir_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Behir.jpg')
const Beholder_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Beholder.jpg')
const Boar_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Boar.jpg')
const Bulette_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Bulette.jpg')
const Bullywug_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Bullywug.jpg')
const Pudding_Black_ADND2 = require('./../../../../../images/creatures/adnd2_mm/Pudding_Black.jpg')

const Beholder_AYAGWW = require('./../../../../../images/creatures/ayagww/beholder.jpg')

const Banshee_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/banshee.jpg')
const Beholder_2_img_AYAGMnC = require('./../../../../../images/creatures/ayagmc/beholder_2.jpg')

const Beholder_img_XGTE = require('./../../../../../images/creatures/xgte/beholder.jpg')

const Bullywug_img_TCoE = require('./../../../../../images/creatures/tcoe/bullywug.png')

const Beholder_2_VGTM_img = require('./../../../../../images/creatures/vgtm/beholder-2.jpg')
const Beholder_VGTM_img = require('./../../../../../images/creatures/vgtm/beholder.webp')

const BeholderXanathar_DMG_img = require('./../../../../../images/creatures/dmg/beholder-xanathar.webp')
const BoneDevil_DMG_img = require('./../../../../../images/creatures/dmg/bone_devil.jpg')
const Bulette_DMG_img = require('./../../../../../images/creatures/dmg/bulette.png')

const Beholder_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/beholder-mtg_afr.jpg')
const Blink_dog_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/blink-dog-mtg-afr.jpg')
const Xanathar_MTG_AFR_img = require('./../../../../../images/creatures/mtg_afr/xanathar-mtg_afr.jpg')

const Bulette_PotA_img = require('./../../../../../images/creatures/pota/bulette_pota.webp')

const Balor_MTG_CLB_img = require('./../../../../../images/creatures/mtg_clb/balor_mtg_clb.jpg')

const Bugbear_data = [
  {
    src: bugbear,
    creatureId: CREATURE_BUGBEAR,
  },
  Bugbear_AYAGMnC_data,
  Bugbear_ADND2_data,
  Goblins_bugbears_AYAGWnW_data,
]

module.exports = {
  [CREATURE_BADGER]: badger,
  [CREATURE_BALOR]: [
    {
      src: Balor_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Balor_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [CREATURE_BANDIT]: [
    {
      src: bandit_captain,
      creatureId: CREATURE_BANDIT_CAPTAIN,
    },
    {
      src: Bandit_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_BANDIT_CAPTAIN]: bandit_captain,
  [CREATURE_BANSHEE]: [
    banshee,
    {
      src: Banshee_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Banshee_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
  ],
  [CREATURE_BARBED_DEVIL]: barbed_devil,
  [CREATURE_BARLGURA]: barlgura,
  [CREATURE_BASILISK]: [
    basilisk,
    {
      src: Basilisk_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_BAT]: [
    bat,
    {
      src: Bat_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_BEARDED_DEVIL]: bearded_devil,
  [CREATURE_BEHIR]: [
    behir,
    {
      src: Behir_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_BEHOLDER]: [
    beholder,
    {
      src: Beholder_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    ...BeholderkinList,
    {
      src: Beholder_AYAGWW,
      source: {
        id: SOURCE_AYAGWnW,
      },
    },
    {
      src: Beholder_2_img_AYAGMnC,
      source: {
        id: SOURCE_AYAGMnC,
      },
    },
    {
      src: BeholderXanathar_DMG_img,
      text: 'Бехолдер Занатар',
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Beholder_img_XGTE,
      text: 'Злобоглаз Занатар в своём логове под Глубоководьем',
      source: {
        id: SOURCE_XGTE,
      },
    },
    {
      src: Beholder_VGTM_img,
      source: {
        id: SOURCE_VGTM,
      },
    },
    {
      src: Beholder_2_VGTM_img,
      source: {
        id: SOURCE_VGTM,
      },
    },
    {
      src: Beholder_MTG_AFR_img,
      source: {
        id: SOURCE_MTG_AFR,
      },
    },
  ],
  [CREATURE_BEHOLDER_ZOMBIE]: [
    beholder_zombie,
    ...BeholderkinList,
  ],
  [CREATURE_BLACK_PUDDING]: [
    black_pudding,
    {
      src: Pudding_Black_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_BLINK_DOG]: [
    blink_dog,
    {
      src: Blink_dog_MTG_AFR_img,
      source: {
        id: SOURCE_MTG_AFR,
      },
    }
  ],
  [CREATURE_BOAR]: {
    src: Boar_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_BONE_DEVIL]: [
    bone_devil,
    {
      src: BoneDevil_DMG_img,
      source: {
        id: SOURCE_DMG,
      },
    },
  ],
  [CREATURE_BONE_NAGA_GUARDIAN]: {
    src: bone_naga,
    text: 'Костяная нага',
  },
  [CREATURE_BONE_NAGA_SPIRIT]: {
    src: bone_naga,
    text: 'Костяная нага',
  },
  [CREATURE_BROWN_BEAR]: {
    src: Bear_ADND2,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  [CREATURE_BUGBEAR]: Bugbear_data,
  [CREATURE_BUGBEAR_CHIEF]: Bugbear_data,
  [CREATURE_BULETTE]: [
    bulette,
    {
      src: Bulette_DMG_img,
      source: {
        id: SOURCE_DMG,
      },
    },
    {
      src: Bulette_PotA_img,
      source: {
        id: SOURCE_PotA,
      },
    },
    {
      src: Bulette_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
  ],
  [CREATURE_BULLYWUG]: [
    bullywug,
    {
      src: Bullywug_ADND2,
      source: {
        id: SOURCE_ADND2_MM,
      },
    },
    {
      src: Bullywug_img_TCoE,
      text: 'Герой сражается с жаболюдом, охраняющим злодейского главу гильдии пекарей',
      source: {
        id: SOURCE_TCoE,
      },
    },
  ],
}
